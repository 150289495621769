import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter, NgZone } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Session } from '../../common/session';
import { GlobalConstants } from '../../common/global-constants';
import { BookingService } from '../../services/booking.service';
import { SupplierService } from '../../services/supplier.service';
import { ReportsService } from '../../services/reports.service';
import { ReceiptService } from '../../services/receipt.service';
import { PaymentService } from '../../services/payment.service';
import { UserService } from '../../services/user.service';
import { AppComponent } from '../../app.component';
import { HostListener } from '@angular/core';
import { environment } from './../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ElementService } from 'src/app/services/element.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AsynchRequests } from 'src/app/common/asynch-requests';
import { Documents } from 'src/app/common/documents';
import { MatPaginator } from '@angular/material/paginator';

export interface Passenger {
  id: number | null,
  title: string;
  firstName: string;
  lastName: string;
  telNo: string;
  email: string;
  postcode: string;
  county: string;
  isLead: boolean;
}

@Component({
  selector: 'app-booking-external',
  templateUrl: './booking-external.component.html',
  styleUrls: ['./booking-external.component.css', '../../../app/app.component.fellohStyles.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', marginTop: '-1.25px' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('inOutAnimationSupplement', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(-50%, -50%); scale(0.5)' }),
        animate(
          '500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 1, transform: 'translate(-50%, -50%); scale(1)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({ opacity: 0, transform: 'translate(-50%, -50%); scale(0.5)' })
        ),
      ]),
    ])
  ]
})
export class BookingExternalComponent implements OnInit {
  static myExternal: any;
  // Imported variables from outside
  constants = new GlobalConstants();
  titles = GlobalConstants.titles;
  sfcPricing = GlobalConstants.sfcPricing;
  safiPricing = GlobalConstants.safiPricing;
  borderauxCodes = GlobalConstants.borderauxCodes;
  standaloneCustoms = GlobalConstants.standaloneCustoms;
  innerWidth = AppComponent.myapp.innerWidth;
  memberLive = AppComponent.myapp.memberLive;
  showExtReference = AppComponent.myapp.showExternalRef;

  // Variables controlling user access
  previewOn = false;
  bookingAccess = false;
  listSearchBox = false;
  editToggle = false;
  failedUpload = false;
  pageLoaded = false;
  supplementLoaded = true;
  sfcExists = false; // Determines whether the SFC already exists in the booking...
  safiExists = false; // Determines whether the SAFI already exists in the booking...
  viewFromMatch = ''; // Originally this will always be empty - unless set from parent component
  canMatchBanking = false; // Only TBD bankstmnts will be able to 'link' themselves to receipt / payment
  matchCodeMismatch = false; // In case SinGS staff tries to auto-match, but they typed in booking belonging to a different member..
  bookingDateDisable = true; // Whether booking date can be changed - it's possible to do so on the same day
  editToggleDisable = false; // We will disable edit toggle button for bookings which are created long time ago..
  supplementDisable = false; // We will disable adding / amending supplements if dept date is tomorrow or in the past..
  dateRangeBlock = true; // Used in the booking list filters..
  branchDetails: any = {}; // This is where we'll dump branch info
  branchList: any = []; // This is where we will have branch list for group users

  // Upload variables
  bookingCsvDataUpload: any = []; // This is where we'll dump CSV data
  uploadedBookingsData: any = []; // Converted upload csv holding booking data
  uploadedElementsData: any = []; // Converted upload csv holidng element data
  bookingViewNo: any = 0; // Used for left/right arrows in Add Booking (useful for upload preview)
  bookingHashData: any = [{ BookingRefSource: '', bookingRefSinGS: 'none', bookingDate: '',
  deptDate: '', returnDate: '', bookingStatus: 'booking', leadPXName: '', px: 0, depositPaid: false,
  balanceDueDate: '', passengers: [] }];
  // Upload variables - smart [payments]
  paymentsCsvData: any = [];
  uploadDetails: any = { validated: false, uploaded: false };
  uploadMessages: any = [];

  // Table data and table columns below
  bookingsData: any = new MatTableDataSource<any>();
  extElementData: any = new MatTableDataSource<any>();
  extPassengersData: any = new MatTableDataSource<any>();
  receiptsData: any = new MatTableDataSource<any>();
  paymentsData: any = new MatTableDataSource<any>();
  newElementData: any = new MatTableDataSource<any>();
  displayedColumns = ['bookingReference', 'externalReference', 'bookingDate', 'departureDate', 'customerName'];
  extElementColumns = ['supplierName', 'ref', 'gross', 'net', 'commission', 'vat', 'discount', 'deposit', 'issueDate'];
  passengerNewColumns = ['name', 'telephone', 'email', 'addElement'];
  passengerColumns = ['removeButton', 'fullName', 'telNo', 'email', 'postcode', 'county', 'leadPassenger'];
  receiptColumns = ['receiptDate', 'totalCharge', 'merchantFee', 'creditValue', 'paymentMethod', 'reference', 'options'];
  paymentColumns = ['paymentDate', 'paymentAmount', 'paymentMethod', 'reference', 'supplier', 'paymentStatus', 'options'];
  paymentColumnsFooter = ['paymentDate', 'paymentAmount', 'paymentMethod', 'reference', 'supplier', 'options'];
  monthlyRetColumns = ['bookingReferenceMonthly', 'leadName', 'paxNo', 'paxNoTatolPackage', 'paxNoTatolFlOnly', 'bookedTS'];

  // Passengers search variables
  passengers: any = [new MatTableDataSource<any>()];
  customerData: any = new MatTableDataSource<any>();
  monthlyReturnData: any = new MatTableDataSource<any>();
  newPaxRadioValue = 'existing';

  // This is to do with booking's current view - payments? general?
  bookViewOptions = ['Supplier Details', 'Passengers', 'Payments In', 'Payments Out'];
  bookSelOptionView = 0;

  // Custom mat paginator varaibles
  pageSizeOptions = [10, 25, 50, 100];
  pageSize = 25; firstRes = 1; lastRes = 25;
  orderColumn = 'bookingReference'; orderHow = 'DESC';

  // Individual external booking data below
  extBookingData: any = {}; // Booking data goes here
  customerBalanceDue: any = 0; // Holds the value customer still needs to pay for the booking
  dueToSuppliers: any = 0; // Holds the value this booking still needs to pay to all suppliers
  suppliersTotalSums: any = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0 };
  suppliersList: any = []; // Dump supplier list from API here
  suppliersListALL: any = []; // This will hold ALL suppleirs (not just grouped..)
  supplementList: any = []; // Dump supplement list from API here
  supplierFilteredData: any = []; // Fileterd supplier list goes here..
  filterString: any = ''; // Filter string, viola
  flightsOnly: any = [];
  selectedSupplement: any = {}; // Currently selected supplement in UI - used in Costs tab
  oldSafi = false; // Determine whether we're displaying old SAFI calcualtions or not [1st of March 2024]
  newSfcInfo: any = {}; // This is the variable which will be used to display booking's sfc price when adding a supplement
  newSafiInfo: any = {}; // This is the variable which will be used to display booking's safi price when adding a supplement
  newReceipt: any = {}; // Variable used for adding new receipt
  selectedSupplier: any = {}; // Variable used when adding new payment
  supplierExisting: any = {}; // Variable used when amending payment <-> supplier's linkage
  newPayment: any = {}; // Variable used for adding new payment

  // Monthly returns variables
  monthlyReportStats: any = { bookings: 0, paxNo: 0, paxNoTAtolFlOnly: 0, paxNoTAtolPackage: 0 };
  monthlyReturnSummary: any = {};
  motnhlyReturnBranch: any = {};
  meetsATOLcriteria: any = {};

  // Other variables
  currentRequest: any = {};
  bookingListRange: any = { dateType: '', startDate: '', endDate: '' };
  errorMessage: any = '';
  successMessage: any = '';
  isSupplementStr: any = '';
  chosenSupplier: any = {};
  paxToEdit: any = {};
  // Date variables
  currentDate: any = '';
  monthlyMonth: number | undefined;
  monthlyYear: number | undefined;
  monthlyPeriod: any = '';

  // Variables taken from the child component
  @Input() bookingReference: any;
  @Input() bookingOperation: any;
  @Input() userTypeOut: any;

  // ViewChilds below used for setting elements visible/not visible
  @ViewChild('paginatorReturns') paginatorReturns!: MatPaginator;
  @ViewChild('myDialog') statusDialog!: TemplateRef<any>;
  @ViewChild('passengerDialog') passengerDialog!: TemplateRef<any>;
  @ViewChild('editPaxDialog') editPaxDialog!: TemplateRef<any>;
  @ViewChild('addSupplDialog') addSupplDialog!: TemplateRef<any>;
  @ViewChild('requestDialog') requestDialog!: TemplateRef<any>;
  @ViewChild('masterNameDialog') masterNameDialog!: TemplateRef<any>;
  @ViewChild('tableNew') tableNew!: MatTable<any>;
  @ViewChild('tableOld') tableOld!: MatTable<any>;

  // Variable going out of the child component
  @Output() selectedRec = new EventEmitter<any>();

  // Stuff needed for the 'expandable rows' to work
  expandedElement: any; // First expandable row (used everywhere)
  isExpansionDetailRow = (i: number, row: object) => row.hasOwnProperty('detailRow');

  constructor(private ngxCsvParser: NgxCsvParser, public dialog: MatDialog, private userService: UserService,
              private bookingService: BookingService, private reportsService: ReportsService,
              private receiptService: ReceiptService, private paymentService: PaymentService,
              private supplierService: SupplierService, private elementService: ElementService,
              private customerService: CustomerService, private ngZone: NgZone) { }

  ngOnInit(): void {
    // Allows other components (child components) to read & set variables in this component
    BookingExternalComponent.myExternal = this;

    // Set the branch details below (whether it's from the group or not..)
    if (Session.mySession.getUsersGroup().length > 0) {
      this.branchList = Session.mySession.getUsersGroup();
      this.branchDetails = this.branchList.find((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode);
    }
    else {
      this.branchList = [Session.mySession.getBranch()];
      this.branchDetails = this.branchList.find((branch: any) => branch.tradeCode === Session.mySession.getUser().tradeCode);
    }

    // Request variable which will pull booking details below
    this.currentRequest = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation, tradeCode: this.branchDetails.tradeCode,
      token: Session.mySession.get('user').token, firstRes: this.firstRes.toString(), lastRes: this.lastRes.toString(), bookRefOnly: 'false', statusFilter: 'all',
      orderColumn: this.orderColumn, orderHow: this.orderHow, leadNameFilter: ''
    };

    if (this.bookingOperation === 'View') {
      // We are pulling supplements & suppliers only after the booking is loaded
      // This is because in case of an admin, we need to use booking's company & trade code
      // To pull appropriate supplements & suppliers (otherwise we could pull ttng supplements for tta booking)
      //this.openSupplDialog('ts').then(() => {
        this.openExternalBooking(this.bookingReference);
        if (this.userTypeOut === 'wcManager') { this.setMonthlyDates(); this.getMonthlyReturns(false); }
      //});
    } else {
      // Non-View operation is performed only by 'normal' users
      // That's why we can pull suppliers & supplements straight away
      //this.openSupplDialog('ts',null).then(() => {
        this.loadBookingList();
        this.setMonthlyDates();
        this.getMonthlyReturns(false);
      //});
    }
  }

  loadBookingList(): void {
    // User decided to search for bookings within a date range
    if (!this.dateRangeBlock) {
      this.currentRequest.dateType = this.bookingListRange.dateType;
      this.currentRequest.startDate = this.constants.convertDateMoment(this.bookingListRange.startDate);
      this.currentRequest.endDate = this.constants.convertDateMoment(this.bookingListRange.endDate);
    } else {
      delete this.currentRequest.dateType;
      delete this.currentRequest.startDate;
      delete this.currentRequest.endDate;
    }

    if (!this.dateRangeBlock && (!this.currentRequest.startDate?.trim() || !this.currentRequest.endDate?.trim())) {
      this.sendMessageToDialog('', 'One of the dates is in the wrong format', '', '');
    } else {
      this.currentRequest.firstRes = this.firstRes.toString(); // Needed to display on the page
      this.currentRequest.lastRes = this.lastRes.toString(); // Needed to display on the page
  
      // Apply agentEmail filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        this.currentRequest.agentEmail = Session.mySession.getUser().email;
      }
  
      this.pageLoaded = false;
      this.bookingService.getBookingList(this.currentRequest).then((output: any) => {
        if (output.status === 'OK') {
          // Status OK meaning everything went good - assign data to data variable
          this.bookingsData.data = output.data; this.pageLoaded = true;
        } else {
          // Something went wrong. Display error message to the user
          this.bookingsData.data = [];
          this.sendMessageToDialog('', output.status, '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2103S)', error, this.currentRequest);
      });
    }
  }

  getS3template(fileName: any): void {
    const downloadRequest = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
      tradeCode: Session.mySession.getUser().tradeCode, pathType: 'templates', fileName: fileName,
      token: Session.mySession.get('user').token
    };

    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    // Call downloadS3file method
    this.pageLoaded = false;
    this.reportsService.downloadS3file(downloadRequest).then((output: any) => {
      if (output.status === 'OK') {
        try {
          // convert base64 to raw binary data held in a string
          // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
          const byteString = atob(output.fileContent);

          // write the bytes of the string to an ArrayBuffer
          const ab = new ArrayBuffer(byteString.length);

          // create a view into the buffer
          const ia = new Uint8Array(ab);

          // set the bytes of the buffer to the correct values
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          // Output BLOB needs to be transformed into an excel application file
          const data = new Blob([ab], { type });
          saveAs(data, fileName); // Call this function which opens browser's 'Save As..' window
          this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2117S)', error, downloadRequest);
    });
  }

  uploadBorderauFile(fileInput: any): void {
    const allFiles: any = Array.from(fileInput.target.files); // Get the file from target array list

    if (allFiles.length > 1) {
      this.sendMessageToDialog('', 'You can only upload one file per request', '', '');
    } else if (allFiles.some((singleFile: any) => singleFile.size > 4194304)) {
      this.sendMessageToDialog('', 'The file is too big. Maximum size is 4MB per file', '', '');
    } else if (allFiles.some((singleFile: any) => /['"]/.test(singleFile.name))) {
      this.sendMessageToDialog('', 'The file name contains invalid characters (\' or \"). Please rename the file and try again.', '', '');
    } else {
      const uploadRequest: any = {
        company: this.currentRequest.company, operation: this.currentRequest.operation, tradeCode: this.currentRequest.tradeCode,
        pathType: 'bordereau', token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.reportsService.uploadS3files(uploadRequest, allFiles).then((output: any) => {
        if (output.status !== 'OK') { this.sendMessageToDialog('', output.status, '', ''); }
        else {
          uploadRequest.fileName = allFiles[0].name;
          this.bookingService.runBordereauUpload(uploadRequest).then((output: any) => {
            // We then add our jobID to the 'watchlist', which will be called to check whether the job has finished
            if (output.jobID) {
              AsynchRequests.myAsynchReq.addJobToWatch(this.currentRequest, output.jobID);
              this.sendMessageToDialog(`Booking upload has started, please wait..`, '', '', '');
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2118S)', error, uploadRequest);
          });
        }
        
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2119S)', error, uploadRequest);
      });
    }
  }

  getCSVtemplate(template: any): void {
    // Create request variable and use it to get the tempalte file
    const request = { name: template, type: 'csv', directory: 'temple', token: Session.mySession.get('user').token };
    this.pageLoaded = false;

    this.reportsService.getFile(request).then((blob: any) => {
      // Output BLOB needs to be transformed into an excel application file
      const data = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      saveAs(data, template); // Call this function which opens browser's 'Save As..' window
      this.pageLoaded = true;
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2104S)', error, request);
    });
  }

  validateBookingFile(fileInputEvent: any): void {
    this.pageLoaded = false;
    // We use the ngxCsvParser here to read the data from CSV files. We want headers to false
    // beacuse that will 'translate' the data into the csv format we need at the back..
    this.ngxCsvParser.parse(fileInputEvent.srcElement.files[0], { header: false, delimiter: ',' })
      .pipe().subscribe((csvData: any) => {
        // Once we've got the data, we need to create a request variable which will hold it along with validation stuff
        const request = {
          company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
          tradeCode: this.currentRequest.tradeCode, data: csvData, format: 'csv',
          token: Session.mySession.get('user').token
        };

        this.bookingService.validateExtBookings(request).then((result: any) => {
          if (result.status === 'OK' && result.errorCount === 0) {
            this.resetUploadData(); // We need to clean up first
            this.bookingCsvDataUpload = csvData; // Assign CSV data to global variable which will be used later on
            this.producePreviewBookings(); // Change the mode and create booking & element variables..
          } else if (result.status === 'OK' && result.errorCount > 0) {
            this.sendMessageToDialog('', result.description, '', ''); // Validation was not successfull
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2104S)', error, request);
        });
      }, (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'BETA version error: ' + error, '', '');
      });
  }

  uploadBookingData(bookingData: any, format: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pageLoaded = false; this.failedUpload = false;
      // Create a request variable which will use all validation properties and takes csv customer data from global variable
      const request: any = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token,
        userID: Session.mySession.getUser().id, data: bookingData, format
      };

      this.bookingService.uploadExtBookings(request).then((result: any) => {
        if (result.status === 'OK' && result.errorCount === 0) {
          if (this.bookingOperation === 'Add') {
            this.openExternalBooking(result.bookingRefList[0]);
            this.sendMessageToDialog('Booking created successfully', '', '', '');
            this.resetUploadData(); // Reset upload variables..
            resolve(result.bookingRefList[0]);
          } else if (this.bookingOperation === 'View') {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.openExternalBooking(result.bookingRefList[0]);
              this.sendMessageToDialog('Booking updated successfully', '', '', '');
              resolve('OK');
            });
          } else {
            this.sendMessageToDialog('Upload completed successfully', '', '', '');
            this.resetUploadData(); // Reset upload variables..
            resolve('OK');
          }
        } else if (result.status === 'OK' && result.errorCount > 0) {
          this.sendMessageToDialog('', result.description, '', ''); // Validation was not successfull
          resolve('ERR');
        } else {
          this.sendMessageToDialog('', result.status, '', ''); // Validation was not successfull
          resolve('ERR');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2105S)', error, request);
        resolve('ERR');
      });
    });
  }

  validatePaymentFile(fileInputEvent: any, paymentType: any): void {
    this.pageLoaded = false;
    // We use the ngxCsvParser here to read the data from CSV files. We want headers to false
    // beacuse that will 'translate' the data into the csv format we need at the back..
    this.ngxCsvParser.parse(fileInputEvent.srcElement.files[0], { header: false, delimiter: ',' })
      .pipe().subscribe((csvData: any) => {
        // Once we've got the data, we need to create a request variable which will hold it along with validation stuff
        const request = {
          company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
          tradeCode: this.currentRequest.tradeCode, data: csvData, paymentType,
          token: Session.mySession.get('user').token
        };

        this.bookingService.validateCSVpayments(request).then((result: any) => {
          if (result.status === 'OK') {
            this.paymentsCsvData = csvData; // Assign CSV data to global variable which will be used later on
            this.uploadMessages = []; // Erase all upload messages
            this.uploadDetails = { validated: true, uploaded: false, paymentType };
          } else {
            this.paymentsCsvData = []; this.uploadDetails = { validated: false, uploaded: false }; // Erase data and the file name here..
            this.uploadMessages = result.status; // Assign upload messages (containing errors)
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2109S)', error, request);
        });
      }, (error: NgxCSVParserError) => {
        this.sendMessageToDialog('', 'BETA version error: ' + error, '', '');
      });
  }

  uploadPaymentFile(): void {
    this.pageLoaded = false;
    // Create a request variable which will use all validation properties and takes csv customer data from global variable
    const request = {
      company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
      tradeCode: this.currentRequest.tradeCode, token: Session.mySession.get('user').token,
      data: this.paymentsCsvData, paymentType: this.uploadDetails.paymentType
    };

    this.uploadDetails.validated = false;
    this.bookingService.uploadCSVpayments(request).then((result: any) => {
      this.paymentsCsvData = []; // Erase the csv data
      if (result.status === 'OK') {
        this.uploadMessages = []; // Erase all upload messages
        this.uploadDetails.uploaded = true; // Mark as 'complete'
      } else {
        this.uploadMessages = result.status; // Show error messages
      }
    }).catch((error: any) => {
      this.paymentsCsvData = []; // Erase the csv data
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2110S)', error, request);
    });
  }

  reloadBooking(bookingRef: any): void {
    Session.mySession.resetTimersOnBookingValues().then((res: any) => {
      this.openExternalBooking(bookingRef);
    });
  }

  openExternalBooking(bookingRef: any): void {
    // Assign booking ref. to global var. and switch view
    this.bookingReference = bookingRef; this.switchView('View'); this.pageLoaded = false;
    this.loadExternalBooking(bookingRef).then((res: any) => {
      if (res === 'OK') {
        this.matchCodeMismatch = false; // We're resetting mismatch here
        this.fetchPassengers().then(() => {
          this.recalculateReceipts().then(() => {
            this.recalculatePayments().then(() => {
              this.pageLoaded = true; this.bookingAccess = true;
            });
          });
        });
      } else { this.pageLoaded = true; this.bookingAccess = false; }
    });
  }

  loadExternalBooking(bookingRef: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Create request object which will be used to load extenral booking data
      const request: any = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: Session.mySession.getUser().tradeCode, bookingReference: bookingRef,
        token: Session.mySession.get('user').token
      };

      // Apply agentEmail filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') { request.agentEmail = Session.mySession.getUser().email; }

      // Check if the booking is in the session variable, It not / expired - reload by calling API
      if (Session.mySession.getCurrentBookingsValue(bookingRef, 'skeleton').expiryTime === 'EXPIRED') {
        this.bookingService.getBookingFull(request).then((skeleton: any) => {
          if (skeleton.status === 'OK' && skeleton.bookingData.status !== 'no such booking') {
            Session.mySession.setCurrentBookingsValue(bookingRef, 'skeleton', skeleton); // Set bookings values in session
            this.recalculateBookingsValuesLogic(skeleton).then(() => {
              // Set the branch details below (whether it's from the group or not..)
              if (Session.mySession.getUsersGroup().length > 0) {
                this.branchDetails = this.branchList.find((branch: any) => branch.tradeCode === this.extBookingData.tradeCode);
              } else { this.branchDetails = Session.mySession.getBranch(); }
              // Work out whether this booking meets ATOL criteria below
              if (this.userTypeOut === 'wcManager' || this.userTypeOut === 'wcMember') { this.currentRequest.tradeCode = this.branchDetails.tradeCode; }
              this.meetsATOLcriteria = Documents.myDocuments.meetsATOLcriteria(this.extElementData.data, this.extBookingData.custPrice, false);
              this.getSFCcoverPrice();
              this.getFlightsOnly();
              resolve('OK'); // Return booking OK, recalculate booking values
              this.tableOld?.renderRows(); // Fix the expandable row
            });
          } else if (skeleton.status === 'OK' && skeleton.bookingData.status === 'no such booking') {
            resolve('No Booking'); // Page loaded but no access for the user..
          } else if (skeleton.status !== 'OK') {
            resolve('No Access'); // Page laoded but the booking wasn't there!
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2106S)', error, request);
          resolve('Error'); // Return 'error' status back
        });
      } else {
        // Use session's variable to recaulcate booking's values below. Return 'OK'
        this.recalculateBookingsValuesLogic(Session.mySession.getCurrentBookingsValue(bookingRef, 'skeleton').value).then(() => {
          // Work out whether this booking meets ATOL criteria below
          this.meetsATOLcriteria = Documents.myDocuments.meetsATOLcriteria(this.extElementData.data, this.extBookingData.custPrice, false);
          this.getSFCcoverPrice();
          this.getFlightsOnly();
          resolve('OK'); // Return booking OK, recalculate booking values
          this.tableOld?.renderRows(); // Fix the expandable row
        });
      }
    });
  }

  recalculateBookingsValuesLogic(skeleton: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.extBookingData = skeleton.bookingData; // Assign booking data from skeleton to the global variable
      this.extElementData.data = skeleton.elementData; // Assign element data from skeleton to the global variable

      this.customerBalanceDue = this.extBookingData.custPrice - this.extBookingData.totalReceiptedCharged; // Calculate the customer balance here
      this.dueToSuppliers = this.extBookingData.net - this.extBookingData.totalSuppPayments; // Calcualte the supplier balance here

      // Reset all global variables below
      this.suppliersTotalSums = { grossCost: 0, netCost: 0, commission: 0, tax: 0, discount: 0, totalDeposit: 0 };

      // Make sure we load supplements and suppliers first..
      const arrayNames = ['accoms', 'carhires', 'carparks', 'cruises', 'flights',  'miscs', 'supplements', 'packages', 'transfers', 'attractions', 'trains'];
      this.openSupplDialog('ts', skeleton.bookingData).then(() => {
        this.extElementData.data.forEach((costing: any) => {
          costing.detailRow = true; // Make the row expandable..
          // Add up all suppliers' values below (gross, net etc..)
          this.suppliersTotalSums.grossCost = this.suppliersTotalSums.grossCost += parseFloat(costing.gross);
          this.suppliersTotalSums.netCost = this.suppliersTotalSums.netCost += parseFloat(costing.net);
          this.suppliersTotalSums.commission = this.suppliersTotalSums.commission += parseFloat(costing.commission);
          this.suppliersTotalSums.tax = this.suppliersTotalSums.tax += parseFloat(costing.tax);
          this.suppliersTotalSums.discount = this.suppliersTotalSums.discount += parseFloat(costing.discount);
          this.suppliersTotalSums.totalDeposit = this.suppliersTotalSums.totalDeposit += parseFloat(costing.depositAmount || 0);

          costing.createTS = costing.createTS || new Date().toISOString().split('T')[0];
          costing.depositAmount = costing.depositAmount || 0;

          // We need to check if the supplement allows custom costings
          if (costing.elementType === 'supplement' && costing.supplements.length > 0) {
            const supplement = this.supplementList.find((item: any) => item.supName === costing.supplierName);
            costing.autoPricing = supplement.autoPricing;
            costing.isSupplement = 'yes';
          } else {
            // Mark (or not) each costing whether it's under SFC coverage or not
            const supplier = this.suppliersListALL.find((obj: any) => {
              return ( obj.id?.toString() === costing.supplierID?.toString() || obj.supplierNameM === costing.supplierName);
            });
            costing.isUnderSFC = supplier?.isUnderSFC; // Assign isUnderSFC to our costing
            costing.isUnderSafi = supplier?.underSafi === 'yes'; // Assign underSAFI to our costing
            costing.isSupplement = 'no';
          }
          // We are assigning minimum costs value for each subElement below
          // When editing each individual costs, the script will validate whether users can amend them (after 14 days they need to contact Operations to reduce them)
          arrayNames.forEach(arrayName => {
            if (costing[arrayName].length > 0) {
                costing[arrayName].forEach((element: any) => {
                    // Assign a new value which will be a 'minimum' value
                    element.minGross = element.grossCost;
                    element.minNet = element.netCost;
                    element.minComm = element.commission;
                    element.minTax = element.tax;
                    element.minDiscount = element.discount;
                });
            }
          });
        });

        // If the booking was created before 2024-03-01 then mark it with oldSafi calculations..
        if (this.extBookingData.createTS.length > 10 && (new Date(this.extBookingData.createTS) < new Date('2024-03-01'))) {
          this.oldSafi = true;
        }

        // If the booking was created TODAY, make it possible to change its booking date. Otherwise - sorry!
        if (this.extBookingData.bookedTS && this.extBookingData.bookedTS.length > 10 &&
          this.constants.convertDateNotMoment(new Date()) === this.extBookingData.bookedTS.substring(0, 10)) {
          this.bookingDateDisable = false;
        }

        // Check if createTS was 14 days ago or more to block EDIT MODE
        const createTSDate = new Date(this.extBookingData.bookedTS);
        const fourteenDaysAgo = new Date();
        fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

        if (createTSDate.getTime() <= fourteenDaysAgo.getTime()) { this.editToggle = false; this.editToggleDisable = true; }
        else { this.editToggleDisable = false; }

        // Check if departureDate was 14 days ago or more to block EDIT MODE
        const departureDate = new Date(this.extBookingData.deptDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset time to the start of today
        const twoDaysFromNow = new Date(today);
        twoDaysFromNow.setDate(today.getDate() + 2);

        if (departureDate >= twoDaysFromNow) { this.supplementDisable = false; }
        else { this.supplementDisable = true; }

        resolve('OK'); // Finish everything here..
      });
    });
  }

  fetchPassengers(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = {
        company: this.extBookingData.company, operation: this.extBookingData.operation,
        tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
        token: Session.mySession.get('user').token
      };
      
      this.pageLoaded = false;
      this.customerService.getBookCustList(request).then((bookCustList: any) => {
        const passengerData: any = []; // Create a new array variable which will hold passengers data
      
        if (bookCustList.status !== 'OK') {
          this.sendMessageToDialog('', bookCustList.status, '', ''); // Print error message
          resolve('');
        } else {
          bookCustList.bookCustList.forEach((element: any) => {
            element.customer.emailDone = element.customer.email; // Assign email address
            element.customer.bookCustID = element.bookCustID; // Assign bookCust.id value..
            element.customer.isLead = element.isLead; // Assign isLead to the passenger
            element.customer.receipted = element.receipted // Assign receipted value to the passenger
            element.customer.active = element.active; // Assign active to the passenger
            element.customer.showOnDocs = element.showOnDocs; // Assign active to the passenger
            passengerData.push(element.customer); // Finally, push the customer to passengersData array list
          });
      
          this.extPassengersData.data = passengerData; // Array fiull of passengers from above will be used to set global passengers table data variable
          resolve('');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2118S)', error, request);
        resolve('');
      });
    });
  }

  getPassenger(form: NgForm): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else {
      form.value.token = Session.mySession.get('user').token; // Assign users token here..
      form.value.tradeCode = this.extBookingData.tradeCode || this.currentRequest.tradeCode; // Assign trade code here..
      form.value.telNo = ''; // Assign telNo (which we don't use anymore..)
      
      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }

      // Call getCustomer API and assign its output to 'customers'
      this.pageLoaded = false;
      this.customerService.getCustomer(form.value).then((passengers: any) => {
        if (passengers.status === 'OK') {
          if (this.bookingOperation === 'View') {
            this.disableAndAssignCustomerData(passengers, this.extPassengersData); // Used to hide 'Add Passenger' buttons
          } else {
            this.disableAndAssignCustomerData(passengers, this.passengers[this.bookingViewNo]); // Used to hide 'Add Passenger' buttons
          }
          this.pageLoaded = true;
        } else {
          this.sendMessageToDialog('', passengers.status, '', ''); // Print error message here..
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0808S)', error, form.value);
      });
    }
  }

  addPassenger(passenger: any, source: any) {
    if (this.bookingOperation === 'View') {
      if (source === 'table') {
        this.addSelectedPassenger(passenger, true).then((res: any) => {
          if (res === 'OK') { this.sendMessageToDialog(passenger.firstName + ' ' + passenger.lastName + ' has been added to the booking', '', '', ''); }
        });
      }
      else {
        this.createPassenger(passenger, true).then((res: any) => {
          if (res === 'OK') { this.sendMessageToDialog(passenger.value.firstName + ' ' + passenger.value.lastName + ' has been added to the booking', '', '', ''); }
        });
      }
    } else {
      const currentData = this.passengers[this.bookingViewNo].data;

      if (source === 'table') {
        // If passengers data is empty, then automatically set the first passengers as lead
        if (this.passengers[this.bookingViewNo].data.length === 0) { passenger.isLead = true; }
        currentData.push(passenger);
        this.passengers[this.bookingViewNo].data = currentData;
        this.disableAndAssignCustomerData(this.customerData, currentData);
        this.sendMessageToDialog(passenger.firstName + ' ' + passenger.lastName + ' has been added to the booking', '', '', '');
      } else {
        this.createPassenger(passenger, false).then((res: any) => {
          if (res.status === 'OK') {
            const pax: Passenger = {
              id: res.id,
              title: passenger.value.title,
              firstName: passenger.value.firstName,
              lastName: passenger.value.lastName,
              telNo: passenger.value.telNo,
              email: passenger.value.email,
              postcode: passenger.value.postcode,
              county: passenger.value.county,
              isLead: false
            };
            // If passengers data is empty, then automatically set the first passengers as lead
            if (this.passengers[this.bookingViewNo].data.length === 0) { pax.isLead = true; }
            currentData.push(pax);
            this.passengers[this.bookingViewNo].data = currentData;
            this.sendMessageToDialog(passenger.value.firstName + ' ' + passenger.value.lastName + ' has been added to the booking', '', '', '');
          }
        });
      }
    }
  }

  createPassenger(form: NgForm, addFlag: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', ''); resolve('ERR');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', ''); resolve('ERR');
    } else {
      form.value.tradeCode = this.extBookingData.tradeCode || this.currentRequest.tradeCode; // Assign trade code here
      form.value.token = Session.mySession.get('user').token; // Assign the token to form.value here
      form.value.onlyUnique = false; // Ignore uniqueness requirement

      // Apply exclusiveToId filter if user is not permitted to see others' bookings
      if (Session.mySession.getUser().othersBookingAccess === 'no') {
        form.value.exclusiveToId = Session.mySession.getUser().id.toString();
      }
      
      this.customerService.createCustomer(form.value).then((createOutput: any) => {
        if (createOutput.status === 'OK' && !addFlag) {
          resolve(createOutput);
        } else if (createOutput.status === 'OK' && addFlag) {
          form.value.id = createOutput.id; // Assign customer ID over here..
          this.addSelectedPassenger(form.value, true).then(() => {
            resolve('OK');
          });
        } else {
          this.sendMessageToDialog('', createOutput.status, '', ''); resolve('ERR');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0811S)', error, form.value);
        resolve('ERR');
      });
    }
    });
  }

  addSelectedPassenger(customer: any, reloadBooking: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: any = {
        company: this.extBookingData.company, operation: this.extBookingData.operation,
        tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
        customerID: customer.id, token: Session.mySession.get('user').token
      };

      if (this.extPassengersData.data.length === 0) { request.isLead = 'true'; }
      else { request.isLead = 'false'; } // It's not the first passenger so no need to lead it

      this.pageLoaded = false;
      //if (this.extPassengersData.data.length === 0 && customer.email === '') {
      //  this.sendMessageToDialog('', 'Lead passenger must have an email address', '', ''); resolve('');
      //} else {
        this.customerService.createBookCustLink(request).then((bookCustOutput: any) => {
          if (bookCustOutput.status === 'OK' && reloadBooking) {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
                this.fetchPassengers().then(() => { // Refresh passenger values..
                  this.disableAndAssignCustomerData(this.customerData, this.extPassengersData); // Re-calculate and hide 'Add Passenger' buttons
                  resolve('OK');
                });
              });
            });
          } else if (bookCustOutput.status === 'OK' && !reloadBooking) {
            resolve('OK');
          } else {
            this.sendMessageToDialog('', bookCustOutput.status, '', ''); // Print error message here..
            resolve('');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2119S)', error, request);
          resolve('');
        });
      //}
    });
  }

  async addGhostPassengers(form: NgForm): Promise<void> {
    const numberOfPassengers: number = parseInt(form.value.ghostPaxNo);
    const customer = { id: null };

    if (this.bookingOperation === 'Add') {
      const currentData = this.passengers[this.bookingViewNo].data;

      for (let i = 0; i < numberOfPassengers; i++) {
        try {
          const pax: Passenger = {
            id: null, title: '', firstName: '', lastName: '',
            telNo: '', email: '', postcode: '', county: '',
            isLead: false
          };
          currentData.push(pax);
          this.passengers[this.bookingViewNo].data = currentData;
          // Add success message..
          if (i === numberOfPassengers - 1) {
            this.sendMessageToDialog(numberOfPassengers + ' Unknown Passenger(s) have been added to the booking', '', '', '');
          }
        } catch (error) {
          this.sendMessageToDialog('', 'There was an error when adding unknown passenger', error, customer);
        }
      }
    } else {
      if (numberOfPassengers > 50) {
        this.sendMessageToDialog('', 'You can add a maximum of 50 unknown passengers at once', '', '');
      } else {
        for (let i = 0; i < numberOfPassengers; i++) {
          try {
            await this.addSelectedPassenger(customer, false); this.pageLoaded = true;
          } catch (error) {
            this.sendMessageToDialog('', 'There was an error when adding unknown passenger', error, customer);
          }
        }
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
            this.fetchPassengers().then(() => { // Refresh passenger values..
              this.disableAndAssignCustomerData(this.customerData, this.extPassengersData); // Re-calculate and hide 'Add Passenger' buttons
              this.sendMessageToDialog(numberOfPassengers + ' Unknown Passengers have been added to the booking', '', '', '');
            });
          });
        });
      }
    }
  }

  setLeadPassenger(passenger: any): void {
    //if (passenger.emailDone === '') {
    //  this.fetchPassengers().then(() => { this.sendMessageToDialog('', 'Lead passenger must have an email address', '', ''); });
    //} else {
      const request: any = {
        company: this.extBookingData.company, operation: this.extBookingData.operation,
        tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
        customerID: passenger.id, isLead: true, token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.customerService.updateIsLead(request).then((isLeadResult: any) => {
        if (isLeadResult.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
              this.fetchPassengers().then(() => {
                this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Print success message here
              });
            });
          });
        } else {
          this.sendMessageToDialog('', isLeadResult.status, '', ''); // Print out error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2120S)', error, passenger);
      });
    //}
  }

  changePaxActive(passenger: any, active: any): void {
    const request = {
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      bookingReference: this.extBookingData.bookingReference, customerID: passenger.id, active: active, bookCustID: passenger.bookCustID,
      token: Session.mySession.get('user').token
    }

    this.customerService.changePaxActive(request).then((result: any) => {
      Session.mySession.resetTimersOnBookingValues().then((res: any) => {
        this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
          this.fetchPassengers().then(() => { // Refresh passenger values..
            this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Display success message here..
          });
        });
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0846S)', error, request);
    });
  }

  radioChangeCustomer(event: any): void {
    // Override the global radio value in new pax section
    this.newPaxRadioValue = event;
    // No matter what have been chosen, customer data array is cleared up every time change is made
    this.customerData.data = [];
  }

  removeLivePassenger(passenger: any): void {
    const paxName = passenger.id !== null ? passenger.firstName + ' ' + passenger.lastName : 'Unknown Passenger';

    if (confirm('Are you sure you want to remove ' + paxName + '?')) {

        const request = {
          company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
          bookingReference: this.extBookingData.bookingReference, customerID: passenger.id, bookCustID: passenger.bookCustID,
          token: Session.mySession.get('user').token
        }

        this.pageLoaded = false;
        this.customerService.removeBookCustLink(request).then((result: any) => {
          if (result.status === 'OK') {
            Session.mySession.resetTimersOnBookingValues().then((res: any) => {
              this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
                this.fetchPassengers().then(() => { // Refresh passenger values..
                  this.sendMessageToDialog('Passenger has been removed', '', '', ''); // Display success message here..
                });
              });
            });
          } else {
            this.sendMessageToDialog('', result.status, '', ''); // Display error message here
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0807S)', error, request);
        });
    }
  }

  editPassenger(form: NgForm, passenger: any): void {
    // Validate characters entered in the form
    if (this.constants.validateFormCharacters(form) !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + this.constants.validateFormCharacters(form), '', '');
    } else if (this.constants.validateVariableRegex(form.value.customerNotes, 'variable') !== true) {
      this.sendMessageToDialog('', 'Invalid characters in ' + form.value.customerNotes, '', '');
    } else {
      // ID exists within the passenger object <-- that's good
      // It means we are editing an actual passenger, not a GHOST PAX
      if (passenger.id) {
        if (passenger.isLead && passenger.email === '') {
          passenger.email = passenger.emailDone; // Set the email back to what it was
          this.sendMessageToDialog('', 'Lead passenger must have an email address', '', '');
        } else {
          passenger.token = Session.mySession.get('user').token; // Assign the token to the object here!

          this.pageLoaded = false; // Send API to amend existing passenger / customer here..
          this.customerService.updateCustomer(passenger).then((updateResult: any) => {
            if (updateResult.status === 'OK') {
              Session.mySession.resetTimersOnBookingValues().then((res: any) => {
                this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
                  this.fetchPassengers().then(() => {
                    this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Print success message here
                  });
                });
              });
            } else {
              this.sendMessageToDialog('', updateResult.status, '', ''); // Print out error message if customer not updated
            }
          }).catch((error: any) => {
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2123S)', error, passenger);
          });
        }
      // ID does not exist in the passenger object <-- not great, but good!
      // It means we'll need to create a new passenger first, and then update bookCust link [append customerID + bookingRef + company + operation + bookingReference]
      } else {
        // Apply exclusiveToId filter if user is not permitted to see others' bookings
        if (Session.mySession.getUser().othersBookingAccess === 'no') { form.value.exclusiveToId = Session.mySession.getUser().id.toString(); }
        form.value.tradeCode = this.extBookingData.tradeCode || this.currentRequest.tradeCode; // Assign trade code here
        form.value.token = Session.mySession.get('user').token; // Assign the token to form.value here
        
        this.pageLoaded = false; // Send API to create passenger here..
        this.customerService.createCustomer(form.value).then((createOutput: any) => {
   
          if (createOutput.status === 'OK') {
            const custLinkRequest = {
              company: this.extBookingData.company || Session.mySession.getUser().company,
              operation: this.extBookingData.operation || Session.mySession.getUser().operation,
              tradeCode: this.extBookingData.tradeCode || this.currentRequest.tradeCode,
              bookingReference: this.extBookingData.bookingReference,
              customerID: createOutput.id, bookCustID: passenger.bookCustID,
              token: Session.mySession.get('user').token
            }
            // Send API to update bookCustLink over here..
            this.customerService.updateGhostBookCust(custLinkRequest).then((ghostUpdate: any) => {
              if (ghostUpdate.status === 'OK') {
                Session.mySession.resetTimersOnBookingValues().then((res: any) => {
                  this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
                    this.fetchPassengers().then(() => {
                      this.sendMessageToDialog('Passenger has been updated', '', '', ''); // Print success message here
                    });
                  });
                });
              } else {
                this.sendMessageToDialog('', ghostUpdate.status, '', ''); // Print error message here..
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2124S)', error, custLinkRequest);
            });
          } else {
            this.sendMessageToDialog('', createOutput.status, '', '');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2125S)', error, form.value);
        });
      }
    }
  }

  // Below method checks for any possible duplicates in the variable
  disableAndAssignCustomerData(customers: any, dataToFilter: any): void {
    if (this.bookingOperation === 'Add' && dataToFilter.data) { dataToFilter = dataToFilter.data; }
    else if (this.bookingOperation === 'View' && dataToFilter.data) { dataToFilter = dataToFilter.data; }

    customers.data = customers.data.filter((pax: any) => {
      if (pax && dataToFilter.some((pass: any) => pass.id === pax.id)) {
        pax.disabled = true;
        return true; // Keep the element in the array
      } else if (pax) {
        pax.disabled = false;
        return true; // Keep the element in the array
      } else {
        return false; // Remove the element from the array
      }
    });
    this.customerData.data = customers.data; // Assign customers data over here..
  }

  recalculateReceipts(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = {
        company: this.extBookingData.company, operation: this.extBookingData.operation,
        tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
        token: Session.mySession.get('user').token
      };

      this.resetNewPayments(); // Reset new payments / receipts object

      // Check if the booking is in the session variable, It not / expired - reload by calling API
      if (Session.mySession.getCurrentBookingsValue(this.extBookingData.bookingReference, 'receipts').expiryTime === 'EXPIRED') {
        this.receiptService.getReceiptList(request).then((receipts: any) => {
          Session.mySession.setCurrentBookingsValue(this.extBookingData.bookingReference, 'receipts', receipts); // Set bookings values in session
          this.recalculateReceiptsLogic(receipts); // Recalculate receipt values
          resolve(''); // And return back
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2101S)', error, request);
          resolve('');
        });
      } else {
        this.recalculateReceiptsLogic(Session.mySession.getCurrentBookingsValue(this.extBookingData.bookingReference, 'receipts').value); // Recalculate receipt values
        resolve(''); // And return back
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.extBookingData.bookingReference + ' - recalculateReceipts() failed');
    });
  }

  recalculateReceiptsLogic(receipts: any): void {
    if (receipts.status !== 'OK') {
      this.sendMessageToDialog('', receipts.status, '', ''); // Print error message
    } else {
      this.receiptsData.data = receipts.receiptList.filter((receipt: any) => receipt.receiptCategory === 'receipt');
    }
  }

  recalculatePayments(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = {
        company: this.extBookingData.company, operation: this.extBookingData.operation,
        tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
        token: Session.mySession.get('user').token
      };

      this.resetNewPayments(); // Reset new payments / receipts object

      // Check if the booking is in the session variable, It not / expired - reload by calling API
      if (Session.mySession.getCurrentBookingsValue(this.extBookingData.bookingReference, 'payments').expiryTime === 'EXPIRED') {
        this.paymentService.getPaymentList(request).then((payments: any) => {
          Session.mySession.setCurrentBookingsValue(this.extBookingData.bookingReference, 'payments', payments); // Set bookings values in session
          this.recalculatePaymentsLogic(payments); // Recalculate payment values
          resolve(''); // And return back
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2102S)', error, request);
          resolve('');
        });
      } else {
        this.recalculatePaymentsLogic(Session.mySession.getCurrentBookingsValue(this.extBookingData.bookingReference, 'payments').value); // Recualcate payment values
        resolve(''); // And return back
      }

    }).catch((err: any) => {
      this.sendMessageToDialog('', '', err, 'Booking ' + this.extBookingData.bookingReference + ' - recalculatePayments() failed');
    });
  }

  recalculatePaymentsLogic(payments: any): void {
    if (payments.status !== 'OK') {
      this.sendMessageToDialog('', payments.status, '', ''); // Print error message
    } else {
      this.paymentsData.data = payments.paymentList.filter((payment: any) => payment.paymentCategory !== 'cancelled' && payment.paymentCategory !== 'mistake');

      this.paymentsData.data.forEach((payment: any) => {
        // Assign supplier name to the object (if possible)
        if (payment.supplierID != null) {
          payment.supplierName = this.suppliersListALL.find((supplier: any) => supplier.id.toString() === payment.supplierID.toString())?.supplierNameM;
          if (payment.supplierName === undefined) { payment.supplierName = 'N/A'; } // Supplier not found? Type in N/A!
        } else { payment.supplierName = 'N/A'; }
      });
    }
  }

  createBooking(): void {
    const errorMessage = this.validateBookingData(this.bookingHashData[0], this.newElementData.data, this.passengers[0].data);
    // Convert integers to string - otherwise back-end will break...
    this.bookingHashData[0].px = this.bookingHashData[0].px.toString();
    this.bookingHashData[0].passengers = this.passengers[0].data;
    this.bookingHashData.length = 1; // Makes sure the array won't contain duplicates

    if (errorMessage !== '') {
      this.sendMessageToDialog('', errorMessage, '', ''); // Display pop-up error message
    } else {
      const request: any = {
        company: Session.mySession.getUser().company, operation: Session.mySession.getUser().operation,
        tradeCode: this.currentRequest.tradeCode, bookingReference: this.bookingHashData[0].BookingRefSource,
        token: Session.mySession.get('user').token
      };

      this.pageLoaded = false;
      this.bookingService.bookingExists(request).then((output: any) => {
        if (output.status === 'Exists') {
          this.sendMessageToDialog('', 'It looks like your reference already exists in the system', '', '');
        } else if (output.status === 'Does not exist') {
          this.bookingHashData = this.genBkElements(this.bookingHashData, this.newElementData.data);
          this.expandedElement = null; // Reset exp. row
          this.uploadBookingData(this.bookingHashData, 'hash');
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // Print error message
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2122S)', error, request);
      });
    }
  }

  createManyBookings(): void {
    let errorMessage = ''; // Variable used to catch any errors thrown by the UI (pre-Ruby check)
    const multiHashArr: any = []; // We'll dump converted hash data here

    // We want to check if any elements have 'unsupported' supplierName (still pseudo)
    this.uploadedElementsData.forEach((elementGroup: any) => {
      elementGroup.forEach((element: any) => {
        if (element.suppNotFound) {
          errorMessage = 'One of the elements has an unrecognized supplier name\n\n' + element.supplierName +
          '\n\nIt\'s highlighted in red in the \'Supplier Details\' table. Click to update.';
        }
      });
    });

    if (errorMessage === '') {
      this.uploadedBookingsData.forEach((bookingData: any, index: any) => {
        // We want to display validation messages only for one booking at the time
        if (errorMessage === '') {
          errorMessage = errorMessage + this.validateBookingData(bookingData[0], this.uploadedElementsData[index], this.passengers[index].data);
        } // Convert integers to string - otherwise back-end will break...
        bookingData[0].px = bookingData[0].px.toString(); bookingData[0].passengers = this.passengers[index].data;
        delete bookingData[0].relation; // Remove relation - unrecognised property at the back..
        // Generate booking & its elements >> push it to the array from the top of the method
        multiHashArr.push(this.genBkElements(bookingData, this.uploadedElementsData[index]));
      });

      if (errorMessage !== '') {
        this.sendMessageToDialog('', errorMessage, '', ''); // Display pop-up error message
      } else {
        this.expandedElement = null; // Reset expanded row
        this.uploadBookingData(multiHashArr.flat(1), 'hash');
      }
    } else {
      this.sendMessageToDialog('', errorMessage, '', ''); // Display pop-up error message
    }
  }

  updateBooking(): void {
    // We need to create a new array of hashes where we'll keep booking & element data (so the API work with us)
    let bookingHashData: any = [{
      BookingRefSource: this.extBookingData.extBookingSource, bookingRefSinGS: this.extBookingData.bookingReference,
      bookingDate: this.extBookingData.bookingDate, deptDate: this.extBookingData.deptDate, returnDate: this.extBookingData.returnDate,
      bookingStatus: this.extBookingData.bookingStatus, depositPaid: this.extBookingData.depositPaid, balanceDueDate: this.extBookingData.balanceDueDate
    }];
    // Just like in the create instance, get the error message (if exists)
    const errorMessage = this.validateBookingData(bookingHashData[0], this.extElementData.data, this.extPassengersData.data);

    if (errorMessage !== '') {
      this.sendMessageToDialog('', errorMessage, '', ''); // Display pop-up error message
    } else {
      bookingHashData = this.genBkElements(bookingHashData, this.extElementData.data);
      this.expandedElement = null; // Reset exp. row
      this.extElementData.data = []; this.extBookingData.data = [];
      this.uploadBookingData(bookingHashData, 'hash');
    }
  }

  validateBookingData(bookingData: any, elementData: any, passengers: any): any {
    // We will need to perform a lot of checks when creating booking
    // That is why it's good to use local error variable below
    let errorMessage = '';
    // First, we'll check if the booking data is correct [0]

    // Get the current date - need to compare with requested date
    const curDate = new Date(); const currentDate = this.constants.convertDateNotMoment(curDate);

    if (bookingData.BookingRefSource.length < 3) {
      errorMessage = 'External ref. needs to be at least 3 characters long';
    } else if (!/^[a-zA-Z0-9-]*$/.test(bookingData.BookingRefSource)) {
      errorMessage = 'Only numbers, letters and dashes are allowed in your reference';
    } else if (!moment(bookingData.bookingDate, 'YYYY-MM-DD', true).isValid()) {
      errorMessage = 'Booking date is in the wrong format';
    } else if (bookingData.bookingDate > currentDate) {
      errorMessage = 'Booking Date cannot be set in the future';
    } else if (!moment(bookingData.deptDate, 'YYYY-MM-DD', true).isValid()) {
      errorMessage = 'Departure date is in the wrong format';
    } else if (!moment(bookingData.returnDate, 'YYYY-MM-DD', true).isValid()) {
      errorMessage = 'Return date is in the wrong format';
    } else if (!moment(bookingData.balanceDueDate, 'YYYY-MM-DD', true).isValid()) {
      errorMessage = 'Balance due date is in the wrong format';
    } else if (moment(bookingData.deptDate).isAfter(bookingData.returnDate)) {
      errorMessage = 'Departure date cannot be after return date';
    } else if (bookingData.bookingStatus === null || bookingData.bookingStatus === '') {
      errorMessage = 'Please select booking status';
    } else if (passengers.every((passenger: Passenger) => !passenger.isLead)) {
      errorMessage = 'Booking needs a lead passenger';
    } else if (passengers.filter((passenger: Passenger) => passenger.isLead).length > 1) {
      errorMessage = 'Booking cannot have multiple lead passengers'
    // } else if (elementData.length === 0) {
    // errorMessage = 'Booking needs to have at least one supplier';
    } else if (elementData.length > 0) {
      elementData.forEach((ele: any) => {
        if (ele.supplierName === '') { errorMessage = 'Supplier names cannot be blank'; }
        else if (ele.supplements.length === 0 && ele.supplierReference === '') { errorMessage = 'Supplier references cannot be blank'; }
        else if (ele.accoms.length === 0 && ele.carhires.length === 0 && ele.carparks.length === 0 &&
          ele.cruises.length === 0 && ele.flights.length === 0 && ele.miscs.length === 0 && ele.supplements.length === 0 &&
          ele.packages.length === 0 && ele.transfers.length === 0 && ele.attractions.length === 0 && ele.trains.length === 0) {
            errorMessage = 'Suppliers must have at least one element';
          }
      });
    }
    if (passengers.filter((passenger: Passenger) => passenger.isLead).length == 1) {
      let leadPax = passengers.filter((passenger: Passenger) => passenger.isLead)[0];
      if (leadPax.firstName === '' || leadPax.lastName === '') {
        errorMessage = 'Lead passenger must have a first and last name'
      }
    } // Hopefully the error message will be empty..
    return errorMessage;
  }

  genBkElements(hashData: any, elementData: any): any {
    // We're looping through each element and its subElements
    // While doing so, we're generating a new hash which is then pushed into
    // array of hashes - this is as per Simon's loader requirements
    elementData.forEach((eleData: any, eleIndex: any) => {
      // We're not interested in iterating supplements (at least for now)
      // if (eleData.elementType === 'supplement') { return; }
      // Loop through each flight element below..
      eleData.flights.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subFlight', subIndex, 'flight', eleData, el, el.flightSource);
        hashData.push(eleHash);
      });
      // Loop through each accommodation element below..
      eleData.accoms.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subAccom', subIndex, 'accom', eleData, el, el.accomSource);
        hashData.push(eleHash);
      });
      // Loop through each cruise element below..
      eleData.cruises.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subCruise', subIndex, 'cruise', eleData, el, el.cruiseSource);
        hashData.push(eleHash);
      });
      // Loop through each carhires element below..
      eleData.carhires.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subCarhire', subIndex, 'carhire', eleData, el, el.carHireSource);
        hashData.push(eleHash);
      });
      // Loop through each carparks element below..
      eleData.carparks.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subCarPark', subIndex, 'carpark', eleData, el, el.carParkSource);
        hashData.push(eleHash);
      });
      // Loop through each attractions element below..
      eleData.attractions.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subAttraction', subIndex, 'attraction', eleData, el, el.attractionSource);
        hashData.push(eleHash);
      });
      // Loop through each miscs element below..
      eleData.miscs.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subMisc', subIndex, 'misc', eleData, el, el.miscSource);
        hashData.push(eleHash);
      });
      // Loop through each transfers element below..
      eleData.transfers.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subTransfer', subIndex, 'transfer', eleData, el, el.transferSource);
        hashData.push(eleHash);
      });
      // Loop through each package element below..
      eleData.packages.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subPackage', subIndex, 'package', eleData, el, el.packageSource);
        hashData.push(eleHash);
      });
      // Loop through each trains element below..
      eleData.trains.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subTrain', subIndex, 'train', eleData, el, el.trainSource);
        hashData.push(eleHash);
      });
      // Loop through each supplement element below..
      eleData.supplements.forEach((el: any, subIndex: any) => {
        const eleHash = this.getBkEleHash(hashData[0].BookingRefSource, eleIndex, 'subSupplement', subIndex, 'supplement', eleData, el, el.supplementSource);
        hashData.push(eleHash);
      });
    });
    return hashData;
  }

  getBkEleHash(bookingRefSource: any, eleIndex: any, subEleSource: any, subIndex: any, eleType: any, eleData: any, el: any, elSource: any): any {
    const eleHash: any = {
      BookingRefSource: bookingRefSource,
      ElementRefSource: 'eleSings' + eleIndex, SubElementRefSource: subEleSource + subIndex,
      bookingRefSinGS: 'none', elementType: eleType, supplierID: eleData.supplierID,
      supplierName: eleData.supplierName, supplierReference: eleData.supplierReference ? eleData.supplierReference : '',
      grossCost: el.grossCost.toString(), netCost: el.netCost.toString(),
      discount: el.discount.toString(), tax: el.tax.toString(), commission: el.commission.toString(), depositAmount: el.depositAmount.toString()
    };
    if (eleData.id !== undefined) { eleHash.ElementRefSource = eleData.elementSource; }
    if (el.id !== undefined) { eleHash.bookingRefSinGS = eleData.bookingReference; eleHash.SubElementRefSource = elSource; }
    return eleHash; // Return proded object back to the method above
  }

  changeAddMode(mode: any): void {
    if (mode === 'blank') {
      // Reset all current data which was meant to be for a new booking - start from zero
      this.bookingHashData = [{ BookingRefSource: '', bookingRefSinGS: 'none', bookingDate: '',
      deptDate: '', returnDate: '', bookingStatus: 'booking', leadPXName: '', px: 0, depositPaid: false }];
      // That includes (sub)Element data; set the preview off - allow creating single booking
      this.newElementData.data = []; this.previewOn = false;
    } else {
      this.bookingViewNo = 0; // Reset the booking view number back to zero - use arrows to navigate UI
      this.switchView('Add'); // In case the method was called from the Upload Booking page..
      // Need to call the method which will set the preview of first booking
      if (mode === 'previewUI') { this.setPreviewBooking(); }
      this.previewOn = true;
    }
  }

  setPreviewBooking(): void {
    // Grouped booking & element data is being pushed to output variable..
    this.newElementData.data = [];
    // Assign both booking and element data which will be currently displayed to the user
    this.bookingHashData = this.uploadedBookingsData[this.bookingViewNo];
    this.newElementData.data = this.uploadedElementsData[this.bookingViewNo];
  }


  producePreviewBookings(): void {
    try {
      const csvToHash: any = []; // This is where we'll dump converted csv->hash to
      const csvBookingInfo: any = []; // This will contain booking details [booking1, booking2 etc..]
      const csvElementInfo: any = []; // It will contain element details [booking1.elements, booking2.element etc..]
      const csvElementArr: any = []; // We'll dump an array of arrays here - to be read by MatTableDataSoruce later..

      this.failedUpload = false; // Set alternative upload button back to hidden..
      this.changeAddMode('previewTS'); // This is so correct values are being called..

      // Loop through each csv row and work out whether the row is booking or element related
      this.bookingCsvDataUpload.forEach((csvRow: any, index: any) => {
        if (index > 0 && csvRow[8] === '') {
          const bookingDate = this.constants.formatDateToYYYYMMDD(csvRow[2]);
          const deptDate = this.constants.formatDateToYYYYMMDD(csvRow[3]);
          const returnDate = this.constants.formatDateToYYYYMMDD(csvRow[4]);
          const balanceDueDate = this.constants.formatDateToYYYYMMDD(csvRow[5]);

          const testHash = {
            BookingRefSource: csvRow[0], bookingRefSinGS: csvRow[1], bookingDate,
            deptDate, returnDate, balanceDueDate, bookingStatus: csvRow[6], leadPXName: '',
            px: '', depositPaid: csvRow[7], relation: 'booking'
          };
          csvToHash.push(testHash);

          // Add new table data source to the array list..
          if (index > 1) {
            const paxData = this.passengers;
            const newPaxTable = new MatTableDataSource<any>();
            paxData.push(newPaxTable);
            this.passengers = paxData;
          }          
        } else if (index > 0) {
          const testHash: any = {
            BookingRefSource: csvRow[0], ElementRefSource: csvRow[1], SubElementRefSource: csvRow[2],
            bookingRefSinGS: csvRow[3], elementType: csvRow[4], supplierName: csvRow[5],
            supplierReference: csvRow[6], grossCost: csvRow[7], netCost: csvRow[8],
            discount: csvRow[9], tax: csvRow[10], commission: csvRow[11], depositAmount: csvRow[12], relation: 'element'
          };
          csvToHash.push(testHash);
        }
      });

      // The csv may have 100+ rows - we'll group them by the external reference below
      const groupedByRef = groupBy(csvToHash, (hash: any) => hash.BookingRefSource);
      Object.keys(groupedByRef).forEach((e: any) => {
        csvBookingInfo.push(groupedByRef[e].filter((x: any) => x.relation === 'booking'));
        csvElementInfo.push(groupedByRef[e].filter((x: any) => x.relation === 'element'));
      });

      csvElementInfo.forEach((e: any) => {
        // For each grouped elements (and its sub elements), we'll be adding a new supplier and flight etc..
        const elementsGrouped = groupBy(e, (hash: any) => hash.ElementRefSource);
        // We need to create a 'dummy' supplier object which will be added to array
        Object.keys(elementsGrouped).forEach((element: any, index: any) => {
          const isSupplement = elementsGrouped[element][0].elementType === 'supplement' ? 'yes' : 'no';
          const suppForm: any = {
            value: {
              supplier: {
                supplierID: null,
                supplierNameM: elementsGrouped[element][0].supplierName,
              },
              supplierReference: elementsGrouped[element][0].supplierReference,
              isSupplement
            }
          };
          this.addSupplier(suppForm, 'ts'); // add supplier to preview array

          // Next, each subElement within element is being iterated and added to the TS element var..
          elementsGrouped[element].forEach((subEle: any) => {
            const selectEvent: any = {
              value: subEle.elementType, grossCost: subEle.grossCost, discount: subEle.discount,
              netCost: subEle.netCost, commission: subEle.commission, tax: subEle.tax, depositAmount: subEle.depositAmount
            }; // Add subElement to the element (under accoms, flights etc..)
            this.addElementType(selectEvent, this.newElementData.data[index]);
            this.addUpCosts(this.newElementData.data[index], 'grossCost');
            this.addUpCosts(this.newElementData.data[index], 'netCost');
            this.addUpCosts(this.newElementData.data[index], 'commission');
            this.addUpCosts(this.newElementData.data[index], 'tax');
            this.addUpCosts(this.newElementData.data[index], 'discount');
            this.addUpCosts(this.newElementData.data[index], 'depositAmount');
          });

        });
        // We're pushing element(+subElements) to the array of array
        // Additionally, we need to 'erase' newElementData array for the next booking
        csvElementArr.push(this.newElementData.data); this.newElementData.data = [];
      });
      // Grouped booking & element data is being pushed to output variable..
      this.uploadedBookingsData = csvBookingInfo; this.uploadedElementsData = csvElementArr;

      // Find out whether the supplier exists in our database or not
      this.uploadedElementsData.forEach((elementGroup: any) => {
        elementGroup.forEach((ele: any, index: any) => {
          if (ele.isSupplement === 'no') {
            const foundSupplier = this.suppliersList.find((suppl: any) => suppl.supplierNameM === ele.supplierName);

            if (foundSupplier) { ele.supplierID = foundSupplier.id; }
            else { ele.suppNotFound = true; }
          } else if (ele.isSupplement === 'yes') {
            const foundSupplement = this.supplementList.find((suppl: any) => suppl.supName === ele.supplierName);

            if (foundSupplement) { ele.supplierID = foundSupplement.id; }
            else { elementGroup.splice(index, 1); }
          }
        });
      });

      this.setPreviewBooking(); // Lastly, call the method which sets all bookings
      this.sendMessageToDialog('This is preview - use arrows to navigate between bookings', '', '', '');
    } catch (error: any) {
      // We need to reset uploaded booking & element data
      this.uploadedBookingsData = []; this.uploadedElementsData = [];
      this.changeAddMode('blank'); // Change add mode back to blank
      this.switchView('Upload'); // Change the view back to upload..
      this.failedUpload = true; // Mark upload as 'failed' - alternative button will apear..

      // Finally - let user know that we're on it, and offer them upload without preview..
      this.sendMessageToDialog('',
      'The upload preview did not load properly and we\'re on it\n' +
      'Please use the Upload Bookings button if you wish to proceed without it',
      { error: error.toString() }, this.bookingCsvDataUpload);
    }
  }

  getPseudoMasterName(element: any): void {
    const request = {
      supplierName: element.supplierName, company: Session.mySession.getUser().company,
      operation: Session.mySession.getUser().operation, tradeCode: this.currentRequest.tradeCode,
      token: Session.mySession.get('user').token
    };

    this.supplierService.findMasterName(request).then((res: any) => {
      if (res.status === 'OK') {
        // In case we can't find any master name, then mark the supplier name as 'done'..
        if (res.data.length === 0) { element.suppNotFound = false; }
        // There are 1+ probable master supplier names - we need user to choose one
        else {
          const dataParse = { element, masterData: res.data }; // This is what we're parsing to dialog

          this.dialog.open(this.masterNameDialog, {
            panelClass: 'masterNameDialog', // Apply custom CSS class
            data: dataParse, // Pass the response data to the dialog component instance
            autoFocus: false
          });
        }
      } else {
        this.sendMessageToDialog('', res.status, '', ''); // Display pop-up error message
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2108S)', error, request);
    });
  }

  updateElementPseudoName(element: any, masterName: any): void {
    this.openSupplDialog('ts', null).then(() => {
      element.supplierID = this.suppliersList.find((suppl: any) => suppl.supplierNameM === masterName).id;
      element.supplierName = masterName;
      element.suppNotFound = false;
    });
  }

  editDate(date: any, whatDate: any, dataVariable: any): void {
    // Date SHOULD be always set up from the date-picker
    // If so, we need to convert it below (if not null obviously..)
    if (date.value != null) { date = this.constants.convertDateMoment(date.value); }
    else { date = null; }

    // CRF 133 - check if booking date is not in the future
    if (date == null) {
      date = ''; // Reset the date input visible on the UI
      if (whatDate === 'Booking Date') { dataVariable.bookingDate = ''; }
      else if (whatDate === 'Departure Date') { dataVariable.deptDate = ''; }
      else if (whatDate === 'Return Date') { dataVariable.returnDate = ''; }
      else if (whatDate === 'Balance Due Date') { dataVariable.balanceDueDate = ''; }
      this.sendMessageToDialog('', 'The date was in the wrong format', '', '');
    } else {
      // Depending on what date has been requested to change, do it as below suggests..
      if (whatDate === 'Booking Date') { dataVariable.bookingDate = date; }
      else if (whatDate === 'Departure Date') { dataVariable.deptDate = date; }
      else if (whatDate === 'Supplier Due Date') { dataVariable.dueDate = date; }
      else if (whatDate === 'Return Date') { dataVariable.returnDate = date; }
      else if (whatDate === 'Deposit Due Date') { dataVariable.depositDueDate = date; }
      else if (whatDate === 'Balance Due Date') { dataVariable.balanceDueDate = date; }
    }
  }

  changeBookingStatus(event: any): void {
    // Assign the status value to main booking object
    // Booking object is determined by the view state
    if (this.bookingOperation === 'View') {
      this.extBookingData.bookingStatus = event.value;
    } else if (this.bookingOperation === 'Add') {
      this.bookingHashData[0].bookingStatus = event.value;
    }
  }

  getSFCcoverPrice(): void {
    let sfcSuppGross: any = 0; let sfcSuppNet: any = 0;

    if (this.bookingOperation === 'Add') {
      this.newElementData.data.forEach((costing: any) => {
        const supplier = this.suppliersList.find((obj: any) => {
          return ( obj.id?.toString() === costing.supplierID?.toString() || obj.supplierNameM === costing.supplierName);
        });
        costing.isUnderSFC = supplier?.isUnderSFC; // Assign isUnderSFC to our costing
        costing.isUnderSafi = supplier?.underSafi === 'yes'; // Assign underSAFI to our costing
        // Adding Gross and Net to the total SFCgross below
        if (supplier && supplier?.isUnderSFC) {
          sfcSuppGross = (parseFloat(sfcSuppGross) + parseFloat(costing.gross)).toFixed(2);
          sfcSuppNet = (parseFloat(sfcSuppNet) + parseFloat(costing.net)).toFixed(2);
        }
      });
    } else if (this.bookingOperation === 'View') {
      this.extElementData.data.forEach((costing: any) => {
        const supplier = this.suppliersList.find((obj: any) => {
          return ( obj.id?.toString() === costing.supplierID?.toString() || obj.supplierNameM === costing.supplierName);
        });
        costing.isUnderSFC = supplier?.isUnderSFC; // Assign isUnderSFC to our costing
        costing.isUnderSafi = supplier?.underSafi === 'yes'; // Assign underSAFI to our costing
        // Adding Gross and Net to the total SFCgross below
        if (supplier && supplier?.isUnderSFC) {
          sfcSuppGross = (parseFloat(sfcSuppGross) + parseFloat(costing.gross)).toFixed(2);
          sfcSuppNet = (parseFloat(sfcSuppNet) + parseFloat(costing.net)).toFixed(2);
        }
      });
    }

    // Divide the total SFC gross & net by the number of passengers
    if (this.extBookingData.paxNo > 0) {
      sfcSuppGross = (sfcSuppGross / parseFloat(this.extBookingData.paxNo)).toFixed(2);
      sfcSuppNet = (sfcSuppNet / parseFloat(this.extBookingData.paxNo)).toFixed(2); 
    }

    // Make sure we're getting float, not string
    sfcSuppGross = parseFloat(sfcSuppGross);
    sfcSuppNet = parseFloat(sfcSuppNet);

    // Find sfc mapping and assign fixed price per pax based on the gross value
    // This is for a 'Gross' calculations
    let sfcPricingCntGross = 0; let loopBreakGross = 'no';
    this.sfcPricing.forEach((pricing: any) => {
      // if (loopBreakGross === 'no' && parseFloat(sfcSuppGross) === 0.00) { loopBreakGross = 'yes'; }
      if (loopBreakGross === 'no' && sfcSuppGross === 0.00 || sfcSuppGross < pricing.cover) { loopBreakGross = 'yes'; }
      else if (loopBreakGross === 'no') { sfcPricingCntGross += 1; }
    });
    // This is for a 'Net' calculations
    let sfcPricingCnttNet = 0; let loopBreakNet = 'no';
    this.sfcPricing.forEach((pricing: any) => {
      // if (loopBreakNet === 'no' && parseFloat(sfcSuppNet) === 0.00) { loopBreakNet = 'yes'; }
      if (loopBreakNet === 'no' && sfcSuppNet === 0.00 || sfcSuppNet < pricing.cover) { loopBreakNet = 'yes'; }
      else if (loopBreakNet === 'no') { sfcPricingCnttNet += 1; }
    });

    // Works out what needs to be read from the mapping (translated from Ruby)
    if (sfcPricingCntGross === this.sfcPricing.length) { sfcPricingCntGross = this.sfcPricing.length - 1; }
    if (sfcPricingCnttNet === this.sfcPricing.length) { sfcPricingCnttNet = this.sfcPricing.length - 1; }
    // Set default SFC prices which are displayed to the user
    this.newSfcInfo.sfcBookGrossPrice = this.sfcPricing[sfcPricingCntGross].pricePerPx;
    this.newSfcInfo.sfcBookNetPrice = this.sfcPricing[sfcPricingCnttNet].pricePerPx;
    this.newSfcInfo.sfcGrossPrice = sfcSuppGross; this.newSfcInfo.sfcNetPrice = sfcSuppNet;
    // Additionally, set 'gross' cover as a default
    this.newSfcInfo.costBasedOn = 'net';
  }

  changeSFCbasis(supplement: any, elementCount: any): void {
    // Change the costBasedOn value below - that's all!
    if (supplement.costBasedOn === 'gross') { supplement.costBasedOn = 'net'; }
    else { supplement.costBasedOn = 'gross'; }
    // Call editSupplement method - viola!
    this.editSupplement(supplement, supplement.supplementStatus, elementCount);
  }

  isUnderSFC(): boolean {
    if (this.bookingOperation === 'Add') {
      return this.newElementData.data.some((element: any) => element.isUnderSFC === true);
    } else if (this.bookingOperation === 'View') {
      return this.extElementData.data.some((element: any) => element.isUnderSFC === true);
    } else {
      return false;
    }
  }

  updateSafiCoverage(): void {
    const request = {
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      flights: this.flightsOnly, token: Session.mySession.get('user').token
    }

    this.pageLoaded = false;
    this.supplierService.updateSafiCoverage(request).then((res: any) => {
      if (res.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
            this.sendMessageToDialog('SAFI coverage has been updated', '', '', ''); // Display success message to the user
          });
        });
      } else {
        this.sendMessageToDialog('', 'Failed to update SAFI coverage (' + res.status + ')', '', ''); // Display error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E0845S)', error, request);
    });
  }

  getFlightsOnly(): void {
    if (this.bookingOperation === 'Add') {
      this.sfcExists = this.newElementData.data.some((element: any) => element.supplierName === 'SFC');
      this.safiExists = this.newElementData.data.some((element: any) => element.supplierName === 'SAFI');
      this.flightsOnly = this.newElementData.data
      .filter((costing: any) => costing.elementType === 'flight')
      .flatMap((costing: any) => {
        // Find the corresponding supplier
        const supplier = this.suppliersListALL.find((obj: any) => { return (obj.id?.toString() === costing.supplierID?.toString() || obj.supplierName === costing.supplierName); });
        // Check if the supplier is under SAFI
        if (supplier?.underSafi === 'yes') { return costing.flights; }
        else { return []; }
      });
    } else if (this.bookingOperation === 'View') {
      this.sfcExists = this.extElementData.data.some((element: any) => element.supplierName === 'SFC');
      this.safiExists = this.extElementData.data.some((element: any) => element.supplierName === 'SAFI');
      this.flightsOnly = this.extElementData.data
      .filter((costing: any) => costing.elementType === 'flight')
      .flatMap((costing: any) => {
        // Find the corresponding supplier
        const supplier = this.suppliersListALL.find((obj: any) => { return (obj.id?.toString() === costing.supplierID?.toString() || obj.supplierName === costing.supplierName); });
        // Check if the supplier is under SAFI
        if (supplier?.underSafi === 'yes') { return costing.flights; }
        else { return []; }
      });
    }

    let safiGross: any = 0;
    // Adding Gross to the total SAFIgross below
    this.flightsOnly.forEach((flight: any) => {
      if (flight.flightStatus !== 'cancelled' && flight.underSafi === 'yes') {
        safiGross = (parseFloat(safiGross) + parseFloat(flight.grossCost)).toFixed(2);
      }
    });

    // Divide the total SAFI gross & net by the number of passengers
    if (this.extBookingData.paxNo > 0) {
      safiGross = (safiGross / parseFloat(this.extBookingData.paxNo)).toFixed(2);
    }

    // Make sure we're getting float, not string
    safiGross = parseFloat(safiGross);

    // Find SAFI mapping and assign fixed price per pax based on the gross value
    let safiPricingCntGross = 0; let loopBreakGross = 'no';
    this.safiPricing.forEach((pricing: any) => {
      // if (loopBreakGross === 'no' && parseFloat(safiGross) === 0.00) { loopBreakGross = 'yes'; }
      if (loopBreakGross === 'no' && safiGross === 0.00 || safiGross < pricing.cover) { loopBreakGross = 'yes'; }
      else if (loopBreakGross === 'no') { safiPricingCntGross += 1; }
    });

    // Works out what needs to be read from the mapping (translated from Ruby)
    if (safiPricingCntGross === this.safiPricing.length) { safiPricingCntGross = this.safiPricing.length - 1; }

    this.newSafiInfo.safiBookGrossPrice = this.safiPricing[safiPricingCntGross].pricePerPx;
    this.newSafiInfo.safiGrossPrice = safiGross;
  }

  changeFlightSafi(event: any, flight: any): void {
    if (!event.target.checked) { flight.underSafi = 'no'; }
    else { flight.underSafi = 'yes'; }

    this.getFlightsOnly(); // This will reload SAFI cost and coverage..
  }

  downloadCertificate(costing: any, type: any): void {
    // We need a request variable which will contain all necessary req. info
    const request = {
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      bookingReference: this.extBookingData.bookingReference, actionedBy: Session.mySession.getUser().fullName,
      supplementCount: costing.supplements[0].supplementCount, type, token: Session.mySession.get('user').token
    };

    this.pageLoaded = false;
    this.reportsService.generateSAG(request).then((output: any) => {
      if (output.status === 'OK') {
        try {
          window.open(output.presignedUrl, '_blank'); this.pageLoaded = true;
        } catch (error) {
          this.sendMessageToDialog('', error, '', ''); // File download OK but failed to convert Base64 to whatever
        }
      } else {
        this.sendMessageToDialog('', output.status, '', ''); // File download failed at the back-end
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2121S)', error, request);
    });
  }

  changeAppliedDiscount(supplement: any, elementCount: any, applyDiscount: boolean): void {
    // Change supplement's applyDiscount boolean below
    supplement.applyDiscount = applyDiscount;
    // Call editSupplement method - viola!
    this.editSupplement(supplement, supplement.supplementStatus, elementCount);
  }

  calcSupplementCosts(supplement: any): void {
    if (supplement.supplements.length === 1) {
      supplement.supplements[0].realGross = supplement.supplements[0].grossCost;
      supplement.supplements[0].realGross = (Number(supplement.supplements[0].grossCost) + Number(supplement.supplements[0].discount)).toFixed(2);
  
      if (supplement.autoPricing && this.expandedElement === supplement && (!['SFC', 'SAFI'].includes(supplement.supplierName) || (supplement.supplierName === 'SAFI' && this.oldSafi))) {
        const suppObj = { ... supplement }; suppObj.supName = suppObj.supplierName;
        this.selectedSupplement = {};
        
        let today: any = new Date(); // Get today's date  here..
        today = this.constants.convertDateNotMoment(today); // Covert it to ruby-friendly format

        suppObj.applicnDate = today; // Apply the date to supplement (so Ruby knows what price to get)
        suppObj.token = Session.mySession.getUser().token; // Usual stuff..

        this.supplementLoaded = false;
        this.supplierService.getSupplmPrice(suppObj).then((suppPrice: any) => {
          if (suppPrice.status === 'OK') {
            this.selectedSupplement = suppPrice.supplm; // Assign the output supplm to global variable
            // Find out whether the branch has special Standalone rate
            const customEntry = this.standaloneCustoms.find((entry: any) => entry.tradeCode === this.extBookingData?.tradeCode); 
            if (this.selectedSupplement.supName === 'Safe Seat Plan Guarantee - Standalone' && customEntry && new Date(this.extBookingData?.createTS) >= new Date('2024-09-01')) {
              this.selectedSupplement.costFixPerPx = customEntry.rate;
            }
            this.supplementLoaded = true;
          } else {
            this.selectedSupplement = {}; // Make sure selected supplement is empty
            this.sendMessageToDialog('', 'Obtaining supplement information has failed (' + suppPrice.status + ')', '', ''); // Display error message
            this.supplementLoaded = true;
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2111S)', error, supplement);
          this.supplementLoaded = true;
        });
      }
    }
  }

  editSupplement(supplement: any, status: any, elementCount: any): void {
    // Create a request variable needed for editing supplement (just like in element-edit page)
    // We set status in core/subElement status taken from the UI (depending on what button has been clicked)
    const request = {
      token: Session.mySession.get('user').token,
      coreElementData: {
        company: supplement.company, operation: supplement.operation, tradeCode: supplement.tradeCode, elementStatus: status,
        bookingReference: supplement.bookingReference, supplierName: supplement.supplementTypeClient, elementType: 'supplement', elementCount
      },
      supplementElementData: {
        subElementType: 'supplement', subElementStatus: status, supplementStatus: status, supplementTypeClient: supplement.supplementTypeClient,
        grossCost: supplement.realGross, netCost: supplement.netCost, tax: supplement.tax, commission: supplement.commission, discount: supplement.discount,
        supplementCount: supplement.supplementCount, costBasedOn: supplement.costBasedOn, applyDiscount: supplement.applyDiscount, action: 'update'
      }
    };

    this.pageLoaded = false;
    this.elementService.updateElement(request).then((output: any) => {
      if (output.status === 'OK' || output.status === 'OK - no diff') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.loadExternalBooking(supplement.bookingReference).then(() => {
            this.sendMessageToDialog('Supplement has been updated', '', '', ''); // Display success message to the user
          });
        });
      } else {
        this.sendMessageToDialog('', 'Failed to update Supplement element (' + output.status + ')', '', ''); // Display error message to the user
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2110S)', error, request);
    });
  }

  changePageSize(size: any): void {
    this.firstRes = 1; // We'll start from the 'bottom' again
    this.lastRes = size; // Last res will be the selected size
    this.pageSize = size; // Set the size requested by the user
    this.loadBookingList();
  }

  changePageNumber(direction: any): void {
    if (this.bookingOperation === 'List') {
      // Booking List related arrows - next / previous page loads calls an API with new args
      if (direction === 'previous') { this.firstRes -= this.pageSize; this.lastRes -= this.pageSize; }
      else if (direction === 'next') { this.firstRes += this.pageSize; this.lastRes += this.pageSize; }
      this.loadBookingList(); // This calls a method which re-loads booking list with new args
    } else if (this.bookingOperation === 'Add') {
      // Booking Upload preview arrows - next / previous page displays another booking - no API is being called
      if (direction === 'previous') { this.bookingViewNo -= 1; }
      else if (direction === 'next') { this.bookingViewNo += 1; }
      this.setPreviewBooking(); // This calls a method which deals with displaying another booking
      this.getFlightsOnly();
    }
  }

  changeOrderBy(orderBy: any): void {
    this.orderColumn = orderBy; // Reassign the value based on selected one
    this.loadBookingList(); // Reload bookings
  }

  changeOrderHow(orderHow: any): void {
    this.orderHow = orderHow; // Reassign the value based on selected one
    this.loadBookingList(); // Reload bookings
  }

  editToggleOnOff(): void {
    if (this.editToggle === true) { this.editToggle = false; } // Make summary non-editable
    else { this.editToggle = true; } // Make summary editable..
  }

  goToBooking(bookingReference: any): void {
    AppComponent.myapp.fellohNav = false; // Turn the Felloh navigation first
    AppComponent.myapp.closeBooking('fromScript').then((res: any) => {
      AppComponent.myapp.navigateToBooking(bookingReference, true);
    });
  }

  addSupplier(form: NgForm, source: string): void {
    // Work out if we need to read name from supplier or supplement
    const supplierName = form.value.supplier.supplierNameM || form.value.supplier.supName;

    const newSupplier: any = {
      company: this.extBookingData.company, operation: this.extBookingData.operation,
      tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
      elementStatus: 'enquiry', supplierName, supplierReference: form.value.supplierReference,
      supplierID: form.value.supplier.id, isSupplement: this.isSupplementStr, externalElement: true, detailRow: true,
      gross: 0, discount: 0, net: 0, commission: 0, tax: 0, depositAmount: 0,
      flights: [], accoms: [], cruises: [], carparks: [], carhires: [], attractions: [], transfers: [],
      supplements: [], miscs: [], packages: [], trains: []
    };
    // Make the row automatically expanded
    if (source === 'ui') { this.expandedElement = newSupplier; }
    // Finally, add new supplier!
    if (this.bookingOperation === 'View') {
      const eleData = this.extElementData.data; eleData.push(newSupplier);
      this.extElementData.data = eleData;
    } else if (this.bookingOperation === 'Add') {
      const eleData = this.newElementData.data; eleData.push(newSupplier);
      this.newElementData.data = eleData;
    }

    // Automatically add Supplement subElement do the element if selected..
    if (source === 'ui' && this.isSupplementStr === 'yes') {
      const event = { value: 'supplement' };
      this.addElementType(event, newSupplier);
    }
    this.getSFCcoverPrice();
  }

  addElementType(event: any, costing: any): void {
    // Create base cost variables below - the are all £0 initially when adding new element type
    let grossCost = 0; let discount = 0; let netCost = 0; let commission = 0; let tax = 0; let depositAmount = 0;
    // The only time above variables may change thier values is when the user uploads CSV data
    // Preview 'removes' £0 values and assign whatever comes from the csv..
    if (this.previewOn && event?.grossCost) {
      grossCost = event.grossCost; discount = event.discount; netCost = event.netCost;
      commission = event.commission; tax = event.tax; depositAmount = event.depositAmount;
    }
    // A variable is being created below which behaves like a subElement - it's then added to element var..
    const baseValues: any = {
      company: this.extBookingData.company, operation: this.extBookingData.operation,
      tradeCode: this.extBookingData.tradeCode, bookingReference: this.extBookingData.bookingReference,
      returnDate: this.extBookingData.returnDate,
      grossCost, discount, netCost, commission, tax, depositAmount
     };
    // Depending on the subElementType, add it to appropiate array list
    if (event.value === 'flight') {
      baseValues.flightStatus = 'enquiry';
      costing.flights.push(baseValues);
    } else if (event.value === 'accom') {
      baseValues.accomStatus = 'enquiry';
      costing.accoms.push(baseValues);
    } else if (event.value === 'cruise') {
      baseValues.cruiseStatus = 'enquiry';
      costing.cruises.push(baseValues);
    } else if (event.value === 'carhire') {
      baseValues.carHireStatus = 'enquiry';
      costing.carhires.push(baseValues);
    } else if (event.value === 'carpark') {
      baseValues.carParkStatus = 'enquiry';
      costing.carparks.push(baseValues);
    } else if (event.value === 'attraction') {
      baseValues.attractionStatus = 'enquiry';
      costing.attractions.push(baseValues);
    } else if (event.value === 'misc') {
      baseValues.miscStatus = 'enquiry';
      costing.miscs.push(baseValues);
    } else if (event.value === 'transfer') {
      baseValues.transferStatus = 'enquiry';
      costing.transfers.push(baseValues);
    } else if (event.value === 'package') {
      baseValues.packageStatus = 'enquiry';
      costing.packages.push(baseValues);
    } else if (event.value === 'train') {
      baseValues.trainStatus = 'enquiry';
      costing.trains.push(baseValues);
    } else if (event.value === 'supplement') {
      // We need to check if the supplement allows custom costings
      const supplement = this.supplementList.find((item: any) => item.supName === costing.supplierName);
      costing.autoPricing = supplement?.autoPricing;
      // Carry on as usual..
      baseValues.supplementStatus = 'enquiry';
      costing.supplements.push(baseValues);
    }
    // Finally, de-select value so user can add the same type once again
    // This happens only if the subElement has been added from the UI
    if (event.source !== undefined) { event.source.writeValue(null); }
    this.workOutElementType(costing);
  }

  workOutElementType(costing: any): void{
    const elementTypes = [
      { array: costing.accoms, type: 'accom' },
      { array: costing.attractions, type: 'attraction' },
      { array: costing.carhires, type: 'carhire' },
      { array: costing.carparks, type: 'carpark' },
      { array: costing.cruises, type: 'cruise' },
      { array: costing.flights, type: 'flight' },
      { array: costing.miscs, type: 'misc' },
      { array: costing.packages, type: 'package' },
      { array: costing.trains, type: 'train' },
      { array: costing.transfers, type: 'transfer' }
    ];

    // Work out whether there are arrays with elements in them..
    const notEmptyElements = elementTypes.filter(item => item.array.length > 0);

    if (notEmptyElements.length === 0) {
      costing.elementType = '';
    } else if (notEmptyElements.length === 1) {
      costing.elementType = notEmptyElements[0].type;  // Assign type based on the single non-empty array
    } else if (notEmptyElements.length >= 2) {
      costing.elementType = 'mixed';
    }
    this.getFlightsOnly();
  }

  removeMainElement(elementIndex: any): void {
    if (this.bookingOperation === 'Add') {
      this.newElementData.data.splice(elementIndex, 1);
      this.tableNew.renderRows();
    } else if (this.bookingOperation === 'View') {
      this.extElementData.data.splice(elementIndex, 1);
      this.tableOld.renderRows();
    }
  }

  removePassenger(passenger: Passenger): void {
    const passengerIndex = this.passengers[this.bookingViewNo].data.findIndex((p: any) => p === passenger);
    if (passengerIndex > -1) {
      this.passengers[this.bookingViewNo].data.splice(passengerIndex, 1);
      this.passengers[this.bookingViewNo].data = [...this.passengers[this.bookingViewNo].data];
    }
  }

  removeElementType(costingIndex: any, costing: any, costingArr: any): void {
    costingArr.splice(costingIndex, 1);
    this.workOutElementType(costing);
  }

  resetUploadData(): void {
    this.changeAddMode('blank');
    this.bookingCsvDataUpload = []; this.uploadedBookingsData = [];
    this.uploadedElementsData = []; this.bookingViewNo = 0;
    this.customerData.data = [];
    this.passengers = [new MatTableDataSource<any>([])];
  }

  openSupplDialog(source: any, bookingData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Session.mySession.getSupplierList(this.extBookingData.tradeCode || bookingData?.tradeCode || this.currentRequest.tradeCode).expiryTime === 'EXPIRED') {
        const request = {
          company: this.extBookingData.company || bookingData?.company || Session.mySession.getUser().company,
          operation: this.extBookingData.operation || bookingData?.operation || Session.mySession.getUser().operation,
          tradeCode: this.extBookingData.tradeCode || bookingData?.tradeCode || this.currentRequest.tradeCode,
          token: Session.mySession.get('user').token
        };

        this.supplierService.getSupplierList(request).then((suppliers: any) => {
          if (suppliers.status === 'OK') {
            let sorted = suppliers.data.sort((a: any, b: any) => (a.supplierNameM > b.supplierNameM) ? 1 : -1); // Sort suppliers alphabetically
            Session.mySession.setSupplierList(this.extBookingData.tradeCode || bookingData?.tradeCode || this.currentRequest.tradeCode, sorted); // Set suppliers values in session
            sorted.map(async (element: any) => { element.isUnderSFC = element.sfcs.some((e: any) => e.status === 'Approved'); });
            sorted = sorted.filter((supplier: any) => supplier.hidden === 'no'); // Filter out all HIDDEN suppliers from the list

            this.groupSuppliers(sorted);
            if (source === 'view') { this.dialog.open(this.addSupplDialog, { panelClass: 'addSupplDialog' }); }

            this.supplierService.getSupplmPriceList(request).then((output: any) => {
              if (output.status === 'OK') {
                const sorted = output.suppriceList.sort((a: any, b: any) => (a.supName > b.supName) ? 1 : -1); // Sort supplements alphabetically
                Session.mySession.setSupplementList(sorted); // Set supplement values in session
                this.supplementList = sorted; // Assign supplement list in global variable
                resolve('');
              } else {
                this.sendMessageToDialog('', 'SinGS could not load supplements (' + output.status + ')', '', ''); // Print error message..
              }
            }).catch((error: any) => {
              this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2107S)', error, request);
            });

          } else {
            this.sendMessageToDialog('', 'SinGS could not load suppliers (' + suppliers.status + ')', '', ''); // Print error message..
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2107S)', error, request);
        });

      } else {
        let supplierList = Session.mySession.getSupplierList(this.extBookingData.tradeCode || bookingData?.tradeCode || this.currentRequest.tradeCode).supplierList;
        supplierList.map(async (element: any) => { element.isUnderSFC = element.sfcs.some((e: any) => e.status === 'Approved'); });
        supplierList = supplierList.filter((supplier: any) => supplier.hidden === 'no'); // Filter out all HIDDEN suppliers from the list

        this.groupSuppliers(supplierList);
        this.supplementList = Session.mySession.getSupplementList().supplementList;
        if (source === 'view') { this.dialog.open(this.addSupplDialog, { panelClass: 'addSupplDialog' }); }
        resolve('');
      }
    });
  }

  openRequestDialog(): void {
    this.dialog.open(this.requestDialog, {panelClass: 'bankingExternalBox', disableClose: true, autoFocus: false});
  }

  openAddPassengerDialog(): void {
    this.customerData.data = [];
    this.dialog.open(this.passengerDialog, { autoFocus: false, panelClass: 'passengerDialog' });
  }

  openEditPassengerDialog(passenger: any): void {
    this.paxToEdit = passenger;
    this.dialog.open(this.editPaxDialog, { autoFocus: false, panelClass: 'passengerDialog' });
  }

  groupSuppliers(supplierList: any): void {
    // Sort suppliers by following: supplierNameM (priority) and isUnderSFC (secondary priority) [yes over no]
    let sorted = supplierList.sort((a: any, b: any) => {
      return a.supplierNameM.localeCompare(b.supplierNameM);
    });
    sorted = sorted.filter((supplier: any) => supplier.supplierType !== 'Supplement'); // Filter out any Supplement suppliers from the array list
    sorted = sorted.filter((e: any, i: any) => sorted.findIndex((a: any) => a.supplierNameM === e.supplierNameM) === i); // Group suppliers (1 of each name) here
    this.suppliersList = sorted; // Assign supplier data to the table data variable
    this.suppliersListALL = supplierList; // Assign ALL supplier data - needed for payment-costing mapping
    this.supplierFilteredData = sorted;
  }

  filterSuppliers(): void {
    this.supplierFilteredData = []; // Empty filtered array first
    const filter = this.filterString.toLowerCase(); // Get the string we filter with here

    // Loop through our MAIN array and add whatever matches our search string
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.suppliersList.length; i++) {
      const option = this.suppliersList[i];
      if (option.supplierNameM.toLowerCase().indexOf(filter) >= 0) {
        this.supplierFilteredData.push(option);
      }
    }
  }

  setEndDate(fromId: any, toId: any): void {
    const divValue = (document.getElementById(fromId) as HTMLInputElement).value;
    (document.getElementById(toId) as HTMLInputElement).value = divValue;
    // Trigger Angular's change detection to update the UI
    this.ngZone.run(() => {
      (document.getElementById(toId) as HTMLInputElement).dispatchEvent(new Event('input'));
      this.bookingHashData[0].returnDate = this.bookingHashData[0].deptDate;
    });
  }

  changeSuppType(event: any): void {
    this.isSupplementStr = event;
  }

  changeSupplier(supplier: any): void {
    this.chosenSupplier = supplier; // Assign supplier groups to the variable
  }

  resetNewPayments(): void {
    this.newReceipt = { 
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      bookingReference: this.extBookingData.bookingReference, creditValue: 0, recStatus: '',
      totalCharge: 0, merchantFee: 0, reference: '', receiptDate: '', paymentMethod: '',
      token: Session.mySession.get('user').token
    };

    this.newPayment = {
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      bookingReference: this.extBookingData.bookingReference, paymentDate: '', paymentCurrency: 'GBP', paymentMethod: '',
      supplierRef: '', supplierID: '', supplierDueDate: '', elementCount: '', paymentReference: '', paymentAmount: 0,
      paymentCategory: 'payment', token: Session.mySession.get('user').token
    };
  }

  calcReceiptMerchantFee(): void {
    if (this.newReceipt.paymentMethod === 'Amex') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(Session.mySession.getBranch().cardFeePercAmex) / 100)).toFixed(2);
    } else if (this.newReceipt.paymentMethod === 'Credit Card') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(Session.mySession.getBranch().cardFeePercCredit) / 100)).toFixed(2);
    } else if (this.newReceipt.paymentMethod === 'Debit Card') {
      this.newReceipt.merchantFee = (Number(this.newReceipt.totalCharge) * (Number(Session.mySession.getBranch().cardFeePercDebit) / 100)).toFixed(2);
    }
  }

  newReceiptCalc(changeAttr: any): void {
    try { // Calculate the total charge - creditValue (what's been received) + totalCharge (what's been taken (by AMEX etc))
      if (!['Amex', 'Credit Card', 'Debit Card'].includes(this.newReceipt.paymentMethod) || this.newReceipt.merchantFee === null) {
        this.newReceipt.merchantFee = 0.00; // Anything but Amex, Credit Card, Debit Card will have £0 merchant fee
      } // Null values must be converted back to £0.00
      if (this.newReceipt.creditValue === null) { this.newReceipt.creditValue = 0.00; }
      if (this.newReceipt.totalCharge === null) { this.newReceipt.totalCharge = 0.00; }

      if (changeAttr === 'creditValue') {
        this.newReceipt.totalCharge = this.newReceipt.creditValue;
        this.calcReceiptMerchantFee();
        this.newReceipt.totalCharge = (Number(this.newReceipt.creditValue) + Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === 'merchantFee') {
        this.newReceipt.totalCharge = (Number(this.newReceipt.creditValue) + Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === 'totalCharge') {
        this.calcReceiptMerchantFee();
        this.newReceipt.creditValue = (Number(this.newReceipt.totalCharge) - Number(this.newReceipt.merchantFee)).toFixed(2);
      } else if (changeAttr === '') {
        this.newReceipt.creditValue = 0.00; this.newReceipt.totalCharge = 0.00; this.newReceipt.merchantFee = 0.00;
      }
    } catch (err: any) { console.log(err); }
  }

  addNewReceipt(): void {
    const creditValue = parseFloat(this.newReceipt.creditValue);
    const totalCharge = parseFloat(this.newReceipt.totalCharge);
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (creditValue === 0 && totalCharge === 0) {
      // Check creditValue and totalCharge are not 0
      this.sendMessageToDialog('', 'Credit value and total charge cannot be £0', '', '');
    } else if (!this.newReceipt.reference || this.newReceipt.reference.length < 3) {
      // Check reference is not empty and at least 3 characters
      this.sendMessageToDialog('', 'Reference is either empty or less than 3 characters', '', '');
    } else if (!this.newReceipt.paymentMethod) {
      // Check paymentMethod is not empty
      this.sendMessageToDialog('', 'Payment method is empty', '', '');
    } else if (!dateRegex.test(this.newReceipt.receiptDate)) {
      // Check receiptDate is in the correct format
      this.sendMessageToDialog('', 'Invalid payment date', '', '');
    } else {
      // Set all necessery dates for date-related validations
      const weekFwDate = new Date(new Date().setDate(new Date().getDate() + 10)).setHours(0, 0, 0, 0);
      const weekBfDate = new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0);
      const chosenDate = new Date(this.newReceipt.receiptDate).setHours(0, 0, 0, 0);

      // Validate receipt dates below - no future, warn olds
      if ((chosenDate > weekBfDate && chosenDate < weekFwDate) ||
      (chosenDate <= weekBfDate && confirm('The receipt date seems to be at least 7 days old. Do you want to continue?')) ||
      (chosenDate >= weekFwDate && confirm('The receipt date seems to be at least 10 days in the future. Do you want to continue?'))) {

        if (this.newReceipt.paymentMethod === 'Felloh - Manual') { this.newReceipt.recStatus = 'noneReqd'; }
        else { this.newReceipt.recStatus = 'newReceipt'; }

        this.pageLoaded = false;
        this.receiptService.createReceipt(this.newReceipt).then((receipt: any) => {
          Session.mySession.resetTimersOnBookingValues().then((res: any) => {
            if (receipt.status === 'OK') {
              this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
                this.recalculateReceipts().then(() => {
                  this.sendMessageToDialog('Receipt has been added to the booking', '', '', ''); // Print success message..
                });
              });
            } else {
              this.sendMessageToDialog('', 'Adding receipt to the booking has failed (' + receipt.status + ')', '', ''); // Print error message..
            }
          });
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2112S)', error, this.newReceipt);
        });
      }
    }
  }

  cancelReceipt(receipt: any): void {
    if (confirm('Are you sure you want to CANCEL receipt ref ' + receipt.reference + '?')) {
      delete receipt.detailRow; // Remove detailRow attribute which is not recognisable at the back
      delete receipt.freeText; // Remove freeText attribute which is not recognisable at the back
      delete receipt.missingPax; // Remove missingPax attribute which is not recognisable at the back
      receipt.token = Session.mySession.get('user').token;
      receipt.receiptCategory = 'mistake'; // Mark the payment as a mistake (reverse thingy)
      receipt.payerId = null; receipt.payerName = null;

      this.pageLoaded = false;
      this.receiptService.updateReceiptSeq(receipt).then((output: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (output.status === 'OK') {
            this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
              this.recalculateReceipts().then(() => {
                this.sendMessageToDialog('Receipt has been cancelled', '', '', ''); // Print success message..
              });
            });
          } else {
            this.sendMessageToDialog('', 'Adding receipt to the booking has failed (' + output.status + ')', '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2113S)', error, receipt);
      });
    }
  }

  payToSuppSel(): void {
    this.newPayment.supplierID = this.selectedSupplier.supplierID;
    this.newPayment.supplierRef = this.selectedSupplier.supplierReference;
    this.newPayment.supplierDueDate = this.selectedSupplier.suppDueDate;
    this.newPayment.elementCount = this.selectedSupplier.elementCount;
  }

  addNewPayment(): void {
    const paymentAmount = parseFloat(this.newPayment.paymentAmount);
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (paymentAmount === 0) {
      // Check paymentAmount is not 0
      this.sendMessageToDialog('', 'Payment amount cannot be £0', '', '');
    } else if (!this.newPayment.paymentReference || this.newPayment.paymentReference.length < 3) {
      // Check reference is not empty and at least 3 characters
      this.sendMessageToDialog('', 'Reference is either empty or less than 3 characters', '', '');
    } else if (!this.newPayment.paymentMethod) {
      // Check paymentMethod is not empty
      this.sendMessageToDialog('', 'Payment method is empty', '', '');
    } else if (!dateRegex.test(this.newPayment.paymentDate)) {
      // Check paymentDate is in the correct format
      this.sendMessageToDialog('', 'Invalid payment date', '', '');
    } else if (!this.newPayment.supplierRef) {
      // Check supplierRef is not empty
      this.sendMessageToDialog('', 'Please select a supplier', '', '');
    } else {
      // Set all necessery dates for date-related validations
      const weekFwDate = new Date(new Date().setDate(new Date().getDate() + 10)).setHours(0, 0, 0, 0);
      const weekBfDate = new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0);
      const chosenDate = new Date(this.newPayment.paymentDate).setHours(0, 0, 0, 0);

      // Validate receipt dates below - no future, warn olds
      if ((chosenDate > weekBfDate && chosenDate < weekFwDate) ||
      (chosenDate <= weekBfDate && confirm('The payment date seems to be at least 7 days old. Do you want to continue?')) ||
      (chosenDate >= weekFwDate && confirm('The payment date seems to be at least 10 days in the future. Do you want to continue?'))) {

      this.pageLoaded = false;
      this.paymentService.createPayment(this.newPayment).then((payment: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (payment.status === 'OK') {
            this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
              this.recalculatePayments().then(() => {
                this.sendMessageToDialog('Payment has been added to the booking', '', '', ''); // Print success message..
              });
            });
          } else {
            this.sendMessageToDialog('', payment.status, '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2114S)', error, this.newPayment);
      });
      }
    }
  }

  cancelPayment(payment: any): void {
    if (confirm('Are you sure you want to CANCEL payment ref ' + payment.paymentReference + '?')) {
      delete payment.detailRow; // Remove detailRow attribute which is not recognisable at the back
      delete payment.freeText; // Remove freeText attribute which is not recognisable at the back
      delete payment.supplierName; // Remove missingPax attribute which is not recognisable at the back
      payment.token = Session.mySession.get('user').token;
      payment.paymentCategory = 'mistake'; // Mark the payment as a mistake (reverse thingy)

      this.pageLoaded = false;
      this.paymentService.updatePaymentSeq(payment).then((output: any) => {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          if (output.status === 'OK') {
            this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
              this.recalculatePayments().then(() => {
                this.sendMessageToDialog('Payment has been cancelled', '', '', ''); // Print success message..
              });
            });
          } else {
            this.sendMessageToDialog('', 'Adding receipt to the booking has failed (' + output.status + ')', '', ''); // Print error message..
          }
        });
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2115S)', error, payment);
      });
    }
  }

  changePaymentLink(linkAction: any, paymentOrg: any, supplier: NgForm|any): void {
    const payment = { ...paymentOrg }; // Create non-binding copy
    // Remove attributes which is not recognisable at the back
    delete payment.freeText; delete payment.detailRow; delete payment.supplierName;

    payment.token = Session.mySession.get('user').token;

    // We are unlinking the element's references from payment data object
    // All of below need to be set to null; the linkAction is used and ignored in Ruby
    if (linkAction === 'unlink') {
      payment.linkAction = 'unlink'; payment.elementCount = null;
      payment.supplierRef = null; payment.supplierID = null;
      payment.supplierDueDate = null;
    // We're linking new element's references to selected payment object
    // All of below properties are assigned to the payment which is then updated in Ruby
    } else if (linkAction === 'link') {
      payment.linkAction = 'link'; payment.elementCount = supplier.elementCount;
      payment.supplierRef = supplier.supplierReference;
      payment.supplierID = supplier.supplierID;
      payment.supplierDueDate = supplier.suppDueDate;
    }

    this.pageLoaded = false;
    this.paymentService.updatePaymentSeq(payment).then((output: any) => {
      Session.mySession.resetTimersOnBookingValues().then((res: any) => {
        if (output.status === 'OK') {
            this.loadExternalBooking(this.extBookingData.bookingReference).then(() => {
              this.recalculatePayments().then(() => {
                this.sendMessageToDialog('Payment link has been updated', '', '', ''); // Print success message..
              });
            });
        } else {
          this.sendMessageToDialog('', output.status, '', ''); // Print error message..
        }
      });
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2116S)', error, payment);
    });
  }

  valiDate(event: any): void {
    if (event.value !== null) {
      const dateIn = event.value._i;
      if (dateIn.year === undefined && // Whenever date is being changed, check if it's in one of three formats (backslash / dots / dash)
        !moment(dateIn, 'DD/MM/YYYY', true).isValid() && !moment(dateIn, 'DD.MM.YYYY', true).isValid() && !moment(dateIn, 'DD-MM-YYYY', true).isValid()) {
        this.sendMessageToDialog('', 'Please follow DD MM YYYY format', '', '');
        event.target.value = '';
      }
    }
  }

  sendMessageToDialog(successMessage: any, failureMessage: any, error: any, requestDetails: any): void {
    if (successMessage === '') {
      // In case the environment is PRODUCTION, we'll need to send error message via email
      if (environment.production && error !== '') {
        // Create a request variable (errorObject) and send it to Greg via API -> SMTP
        const request = this.constants.createErrObj(failureMessage, error, requestDetails, Session.mySession.getUser());
        this.userService.writeError(request).then(() => { });
      } // The environment was not a produciton - we can simply print errors to the console
      else if (!environment.production && JSON.stringify(error) === '{}') { console.log(error); }
      else if (!environment.production && error !== '') { console.log(JSON.stringify(error)); }
    }
    // Append both success & failure message to variables (either NEEDS to be empty)
    this.successMessage = successMessage; this.errorMessage = failureMessage;
    // Mark page as 'loaded' and open statusDialog (to pop-up the message)
    this.pageLoaded = true; this.dialog.open(this.statusDialog);
  }

  // Switch the page view as per the argument (one and only)
  switchView(view: any): void {
    this.bookingOperation = view;
    this.getSFCcoverPrice();
    this.getFlightsOnly();
    this.uploadDetails = { validated: false, uploaded: false };
    if (view === 'Returns') { this.getMonthlyReturns(true); }
  }

  linkToStmntLine(recObject: any): void {
    recObject.extBookingSource = this.extBookingData.extBookingSource;
    this.selectedRec.emit(recObject); // This will 'push' object back to parent
  }

  toNumber(numString: any): number {
    return Number(numString);
  }

  getMonthlyReturns(fullData: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Number(this.monthlyYear) < 2024 || Number(this.monthlyYear) > 2030 || Number(this.monthlyMonth) < 1 || Number(this.monthlyMonth) > 12) {
        this.sendMessageToDialog('', 'One of requested dates is invalid', '', ''); resolve('');
      } else if (this.userTypeOut === 'wcManager') {
        const request: any = {
          company: this.currentRequest.company, operation: this.currentRequest.operation, tradeCode: this.currentRequest.tradeCode,
          year: this.monthlyYear, month: this.monthlyMonth, fullData, token: Session.mySession.get('user').token
        }
        // We need to re-set monthly return data breakdown first (set to empty array)
        this.monthlyReturnData.data = [];

        this.pageLoaded = false;
        this.reportsService.getMonthlyReturns(request).then((output: any) => {
          if (output.status === 'OK') {
            if (fullData) {
              this.motnhlyReturnBranch = output.branchDetails; // Branch details to be displayed on the return
              this.monthlyReturnData.data = output.breakdown; // Breakdown - display each individual booking..
              this.monthlyReturnData.paginator = this.paginatorReturns; // Apply pagination
              this.recalcMonthlyReport();
              this.formatMonthYear();
            }
            this.monthlyReturnSummary = output.data[0]; // Summary - show return details
            this.pageLoaded = true;
            resolve('');
          } else {
            this.sendMessageToDialog('', 'Failed to produce monthly report (' + output.status + ')', '', ''); resolve('');
          }
        }).catch((error: any) => {
          this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1304S)', error, request);
        });
      }
    });
  }

  authenticate(form: NgForm): void {
    const part1 = "I hereby declare that the information I have confirmed in the Monthly Returns Compliance Certificate is correct and we have acted in accordance with the TTA Code of Conduct.";
    const part2 = "I confirm that a Standalone Safe Seat Plan Guarantee has been issued to all customers booked throughout this month and has been declared correctly on the Monthly Returns Compliance Certificate.";
    const final = "Do you wish to submit?";

    if (confirm(part1)) {
      if (confirm(part2)) {
        if (confirm(final)) {
          // Use login funcitonality (copied from login) to authenticate the user
          const request = { email: form.value.email, password: form.value.password };
          this.pageLoaded = false;
          this.userService.login(request).then(async (output: any) => {
            if (output.status === 'OK') {
              // Re-do sesson variables as we've just logged in
              Session.mySession.set('user', { id: output.data.id, token: output.data.token });
              Session.mySession.setToken(output.data.token);
              this.confirmMonthlyReturn();
            } else {
              this.pageLoaded = true;
              this.sendMessageToDialog('', output.status, '', ''); // Display error status from the back
            }
          }).catch((error: any) => {
            this.pageLoaded = true;
            this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1305S) - please try again', error, request);
          })
        }
      }
    }
  }

  confirmMonthlyReturn(): void {
    const request = {
      company: this.monthlyReturnSummary.company, operation: this.monthlyReturnSummary.operation, tradeCode: this.monthlyReturnSummary.tradeCode,
      year: this.monthlyYear, month: this.monthlyMonth, monthlyArr: [this.monthlyReturnSummary], monthlyBreakdown: this.monthlyReturnData.data,
      agentName: Session.mySession.getUser().fullName, agentEmail: Session.mySession.getUser().email,
      token: Session.mySession.get('user').token
     }

     this.pageLoaded = false;
     this.reportsService.confirmMonthlyReturn(request).then((output: any) => {
      if (output.status === 'OK') {
        this.sendMessageToDialog('Your monthly report has been signed off', '', '', '');
        this.getMonthlyReturns(true);
      } else {
        this.sendMessageToDialog('', output.status, '', '');
      }
     }).catch((error: any) => {
      this.pageLoaded = true;
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1306S) - please try again', error, request);
    })
  }

  recalcMonthlyReport(): void {
    this.monthlyReportStats.bookings = this.monthlyReturnData.data.filter((booking: any) => !booking.oldBooking).length;
    this.monthlyReportStats.paxNo = this.monthlyReturnData.data.reduce((total: any, booking: any) => total + booking.paxNoSbmt, 0);
    this.monthlyReportStats.paxNoTAtolFlOnly = this.monthlyReturnData.data.reduce((total: any, booking: any) => total + booking.tAtolPaxFlOnlySbmt, 0);
    this.monthlyReportStats.paxNoTAtolPackage = this.monthlyReturnData.data.reduce((total: any, booking: any) => total + booking.tAtolPaxPkgSbmt, 0);
  }

  addUpCosts(element: any, type: any): void {
    // Initialize the total gross to 0
    let total: number = 0;

    // List of keys in the element object that contain arrays
    const keys = ['accoms', 'attractions', 'carhires', 'carparks', 'cruises', 'flights', 'miscs', 'packages', 'supplements', 'trains', 'transfers'];

    // Iterate over each key
    keys.forEach(key => {
      // Check if the key exists in the element and is an array
      if (Array.isArray(element[key])) {
        // Sum up the gross values in the array and add to totalGross
        element[key].forEach((item: any) => {
          // Validate whether each item (subElement) can be reduced below minimum value (set in the recalculateBookingsValuesLogic method)
          // Validation takes place only if booking has been created more than 14 days ago (editToggleDisable == true)
          // If more than 14 days, and the value entered is less than minXXX, then the attribute is set back to minXXX, and message is displayd (showCostMsg)
          if (item[type] === null || item[type] === 0 || item[type] === undefined) { item[type] = '0'; }
          if (this.editToggleDisable && Number(item.grossCost) < Number(item.minGross)) { item.grossCost = item.minGross; this.showCostMsg(element); }
          if (this.editToggleDisable && Number(item.netCost) < Number(item.minNet)) { item.netCost = item.minNet; this.showCostMsg(element); }
          if (this.editToggleDisable && Number(item.commission) < Number(item.minComm)) { item.commission = item.minComm; this.showCostMsg(element); }
          if (this.editToggleDisable && Number(item.tax) < Number(item.minTax)) { item.tax = item.minTax; this.showCostMsg(element); }
          if (this.editToggleDisable && Number(item.discount) < Number(item.minDiscount)) { item.discount = item.minDiscount; this.showCostMsg(element); }
          total += Number(item[type]);
        });
      }
    });

    // Assign the total gross to element[type]
    if (type === 'grossCost') { element.gross = total; }
    else if (type === 'netCost') { element.net = total; }
    else if (type === 'commission') { element.commission = total; }
    else if (type === 'tax') { element.tax = total; }
    else if (type === 'discount') { element.discount = total; }
    else if (type === 'depositAmount') { element.depositAmount = total; }
  }

  showCostMsg(element: any): void {
    // Set costErr to true
    element.costErr = true;
    // Set a timeout to reset costErr to false after 2 seconds (2000 milliseconds)
    setTimeout(() => {
      element.costErr = false;
    }, 2000); // 2000 milliseconds = 2 seconds
  }

  formatMonthYear(): void {
    // Split the input value into year and month
    const year: any = this.monthlyYear;
    const month: any = this.monthlyMonth;
  
    // Create a new Date object with the given year and month
    const date = new Date(year, month - 1); // month is 0-indexed
  
    // Define options for formatting the month
    const options = { year: 'numeric', month: 'long' } as const;
  
    // Use the Intl.DateTimeFormat API to format the date
    this.monthlyPeriod = new Intl.DateTimeFormat('en-US', options).format(date);
  }

  setMonthlyDates(): void {
    const currentDate = new Date(); // Get current date
    let currentMonth = currentDate.getMonth(); // getMonth() is zero-based
    let currentYear = currentDate.getFullYear();
    
    // Adjust to get the previous month
    if (currentMonth === 0) { // January
      currentMonth = 12; // December
      currentYear -= 1; // Previous year
    } else if (currentMonth === 1) {
      currentMonth = currentMonth;
    }
    // Set variables to be displayed on the page
    this.currentDate = currentDate;
    this.monthlyMonth = currentMonth;
    this.monthlyYear = currentYear;
  }

  dateTypeChange(selectValue: any): void {
    // If selected date type is blank, that means user does not want to search by date type anymore..
    if (selectValue.value == null) {
      this.dateRangeBlock = true;
    } else {
      this.dateRangeBlock = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  // Very much needed for the UI responsiveness
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  updateBookingSeq(type: any): void {
    const request: any = {
      company: this.extBookingData.company, operation: this.extBookingData.operation, tradeCode: this.extBookingData.tradeCode,
      bookingReference: this.extBookingData.bookingReference, bookingStatus: this.extBookingData.bookingStatus, returnDate: this.extBookingData.returnDate,
      token: Session.mySession.get('user').token
    }

    if (type === 'ATOL coverage') {
      request.underATOL = String(this.extBookingData.underATOL); // Assign underATOL variable
      // Assign underATOL variable
      if (this.meetsATOLcriteria.flightOnly) { request.atolType = 'Flight'; }
      else if (this.meetsATOLcriteria.atolPackage) { request.atolType = 'Package'; }
    } else if (type === 'Branch name') {
      request.tradingNameId = this.extBookingData.tradingNameId;
      request.branchName = this.branchDetails.tradingNames.find((name: any) => name.id === request.tradingNameId).tradingName;
      this.extBookingData.branchName = request.branchName;
    }

    this.pageLoaded = false;
    this.bookingService.updateBookingSeq(request).then((output: any) => {
      if (output.status === 'OK') {
        Session.mySession.resetTimersOnBookingValues().then((res: any) => {
          this.reloadBooking(this.extBookingData.bookingReference);
          this.sendMessageToDialog(type + ` has been updated`, '', '', ''); // Display success message..
        });
      } else {
        this.sendMessageToDialog('', type + ' update has failed (' + output.status + ')', '', ''); // Display error message
      }
    }).catch((error: any) => {
      this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E1307S)', error, request);
    });
  }

  openEmailToOps(bookingReference: any): void {
    window.open(`mailto:operations@thetravelnetworkgroup.co.uk?subject=Request for changes - booking ${bookingReference}&body=` +
      `%0d%0a%0d%0aKind regards%2c%0d ${Session.mySession.getUser().fullName}`);
  }

  showExtRefSwitch(): void {
    AppComponent.myapp.showExtRefSwitch(!this.showExtReference);
    this.showExtReference = AppComponent.myapp.showExternalRef;
  }

  onBranchChange(): void {
    this.currentRequest.tradeCode = this.branchDetails.tradeCode;

    if (this.bookingOperation === 'Upload' && !this.borderauxCodes.includes(this.currentRequest.tradeCode)) { this.bookingOperation = 'List'; this.firstRes = 1; this.loadBookingList(); this.getMonthlyReturns(false); }
    else if (this.bookingOperation === 'List') { this.firstRes = 1; this.loadBookingList(); this.getMonthlyReturns(false); }
    else if (this.bookingOperation === 'Returns') { this.getMonthlyReturns(true); }
    else if (this.bookingOperation === 'Add') { this.resetUploadData(); }
  }

  /*
  Below are methods which were initially used in the updateBooking functionality
  They were deprecated (at least for now) because updateBooking & createBooking
  can use the same API - I'd like to avoid using the same APIs from the main booking platfrom

  updateBookingSeq(): Promise<any> {
    return new Promise((resolve, reject) => {
      const booking = this.extBookingData; // Assign global variable to local (shorters it)
      // Create a request variable to hold the booking status user wants to change it to
      const request: any = {
        company: booking.company, operation: booking.operation, tradeCode: booking.tradeCode,
        bookingReference: booking.bookingReference, bookingStatus: booking.bookingStatus,
        bookingDate: booking.bookingDate, deptDate: booking.deptDate, returnDate: booking.returnDate,
        leadName: booking.leadName, paxNo: String(booking.paxNo),
        token: Session.mySession.get('user').token
      };

      this.bookingService.updateBookingSeq(request).then((output: any) => {
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then(() => { resolve('Done'); });
        } else {
          this.sendMessageToDialog('', 'Failed to update booking (' + output.status + ')', '', ''); // Print error message
          resolve('Error'); // Job done
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2106S)', error, request);
        resolve('Error'); // Job done
      });
    });
  }

  updateElementSeq(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: any = { data: [], token: Session.mySession.get('user').token };
      request.data = this.constructElementRequest();

      this.elementService.updateElement(request).then((output: any) => {
        console.log(output);
        if (output.status === 'OK') {
          Session.mySession.resetTimersOnBookingValues().then(() => { resolve('Done'); });
        } else {
          this.sendMessageToDialog('', 'Failed to edit one of the elements (' + output.status + ')', '', '');
        }
      }).catch((error: any) => {
        this.sendMessageToDialog('', 'SinGS could not complete your request at this time (E2107S)', error, request);
        resolve('Error');
      });
    });
  }

  constructElementRequest(): any {
    const bkData = this.extBookingData; // Used only as a reference..
    const elementRequestArray: any = []; // This is where we'll dump everything..
    // For each element data, we will construct a new request variable
    // That variable will hold current element (supplier) data (with miniHashes etc)
    this.extElementData.data.forEach((eleData: any, eleIndex: any) => {
      const request: any = {
        coreElementData: {
          company: eleData.company, operation: eleData.operation, tradeCode: eleData.tradeCode,
          bookingReference: eleData.bookingReference, elementStatus: eleData.elementStatus,
          supplierName: eleData.supplierName, externalElement: eleData.externalElement,
          supplierReference: eleData.supplierReference, bookingDate: bkData.bookingDate,
          deptDate: bkData.deptDate, returnDate: bkData.returnDate
        }
      };
      // Assign the elementSource if the element (supplier) is freshly added
      if (eleData.elementCount === undefined) { request.coreElementData.elementSource = 'eleSings' + eleIndex; }
      else { request.coreElementData.elementCount = eleData.elementCount; }

      let index = 0; // This index will be assigned to miniHash number
      const uniqueTypes = new Set(); // This is where we'll store unique types in
      eleData.flights.forEach((el: any, subIndex: any) => {
        let miniHash = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}');
        // Work out all important dates below
        miniHash[Object.keys(miniHash)[0]].bookingDate = bkData.bookingDate;
        miniHash[Object.keys(miniHash)[0]].deptDate = bkData.deptDate;
        miniHash[Object.keys(miniHash)[0]].returnDate = bkData.returnDate;
        // Work out the rest of basic information below
        miniHash[Object.keys(miniHash)[0]].subElementType = 'flight';
        miniHash[Object.keys(miniHash)[0]].subElementStatus = el.flightStatus;
        miniHash[Object.keys(miniHash)[0]].supplierRef = eleData.supplierReference;
        // Calculate costs and assign miniHash to megaHash
        miniHash = this.constructElementCosts(miniHash, el, eleData, subIndex);
        Object.assign(request, miniHash); index += 1;
        uniqueTypes.add('flight');
      });
      eleData.accoms.forEach((el: any, subIndex: any) => {
        let miniHash = JSON.parse('{"elmentDataMiniHash' + index + '" : {}}');
        // Work out all important dates below
        miniHash[Object.keys(miniHash)[0]].checkInDate = bkData.bookingDate;
        miniHash[Object.keys(miniHash)[0]].numNights = Math.round(
          (Math.abs(Number(new Date(bkData.returnDate)) - Number(new Date(bkData.deptDate)))) / (24 * 60 * 60 * 1000)
        );

        // Work out the rest of basic information below
        miniHash[Object.keys(miniHash)[0]].subElementType = 'accom';
        miniHash[Object.keys(miniHash)[0]].subElementStatus = el.accomStatus;
        miniHash[Object.keys(miniHash)[0]].supplierReference = eleData.supplierReference;
        // Calculate costs and assign miniHash to megaHash
        miniHash = this.constructElementCosts(miniHash, el, eleData, subIndex);
        Object.assign(request, miniHash); index += 1;
        uniqueTypes.add('accom');
      });
      // TBD - more element types to come...

      // Work out the elementType we'll update the element with
      // The elementTypeNew property will hold what will be inserted at the end
      if (uniqueTypes.has('package')) { request.coreElementData.elementType = 'package'; }
      else if (!uniqueTypes.has('package') && uniqueTypes.size > 1) { request.coreElementData.elementType = 'mixed'; }
      else { request.coreElementData.elementType = uniqueTypes.values().next().value; }
      console.log(request);
      elementRequestArray.push(request);
    });
    return elementRequestArray;
  }

  constructElementCosts(miniHash: any, subElement: any, element: any, subIndex: any): any {
    miniHash[Object.keys(miniHash)[0]].grossCost = subElement.grossCost;
    miniHash[Object.keys(miniHash)[0]].netCost = subElement.netCost;
    miniHash[Object.keys(miniHash)[0]].tax = subElement.tax;
    miniHash[Object.keys(miniHash)[0]].discount = subElement.discount;
    miniHash[Object.keys(miniHash)[0]].commission = subElement.commission;

    // Set flag to update / create depending on whether the ID is present..
    if (element.id !== undefined) { // This is set only in existing elements!
      if (subElement.id === undefined) { miniHash[Object.keys(miniHash)[0]].action = 'create'; }
      else { miniHash[Object.keys(miniHash)[0]].action = 'update'; }
    }

    // Assign xxxCount below - required when updating existing element..
    if (miniHash[Object.keys(miniHash)[0]].subElementType === 'accom') {
      if (subElement.id === undefined) { // Assign xxxSource & externalXxx (true) if it's new subElement
        miniHash[Object.keys(miniHash)[0]].accomSource = 'accSings' + subIndex;
        miniHash[Object.keys(miniHash)[0]].externalAccom = true;
      } else { miniHash[Object.keys(miniHash)[0]].accomCount = subElement.accomCount; }
    }
    if (miniHash[Object.keys(miniHash)[0]].subElementType === 'flight') {
      if (subElement.id === undefined) { // Assign xxxSource & externalXxx (true) if it's new subElement
        miniHash[Object.keys(miniHash)[0]].flightSource = 'fliSings' + subIndex;
        miniHash[Object.keys(miniHash)[0]].externalFlight = true;
      } else { miniHash[Object.keys(miniHash)[0]].flightCount = subElement.flightCount; }
    }

    return miniHash;
  }
  */
}

// Used for grouping uploaded bookings..
const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});
