import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient, private apiService: ApiService) { }

  async getBookingList(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'getBookingList', body, { headers }).toPromise();
    return output;
  }

  async getBookingsLatest(request: any, bookingCount: any, whatStatus: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getBookingsLatest?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&bookingCount=' + bookingCount + '&bookingStatus=' + whatStatus + '&agentEmail=' + request.email + '&createOrUpdate=create&token=' + request.token).toPromise();
    return output;
  }

  async createBooking(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'createBooking', body, { headers }).toPromise();
    return output;
  }

  async duplicateBooking(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = this.http.post(environment.apiURL + 'duplicateBooking', body, { headers }).toPromise();
    return output;
  }

  async updateBookingSeq(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updateBookingSeq', body, { headers }).toPromise();
    return output;
  }

  async autoCalcBookingDates(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'autoCalcBookingDates', body, { headers }).toPromise();
    return output;
  }

  async updateBookingStatus(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'updateBookingStatus', body, { headers }).toPromise();
    return output;
  }

  async getBookingFull(request: any): Promise<object> {
    let output;
    if (request.agentEmail) {
      output = await this.http.get(environment.apiURL + 'getBookingFull?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference + '&token=' + request.token + '&agentEmail=' + request.agentEmail).toPromise();
    } else {
      output = await this.http.get(environment.apiURL + 'getBookingFull?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference + '&token=' + request.token).toPromise();
    }
    return output;
  }

  async getBookingHistory(request: any): Promise<object> {
    const output = await this.http.get(environment.apiURL + 'getBookingHistory?company=' + request.company + '&operation=' + request.operation +
      '&tradeCode=' + request.tradeCode + '&bookingReference=' + request.bookingReference + '&token=' + request.token).toPromise();
    return output;
  }

  async bookingExists(request: any): Promise<object> {
    const url = environment.apiURL + 'bookingExists?company=' + request.company + '&operation=' + request.operation +
    '&tradeCode=' + request.tradeCode + '&bookingReference=' + encodeURIComponent(request.bookingReference) + '&token=' + request.token;

    return this.apiService.makeHttpRequest('get', url);
  }

  async validateExtBookings(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'validateExtBookings', body, { headers }).toPromise();
    return output;
  }

  async uploadExtBookings(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'uploadExtBookings', body, { headers }).toPromise();
    return output;
  }

  async validateCSVpayments(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'validateCSVpayments', body, { headers }).toPromise();
    return output;
  }

  async uploadCSVpayments(request: any): Promise<object> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'uploadCSVpayments', body, { headers }).toPromise();
    return output;
  }

  async runBordereauUpload(request: any): Promise<any> {
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const output = await this.http.post(environment.apiURL + 'runBordereauUpload', body, { headers }).toPromise();
    return output;
  }
}
