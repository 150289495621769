<mat-card-content>

  <div *ngIf="!haveAccess && pageLoaded">
    <div class="errorMessage">
      <h2 style="text-align: center; margin-top: 2.5%;">We're sorry but You don't have access to this page.</h2>
    </div>
  </div>

  <div *ngIf="!pageLoaded" class="loading-indicator">
    <mat-spinner style="margin:0 auto;"></mat-spinner>
  </div>

  <div *ngIf="haveAccess">
    <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
      <!-- PRF HEADER LIST -->
      <div *ngIf="innerWidth > 1024" style="width: 100%;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="switchView('list')" mat-button [disabled]="prfList">
            <mat-icon>list</mat-icon> PRF List
          </button>
          <button class="navButtons" (click)="switchView('reports')" mat-button [disabled]="prfReports">
            <mat-icon>description</mat-icon> PRF Reports          
          </button>
          <button class="navButtons" (click)="switchView('new')" mat-button [disabled]="prfNew" *ngIf="!checkUserType()">
            <mat-icon>add</mat-icon> Create PRF
          </button>
          <div *ngFor="let prf of prfBreakdown; let prfNo = index" id="div{{prfNo}}">
            <button class="navButtons" mat-button [disabled]="prfNoView[prfNo]" (click)="switchView(prfNo)">
              <mat-icon>insert_drive_file</mat-icon> PRF {{prf[0]["prfNumber"]}}
            </button>
          </div>
          <button *ngIf="listedViewPrfs.length > 0" class="navButtons" style="width: 64px;" mat-button matTooltip="Remove Last" (click)="closePrfTab()">
            <mat-icon>clear</mat-icon>
          </button>
          <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon> Help
          </button>
        </div>
      </div>
      <!-- PRF TAB LIST -->
      <div style="width: 100%" *ngIf="innerWidth < 1025">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button class="navButtons" (click)="switchView('list')" mat-button [disabled]="prfList">
            <mat-icon>list</mat-icon>
          </button>
          <button class="navButtons" (click)="switchView('new')" mat-button [disabled]="prfNew" *ngIf="!checkUserType()">
            <mat-icon>add</mat-icon>
          </button>
          <div *ngFor="let prf of prfBreakdown; let prfNo = index" id="div{{prfNo}}">
            <button class="navButtons" mat-button [disabled]="prfNoView[prfNo]" (click)="switchView(prfNo)">
              <mat-icon>insert_drive_file</mat-icon>
            </button>
          </div>
          <button *ngIf="listedViewPrfs.length > 0" class="navButtons" style="width: 64px;" mat-button matTooltip="Remove Last" (click)="closePrfTab()">
            <mat-icon>clear</mat-icon>
          </button>
          <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
            <mat-icon class="helpIcon">help</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <mat-sidenav-container>
      <mat-sidenav #drawer mode="side" closed>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-card [style.overflow]="'auto'">
          <div class="sideNavContent">
            <!-- CREATE PRF AREA -->
            <div *ngIf="prfNew">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create PRF
                <!--  
                  <button style="margin-left: 10px;" *ngIf="firstCompleted && thirdCompleted" mat-raised-button color="primary" (click)="showCreateReport()" mat-button>
                   Summary Report
                  </button>
                -->
              </h1>
              <mat-divider></mat-divider>
              <mat-horizontal-stepper [linear]="true" #stepper>
                <ng-template matStepperIcon="edit">
                  <mat-icon>done</mat-icon>
                </ng-template>
                <mat-step label="Online PRF Information" [completed]="firstCompleted">
                  <!--
                    <h3 *ngIf="createMatchedBookingReference == ''" style="margin-top: 40px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Suggested Booking References
                      </div>
                      <div fxFlex="40">
                        <div *ngIf="createToogleSuggestedBookings == false && createListOfSuggestedBookings.length == 0">
                          <button color="primary" mat-raised-button (click)="getSuggestedBookingReferences()">
                            Search
                          </button>
                        </div>
                        <div *ngIf="createToogleSuggestedBookings == true && createListOfSuggestedBookings.length > 0">
                          <div fxFlex="50" class="containerOfReferenceAtSuggestions">
                            <ng-container *ngFor="let suggestion of createListOfSuggestedBookings">
                              <div class="reference" style="padding:5px 5px;">
                                <button mat-button 
                                  class="suggestions" 
                                  matTooltip="Select Booking" 
                                  [class.selected]="suggestion.selected"
                                  (click)="toggleSuggestedBooking(suggestion)">
                                    {{ suggestion.bookingReference }}
                                </button>
                                <button
                                  style="color:#4D5FD1; border: 0px; padding: 10px; cursor: pointer;"
                                  color="default"
                                  matTooltip="More Info"
                                  (click)="openSuggestedBookingDialog(suggestion)">
                                  <mat-icon>info</mat-icon>
                                </button>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <div *ngIf="createToogleSuggestedBookings == true && createListOfSuggestedBookings.length == 0">
                          <div fxFlex="50">
                            No Suggestions Found
                          </div>
                        </div>
                      </div>
                    </div>
                  </h3>
                  -->
                  <div *ngIf="createMatchedBookingReference == ''">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25" style="margin-bottom: 10px !important;"> 
                          Filter Booking Reference List
                        </div>
                        <div fxFlex="20" style="display:contents;">
                          <mat-form-field>
                            <input matInput placeholder="Booking Reference" [(ngModel)]="filterBookingReference">
                          </mat-form-field>
                          <mat-form-field style="margin-left:20px !important;">
                            <input matInput placeholder="Lead Client Name" [(ngModel)]="filterClientName">
                          </mat-form-field>
                          <mat-form-field style="margin-left:20px !important;">
                            <input matInput placeholder="Booking Group Name" [(ngModel)]="filterBookingGroup">
                          </mat-form-field>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important; margin-left:20px !important;">
                            <mat-label>Departure Date</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="filterDepartureDate" name="filterDepartureDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <button matTooltip="Filter Booking Reference List"  class="buttonClass" mat-raised-button color="primary" (click)="applyBookingReferenceListFilters()" style="margin-bottom:25px; margin-left:20px;">
                          <mat-icon>search</mat-icon>
                        </button>
                        <button matTooltip="Reset All Filters" class="buttonClass" mat-raised-button color="primary" (click)="clearBookingReferenceListFilters()" style="margin-bottom:25px; margin-left:20px;">
                          <mat-icon>refresh</mat-icon>
                        </button>
                      </div>
                    </h3>
                    <h3>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25" style="margin-bottom: 10px !important;"> 
                          <span style="color:#4D5FD1">*</span> Booking Reference 
                        </div>
                        <div fxFlex="20" style="display:contents;">
                          <mat-form-field style="width: 750px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select placeholder="Booking Reference ( Booking Date | Source | Lead Client | Total Gross | External Booking )" [(ngModel)]="createBookingReference" multiple style="vertical-align: middle;" (selectionChange)="checkOnlinePrfInformation()">
                              <mat-option *ngFor="let booking of listOfAvailableBookings" [value]="booking.bookingReference" [disabled]="isCreateBookingDisabled()">
                                {{booking.bookingReference}} 
                                <span style="color:gray">( {{booking.bookingDate}} | <span *ngIf="booking.bookingSource != null && booking.bookingSource !=''">{{booking.bookingSource}}</span> <span *ngIf="booking.bookingSource == null || booking.bookingSource ==''">N/A</span> | <span *ngIf="booking.leadName != null && booking.leadName !=''">{{booking.leadName}}</span> <span *ngIf="booking.leadName == null || booking.leadName ==''">N/A</span> | {{booking.gross}} | <span *ngIf="booking.extBookingSource == null || booking.extBookingSource == ''">N/A</span> <span *ngIf="booking.extBookingSource != null && booking.extBookingSource != ''">{{booking.extBookingSource}}</span> ) </span> 
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <button  matTooltip="Attach Booking Reference(s)"  class="buttonClass" mat-raised-button color="primary" (click)="searchForCreateBookingRef()" style="margin-bottom:25px; margin-left:20px;">
                          Attach <mat-icon>add</mat-icon>
                        </button>
                        <button matTooltip="Remove Added Booking Reference(s)" class="buttonClass" mat-raised-button color="primary" (click)="clearAttachedBookingReferences()" style="margin-bottom:25px; margin-left:20px;">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </h3>
                  </div>

                  <div *ngIf="createMatchedBookingReference != '' && createMatchedBookingReference != null && createMultipleBookingReference == true">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Added Booking Reference(s)
                        </div>
                        <div fxFlex="50" class="containerOfReference">
                          <ng-container *ngFor="let reference of createMatchedBookingReference">
                            <div class="reference">{{ reference.trim() }}</div>
                          </ng-container>
                        </div>
                        <div fxFlex="25">
                          <button matTooltip="Remove Added Booking Reference(s)" class="buttonClass" mat-raised-button color="primary" (click)="removeCreateMatchBookingRef()" style="margin-top:5px; margin-left:20px;">
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createMatchedBookingReference != '' && createMatchedBookingReference != null && createMultipleBookingReference == false">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Added Booking Reference(s)
                        </div>
                        <div fxFlex="50">
                          <div class="reference" style="display:initial;">{{ createMatchedBookingReference }}</div>
                          <button matTooltip="Remove Added Booking Reference(s)" class="buttonClass" mat-raised-button color="primary" (click)="removeCreateMatchBookingRef()" style="margin-top:5px; margin-left:20px;">
                            <mat-icon>clear</mat-icon>
                          </button>
                          <button matTooltip="Refresh Booking Data" class="buttonClass" mat-raised-button color="primary" (click)="reLoadCreateBookingData(false)" style="margin-top:5px; margin-left:20px;">
                            <mat-icon>refresh</mat-icon>
                          </button>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Have the client funds been received?
                      </div>
                      <div fxFlex="40">
                        <mat-radio-group [(ngModel)]="createIsClientFundsReceived" name="createIsClientFundsReceived" style="vertical-align: middle;" color="primary" (change)="checkOnlinePrfInformation()">
                          <mat-radio-button [value]="1">Yes</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" [value]="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Has the client returned from travel?
                      </div>
                      <div fxFlex="40">
                        <mat-radio-group [(ngModel)]="createHasClientReturned" (change)="setFP($event,'Create')"  name="createHasClientReturned" style="vertical-align: middle;" color="primary">
                          <mat-radio-button [value]="1">Yes</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" [value]="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 40px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Type of Claim Requested
                      </div>
                      <div fxFlex="50">
                        <mat-checkbox *ngIf="createNotMatchingSuppliers == false" (change)="clickedClaim($event,'Supplier Payment')" name="{{createClaimSupplierPayment.checked}}" [(ngModel)]="createClaimSupplierPayment.checked">Supplier Payment</mat-checkbox>
                        <mat-checkbox (change)="clickedClaim($event,'Commission')" name="{{createClaimCommission.checked}}" [(ngModel)]="createClaimCommission.checked" style="margin-left: 20px;">Commission</mat-checkbox>
                        <mat-checkbox (change)="clickedClaim($event,'Reimbursement')" name="{{createClaimReimbursement.checked}}" [(ngModel)]="createClaimReimbursement.checked" style="margin-left: 20px;">Reimbursement</mat-checkbox>
                        <mat-checkbox *ngIf="createMultipleBookingReference == false" (change)="clickedClaim($event,'Client Refund')" name="{{createClaimClientRefund.checked}}" [(ngModel)]="createClaimClientRefund.checked" style="margin-left: 32.35px;">Client Refund</mat-checkbox>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Select Supplier
                      </div>
                      <div fxFlex="40">
                        <mat-form-field style="width: 300px; max-width: unset;" class="transactionValueNormalTab">
                          <mat-select placeholder="Supplier" [(ngModel)]="createSupplierName" style="vertical-align: middle;" (selectionChange)="loadCreateSupplierDataFromBooking(false)">
                            <mat-option *ngIf="createSupplierNoneIsAvailable" value="None">None</mat-option>
                            <mat-option *ngIf="createClaimSupplierPayment.checked == false" value="Various Suppliers">Various Suppliers</mat-option>
                            <mat-option *ngFor="let supplier of suppliersList" [value]="supplier.supplierNameM">
                              {{supplier.supplierNameM}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <button matTooltip="Refresh Supplier Data" *ngIf="createSupplierName!='None' && createSupplierName!='Various Suppliers' && createSupplierName!=''" class="buttonClass" mat-raised-button color="primary" (click)="loadCreateSupplierDataFromBooking(true)" style="margin-top:5px; margin-left:20px;">
                          <mat-icon>refresh</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                      </div>
                      <div *ngIf="!newSupplierDiv" fxFlex="40" class="newSupplierDiv">
                        <button type="submit" color="accent" mat-raised-button (click)="newSupplierDiv = true;">
                          Request New Supplier
                        </button>
                      </div>
                      <div *ngIf="newSupplierDiv" class="newSupplierDiv">
                        <h4>Please note that your request may take up to 3 working days to process.<br>
                          We appreciate your patience and will do our best to resolve it as soon as possible.<br><br>
                          If you need any assistance or have questions during this process, feel free to contact our support team<br>
                          at <a href="mailto:operations@thetravelnetworkgroup.co.uk">operations@thetravelnetworkgroup.co.uk</a></h4>
                        <form ngNativeValidate #createSupplierForm="ngForm" (ngSubmit)="createUnapprovedSupplier(createSupplierForm)">
                          <div style="display: flex; justify-content: space-between;">
                            <div>
                              <mat-form-field class="newSuppFields">
                                <input matInput ngModel required placeholder="Supplier Name" name="supplierNameM" #supplierNameM="ngModel" minlength="3" maxlength="100">
                              </mat-form-field>
                            </div>
                            <!--<div>
                              <mat-form-field class="newSuppFields">
                                <mat-select ngModel required name="supplierType" #supplierType="ngModel" placeholder="Supplier Type">
                                  <mat-option *ngFor="let type of supplierTypes" [value]="type['value']">
                                    {{type['viewValue']}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>-->
                          </div>
                          <button style="width: 140px;" type="submit" color="primary" mat-raised-button [disabled]="createSupplierForm.invalid">
                            Send Request
                          </button>
                        </form>
                      </div>
                    </div>
                  </h3>
                  <div *ngIf="createFinancialProtectionEnable">
                    <h3 style="margin-top: 35px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Financial Protection
                        </div>
                        <div fxFlex="50">
                          <mat-checkbox (change)="setCRD({value:'None'},'Create')" name="{{createFinancialProtection.checks[0]}}" [(ngModel)]="createFinancialProtection.checks[0]">None</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'ATOL'},'Create')" name="{{createFinancialProtection.checks[1]}}" [(ngModel)]="createFinancialProtection.checks[1]" style="margin-left: 20px;">ATOL</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'PSA'},'Create')" name="{{createFinancialProtection.checks[2]}}" [(ngModel)]="createFinancialProtection.checks[2]" style="margin-left: 20px;">PSA</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'SFC / SAFI'},'Create')" name="{{createFinancialProtection.checks[3]}}" [(ngModel)]="createFinancialProtection.checks[3]" style="margin-left: 20px;">SFC / SAFI</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'TTA Member'},'Create')" name="{{createFinancialProtection.checks[4]}}" [(ngModel)]="createFinancialProtection.checks[4]" style="margin-left: 20px;">TTA Member</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createClientReturnDateEnable">
                    <h3 style="margin-top: 25px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Client Return Date
                        </div>
                        <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="createClientReturnDate" name="createClientReturnDate" (dateChange)="checkOnlinePrfInformation()">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createClaimSupplierPayment.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Supplier Payment Method
                        </div>
                        <div fxFlex="50">
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[0]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[0]">Natwest Faster</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[1]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[1]" style="margin-left: 20px;">Natwest CHAPS</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[2]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[2]" style="margin-left: 20px;">Barclays</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[3]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[3]" style="margin-left: 20px;">Corpay</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[4]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[4]" style="margin-left: 20px;">Monteith</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[5]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[5]" style="margin-left: 20px;">Amex Forex Transfer</mat-checkbox>
                          <mat-checkbox (change)="checkOnlinePrfInformation()" name="{{createClaimSupplierPayment.paymentMethods.checks[6]}}" [(ngModel)]="createClaimSupplierPayment.paymentMethods.checks[6]" style="margin-left: 20px;">Other</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createClaimCommission.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Commission Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="createClaimCommission.paymentMethod" name="createClaimCommissionMethod" style="display:flex; flex-wrap: wrap;" color="primary" (change)="checkOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createClaimReimbursement.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Reimbursement Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="createClaimReimbursement.paymentMethod" name="createClaimReimbursementMethod" style="display:flex; flex-wrap: wrap;"  color="primary" (change)="checkOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="createClaimClientRefund.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Client Refund Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="createClaimClientRefund.paymentMethod" name="createClaimClientRefundMethod" style="display:flex; flex-wrap: wrap;" color="primary" (change)="checkOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="checkIfNatWestChapsIsChecked('Create')">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Natwest CHAPS
                        </div>
                        <h3 style="color: #FA0E62;">
                          Please note that Chaps payments incur a £12.50 charge.<br>Day 1 to Day 3 Trustees SLA is still applicable.                       
                        </h3>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="firstCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                  </div>
                </mat-step>
                <mat-step label="Supporting Documentation" [completed]="secondCompleted">
                  <div *ngIf="createMatchedBookingReference != '' && createMatchedBookingReference != null">  
                    <div *ngIf="createMultipleBookingReference == true">
                      <div *ngFor="let tempBooking of createMatchedBookingData">
                        <div style="margin-top:20px;" class="ownExpansionHeader">
                          <div class="headerTopLeft">
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = false;">
                              <mat-icon>expand_less</mat-icon>
                            </button>
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = true;">
                              <mat-icon>expand_more</mat-icon>
                            </button>
                             Attached Documents from {{tempBooking.bookingReference}}       
                            <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3document(tempBooking)" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                              <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                            </button>                      
                          </div>
                        </div>
                        <div [@customExpansionDetails]="tempBooking.showListOfDocuments == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                          <div *ngIf="tempBooking.listOfDocumentsData.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                            <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                              <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="fileName">
                                  <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="lastModified">
                                  <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="size">
                                  <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="download">
                                  <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                              </table>
                            </div>
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table [dataSource]="tempBooking.listOfDocumentsData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="fileName">
                                  <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="lastModified">
                                  <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="size">
                                  <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                                </ng-container>                          
                                <ng-container matColumnDef="download">
                                  <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocumentFromBooking(data,tempBooking.bookingReference)">
                                    <mat-icon>insert_drive_file</mat-icon>
                                  </button>
                                </ng-container>
                                <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                              </table>
                            </div>
                          </div>
                          <div [@inAnimation] *ngIf="tempBooking.listOfDocumentsData.data.length == 0" style="clear: both;">
                            <h3>There is no data to show</h3>
                          </div>
                        </div>
                        <div [@customExpansionDetails]="tempBooking.showListOfDocuments == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="tempBooking.showListOfDocuments = true;">
                          <mat-icon>expand_more</mat-icon>
                        </div>
                      </div>
                    </div>            
                    <div *ngIf="createMultipleBookingReference == false">
                      <div *ngIf="createMatchedBookingReference != '' && createMatchedBookingReference != null">
                        <div style="margin-top:25px;" class="ownExpansionHeader">
                          <div class="headerTopLeft">
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="s3Documents" (click)="s3Documents = false;">
                              <mat-icon>expand_less</mat-icon>
                            </button>
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!s3Documents" (click)="s3Documents = true;">
                              <mat-icon>expand_more</mat-icon>
                            </button>
                             Attached Documents from Booking       
                            <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3documents()" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                              <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                            </button>                      
                          </div>
                        </div>
                        <div [@customExpansionDetails]="s3Documents == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                          <div *ngIf="attachedDocs.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                            <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                              <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="fileName">
                                  <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="lastModified">
                                  <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="size">
                                  <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="download">
                                  <th style="margin-right: 35px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                              </table>
                            </div>
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table [dataSource]="attachedDocs" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="fileName">
                                  <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="lastModified">
                                  <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="size">
                                  <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                                </ng-container>                          
                                <ng-container matColumnDef="download">
                                  <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocument(data)">
                                    <mat-icon>insert_drive_file</mat-icon>
                                  </button>
                                </ng-container>
                                <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                              </table>
                            </div>
                          </div>
                          <div [@inAnimation] *ngIf="attachedDocs.data.length == 0" style="clear: both;">
                            <h3>There is no data to show</h3>
                          </div>
                        </div>
                        <div [@customExpansionDetails]="s3Documents == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="s3Documents = true;">
                          <mat-icon>expand_more</mat-icon>
                        </div>
                      </div>
                    </div>            
                  </div>
                  <div style="margin-top: 25px;" *ngIf="uploadedPrfDocuments.data.length != 0">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="documentType">
                          <mat-header-cell *matHeaderCellDef> Document Type </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="dateAttached">
                          <mat-header-cell *matHeaderCellDef> Date Attached </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                          <mat-header-cell style="padding-right:15px !important;" *matHeaderCellDef> Filename </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="downloadDocument">
                          <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="viewTabOnDocuments"> Download </th>
                        </ng-container>
                        <ng-container matColumnDef="deleteDocument">
                          <th style="margin-right: 35px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Delete </th>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="uploadedPrfDocumentsColumns"></mat-header-row>
                      </table>
                    </div>
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table [dataSource]="uploadedPrfDocuments" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="documentType">
                          <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;" *matCellDef="let document"> {{document['documentType']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dateAttached">
                          <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let document"> {{document['dateAttached']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                          <mat-cell style="padding-left: 0px; padding-right: 35px; font-weight:bold;" *matCellDef="let document"> {{document['fileName']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="downloadDocument">
                          <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let document" (click)="downloadCreatePrfDocument(document)">
                            <mat-icon>insert_drive_file</mat-icon>
                          </button>
                        </ng-container>
                        <ng-container matColumnDef="deleteDocument">
                          <button [disabled]="checkUserType()" mat-button style="color: rgb(88, 88, 88) !important; margin-top: 10px; margin-bottom: 10px; margin-right: 28px;" *matCellDef="let document" (click)="removeCreatePrfDocument(document)">
                            <mat-icon>clear</mat-icon>
                          </button>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: uploadedPrfDocumentsColumns;"></mat-row>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="!checkUserType()">
                    <h3 style="margin-top: 25px; color: black;">{{getCreateSupportingDocumentationTitle()}}</h3>
                    <h3 style="margin-top: 25px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30" style="margin-left:10px;">
                          <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadCreatePrfDocument($event, 'invoice')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                          <button mat-raised-button color="primary" (click)="csvInput1.click()" matTooltip="Upload Invoice">
                            <span style="color:white;"> Invoice </span> <mat-icon>file_upload</mat-icon>
                          </button>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-top: 25px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30" style="margin-left:10px;">
                          <input #csvInput2 hidden="true" type="file" onclick="this.value=null" (change)="uploadCreatePrfDocument($event, 'schedule')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                          <button mat-raised-button color="primary" (click)="csvInput2.click()" matTooltip="Upload Schedule">
                            <span style="color:white;"> Schedule </span> <mat-icon>file_upload</mat-icon>
                          </button>
                        </div>
                      </div>
                    </h3>
                    <h3 *ngIf="createClaimCommission.checked == true || createClaimReimbursement.checked == true" style="margin-top: 25px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="30" style="margin-left:10px;">
                          <input #csvInput3 hidden="true" type="file" onclick="this.value=null" (change)="uploadCreatePrfDocument($event, 'confirmationOfPayment')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                          <button mat-raised-button color="primary" (click)="csvInput3.click()" matTooltip="Upload Confirmation of Payment">
                            <span style="color:white;"> Confirmation of Payment </span> <mat-icon>file_upload</mat-icon>
                          </button>
                        </div>
                      </div>
                    </h3>
                    <h3 style="margin-top: 25px; color: black;">Online Payment<span style="color:#4D5FD1">*</span></h3>
                    <div *ngIf="createListOfPaymentMethods.length > 0">
                      <div *ngFor="let paymentMethod of createListOfPaymentMethods" >
                        <h3 style="margin-top: 25px;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="30" style="margin-left:10px;">
                              <input #csvInput4 hidden="true" type="file" onclick="this.value=null" (change)="uploadCreatePrfDocument($event, paymentMethod)" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                              <button mat-raised-button color="primary" (click)="csvInput4.click()" matTooltip="Online Payment">
                                <span style="color:white;"> {{paymentMethod}} </span> <mat-icon>file_upload</mat-icon>
                              </button>
                            </div>
                          </div>
                        </h3>
                      </div>  
                    </div>
                  </div>
                  <div *ngIf="!secondCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                  </div>
                  <div *ngIf="secondCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                    <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                  </div>
                </mat-step>
                <mat-step label="PRF Transaction Values" [completed]="thirdCompleted">
                  <div *ngIf="createClaimCommission.checked == true">
                    <p class="transactionValueTab">Guarantees and Insurances Collected From</p>
                    <mat-form-field appearance="outline" class="transactionValueNormalTab">
                      <mat-label>From<span style="color:#4D5FD1">*</span></mat-label>
                      <mat-select [(ngModel)]="createCollectedFrom" name="createCollectedFrom" (selectionChange)="checkGuaranteeDeducted()">
                        <mat-option value="Trust Account">Trust Account</mat-option>
                        <mat-option value="Trading Account">Trading Account</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="createClaimCommission.checked == false" style="margin-top:25px;"></div>
                  <div *ngIf="createMultipleBookingReference == true">
                      <div *ngFor="let transaction of createTransactionValuesList; let id=index">

                        <div class="transactionValueTable">
                          <div class="transactionValueContainer">
                            <p class="transactionValueTab">Booking Reference</p>
                            <mat-form-field appearance="outline" class="transactionValueNormalTab">
                              <mat-label>Booking Reference<span style="color:#4D5FD1">*</span></mat-label>
                              <mat-select [(ngModel)]="transaction.createBookingReference" (selectionChange)="setupCreateBookingTransactionRecord(transaction)">
                                <mat-option *ngFor="let bookingRef of createMatchedBookingReference" [value]="bookingRef">
                                  {{bookingRef}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="transactionValueContainer">
                            <p class="transactionValueTab">Client Surname</p>
                            <div style="display:grid;">
                              <mat-form-field appearance="outline" class="transactionValueNormalTab">
                                <mat-label>Surname<span style="color:#4D5FD1">*</span></mat-label>
                                <input  matInput [(ngModel)]="transaction.createClientSurname" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                              <mat-form-field *ngIf="transaction.createListOfSurnames.length > 0" appearance="outline" class="transactionValueNormalTab">
                                <mat-label>Booking Customers</mat-label>
                                <mat-select [(ngModel)]="transaction.createClientSurname" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let customer of transaction.createListOfSurnames" [value]="customer.lastName">
                                    <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> 
                                    {{customer.lastName}} 
                                  </mat-option>
                                </mat-select>
                              </mat-form-field> 
                            </div>
                          </div>
    
                          <div class="transactionValueContainer">
                            <p class="transactionValueTab">Date Funds Banked</p>
                            <div style="display:grid;">
                              <mat-form-field appearance="outline" class="transactionValueNormalTab">
                                <mat-label>Date</mat-label>
                                <input matInput 
                                       [matDatepicker]="picker" 
                                       [(ngModel)]="transaction.createDateFundsBanked" 
                                       (dateChange)="checkTransactionValues(false)">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker ></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
    
                          <div class="transactionValueContainer">
                            <p class="transactionValueTab">Payment Method</p>
                            <mat-form-field appearance="outline" class="transactionValueNormalTab">
                              <mat-label>Method<span style="color:#4D5FD1">*</span></mat-label>
                              <mat-select [(ngModel)]="transaction.createPaymentMethod" (selectionChange)="checkTransactionValues(false)">
                                <mat-option value="Cash">Cash</mat-option>
                                <mat-option value="Cheque">Cheque</mat-option>
                                <mat-option value="Card">Card</mat-option>
                                <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                                <mat-option value="AMEX">AMEX</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="transactionValueContainer">
                            <p class="transactionValueTab">Client Funds Banked</p>
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createGrossClientFunds.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                            </span>
                          </div>

                          <div class="transactionValueContainer" *ngIf="checkCreateSupplierAmountAvailable()">
                            <p class="transactionValueTab">Supplier Amount</p>
                            <div style="display:grid;">
                              <span class="centerCell">
                                <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                  <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                  <mat-select [(ngModel)]="transaction.createSupplierAmount.currency" (selectionChange)="checkTransactionValues(false)">
                                    <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                  <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                  <input matInput [(ngModel)]="transaction.createSupplierAmount.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                                </mat-form-field>
                              </span>
                            </div>
                          </div>

                          <div *ngIf="createCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                            <p class="transactionValueTab">Guarantee Deducted</p>
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createGuaranteeDeducted.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                            </span>
                          </div>

                          <div class="transactionValueContainer" *ngIf="createClaimClientRefund.checked == true">
                            <p class="transactionValueTab">Client Refund</p>
                            <div style="display:grid;">
                              <span class="centerCell">
                                <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                  <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                  <mat-select [(ngModel)]="transaction.createClientRefund.currency" (selectionChange)="checkTransactionValues(false)">
                                    <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                  <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                  <input matInput [(ngModel)]="transaction.createClientRefund.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                                </mat-form-field>
                              </span>
                            </div>
                          </div>
    
                          <div class="transactionValueContainer" *ngIf="createClaimReimbursement.checked == true">
                            <p class="transactionValueTab">Reimbursement</p>
                            <div style="display:grid;">
                              <span class="centerCell">
                                <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                  <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                  <mat-select [(ngModel)]="transaction.createReimbursement.currency" (selectionChange)="checkTransactionValues(false)">
                                    <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                  <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                  <input matInput [(ngModel)]="transaction.createReimbursement.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                                </mat-form-field>
                              </span>
                            </div>
                          </div>
    
                          <div class="transactionValueContainer" *ngIf="createClaimCommission.checked == true">
                            <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.createVAT}} )</p> 
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createCommission.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createCommission.amount" type="number" step="0.01" (keyup)="checkTransactionValues(true)">
                              </mat-form-field>
                            </span>
                          </div>

                          <div class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                            <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                            <button class="buttonClass" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'Create')" style="margin-top:15px;">
                              <mat-icon>clear</mat-icon>
                            </button>
                          </div>

                        </div>

                        <div *ngIf="transaction.createReceiptData.data.length > 0">
                          <div style="margin-bottom: 50px;">
                            <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                              <div class="headerTopLeft" style="width: 500px;">
                                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="transaction.createShowReceipt" (click)="transaction.createShowReceipt = false;">
                                  <mat-icon>expand_less</mat-icon>
                                </button>
                                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!transaction.createShowReceipt" (click)="transaction.createShowReceipt = true;">
                                  <mat-icon>expand_more</mat-icon>
                                </button>
                                Receipts from {{transaction.createBookingReference}}
                                <button mat-icon-button type="button" (click)="reloadReceiptsList('Create Multiple', transaction.createBookingReference)" matTooltip="Reload Receipts" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                                  <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                                </button>    
                              </div>
                            </div>
                            <div [@customExpansionDetails]="transaction.createShowReceipt == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                              <div style="padding: 20px;">
                                <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                                  <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                    <ng-container matColumnDef="receiptDate">
                                      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="totalCharge">
                                      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="paymentMethod">
                                      <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="reference">
                                      <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="payerRef">
                                      <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="receiptStatus">
                                      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                                  </table>
                                </div>
                                <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                                  <table mat-table [dataSource]="transaction.createReceiptData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                    <ng-container matColumnDef="receiptDate">
                                      <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt">
                                        <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                                      </mat-cell>
                                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="totalCharge">
                                      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt">
                                        <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                                          <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                                          <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                                        </span>
                                      </mat-cell>
                                      <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="paymentMethod">
                                      <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="reference">
                                      <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="payerRef">
                                      <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt">
                                        <div style="display: flex; align-items: center;">
                                          <div>{{ receipt.payerName }}</div>
                                          <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                                            <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                                          </div>
                                          <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                                            <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                                    style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                                              <mat-icon>person_add</mat-icon>
                                            </button>
                                          </div>
                                        </div>
                                      </mat-cell>
                                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="receiptStatus">
                                      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                      <mat-cell *matCellDef="let receipt">
                                        {{ receipt.receiptCategory | titlecase }}
                                      </mat-cell>
                                      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                    </ng-container>
                                    <mat-row *matRowDef="let row; columns: receiptColumns;"></mat-row>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div [@customExpansionDetails]="transaction.createShowReceipt == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="transaction.createShowReceipt = true;">
                              <mat-icon>expand_more</mat-icon>
                            </div>
                          </div>
                        </div>
                        

                      </div>
                  </div>
                  <div *ngIf="createMultipleBookingReference == false">
                    <div *ngFor="let transaction of createTransactionValuesList; let id=index">
                      <div class="transactionValueTable">
                        <div class="transactionValueContainer">
                          <p class="transactionValueTab">Client Surname</p>
                          <div style="display:grid;">
                            <mat-form-field appearance="outline" class="transactionValueNormalTab">
                              <mat-label>Surname<span style="color:#4D5FD1">*</span></mat-label>
                              <input  matInput [(ngModel)]="transaction.createClientSurname" (keyup)="checkTransactionValues(false)">
                            </mat-form-field>
                            <mat-form-field *ngIf="createListOfClientSurnames.length > 0" appearance="outline" class="transactionValueNormalTab">
                              <mat-label>Booking Customers</mat-label>
                              <mat-select [(ngModel)]="transaction.createClientSurname" (selectionChange)="checkTransactionValues(false)">
                                <mat-option *ngFor="let customer of createListOfClientSurnames" [value]="customer.lastName">
                                  <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> {{customer.lastName}} 
                                </mat-option>
                              </mat-select>
                            </mat-form-field> 
                          </div>
                        </div>
  
                        <div class="transactionValueContainer">
                          <p class="transactionValueTab">Date Funds Banked</p>
                          <div style="display:grid;">
                            <mat-form-field appearance="outline" class="transactionValueNormalTab">
                              <mat-label>Date</mat-label>
                              <input matInput 
                                     [matDatepicker]="picker" 
                                     [(ngModel)]="transaction.createDateFundsBanked" 
                                     (dateChange)="checkTransactionValues(false)">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
  
                        <div class="transactionValueContainer">
                          <p class="transactionValueTab">Payment Method</p>
                          <mat-form-field appearance="outline" class="transactionValueNormalTab">
                            <mat-label>Method<span style="color:#4D5FD1">*</span></mat-label>
                            <mat-select [(ngModel)]="transaction.createPaymentMethod" (selectionChange)="checkTransactionValues(false)">
                              <mat-option value="Cash">Cash</mat-option>
                              <mat-option value="Cheque">Cheque</mat-option>
                              <mat-option value="Card">Card</mat-option>
                              <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                              <mat-option value="AMEX">AMEX</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="transactionValueContainer">
                          <p class="transactionValueTab">Client Funds Banked</p>
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                              <mat-select [(ngModel)]="transaction.createGrossClientFunds.currency" (selectionChange)="checkTransactionValues(false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                              <input matInput [(ngModel)]="transaction.createGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                            </mat-form-field>
                          </span>
                        </div>
                        <div class="transactionValueContainer" *ngIf="checkCreateSupplierAmountAvailable()">
                          <p class="transactionValueTab">Supplier Amount</p>
                          <div style="display:grid;">
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createSupplierAmount.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createSupplierAmount.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                            </span>
                          </div>
                        </div>
                        <div *ngIf="createCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                          <p class="transactionValueTab">Guarantee Deducted</p>
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                              <mat-select [(ngModel)]="transaction.createGuaranteeDeducted.currency" (selectionChange)="checkTransactionValues(false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                              <input matInput [(ngModel)]="transaction.createGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                            </mat-form-field>
                          </span>
                        </div>
                        <div class="transactionValueContainer" *ngIf="createClaimClientRefund.checked == true">
                          <p class="transactionValueTab">Client Refund</p>
                          <div style="display:grid;">
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createClientRefund.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createClientRefund.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                            </span>
                          </div>
                        </div>
  
                        <div class="transactionValueContainer" *ngIf="createClaimReimbursement.checked == true">
                          <p class="transactionValueTab">Reimbursement</p>
                          <div style="display:grid;">
                            <span class="centerCell">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                                <mat-select [(ngModel)]="transaction.createReimbursement.currency" (selectionChange)="checkTransactionValues(false)">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab">
                                <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                                <input matInput [(ngModel)]="transaction.createReimbursement.amount" type="number" step="0.01" (keyup)="checkTransactionValues(false)">
                              </mat-form-field>
                            </span>
                          </div>
                        </div>
  
                        <div class="transactionValueContainer" *ngIf="createClaimCommission.checked == true">
                          <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.createVAT}} )</p> 
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency<span style="color:#4D5FD1">*</span> </mat-label>
                              <mat-select [(ngModel)]="transaction.createCommission.currency" (selectionChange)="checkTransactionValues(false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount<span style="color:#4D5FD1">*</span> </mat-label>
                              <input matInput [(ngModel)]="transaction.createCommission.amount" type="number" step="0.01" (keyup)="checkTransactionValues(true)">
                            </mat-form-field>
                          </span>
                        </div>
                        <div class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                          <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                          <button class="buttonClass" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'Create')" style="margin-top:15px;">
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="receiptData.data.length > 0">
                      <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                        <div class="headerTopLeft" style="width: 500px;">
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="showReceiptList" (click)="showReceiptList = false;">
                            <mat-icon>expand_less</mat-icon>
                          </button>
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!showReceiptList" (click)="showReceiptList = true;">
                            <mat-icon>expand_more</mat-icon>
                          </button>
                          Receipts
                          <button mat-icon-button type="button" (click)="reloadReceiptsList('Create Single',0)" matTooltip="Reload Receipts" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 50px; display: inline;">
                            <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                          </button>    
                        </div>
                      </div>
                      <div [@customExpansionDetails]="showReceiptList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                        <div style="padding: 20px;">
                          <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                            <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="receiptDate">
                                <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="totalCharge">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="paymentMethod">
                                <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="reference">
                                <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="payerRef">
                                <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="receiptStatus">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                            </table>
                          </div>
                          <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                            <table mat-table [dataSource]="receiptData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="receiptDate">
                                <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                <mat-cell *matCellDef="let receipt">
                                  <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                              </ng-container>
                              <ng-container matColumnDef="totalCharge">
                                <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                <mat-cell *matCellDef="let receipt">
                                  <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                                    <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                                    <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                                  </span>
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                              </ng-container>
                              <ng-container matColumnDef="paymentMethod">
                                <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                              </ng-container>
                              <ng-container matColumnDef="reference">
                                <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                              </ng-container>
                              <ng-container matColumnDef="payerRef">
                                <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                <mat-cell *matCellDef="let receipt">
                                  <div style="display: flex; align-items: center;">
                                    <div>{{ receipt.payerName }}</div>
                                    <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                                      <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                                    </div>
                                    <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                                      <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                              style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                                        <mat-icon>person_add</mat-icon>
                                      </button>
                                    </div>
                                  </div>
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                              </ng-container>
                              <ng-container matColumnDef="receiptStatus">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell *matCellDef="let receipt">
                                  {{ receipt.receiptCategory | titlecase }}
                                </mat-cell>
                                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: receiptColumns;"></mat-row>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div [@customExpansionDetails]="showReceiptList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="showReceiptList = true;">
                        <mat-icon>expand_more</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div style="padding:10px;">
                    <h3 *ngIf="createClaimReimbursement.checked == true">
                      Total Reimbursement : {{createTransactionValuesList[0].createReimbursement.currency}} {{calculateCreateReimbursementTotal()}}
                    </h3>
                    <h3 *ngIf="createClaimClientRefund.checked == true">
                      Total Client Refund : {{createTransactionValuesList[0].createClientRefund.currency}} {{calculateCreateClientRefundTotal()}}
                    </h3>
                    <h3 *ngIf="createClaimSupplierPayment.checked == true">
                      Total Supplier Payment : {{createTransactionValuesList[0].createSupplierAmount.currency}} {{calculateCreateSupplierAmountTotal()}}
                    </h3>
                    <h3 *ngIf="createClaimCommission.checked == true">
                      Total Commission : {{createTransactionValuesList[0].createCommission.currency}} {{calculateCreateCommissionTotal()}}
                    </h3>
                  </div>
                  <div>
                      <button class="buttonClass" mat-raised-button color="primary" (click)="addTransactionValue('Create')" style="margin-left: 10px; margin-top: 10px;">
                        <mat-icon>add</mat-icon>
                      </button>
                  </div>
                  <div *ngIf="!thirdCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                  </div>
                  <div *ngIf="thirdCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                    <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                  </div>
                </mat-step>
                <mat-step label="Sign Off" [completed]="thirdCompleted">
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="15">
                        Notes to Trustees
                      </div>
                      <div fxFlex="30">
                        <textarea class="emailBox" name="notes" rows="3" [(ngModel)]="createNotesToTrustee" maxlength="255"></textarea>
                      </div>
                    </div>
                  </h3>
                  <div name="Member Manager">
                    <h3 style="margin-top: 25px; color: black;">Sign Off</h3>
                    I hereby certify the above release of funds is due in accordance with the trust deed document and in compliance with the code of conduct.<br>
                    All commission and reimbursements from the trust account have taken into account supplier amounts paid.
                    <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticate(authForm, actionType)">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="10">
                          <h3 style="padding-left: 20px;">Email</h3>
                        </div>
                        <div fxFlex="40">
                          <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                            <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                          </mat-form-field>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="10">
                          <h3 style="padding-left: 20px;">Password</h3>
                        </div>
                        <div fxFlex="40">
                          <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                            <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                          </mat-form-field>
                        </div>
                      </div>
                      <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                        <div>
                          <!-- Button for "Sign PRF" (Create) -->
                          <button *ngIf="checkIfManager()" class="buttonClass" matTooltip="This will send your PRF to the TTA Trustees" mat-raised-button color="primary" type="submit" (click)="setAuthenticationAction('Create')" style="margin-right: 50px;">
                            Send PRF to TTA Trustees
                          </button>
                          <!-- Button for "Save PRF" (Create) -->
                          <button class="buttonClass" mat-raised-button color="accent" matTooltip="This will not send your PRF to the TTA Trustees" type="submit" (click)="setAuthenticationAction('Send')">
                            Save PRF (Not Submitted)
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="prfNavigationBox">
                    <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
            <!-- PRF LIST VIEW AREA -->
            <div *ngIf="prfList">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>PRF List
              </h1>
              <mat-divider></mat-divider>
              <br>
              <h3 style="margin: auto; text-align: center;">
                <mat-form-field class="prfFilterInput" style="width: 100px; max-width: unset;">
                  <mat-label>PRF Num.</mat-label>
                  <input type="text" pattern="\d*" matInput [(ngModel)]="prfNumber" maxlength="6" size="6" autocomplete="nope">
                </mat-form-field>
                <mat-form-field class="prfFilterInput" *ngIf="checkUserType()" style="width: 100px; max-width: unset;">
                  <mat-label>TTA Number</mat-label>
                  <input matInput [(ngModel)]="prfsTradeCode" autocomplete="nope">
                </mat-form-field>
                <mat-form-field class="prfFilterInput" *ngIf="checkUserType()" style="width: 150px; max-width: unset;">                
                  <mat-label>Assigned To</mat-label>
                  <mat-select [(ngModel)]="prfsAssignedTo">
                    <mat-option value="Trustees">Trustees</mat-option>
                    <mat-option *ngIf="!checkIfJustTrustee()" value="Steve Button">Steve Button</mat-option>
                    <mat-option *ngIf="!checkIfJustTrustee()" value="Ryan Cherrington">Ryan Cherrington</mat-option>
                    <mat-option *ngIf="!checkIfJustTrustee()" value="Riffat Bahar">Riffat Bahar</mat-option>
                    <mat-option *ngIf="!checkIfJustTrustee()" value="Ashley Harvey">Ashley Harvey</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="prfFilterInput" style="width: 250px; max-width: unset;">
                  <mat-label>From - To</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date"  [(ngModel)]="prfsFrom">
                    <input matEndDate placeholder="End date"  [(ngModel)]="prfsTo">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field class="prfFilterInput" style="width: 130px; max-width: unset;">
                  <mat-label>Supplier Name</mat-label>
                  <input matInput [(ngModel)]="supplierName" autocomplete="nope">
                </mat-form-field>
                <mat-form-field class="prfFilterInput" style="width: 150px; max-width: unset;" >
                  <mat-label>Passenger Name</mat-label>
                  <input matInput [(ngModel)]="passengerName" autocomplete="nope">
                </mat-form-field>
                <mat-form-field class="prfFilterInput" style="width: 150px; max-width: unset;" *ngIf="checkUserType()">
                  <mat-label>Payment Option</mat-label>
                  <mat-select multiple [(ngModel)]="prfsPaymentOptions">
                    <mat-option value="Amex Forex Transfer">Amex Forex Transfer</mat-option>
                    <mat-option value="Corpay">Corpay</mat-option>
                    <mat-option value="Natwest Faster">Natwest Faster</mat-option>
                    <mat-option value="Natwest CHAPS">Natwest CHAPS</mat-option>
                    <mat-option value="Barclays">Barclays</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="prfFilterInput" style="width: 150px; max-width: unset;">
                  <mat-label>Status</mat-label>
                  <mat-select [(value)]="prfsStatus">
                    <mat-option value="0">All</mat-option>
                    <mat-option *ngIf="!checkUserType()" value="Not Submitted"> Not Submitted <mat-icon style="color: #4D5FD1;" >fiber_manual_record</mat-icon> </mat-option>
                    <mat-option *ngIf="!checkIfJustTrustee()" value="Ready for signing"> Ready for signing <mat-icon style="color: #4D5FD1;" >radio_button_unchecked</mat-icon></mat-option>
                    <mat-option value="Processing"> Processing <mat-icon style="color: #4D5FD1;" >pending</mat-icon></mat-option>
                    <mat-option value="Action Required"> Action Required <mat-icon style="color: #4D5FD1;" >new_releases</mat-icon> </mat-option>
                    <mat-option value="Awaiting return date"> Awaiting return date <mat-icon style="color: #4D5FD1;" >pause_circle_outline</mat-icon> </mat-option>
                    <mat-option value="Awaiting cleared fund"> Awaiting cleared fund <mat-icon style="color: #4D5FD1;" >pause_circle_outline</mat-icon> </mat-option>
                    <mat-option value="Authorisation Pending"> Authorisation Pending <mat-icon style="color: #4D5FD1;" >pause_circle_outline</mat-icon></mat-option>
                    <mat-option value="Authorised"> Authorised <mat-icon style="color: #00df00;">check_circle</mat-icon> </mat-option>
                    <mat-option value="Cancelled"> Cancelled <mat-icon style="color: rgb(196, 47, 47);">remove_circle</mat-icon></mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="prfFilterInput" style="width: 100px; max-width: unset;">
                  <mat-label>Per Page</mat-label>
                  <mat-select [(value)]="prfsPerPage">
                    <mat-option value="20">20</mat-option>
                    <mat-option value="50">50</mat-option>
                    <mat-option value="100">100</mat-option>
                  </mat-select>
                </mat-form-field>
                <button [disabled]="prfsEditMode == true" mat-raised-button color="primary" matDialogClose (click)="filterPrfData()" style="margin-right:10px; margin-left:10px;">Filter</button>
                <button *ngIf="checkUserType()" mat-raised-button color="primary" matDialogClose (click)="bulkEdit()" style="margin-right:10px; margin-left:10px;">
                  <span *ngIf="prfsEditMode == true"><mat-icon>stop</mat-icon></span>
                  <span *ngIf="prfsEditMode == false"><mat-icon>edit</mat-icon></span>
                </button>
                <button *ngIf="checkUserType() && prfsEditMode == true" mat-raised-button color="primary" matDialogClose (click)="openEditWindow()" style="margin-right:10px; margin-left:10px;">
                  Edit Selected
                </button>
                <button  mat-button style="color: rgb(88, 88, 88) !important; margin-top: 10px; margin-bottom: 10px; margin-right: 28px;" (click)="clearfilters()">
                  <mat-icon>clear</mat-icon>
                </button>
              </h3>
              <div *ngIf="checkUserType()" name="Trustee PRF List">
                <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                  <table mat-table matSort style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                    <ng-container matColumnDef="prfNumber">
                      <mat-header-cell *matHeaderCellDef> PRF<br>Num. </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="prfTradeCode">
                      <mat-header-cell *matHeaderCellDef> TTA<br>Number </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="prfCreator">
                      <mat-header-cell *matHeaderCellDef> Member<br>Name </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="assignedTo">
                      <mat-header-cell *matHeaderCellDef> Assigned<br>To </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="dateSubmitted">
                      <mat-header-cell *matHeaderCellDef> Date<br>Submitted </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="supplierName">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Supplier<br>Name </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="passengerName">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Passenger<br>Name </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="prfType">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> PRF<br>Type </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentOption">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Payment<br>Option </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <mat-header-cell (click)="sortPrfAmount()" *matHeaderCellDef style="text-align: end;  display: block; padding-right: 10px; cursor: pointer;">
                        <span style="color:white" *ngIf="prfsSorted == false">Amount</span>
                        <span style="color:rgb(217, 217, 217)" *ngIf="prfsSorted == true">Amount</span>
                      </mat-header-cell>
                     </ng-container>
                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Status </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef class="viewTab">
                        View
                      </th>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="prfsTrusteeColumns"></mat-header-row>
                  </table>
                  </div>
                  <div class="example-container mat-elevation-z8">
                    <table matSort mat-table [dataSource]="prfs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                      <ng-container matColumnDef="prfNumber">
                        <mat-header-cell *matHeaderCellDef> PRF Num. </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf">
                          <span *ngIf="prfsEditMode == true" style="padding:10px;">
                            <mat-checkbox
                                (change)="selectedPrfTab(prf.prfNumber)">
                            </mat-checkbox>
                          </span>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Action Required'">new_releases</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Processing'">pending</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Ready for signing'">radio_button_unchecked</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Awaiting cleared fund'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Awaiting return date'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Authorisation Pending'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #00c521; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Authorised'">check_circle</mat-icon>
                          <mat-icon style="color: #FA0E62; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Cancelled'">remove_circle</mat-icon>
                          <div style="display:inline-block; ">
                            <div>{{prf.prfNumber}}</div>
                            <div style="color:gray;" *ngIf="prf.bookingReference != null && prf.bookingReference != 'null' && prf.bookingReference != ''">
                              <span style="font-size:10px !important;">{{prf.bookingReference}}</span>
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="prfTradeCode">
                        <mat-header-cell *matHeaderCellDef> TTA Number </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 5px; margin-left: 15px;" *matCellDef="let prf"> {{prf.tradeCode}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="prfCreator">
                        <mat-header-cell *matHeaderCellDef> Member Name </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 5px; margin-left: 5px;" *matCellDef="let prf"> {{prf.branchName}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="assignedTo">
                        <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf"> {{prf.assignedTo}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="dateSubmitted">
                        <mat-header-cell *matHeaderCellDef> Date Submitted </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf"> {{prf.dateSubmitted}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                        <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px; margin-left: 10px;" *matCellDef="let prf"> {{prf.supplierName}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="passengerName">
                        <mat-header-cell *matHeaderCellDef> Passenger Name </mat-header-cell>
                        <mat-cell style="font-weight:bold; display:block; text-align: center;" *matCellDef="let prf"> {{prf.passengerName}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="prfType">
                        <mat-header-cell *matHeaderCellDef> PRF Type </mat-header-cell>
                        <mat-cell style="display:block; text-align: center; font-weight: bold; padding: 10px;" *matCellDef="let prf">
                          <div style="display:block;">
                            <span class="brsmall"></span>{{prf.claimOne}}
                            <span *ngIf="prf.claimTwo != null" class="brsmall"></span>{{prf.claimTwo}}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="paymentOption">
                        <mat-header-cell *matHeaderCellDef> Payment Option </mat-header-cell>
                        <mat-cell style="display:block; text-align: center; font-weight: bold; padding: 10px;" *matCellDef="let prf">
                          <div style="display:block;">
                            <span class="brsmall"></span>{{prf.paymentMethodOne}}
                            <span *ngIf="prf.paymentMethodTwo != null" class="brsmall"></span>{{prf.paymentMethodTwo}}
                          </div>                        
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                        <mat-cell style="display:block; font-weight: bold; padding: 10px; text-align: end;" *matCellDef="let prf">
                          <div>
                            <span class="brsmall"></span>
                            <div style="display:block;">
                              {{getClaimAcronym(prf.claimOne)}}<br>
                              <span *ngIf="prf.currencyOneTrustee == null">{{prf.currencyOne}}<br></span>
                              <span *ngIf="prf.currencyOneTrustee != null">{{prf.currencyOneTrustee}}<br></span>
                              <span *ngIf="prf.amountOneTrustee == null">{{prf.amountOne | number : '1.2-2'}}</span>
                              <span *ngIf="prf.amountOneTrustee != null">{{prf.amountOneTrustee | number : '1.2-2'}}</span>
                            </div>
                            <div *ngIf="prf.amountTwo != null && prf.currencyTwo != null && prf.claimTwo != null">
                              <span class="brsmall"></span>
                              <div style="display:block;">
                                <br>{{getClaimAcronym(prf.claimTwo)}}<br>
                                <span *ngIf="prf.currencyTwoTrustee == null">{{prf.currencyTwo}}<br></span>
                                <span *ngIf="prf.currencyTwoTrustee != null">{{prf.currencyTwoTrustee}}<br></span>
                                <span *ngIf="prf.amountTwoTrustee == null">{{prf.amountTwo | number : '1.2-2'}}</span>
                                <span *ngIf="prf.amountTwoTrustee != null">{{prf.amountTwoTrustee | number : '1.2-2'}}</span>
                              </div>
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let prf" style="padding-right: 0px; font-weight: bold;">{{prf.status}}</mat-cell>
                      </ng-container>
                      <ng-container *ngIf="prfsEditMode == false" matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> View </mat-header-cell>
                        <button mat-raised-button color="primary" *matCellDef="let prf" (click)="openPrfTab(prf)" style="margin: 15px 15px 15px 10px;">View</button>
                      </ng-container>
                      <mat-row *matRowDef="let row; columns: prfsTrusteeColumns;"></mat-row>
                    </table>
                  </div>
              </div>
              <div *ngIf="!checkUserType()" name="Member PRF List">
                <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                  <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                    <ng-container matColumnDef="prfNumber">
                      <mat-header-cell *matHeaderCellDef> PRF<br>Num. </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="assignedTo">
                      <mat-header-cell *matHeaderCellDef> Assigned<br>To </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="dateSubmitted">
                      <mat-header-cell *matHeaderCellDef> Date<br>Submitted </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="supplierName">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Supplier<br>Name </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="passengerName">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Passenger<br>Name </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="prfType">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> PRF<br>Type </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentOption">
                      <mat-header-cell *matHeaderCellDef style="display: block; text-align: center;"> Payment<br>Option </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                      <mat-header-cell *matHeaderCellDef (click)="sortPrfAmount()" style="text-align: end;  display: block; padding-right: 10px; cursor: pointer;"> 
                        <span style="color:white" *ngIf="prfsSorted == false">Amount</span>
                        <span style="color:rgb(217, 217, 217)" *ngIf="prfsSorted == true">Amount</span>
                      </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Status </mat-header-cell>
                    </ng-container>
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef class="viewTab"> View </th>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="prfsColumns"></mat-header-row>
                  </table>
                  </div>
                  <div class="example-container mat-elevation-z8">
                    <table mat-table [dataSource]="prfs" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                      <ng-container matColumnDef="prfNumber">
                        <mat-header-cell *matHeaderCellDef> PRF Num. </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf">
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Not Submitted'">fiber_manual_record</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Action Required'">new_releases</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Processing'">pending</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Ready for signing'">radio_button_unchecked</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Awaiting cleared fund'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Awaiting return date'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Authorisation Pending'">pause_circle_outline</mat-icon>
                          <mat-icon style="color: #00c521; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Authorised'">check_circle</mat-icon>
                          <mat-icon style="color: #FA0E62; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="checkStatusIcon(prf.status) == 'Cancelled'">remove_circle</mat-icon>
                          <div style="display:inline-block; ">
                            <div>{{prf.prfNumber}}</div>
                            <div style="color:gray; font-size: 10px;" *ngIf="prf.bookingReference != null && prf.bookingReference != 'null' && prf.bookingReference != ''">
                               <span style="font-size:10px !important;">{{prf.bookingReference}}</span>
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="assignedTo">
                        <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf"> {{prf.assignedTo}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="dateSubmitted">
                        <mat-header-cell *matHeaderCellDef> Date Submitted </mat-header-cell>
                        <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let prf"> {{prf.dateSubmitted}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef> Supplier Name </mat-header-cell>
                        <mat-cell style="font-weight:bold; display:block; text-align: center; padding: 10px;" *matCellDef="let prf"> {{prf.supplierName}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="passengerName">
                        <mat-header-cell *matHeaderCellDef> Passenger Name </mat-header-cell>
                        <mat-cell style="font-weight:bold; display:block; text-align: center; margin-left: 10px;" *matCellDef="let prf"> {{prf.passengerName}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="prfType">
                        <mat-header-cell *matHeaderCellDef> PRF Type </mat-header-cell>
                        <mat-cell style="display:block; text-align:center; font-weight: bold; padding: 10px;" *matCellDef="let prf">
                          <div style="display:block;">
                            <span class="brsmall"></span>{{prf.claimOne}}
                            <span *ngIf="prf.claimTwo != null" class="brsmall"></span>{{prf.claimTwo}}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="paymentOption">
                        <mat-header-cell *matHeaderCellDef> Payment Option </mat-header-cell>
                        <mat-cell style="display:block; text-align:center; font-weight: bold; padding: 10px; display: block; text-align: center;" *matCellDef="let prf">
                          <div style="display:block;">
                            <span class="brsmall"></span>{{prf.paymentMethodOne}}
                            <span *ngIf="prf.paymentMethodTwo != null" class="brsmall"></span>{{prf.paymentMethodTwo}}
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                        <mat-cell style="display:block; font-weight: bold; padding: 10px; text-align: end;" *matCellDef="let prf">
                          <div>
                            <span class="brsmall"></span>
                            <div style="display:block;">
                              {{getClaimAcronym(prf.claimOne)}}<br>{{prf.currencyOne}}<br>{{prf.amountOne}}
                            </div>
                            <div *ngIf="prf.amountTwo != null && prf.currencyTwo != null && prf.claimTwo != null">
                              <span class="brsmall"></span>
                              <div style="display:block;">
                                <br>{{getClaimAcronym(prf.claimTwo)}}<br>{{prf.currencyTwo}}<br>{{prf.amountTwo}}
                              </div>
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let prf" style="padding-right: 0px; font-weight: bold;">{{prf.status}}</mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> View </mat-header-cell>
                        <button mat-raised-button color="primary" *matCellDef="let prf" (click)="openPrfTab(prf)" style="margin: 15px 15px 15px 10px;">View</button>
                      </ng-container>
                      <mat-row *matRowDef="let row; columns: prfsColumns;"></mat-row>
                    </table>
                  </div>
              </div>
            </div>
            <!-- PRF VIEW AREA -->
            <div *ngIf="prfNoView.includes(true) && prfView;">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> PRF - {{viewPrfNumber}}</p>
                <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> {{viewPrfBranchName}} - {{viewPrfTradeCode}}</p>
                <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> Assigned To - {{viewPrfAssignedTo}} </p>
                <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> Status - {{viewPrfStatus}} <span *ngIf="viewPrfActionRequiredMessage != null">({{viewPrfActionRequiredMessage}})</span></p>
                <!--
                  <button style="margin-left: 10px;" *ngIf="firstViewCompleted && thirdViewCompleted" mat-raised-button color="primary" (click)="showViewReport()" mat-button>
                    Summary Report
                  </button>
                -->
              </h1>
              <mat-divider></mat-divider>
              <mat-horizontal-stepper [linear]="true" #stepper>
              <mat-step label="Online PRF Information" [completed]="firstViewCompleted">
                  <!-- View Booking Reference Check -->
                  <div *ngIf="!checkUserType() && viewMatchedBookingReference != null">
                    <div *ngIf="viewMatchedBookingReference == ''">
                      <h3 style="margin-top: 40px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="25" style="margin-bottom: 10px !important;">
                            <span style="color:#4D5FD1">*</span> Booking Reference 
                          </div>
                          <div fxFlex="20" style="display:contents;">
                            <mat-form-field style="width: 750px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select placeholder="Booking Reference ( Booking Date | Source | Lead Client | Total Gross )" multiple [(ngModel)]="viewBookingReference" style="vertical-align: middle;" (selectionChange)="checkOnlinePrfInformation()">
                                <mat-option *ngFor="let booking of listOfAvailableBookings" [value]="booking.bookingReference">
                                  {{booking.bookingReference}} <span style="color:gray">( {{booking.bookingDate}} | {{booking.bookingSource}} | {{booking.leadName}} | {{booking.gross}} )</span>
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                    </div>
                    <div *ngIf="viewPrfLinkedBookings.length > 0  && viewPrfLinkedBookings != null">
                      <h3 style="margin-top: 40px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="25">
                            Added Booking Reference(s)
                          </div>
                          <div fxFlex="50" class="containerOfReference">
                            <ng-container *ngFor="let bookingReference of viewPrfLinkedBookings">
                              <div class="reference">{{ bookingReference }}</div>
                            </ng-container>
                          </div>
                        </div>
                      </h3>
                    </div>
                    <div *ngIf="viewPrfLinkedBookings.length == 0 && viewMatchedBookingReference != ''">
                      <h3 style="margin-top: 40px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="25">
                            Added Booking Reference(s)
                          </div>
                          <div fxFlex="50">
                              <div class="reference" style="display:initial;">{{ viewMatchedBookingReference }}</div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  </div>
                  <div *ngIf="checkUserType()">
                    <div *ngIf="viewPrfLinkedBookings.length > 0 && viewPrfLinkedBookings != null">
                      <h3 style="margin-top: 40px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="25">
                            Added Booking Reference(s)
                          </div>
                          <div fxFlex="50" class="containerOfReference">
                            <ng-container *ngFor="let bookingReference of viewPrfLinkedBookings">
                              <div class="reference">{{ bookingReference }}</div>
                            </ng-container>
                          </div>
                        </div>
                      </h3>
                    </div>
                    <div *ngIf="viewPrfLinkedBookings.length == 0 && viewMatchedBookingReference != null">
                      <h3 style="margin-top: 40px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="25">
                            Added Booking Reference(s)
                          </div>
                          <div fxFlex="50" class="containerOfReference">
                              <div class="reference">{{ viewMatchedBookingReference }}</div>
                          </div>
                        </div>
                      </h3>
                    </div>
                  </div>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Have the client funds been received?
                      </div>
                      <div fxFlex="40">
                        <mat-radio-group [(ngModel)]="viewPrfIsClientFundsReceived" name="viewIsClientFundsReceived" style="vertical-align: middle;" color="primary" (change)="checkViewOnlinePrfInformation()">
                          <mat-radio-button [value]="1">Yes</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" [value]="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Has the client returned from travel?
                      </div>
                      <div fxFlex="40">
                        <mat-radio-group [(ngModel)]="viewPrfHasClientReturned" (change)="setFP($event,'View')"  name="viewHasClientReturned" style="vertical-align: middle;" color="primary">
                          <mat-radio-button [value]="1">Yes</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" [value]="0">No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 40px; ">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Type of Claim Requested
                      </div>
                      <div fxFlex="50">
                        <h3> 
                          <h3>{{getSupportingDocumentationTitle(viewPrfClaimOne,viewPrfClaimTwo)}}</h3>
                        </h3>
                      </div>
                    </div>
                  </h3>                  
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="25">
                        Select Supplier
                      </div>
                      <div fxFlex="40">
                        <h3>{{viewPrfSupplierName}}</h3>
                      </div>
                    </div>
                  </h3>
                  <div *ngIf="viewPrfFinancialProtectionEnable">
                    <h3 style="margin-top: 35px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Financial Protection
                        </div>
                        <div fxFlex="50">
                          <mat-checkbox (change)="setCRD({value:'None'},'View')" name="{{viewPrfFinancialProtection.checks[0]}}" [(ngModel)]="viewPrfFinancialProtection.checks[0]">None</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'ATOL'},'View')" name="{{viewPrfFinancialProtection.checks[1]}}" [(ngModel)]="viewPrfFinancialProtection.checks[1]" style="margin-left: 20px;">ATOL</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'PSA'},'View')" name="{{viewPrfFinancialProtection.checks[2]}}" [(ngModel)]="viewPrfFinancialProtection.checks[2]" style="margin-left: 20px;">PSA</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'SFC / SAFI'},'View')" name="{{viewPrfFinancialProtection.checks[3]}}" [(ngModel)]="viewPrfFinancialProtection.checks[3]" style="margin-left: 20px;">SFC / SAFI</mat-checkbox>
                          <mat-checkbox (change)="setCRD({value:'TTA Member'},'View')" name="{{viewPrfFinancialProtection.checks[4]}}" [(ngModel)]="viewPrfFinancialProtection.checks[4]" style="margin-left: 20px;">TTA Member</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewPrfClientReturnDateEnable">
                    <h3 style="margin-top: 25px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Client Return Date
                        </div>
                        <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [value]="viewPrfClientReturnDate" [(ngModel)]="viewPrfClientReturnDate" name="viewClientReturnDate" (dateChange)="checkViewOnlinePrfInformation()">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimSupplierPayment.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Supplier Payment Method
                        </div>
                        <div fxFlex="50">
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[0]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[0]">Natwest Faster</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[1]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[1]" style="margin-left: 20px;">Natwest CHAPS</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[2]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[2]" style="margin-left: 20px;">Barclays</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[3]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[3]" style="margin-left: 20px;">Corpay</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[4]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[4]" style="margin-left: 20px;">Monteith</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[5]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[5]" style="margin-left: 20px;">Amex Forex Transfer</mat-checkbox>
                          <mat-checkbox (change)="checkViewOnlinePrfInformation()" name="{{viewClaimSupplierPayment.paymentMethods.checks[6]}}" [(ngModel)]="viewClaimSupplierPayment.paymentMethods.checks[6]" style="margin-left: 20px;">Other</mat-checkbox>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimCommission.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Commission Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="viewClaimCommission.paymentMethod" name="viewClaimCommissionMethod"  style="display:flex; flex-wrap: wrap;" color="primary" (change)="checkViewOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimReimbursement.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Reimbursement Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="viewClaimReimbursement.paymentMethod" name="viewClaimReimbursementMethod" style="display:flex; flex-wrap: wrap;" color="primary" (change)="checkViewOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimClientRefund.checked">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Client Refund Payment Method
                        </div>
                        <mat-radio-group [(ngModel)]="viewClaimClientRefund.paymentMethod" name="viewClaimClientRefundMethod"  style="display:flex; flex-wrap: wrap;" color="primary" (change)="checkViewOnlinePrfInformation()">
                          <mat-radio-button value="Natwest Faster">Natwest Faster</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Natwest CHAPS">Natwest CHAPS</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Barclays">Barclays</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Corpay">Corpay</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Monteith">Monteith</mat-radio-button>
                          <mat-radio-button style="margin-left: 20px;" value="Amex Forex Transfer">Amex Forex Transfer</mat-radio-button>                          
                          <mat-radio-button style="margin-left: 20px;" value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="checkIfNatWestChapsIsChecked('View')">
                    <h3 style="margin-top: 40px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="25">
                          Natwest CHAPS
                        </div>
                        <h3 style="color: #FA0E62;">
                          Please note that Chaps payments incur a £12.50 charge.<br>Day 1 to Day 3 Trustees SLA is still applicable.                       
                        </h3>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="firstViewCompleted" class="prfNavigationBox">
                    <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                  </div>
              </mat-step>
              <mat-step label="Supporting Documentation" [completed]="firstViewCompleted">
                <div *ngIf="viewMatchedBookingReference != '' && viewMatchedBookingReference != null">
                  <div *ngIf="viewMultipleBookingReference == true">
                    <div *ngFor="let tempBooking of viewMatchedBookingData">
                      <div style="margin-top:20px;" class="ownExpansionHeader">
                        <div class="headerTopLeft" style="width:fit-content !important;">
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = false;">
                            <mat-icon>expand_less</mat-icon>
                          </button>
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = true;">
                            <mat-icon>expand_more</mat-icon>
                          </button>
                           Attached Documents from {{tempBooking.bookingReference}}       
                          <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3document(tempBooking)" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                            <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                          </button>                      
                        </div>
                      </div>
                      <div [@customExpansionDetails]="tempBooking.showListOfDocuments == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                        <div *ngIf="tempBooking.listOfDocumentsData.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                          <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                            <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="fileName">
                                <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="lastModified">
                                <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="size">
                                <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="download">
                                <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                            </table>
                          </div>
                          <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                            <table mat-table [dataSource]="tempBooking.listOfDocumentsData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="fileName">
                                <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="lastModified">
                                <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="size">
                                <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                              </ng-container>                          
                              <ng-container matColumnDef="download">
                                <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocumentFromBooking(data,tempBooking.bookingReference)">
                                  <mat-icon>insert_drive_file</mat-icon>
                                </button>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                            </table>
                          </div>
                        </div>
                        <div [@inAnimation] *ngIf="tempBooking.listOfDocumentsData.data.length == 0" style="clear: both;">
                          <h3>There is no data to show</h3>
                        </div>
                      </div>
                      <div [@customExpansionDetails]="tempBooking.showListOfDocuments == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="tempBooking.showListOfDocuments = true;">
                        <mat-icon>expand_more</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="viewMultipleBookingReference == false">
                    <div style="margin-top:25px;" class="ownExpansionHeader">
                      <div class="headerTopLeft" style="width:fit-content !important;">
                        <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="s3Documents" (click)="s3Documents = false;">
                          <mat-icon>expand_less</mat-icon>
                        </button>
                        <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!s3Documents" (click)="s3Documents = true;">
                          <mat-icon>expand_more</mat-icon>
                        </button>
                         Attached Documents from Booking       
                        <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3documents()" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                          <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                        </button>                      
                      </div>
                    </div>
                    <div [@customExpansionDetails]="s3Documents == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                      <div *ngIf="attachedDocs.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                        <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">
                              <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="download">
                              <th style="margin-right: 35px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                          </table>
                        </div>
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table [dataSource]="attachedDocs" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="fileName">
                              <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastModified">
                              <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="size">
                              <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                            </ng-container>                          
                            <ng-container matColumnDef="download">
                              <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocument(data)">
                                <mat-icon>insert_drive_file</mat-icon>
                              </button>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                          </table>
                        </div>
                      </div>
                      <div [@inAnimation] *ngIf="attachedDocs.data.length == 0" style="clear: both;">
                        <h3>There is no data to show</h3>
                      </div>
                    </div>
                    <div [@customExpansionDetails]="s3Documents == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="s3Documents = true;">
                      <mat-icon>expand_more</mat-icon>
                    </div>
                  </div>
                </div>
                <div style="margin-top:25px;" class="ownExpansionHeader">
                  <div class="headerTopLeft" style="width:fit-content !important;">
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="showPrfDocuments" (click)="showPrfDocuments = false;">
                      <mat-icon>expand_less</mat-icon>
                    </button>
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!showPrfDocuments" (click)="showPrfDocuments = true;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                     Attached Documents from PRF       
                    <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3prfDocs(false)" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                      <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                    </button>                      
                  </div>
                </div>
                <div [@customExpansionDetails]="showPrfDocuments == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                  <div *ngIf="viewPrfDocumentsData.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="documentType">
                          <mat-header-cell *matHeaderCellDef> Document Type </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="dateAttached">
                          <mat-header-cell *matHeaderCellDef> Date Attached </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                          <mat-header-cell style="padding-right:15px !important;" *matHeaderCellDef> Filename </mat-header-cell>
                        </ng-container>
                        <ng-container matColumnDef="downloadDocument">
                          <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="viewTabOnDocuments"> Download </th>
                        </ng-container>
                        <ng-container matColumnDef="deleteDocument">
                          <th style="margin-right: 35px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Delete </th>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="viewPrfDocumentColumns"></mat-header-row>
                      </table>
                    </div>
                    <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                      <table mat-table [dataSource]="viewPrfDocumentsData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                        <ng-container matColumnDef="documentType">
                          <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;" *matCellDef="let document"> {{document['documentType']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dateAttached">
                          <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let document"> {{document['dateAttached']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="fileName">
                          <mat-cell style="padding-left: 0px; padding-right: 35px; font-weight:bold;" *matCellDef="let document"> {{document['fileName']}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="downloadDocument">
                          <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let document" (click)="downloadPrfDocument(document['fileName'])">
                            <mat-icon>insert_drive_file</mat-icon>
                          </button>
                        </ng-container>
                        <ng-container matColumnDef="deleteDocument">
                          <button [disabled]="checkUserType()" mat-button style="color: rgb(88, 88, 88) !important; margin-top: 10px; margin-bottom: 10px; margin-right: 28px;" *matCellDef="let document" (click)="removePrfDocument(document['fileName'])">
                            <mat-icon>clear</mat-icon>
                          </button>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: viewPrfDocumentColumns;"></mat-row>
                      </table>
                    </div>
                  </div>
                  <div [@inAnimation] *ngIf="viewPrfDocumentsData.data.length == 0" style="clear: both;">
                    <h3>There is no data to show</h3>
                  </div>
                </div>
                <div [@customExpansionDetails]="showPrfDocuments == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="showPrfDocuments = true;">
                  <mat-icon>expand_more</mat-icon>
                </div>
                <div *ngIf="!checkUserType()">
                  <h3 style="margin-top: 25px; color: black;">{{getSupportingDocumentationTitle(viewPrfClaimOne,viewPrfClaimTwo)}}</h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="30" style="margin-left:10px;">
                        <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadViewPrfDocument($event, 'invoice')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                        <button mat-raised-button color="primary" (click)="csvInput1.click()" matTooltip="Upload Invoice">
                          <span style="color:white;"> Invoice </span> <mat-icon>file_upload</mat-icon>
                        </button>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="30" style="margin-left:10px;">
                        <input #csvInput2 hidden="true" type="file" onclick="this.value=null" (change)="uploadViewPrfDocument($event, 'schedule')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                        <button mat-raised-button color="primary" (click)="csvInput2.click()" matTooltip="Upload Schedule">
                          <span style="color:white;"> Schedule </span> <mat-icon>file_upload</mat-icon>
                        </button>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="30" style="margin-left:10px;">
                        <input #csvInput3 hidden="true" type="file" onclick="this.value=null" (change)="uploadViewPrfDocument($event, 'confirmationOfPayment')" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                        <button mat-raised-button color="primary" (click)="csvInput3.click()" matTooltip="Upload Confirmation of Payment">
                          <span style="color:white;"> Confirmation of Payment </span> <mat-icon>file_upload</mat-icon>
                        </button>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px; color: black;">Online Payment</h3>
                  <div *ngIf="viewListOfPaymentMethods.length > 0">
                    <div *ngFor="let paymentMethod of viewListOfPaymentMethods" >
                      <h3 style="margin-top: 25px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="30" style="margin-left:10px;">
                            <input #csvInput4 hidden="true" type="file" onclick="this.value=null" (change)="uploadViewPrfDocument($event, paymentMethod)" accept=".doc,.xlsx,.pdf,.csv,.eml,.png,.jpg,.jpeg,.msg" multiple="true" />
                            <button mat-raised-button color="primary" (click)="csvInput4.click()" matTooltip="Online Payment">
                              <span style="color:white;"> {{paymentMethod}} </span> <mat-icon>file_upload</mat-icon>
                            </button>
                          </div>
                        </div>
                      </h3>
                    </div>  
                  </div>
                </div>
                <div *ngIf="checkUserType()" class="trusteeContainer1" style="margin-top: 25px;">
                  <h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="35" style="color: black;">
                        Trustee checks
                      </div>
                      <div style="margin-right: 20px; margin-left:20px;">
                        <section class="example-section">
                          <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfThirdPartyPaperwork'>Third party paperwork</mat-checkbox>
                        </section>
                      </div>
                    </div>
                  </h3>
                  <h3 style="margin-top: 25px;" *ngIf="getSupportingDocumentationTitle(viewPrfClaimOne,viewPrfClaimTwo).includes('Commission')">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="35" style="color: black;">
                        Commission checks
                      </div>
                      <div style="margin-right: 20px; margin-left:20px;">
                        <section class="example-section">
                          <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForAtol'>ATOL</mat-checkbox>
                          <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForPsa'>PSA</mat-checkbox>
                          <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForSfc'>SFC / SAFI</mat-checkbox>
                          <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForClientsReturned'>Clients Returned</mat-checkbox>
                        </section>
                      </div>
                    </div>
                  </h3>
                </div>
                <div class="prfNavigationBox">
                  <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                  <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                </div>
              </mat-step>
              <mat-step label="PRF Transaction Values" [completed]="thirdViewCompleted">
                <div *ngIf="viewClaimCommission.checked == true">
                  <p class="transactionValueTab">Guarantees and Insurances Collected From</p>
                  <mat-form-field appearance="outline" class="transactionValueNormalTab">
                    <mat-label>From</mat-label>
                    <mat-select [(ngModel)]="viewPrfCollectedFrom" name="viewCollectedFrom" (selectionChange)="checkViewGuaranteeDeducted()">
                      <mat-option value="Trust Account">Trust Account</mat-option>
                      <mat-option value="Trading Account">Trading Account</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="viewClaimCommission.checked == false" style="margin-top:25px;"></div>
                <div *ngIf="viewMultipleBookingReference == true">
                  <div *ngFor="let transaction of viewPrfTransactionValuesList; let id=index">
                    <div class="transactionValueTable">
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Booking Reference</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Booking Reference<span style="color:#4D5FD1">*</span></mat-label>
                          <mat-select [(ngModel)]="transaction.viewBookingReference" (selectionChange)="setupViewBookingTransactionRecord(transaction)">
                            <mat-option *ngFor="let bookingRef of viewPrfLinkedBookings" [value]="bookingRef">
                              {{bookingRef}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Surname</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Surname</mat-label>
                          <input matInput ngModel [(ngModel)]="transaction.viewClientSurname" (keyup)="checkViewTransactionValues(false,transaction)">
                        </mat-form-field>
                        <div *ngIf="!checkUserType() && transaction.viewListOfClientSurnames.length > 0">
                          <mat-form-field appearance="outline" class="transactionValueNormalTab">
                            <mat-label>Booking Customers</mat-label>
                            <mat-select [(ngModel)]="transaction.viewClientSurname" (selectionChange)="checkViewTransactionValues(false,transaction)">
                              <mat-option *ngFor="let customer of transaction.viewListOfClientSurnames" [value]="customer.lastName">
                                <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> {{customer.lastName}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                        </div>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Date Funds Banked</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.viewDateFundsBanked" (dateChange)="checkViewTransactionValues(false,transaction)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Payment Method</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Method</mat-label>
                          <mat-select [(ngModel)]="transaction.viewPaymentMethod" (selectionChange)="checkViewTransactionValues(false,transaction)">
                            <mat-option value="Cash">Cash</mat-option>
                              <mat-option value="Cheque">Cheque</mat-option>
                              <mat-option value="Card">Card</mat-option>       
                              <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                              <mat-option value="AMEX">AMEX</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Funds Banked</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGrossClientFunds.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,transaction)">
                          </mat-form-field>
                        </span>
                      </div>                    
                      <div class="transactionValueContainer" *ngIf="checkViewSupplierAmountAvailable()">
                        <p class="transactionValueTab">Supplier Amount</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewSupplierAmount.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewSupplierAmount.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,transaction)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div *ngIf="viewPrfCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                        <p class="transactionValueTab">Guarantee Deducted</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGuaranteeDeducted.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,transaction)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimClientRefund.checked == true">
                        <p class="transactionValueTab">Client Refund</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewClientRefund.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewClientRefund.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,transaction)">
                            </mat-form-field>
                          </span>
                        </div>                      
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimReimbursement.checked == true">
                        <p class="transactionValueTab">Reimbursement</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewReimbursement.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewReimbursement.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,transaction)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimCommission.checked == true">
                        <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.viewVAT}} )</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewCommission.currency" (selectionChange)="checkViewTransactionValues(false,transaction)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewCommission.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(true,transaction)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div *ngIf="!checkUserType()" class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                        <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                        <button class="buttonClass" matTooltip="Delete Transaction" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'View')" style="margin-top:15px;">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="transaction.viewReceiptData.data.length > 0">
                      <div style="margin-bottom: 50px;">
                        <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                          <div class="headerTopLeft" style="width: 500px;">
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="transaction.viewShowReceipt" (click)="transaction.viewShowReceipt = false;">
                              <mat-icon>expand_less</mat-icon>
                            </button>
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!transaction.viewShowReceipt" (click)="transaction.viewShowReceipt = true;">
                              <mat-icon>expand_more</mat-icon>
                            </button>
                            Receipts from {{transaction.viewBookingReference}}
                            <button mat-icon-button type="button" (click)="reloadReceiptsList('View Multiple', transaction.viewBookingReference)" matTooltip="Reload Receipts" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                              <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                            </button>    
                          </div>
                        </div>
                        <div [@customExpansionDetails]="transaction.viewShowReceipt == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                          <div style="padding: 20px;">
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="receiptDate">
                                  <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalCharge">
                                  <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="paymentMethod">
                                  <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="reference">
                                  <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="payerRef">
                                  <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="receiptStatus">
                                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                              </table>
                            </div>
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table [dataSource]="transaction.viewReceiptData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="receiptDate">
                                  <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalCharge">
                                  <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                                      <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                                      <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                                    </span>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="paymentMethod">
                                  <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="reference">
                                  <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="payerRef">
                                  <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <div style="display: flex; align-items: center;">
                                      <div>{{ receipt.payerName }}</div>
                                      <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                                        <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                                      </div>
                                      <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                                        <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                                style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                                          <mat-icon>person_add</mat-icon>
                                        </button>
                                      </div>
                                    </div>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="receiptStatus">
                                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    {{ receipt.receiptCategory | titlecase }}
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <mat-row *matRowDef="let row; columns: receiptColumns;"></mat-row>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div [@customExpansionDetails]="transaction.viewShowReceipt == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="transaction.viewShowReceipt = true;">
                          <mat-icon>expand_more</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let transaction of viewPrfAddedTransactionValuesList; let id=index">
                    <div class="transactionValueTable">
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Booking Reference</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Booking Reference<span style="color:#4D5FD1">*</span></mat-label>
                          <mat-select [(ngModel)]="transaction.viewBookingReference" (selectionChange)="setupViewBookingTransactionRecord(transaction)">
                            <mat-option *ngFor="let bookingRef of viewPrfLinkedBookings" [value]="bookingRef">
                              {{bookingRef}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Surname</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Surname</mat-label>
                          <input matInput ngModel [(ngModel)]="transaction.viewClientSurname" (keyup)="checkViewTransactionValues(false,false)">
                        </mat-form-field>

                        <div *ngIf="!checkUserType() && transaction.viewListOfClientSurnames.length > 0">
                          <mat-form-field appearance="outline" class="transactionValueNormalTab">
                            <mat-label>Booking Customers</mat-label>
                            <mat-select [(ngModel)]="transaction.viewClientSurname" (selectionChange)="checkViewTransactionValues(false,transaction)">
                              <mat-option *ngFor="let customer of transaction.viewListOfClientSurnames" [value]="customer.lastName">
                                <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> {{customer.lastName}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                        </div>

                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Date Funds Banked</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.viewDateFundsBanked" (dateChange)="checkViewTransactionValues(false,false)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Payment Method</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Method</mat-label>
                          <mat-select [(ngModel)]="transaction.viewPaymentMethod" (selectionChange)="checkViewTransactionValues(false,false)">
                            <mat-option value="Cash">Cash</mat-option>
                              <mat-option value="Cheque">Cheque</mat-option>
                              <mat-option value="Card">Card</mat-option>       
                              <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                              <mat-option value="AMEX">AMEX</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Funds Banked</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGrossClientFunds.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>                    
                      <div class="transactionValueContainer" *ngIf="checkViewSupplierAmountAvailable()">
                        <p class="transactionValueTab">Supplier Amount</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewSupplierAmount.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewSupplierAmount.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                          
                        </div>            
                      </div>
                      <div *ngIf="viewPrfCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                        <p class="transactionValueTab">Guarantee Deducted</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGuaranteeDeducted.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimClientRefund.checked == true">
                        <p class="transactionValueTab">Client Refund</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewClientRefund.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewClientRefund.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>   
                        </div>                                        
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimReimbursement.checked == true">
                        <p class="transactionValueTab">Reimbursement</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewReimbursement.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewReimbursement.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimCommission.checked == true">
                        <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.viewVAT}} )</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewCommission.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewCommission.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(true,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div *ngIf="!checkUserType()" class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                        <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                        <button class="buttonClass" matTooltip="Delete Transaction" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'View Added')" style="margin-top:15px;">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </div>
                    <div *ngIf="transaction.viewReceiptData.data.length > 0">
                      <div style="margin-bottom: 50px;">
                        <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                          <div class="headerTopLeft" style="width: 500px;">
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="transaction.viewShowReceipt" (click)="transaction.viewShowReceipt = false;">
                              <mat-icon>expand_less</mat-icon>
                            </button>
                            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!transaction.viewShowReceipt" (click)="transaction.viewShowReceipt = true;">
                              <mat-icon>expand_more</mat-icon>
                            </button>
                            Receipts from {{transaction.viewBookingReference}}
                            <button mat-icon-button type="button" (click)="reloadReceiptsList('View Multiple', transaction.viewBookingReference)" matTooltip="Reload Receipts" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 100px; display: inline;">
                              <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                            </button>    
                          </div>
                        </div>
                        <div [@customExpansionDetails]="transaction.viewShowReceipt == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                          <div style="padding: 20px;">
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="receiptDate">
                                  <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalCharge">
                                  <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="paymentMethod">
                                  <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="reference">
                                  <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="payerRef">
                                  <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                </ng-container>
                                <ng-container matColumnDef="receiptStatus">
                                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                              </table>
                            </div>
                            <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                              <table mat-table [dataSource]="transaction.viewReceiptData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                                <ng-container matColumnDef="receiptDate">
                                  <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="totalCharge">
                                  <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                                      <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                                      <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                                    </span>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="paymentMethod">
                                  <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="reference">
                                  <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="payerRef">
                                  <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    <div style="display: flex; align-items: center;">
                                      <div>{{ receipt.payerName }}</div>
                                      <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                                        <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                                      </div>
                                      <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                                        <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                                style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                                          <mat-icon>person_add</mat-icon>
                                        </button>
                                      </div>
                                    </div>
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="receiptStatus">
                                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                  <mat-cell *matCellDef="let receipt">
                                    {{ receipt.receiptCategory | titlecase }}
                                  </mat-cell>
                                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                                </ng-container>
                                <mat-row *matRowDef="let row; columns: receiptColumns;"></mat-row>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div [@customExpansionDetails]="transaction.viewShowReceipt == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="transaction.viewShowReceipt = true;">
                          <mat-icon>expand_more</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="viewMultipleBookingReference == false">
                  <div *ngFor="let transaction of viewPrfTransactionValuesList; let id=index">
                    <div class="transactionValueTable">
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Surname</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Surname</mat-label>
                          <input matInput ngModel [(ngModel)]="transaction.viewClientSurname" (keyup)="checkViewTransactionValues(false,false)">
                        </mat-form-field>
                        <div *ngIf="!checkUserType() && viewMatchedBookingReference != null">
                          <mat-form-field *ngIf="viewListOfClientSurnames.length > 0" appearance="outline" class="transactionValueNormalTab">
                            <mat-label>Booking Customers</mat-label>
                            <mat-select [(ngModel)]="transaction.viewClientSurname" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let customer of viewListOfClientSurnames" [value]="customer.lastName">
                                <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> {{customer.lastName}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                        </div>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Date Funds Banked</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.viewDateFundsBanked" (dateChange)="checkViewTransactionValues(false,false)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Payment Method</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Method</mat-label>
                          <mat-select [(ngModel)]="transaction.viewPaymentMethod" (selectionChange)="checkViewTransactionValues(false,false)">
                            <mat-option value="Cash">Cash</mat-option>
                              <mat-option value="Cheque">Cheque</mat-option>
                              <mat-option value="Card">Card</mat-option>       
                              <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                              <mat-option value="AMEX">AMEX</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Funds Banked</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGrossClientFunds.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>                    
                      <div class="transactionValueContainer" *ngIf="checkViewSupplierAmountAvailable()">
                        <p class="transactionValueTab">Supplier Amount</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewSupplierAmount.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewSupplierAmount.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div *ngIf="viewPrfCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                        <p class="transactionValueTab">Guarantee Deducted</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGuaranteeDeducted.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimClientRefund.checked == true">
                        <p class="transactionValueTab">Client Refund</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewClientRefund.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewClientRefund.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>
                        </div>                      
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimReimbursement.checked == true">
                        <p class="transactionValueTab">Reimbursement</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewReimbursement.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewReimbursement.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimCommission.checked == true">
                        <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.viewVAT}} )</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewCommission.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewCommission.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(true,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div *ngIf="!checkUserType()" class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                        <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                        <button class="buttonClass" matTooltip="Delete Transaction" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'View')" style="margin-top:15px;">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div *ngFor="let transaction of viewPrfAddedTransactionValuesList; let id=index">
                    <div class="transactionValueTable">
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Surname</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Surname</mat-label>
                          <input matInput ngModel [(ngModel)]="transaction.viewClientSurname" (keyup)="checkViewTransactionValues(false,false)">
                        </mat-form-field>
                        <div *ngIf="!checkUserType() && viewMatchedBookingReference != null">
                          <mat-form-field *ngIf="viewListOfClientSurnames.length > 0" appearance="outline" class="transactionValueNormalTab">
                            <mat-label>Booking Customers</mat-label>
                            <mat-select [(ngModel)]="transaction.viewClientSurname" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let customer of viewListOfClientSurnames" [value]="customer.lastName">
                                <span *ngIf="customer.lead"><svg-icon src="assets/icons/viking-helmet.svg" [svgStyle]="{'width.px':15,'height.px':15,'fill':'rgb(77,95,209)'}"></svg-icon></span> {{customer.lastName}} 
                              </mat-option>
                            </mat-select>
                          </mat-form-field> 
                        </div>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Date Funds Banked</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.viewDateFundsBanked" (dateChange)="checkViewTransactionValues(false,false)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker ></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Payment Method</p>
                        <mat-form-field appearance="outline" class="transactionValueNormalTab">
                          <mat-label>Method</mat-label>
                          <mat-select [(ngModel)]="transaction.viewPaymentMethod" (selectionChange)="checkViewTransactionValues(false,false)">
                            <mat-option value="Cash">Cash</mat-option>
                              <mat-option value="Cheque">Cheque</mat-option>
                              <mat-option value="Card">Card</mat-option>       
                              <mat-option value="Bank Transfer">Bank Transfer</mat-option>
                              <mat-option value="AMEX">AMEX</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="transactionValueContainer">
                        <p class="transactionValueTab">Client Funds Banked</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGrossClientFunds.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGrossClientFunds.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>                    
                      <div class="transactionValueContainer" *ngIf="checkViewSupplierAmountAvailable()">
                        <p class="transactionValueTab">Supplier Amount</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewSupplierAmount.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewSupplierAmount.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                          
                        </div>            
                      </div>
                      <div *ngIf="viewPrfCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                        <p class="transactionValueTab">Guarantee Deducted</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewGuaranteeDeducted.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewGuaranteeDeducted.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimClientRefund.checked == true">
                        <p class="transactionValueTab">Client Refund</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewClientRefund.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewClientRefund.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>   
                        </div>                                        
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimReimbursement.checked == true">
                        <p class="transactionValueTab">Reimbursement</p>
                        <div style="display:grid;">
                          <span class="centerCell">
                            <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                              <mat-label>Currency</mat-label>
                              <mat-select [(ngModel)]="transaction.viewReimbursement.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                                <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="transactionValueAmountTab">
                              <mat-label>Amount</mat-label>
                              <input matInput [(ngModel)]="transaction.viewReimbursement.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(false,false)">
                            </mat-form-field>
                          </span>                        
                        </div>
                      </div>
                      <div class="transactionValueContainer" *ngIf="viewClaimCommission.checked == true">
                        <p class="transactionValueTab">Commission ( Incl. VAT - {{transaction.viewVAT}} )</p>
                        <span class="centerCell">
                          <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                            <mat-label>Currency</mat-label>
                            <mat-select [(ngModel)]="transaction.viewCommission.currency" (selectionChange)="checkViewTransactionValues(false,false)">
                              <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="transactionValueAmountTab">
                            <mat-label>Amount</mat-label>
                            <input matInput [(ngModel)]="transaction.viewCommission.amount" type="number" step="0.01" (keyup)="checkViewTransactionValues(true,false)">
                          </mat-form-field>
                        </span>
                      </div>
                      <div *ngIf="!checkUserType()" class="transactionValueContainer" style="padding: 2px 0px 2px 10px;">
                        <p class="transactionValueTab" style="padding: 5px 5px 5px 6px;">Delete</p>
                        <button class="buttonClass" matTooltip="Delete Transaction" mat-raised-button color="primary" (click)="deleteTransactionValue(id,'View Added')" style="margin-top:15px;">
                          <mat-icon>clear</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="receiptData.data.length > 0">
                    <div class="ownExpansionHeader" style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                      <div class="headerTopLeft" style="width: 500px;">
                        <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="showReceiptList" (click)="showReceiptList = false;">
                          <mat-icon>expand_less</mat-icon>
                        </button>
                        <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!showReceiptList" (click)="showReceiptList = true;">
                          <mat-icon>expand_more</mat-icon>
                        </button>
                        Receipts
                        <button mat-icon-button type="button" (click)="reloadReceiptsList('View Single',0)" matTooltip="Reload Receipts" matTooltipClass="line-normal-tooltip" color="primary" style="margin-left: 50px; display: inline;">
                          <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                        </button>    
                      </div>
                    </div>
                    <div [@customExpansionDetails]="showReceiptList == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                      <div style="padding: 20px;">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="receiptDate">
                              <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="totalCharge">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="paymentMethod">
                              <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="reference">
                              <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="payerRef">
                              <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="receiptStatus">
                              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="receiptColumns"></mat-header-row>
                          </table>
                        </div>
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table [dataSource]="receiptData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="receiptDate">
                              <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                              <mat-cell *matCellDef="let receipt">
                                <span style="width: 73px;">{{receipt.receiptDate | date : "dd.MM.y" }}</span>
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="totalCharge">
                              <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                              <mat-cell *matCellDef="let receipt">
                                <span matTooltip="Including {{ receipt.merchantFee | currency: 'GBP' }} merchant fee" matTooltipClass="line-normal-tooltip">
                                  <span *ngIf="receipt.receiptCategory == 'receipt'">{{ receipt.totalCharge | currency:'GBP' }}</span>
                                  <span *ngIf="receipt.receiptCategory == 'mistake'"><s>{{ receipt.totalCharge | currency:'GBP' }}</s></span>
                                </span>
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef style="font-size: 13px;"><b>{{ receiptTotalSum.totalCharge | currency: 'GBP' }}</b></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="paymentMethod">
                              <mat-header-cell *matHeaderCellDef> Method </mat-header-cell>
                              <mat-cell *matCellDef="let receipt"> {{ receipt.paymentMethod | titlecase }} </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="reference">
                              <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                              <mat-cell *matCellDef="let receipt"> {{ receipt.reference }} </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="payerRef">
                              <mat-header-cell *matHeaderCellDef> Payer </mat-header-cell>
                              <mat-cell *matCellDef="let receipt">
                                <div style="display: flex; align-items: center;">
                                  <div>{{ receipt.payerName }}</div>
                                  <div *ngIf="(receipt.payerId == null || receipt.payerId == undefined || receipt.payerId == '') && receipt.receiptCategory == 'receipt'">
                                    <mat-icon style="margin-left: 10px; color: #ce983a;">priority_high</mat-icon>
                                  </div>
                                  <div *ngIf="receipt.missingPax && receipt.receiptCategory == 'receipt'">
                                    <button class="matIcon" type="button" mat-mini-fab color="accent" (click)="addMissingPaxFromReceipt(receipt)" [class.mat-elevation-z0]="true"
                                            style="margin-left: 10px;" matTooltip="Add missing passenger" matTooltipClass="line-normal-tooltip">
                                      <mat-icon>person_add</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="receiptStatus">
                              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                              <mat-cell *matCellDef="let receipt">
                                {{ receipt.receiptCategory | titlecase }}
                              </mat-cell>
                              <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                            </ng-container>
                            <mat-row *matRowDef="let row; columns: receiptColumns;"></mat-row>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div [@customExpansionDetails]="showReceiptList == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="showReceiptList = true;">
                      <mat-icon>expand_more</mat-icon>
                    </div>
                  </div>


                </div>   
                <div style="padding:10px;">
                  <h3 *ngIf="viewClaimReimbursement.checked == true">
                    Total Reimbursement : <span *ngIf="viewPrfTransactionValuesList.length > 0">{{viewPrfTransactionValuesList[0]?.viewReimbursement.currency}}</span> 
                                          <span *ngIf="viewPrfTransactionValuesList.length == 0">{{viewPrfAddedTransactionValuesList[0]?.viewReimbursement.currency}}</span> {{calculateViewReimbursementTotal() | number : '1.2-2'}}
                  </h3>
                  <h3 *ngIf="viewClaimClientRefund.checked == true">
                    Total Client Refund : <span *ngIf="viewPrfTransactionValuesList.length > 0">{{viewPrfTransactionValuesList[0]?.viewClientRefund.currency}}</span> 
                                          <span *ngIf="viewPrfTransactionValuesList.length == 0">{{viewPrfAddedTransactionValuesList[0]?.viewClientRefund.currency}}</span> {{calculateViewClientRefundTotal() | number : '1.2-2'}}
                  </h3>
                  <h3 *ngIf="viewClaimSupplierPayment.checked == true">
                    Total Supplier Payment : <span *ngIf="viewPrfTransactionValuesList.length > 0">{{viewPrfTransactionValuesList[0]?.viewSupplierAmount.currency}}</span> 
                                             <span *ngIf="viewPrfTransactionValuesList.length == 0">{{viewPrfAddedTransactionValuesList[0]?.viewSupplierAmount.currency}}</span>  {{calculateViewSupplierAmountTotal() | number : '1.2-2'}}
                  </h3>
                  <h3 *ngIf="viewClaimCommission.checked == true">
                    Total Commission : <span *ngIf="viewPrfTransactionValuesList.length > 0">{{viewPrfTransactionValuesList[0]?.viewCommission.currency}}</span> 
                                       <span *ngIf="viewPrfTransactionValuesList.length == 0">{{viewPrfAddedTransactionValuesList[0]?.viewCommission.currency}}</span> {{calculateViewCommissionTotal() | number : '1.2-2'}}
                  </h3>
                </div>
                <div *ngIf="!checkUserType()">
                    <button class="buttonClass" matTooltip="Add Transaction" mat-raised-button color="primary" (click)="addTransactionValue('View')" style="margin-left: 10px; margin-top: 10px;">
                      <mat-icon>add</mat-icon>
                    </button>
                    <button class="buttonClass" matTooltip="Reload Transactions" mat-raised-button color="primary" (click)="onPrfTransactionsReload()" style="margin-left: 10px; margin-top: 10px;">
                      <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div *ngIf="checkUserType()" class="trusteeContainer2" style="margin-top: 25px;">
                  <h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="35" style="color: black;">
                        Trustee checks
                      </div>
                      <div style="margin-left:20px; margin-right: 20px;">
                        <section class="example-section">
                          <mat-checkbox color="primary" [(ngModel)]='viewPrfClientFundsBanked'>Client funds banked</mat-checkbox>
                        </section>
                      </div>
                    </div>
                  </h3>
                </div>
                <div *ngIf="!thirdViewCompleted" class="prfNavigationBox">
                  <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                </div>
                <div *ngIf="thirdViewCompleted" class="prfNavigationBox">
                  <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                  <button class="prfNavigationBtn" (click)="goForward()" mat-raised-button color="primary"><mat-icon>arrow_forward</mat-icon></button>
                </div>
              </mat-step>
              <mat-step label="Sign Off" [completed]="thirdViewCompleted">
                <h3 *ngIf="!checkUserType()" style="margin-top: 25px;">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="15">
                      Notes to Trustees
                    </div>
                    <div fxFlex="40">
                      <textarea class="emailBox" name="notes" rows="3" [(ngModel)]="viewPrfNotesToTrustee" maxlength="255"></textarea>
                    </div>
                  </div>
                </h3>
                <h3 style="margin-top: 25px; color: black;">Sign Off</h3>
                I hereby certify the above release of funds is due in accordance with the trust deed document and in compliance with the code of conduct.<br>
                All commission and reimbursements from the trust account have taken into account supplier amounts paid.
                <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticate(authForm, actionType)" *ngIf="!checkUserType() && !checkIfSingsAdmin()">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="10">
                      <h3 style="padding-left: 20px;">Email</h3>
                    </div>
                    <div fxFlex="40">
                      <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                        <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                      </mat-form-field>
                    </div>
                  </div>
                
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="10">
                      <h3 style="padding-left: 20px;">Password</h3>
                    </div>
                    <div fxFlex="40">
                      <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                        <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                      </mat-form-field>
                    </div>
                  </div>
                
                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                    <div >
                      <!-- Button for "Sign PRF" (VIEW) -->
                      <button *ngIf='checkIfManager()' class="buttonClass" matTooltip="This will send your PRF to the TTA Trustees" mat-raised-button color="primary" type="submit" (click)="setAuthenticationAction('Update')" style="margin-right: 50px;">
                        Send PRF to TTA Trustees
                      </button>
                      <!-- Button for "Save PRF" (VIEW) -->
                      <button *ngIf='checkIfSaveOption("View")'  matTooltip="This will not send your PRF to the TTA Trustees" class="buttonClass" mat-raised-button color="accent" type="submit" (click)="setAuthenticationAction('SaveUpdate')">
                        Save PRF (Not Submitted)
                      </button>
                    </div>
                  </div>
                </form>
                <!-- Delete PRF (only SingsAdmin) --> 
                <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticate(authForm,'Delete')" *ngIf="checkIfSingsAdmin()">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="10">
                      <h3 style="padding-left: 20px;">Email</h3>
                    </div>
                    <div fxFlex="40">
                      <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                        <input matInput required ngModel name="email" #email="ngModel" maxlength="100" type="email">
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="10">
                      <h3 style="padding-left: 20px;">Password</h3>
                    </div>
                    <div fxFlex="40">
                      <mat-form-field style="width: 300px; max-width: unset; font-size: 15px;">
                        <input matInput required ngModel name="password" #password="ngModel" maxlength="100" type="password">
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:10px;">
                    <div fxFlex="30">
                      <button class="buttonClass" mat-raised-button color="primary" type="submit">Delete PRF</button>
                    </div>
                  </div>
                </form>
                <!-- Office Control View --> 
                <div *ngIf="checkUserType()">
                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 15px; font-weight: bold;">
                    <div fxFlex="30">
                      <span style="color:black;">PRF Signed Off By : </span>{{viewPrfCreator}}
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 5px; font-weight: bold;">
                    <div fxFlex="30">
                      <span style="color:black;">Signed On : </span>{{viewPrfDateSubmitted}}
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center" style="font-weight: bold; margin-top: 5px; margin-bottom: 30px;">
                    <div fxFlex="30">
                      <span style="color:black;">Notes to Trustees : </span>
                      <span *ngIf="viewPrfNotesToTrustee != ''">
                        {{viewPrfNotesToTrustee}}
                      </span>
                      <span *ngIf="viewPrfNotesToTrustee == ''">
                        N/A
                      </span>
                    </div>
                  </div>
                  <div class="trusteeControl">
                    <h2 style="margin-top: 25px; color: black; padding:5px;">Office Control</h2>
                    <hr class="customDivider">
                    <div name="Audit Tabel" style="padding-top: 20px; padding-bottom: 30px; padding-right: 40px; padding-left: 20px;">
                        <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                          <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                            <ng-container matColumnDef="signedDate">
                              <mat-header-cell *matHeaderCellDef> Date & Time </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="trusteeName">
                              <mat-header-cell *matHeaderCellDef> Trustee Name </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="status">
                              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            </ng-container>
                            <ng-container matColumnDef="officeNotes">
                              <mat-header-cell *matHeaderCellDef style="padding-right: 0px !important;"> Office Notes </mat-header-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="viewPrfAuditListColumns"></mat-header-row>
                          </table>
                          </div>
                          <div class="example-container mat-elevation-z8" style="height: fit-content;">
                            <table mat-table [dataSource]="viewPrfAuditListData" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                              <ng-container matColumnDef="signedDate">
                                <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let user"> {{user.signedDate.split("T")[0]}} <br>{{user.signedDate.split("T")[1].split("Z").join("").split('.000').slice(0, -1)}} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="trusteeName">
                                <mat-header-cell *matHeaderCellDef> Trustee Name </mat-header-cell>
                                <mat-cell style="font-weight:bold;" *matCellDef="let user">{{user.trusteeName}}</mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell style="font-weight:bold;" *matCellDef="let user">
                                  {{user.status}}
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Action Required'">new_releases</mat-icon>
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Processing'">pending</mat-icon>
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Ready for signing'">radio_button_unchecked</mat-icon>
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Awaiting return date'">pause_circle_outline</mat-icon>
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Awaiting cleared fund'">pause_circle_outline</mat-icon>
                                  <mat-icon style="color: #4D5FD1; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Authorisation Pending'">pause_circle_outline</mat-icon>
                                  <mat-icon style="color: #00c521; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Authorised'">check_circle</mat-icon>
                                  <mat-icon style="color: #FA0E62; padding-top: 2px; padding-right: 5px; padding-left: 5px;" *ngIf="user.status == 'Cancelled'">remove_circle</mat-icon>
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="officeNotes">
                                <mat-header-cell *matHeaderCellDef> Office Notes </mat-header-cell>
                                <mat-cell style="font-weight:bold; padding: 10px 15px 10px 10px;" *matCellDef="let user">
                                  <div style="padding: 0px 0px 0px 15px;" [innerHTML]="replaceLineBreak(user.officeNotes)"></div>
                                  <div style="padding: 0px 0px 0px 15px;" *ngIf="user.officeNotes==''">N/A</div>
                                </mat-cell>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: viewPrfAuditListColumns;"></mat-row>
                            </table>
                          </div>
                    </div>
                    <hr class="customDivider">
                    <div style="padding-right:20px; padding-top:10px; padding-left: 20px;">
                      <h3 style="margin-top: 15px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Trustee checks
                          </div>
                          <div fxFlex="40">
                            <section class="example-section">
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfClientFundsBanked'>Client funds banked</mat-checkbox>
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfThirdPartyPaperwork'>Third party paperwork</mat-checkbox>
                            </section>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-top: 0px; margin-bottom: 0px;" *ngIf="getSupportingDocumentationTitle(viewPrfClaimOne,viewPrfClaimTwo).includes('Commission')">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Commission checks
                          </div>
                          <div fxFlex="50">
                            <section class="example-section">
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForAtol'>ATOL</mat-checkbox>
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForPsa'>PSA</mat-checkbox>
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForSfc'>SFC / SAFI</mat-checkbox>
                              <mat-checkbox style="padding: 7px;" color="primary" [(ngModel)]='viewPrfComCheckForClientsReturned'>Clients Returned</mat-checkbox>
                            </section>
                          </div>
                        </div>
                      </h3>
                      <div *ngIf="checkIfExecutive()">
                        <h3 style="margin-top: 0px; margin-bottom: 0px;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="15" style="color: black;">
                              TTA Member<br>Account Balance
                            </div>
                            <div fxFlex="40" style="padding-top: 20px;">
                              <span class="centerCell">
                                <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                  <mat-label>Currency</mat-label>
                                  <mat-select [(ngModel)]="viewPrfMemberAccountBalanceCurrency">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="transactionValueAmountTab"  [style.width.px]=150>
                                  <mat-label>Balance</mat-label>
                                  <input matInput [(ngModel)]="viewPrfMemberAccountBalanceAmount" type="number" step="0.01">
                                </mat-form-field>
                              </span>
                            </div>
                          </div>
                        </h3>

                        <h3 style="margin-top: 0px; margin-bottom: 0px;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="15" style="color: black;">
                              Overall PRF-Amount(s)<br>(Trustee View)
                            </div>
                            <div fxFlex="40" style="padding: 7px;">
                              <div style="display:block; margin-bottom:10px;">
                                <span class="brsmall"></span>
                                <div style="display:block;">
                                  {{getClaimAcronym(viewPrfClaimOne)}}
                                  <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                    <mat-label>Currency</mat-label>
                                    <mat-select [(ngModel)]="viewPrfCurrencyOneTrustee" (selectionChange)="checkViewExTransactionValues()">
                                      <mat-option [value]="null"></mat-option>
                                      <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field appearance="outline" class="transactionValueAmountTab"  [style.width.px]=150>
                                    <mat-label>Amount</mat-label>
                                    <input matInput [(ngModel)]="viewPrfAmountOneTrustee" type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                                  </mat-form-field>
                                </div>
                                <span class="brsmall"></span>
                                <div style="display:block;" *ngIf="viewPrfClaimTwo != null && viewPrfAmountTwo != null && viewPrfCurrencyTwo != null">
                                  {{getClaimAcronym(viewPrfClaimTwo)}} 
                                  <mat-form-field appearance="outline" class="transactionValueCurrencyTab">
                                    <mat-label>Currency</mat-label>
                                    <mat-select [(ngModel)]="viewPrfCurrencyTwoTrustee" (selectionChange)="checkViewExTransactionValues()">
                                      <mat-option [value]="null"></mat-option>
                                      <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <mat-form-field appearance="outline" class="transactionValueAmountTab"  [style.width.px]=150>
                                    <mat-label>Amount</mat-label>
                                    <input matInput [(ngModel)]="viewPrfAmountTwoTrustee" type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </h3>

                      </div>
                      
                      <div *ngIf="!checkIfExecutive()">

                        <h3 style="margin-top: 0px; margin-bottom: 0px;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="15" style="color: black;">
                              Overall PRF-Amount(s)<br>(Trustee View)
                            </div>
                            <div fxFlex="40" style="padding: 7px;">
                              <div style="display:block; margin-bottom:10px;">
                                <span class="brsmall"></span>
                                <div style="display:block;">
                                  {{getClaimAcronym(viewPrfClaimOne)}} {{viewPrfCurrencyOneTrustee}} {{viewPrfAmountOneTrustee | number : '1.2-2'}}
                                </div>
                                <span class="brsmall"></span>
                                <div style="display:block;" *ngIf="viewPrfClaimTwo != null && viewPrfAmountTwo != null && viewPrfCurrencyTwo != null">
                                  {{getClaimAcronym(viewPrfClaimTwo)}} {{viewPrfCurrencyTwoTrustee}} {{viewPrfAmountTwoTrustee | number : '1.2-2'}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </h3>

                        <h3 style="margin-top: 0px; margin-bottom: 0px;">
                          <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="15" style="color: black;">
                              TTA Member<br>Account Balance
                            </div>
                            <div fxFlex="40" style="padding: 7px;">
                              <span class="centerCell">
                                <h3>{{viewPrfMemberAccountBalanceCurrency}} {{viewPrfMemberAccountBalanceAmount}}</h3>
                              </span>
                            </div>
                          </div>
                        </h3>

                      </div>


                      <h3 style="margin-top: 0px; margin-bottom: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Overall PRF-Amount(s)<br>(Member View)
                          </div>
                          <div fxFlex="40" style="padding: 7px;">
                            <div style="display:block; margin-bottom:10px;">
                              <span class="brsmall"></span>
                              <div style="display:block;">
                                {{getClaimAcronym(viewPrfClaimOne)}} {{viewPrfCurrencyOne}} {{viewPrfAmountOne | number : '1.2-2'}}
                              </div>
                              <span class="brsmall"></span>
                              <div style="display:block;" *ngIf="viewPrfClaimTwo != null && viewPrfAmountTwo != null && viewPrfCurrencyTwo != null">
                                {{getClaimAcronym(viewPrfClaimTwo)}} {{viewPrfCurrencyTwo}} {{viewPrfAmountTwo | number : '1.2-2'}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </h3>

                      <div *ngIf="checkIfExecutive()">
                        <h3 *ngFor="let transaction of viewPrfTransactionValuesList; let transactionCounter=index">
                          <div fxFlex="15" style="color: black; padding-top: 25px;">
                            Transaction {{transactionCounter+1}}
                          </div>
                          <div fxFlex="60" style="display: inherit;">
                            <div *ngIf="viewClaimSupplierPayment.checked == true">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab" [style.width.px]=120>
                                <mat-label>Currency SUP</mat-label>
                                <mat-select [(ngModel)]="transaction.viewSupplierAmount.currency" (selectionChange)="checkViewExTransactionValues()">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab" [style.width.px]=120>
                                <mat-label>Amount SUP</mat-label>
                                <input matInput [(ngModel)]="transaction.viewSupplierAmount.amount"  type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                              </mat-form-field>
                            </div>
                            <div *ngIf="viewClaimCommission.checked == true">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab" [style.width.px]=120>
                                <mat-label>Currency COM</mat-label>
                                <mat-select [(ngModel)]="transaction.viewCommission.currency" (selectionChange)="checkViewExTransactionValues()">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab" [style.width.px]=120>
                                <mat-label>Amount COM</mat-label>
                                <input matInput [(ngModel)]="transaction.viewCommission.amount" type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                              </mat-form-field>
                            </div>
                            <div *ngIf="viewClaimClientRefund.checked == true">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab" [style.width.px]=120>
                                <mat-label>Currency CR</mat-label>
                                <mat-select [(ngModel)]="transaction.viewClientRefund.currency" (selectionChange)="checkViewExTransactionValues()">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab" [style.width.px]=120>
                                <mat-label>Amount CR</mat-label>
                                <input matInput [(ngModel)]="transaction.viewClientRefund.amount" type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                              </mat-form-field>
                            </div>
                            <div *ngIf="viewClaimReimbursement.checked == true">
                              <mat-form-field appearance="outline" class="transactionValueCurrencyTab" [style.width.px]=120>
                                <mat-label>Currency REI</mat-label>
                                <mat-select [(ngModel)]="transaction.viewReimbursement.currency" (selectionChange)="checkViewExTransactionValues()">
                                  <mat-option *ngFor="let currency of listOfCurrency" [value]="currency.value">{{currency.key}}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="outline" class="transactionValueAmountTab" [style.width.px]=120>
                                <mat-label>Amount REI</mat-label>
                                <input matInput [(ngModel)]="transaction.viewReimbursement.amount" type="number" step="0.01" (keyup)="checkViewExTransactionValues()">
                              </mat-form-field>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <h3 style="margin-top: 0px; margin-bottom: 0px;" *ngIf="viewPrfClientReturnDateEnable">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Return Date
                          </div>
                          <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="picker" [value]="viewPrfClientReturnDate" [(ngModel)]="viewPrfClientReturnDate" name="viewClientReturnDate" (dateChange)="checkViewOnlinePrfInformation()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </h3>
                      <h3 style="margin-top: 0px; margin-bottom: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Office Notes
                          </div>
                          <div fxFlex="40">
                              <textarea class="emailBox" rows="5" [(ngModel)]="viewPrfOfficeNotes"></textarea>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-top: 20px; margin-bottom: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Currently Assigned To
                          </div>
                          <div fxFlex="40">
                            <h3>{{viewPrfAssignedTo}}</h3>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-top: 0px; margin-bottom: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Re-assign to
                          </div>
                          <div fxFlex="40">
                            <mat-form-field style="width: 300px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select [(ngModel)]="reAssignTo" style="vertical-align: middle;" (selectionChange)="checkReAssignTo()">
                                <mat-option value="0">None</mat-option>
                                <mat-option value="Steve Button">Steve Button</mat-option>
                                <mat-option value="Ryan Cherrington">Ryan Cherrington</mat-option>
                                <mat-option value="Riffat Bahar">Riffat Bahar</mat-option>
                                <mat-option value="Ashley Harvey">Ashley Harvey</mat-option>
                                <div *ngIf="checkIfExecutive()">
                                  <mat-option value="Trustees">All Trustees</mat-option>
                                  <mat-option value="All">All Members ({{viewPrfTradeCode}})</mat-option>
                                  <mat-option value="{{viewPrfCreator}}">{{viewPrfCreator}} (Creator)</mat-option>
                                </div>
                                <div *ngIf="!checkIfExecutive()">
                                  <mat-option value="{{userName}}">{{userName}}</mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3 style="margin-top: 0px; margin-bottom: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Status
                          </div>
                          <div fxFlex="40">
                            <mat-form-field style="width: 300px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select [(ngModel)]="viewPrfStatus" style="vertical-align: middle;" (selectionChange)="checkStatus()">
                                <mat-option value="Processing">Processing</mat-option>
                                <mat-option value="Action Required">Action Required</mat-option>
                                <mat-option value="Authorised">Authorised</mat-option>
                                <div *ngIf="checkIfExecutive()">
                                  <mat-option value="Awaiting return date">Awaiting return date</mat-option>
                                  <mat-option value="Awaiting cleared fund">Awaiting cleared fund</mat-option>
                                  <mat-option value="Authorisation Pending">Authorisation Pending</mat-option>
                                  <mat-option value="Cancelled">Cancelled</mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3 *ngIf="viewPrfStatus == 'Action Required' && checkIfExecutive()" style="margin-top: 0px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Action Required*
                          </div>
                          <div fxFlex="60">
                            <mat-form-field style="width: 650px; max-width: unset;" class="transactionValueNormalTab">
                              <mat-select style="vertical-align: middle;" [(ngModel)]="selectedAction" (selectionChange)="checkActionRequired()">
                                <mat-option *ngFor="let action of listOfActions" [value]="action.value">
                                  {{action.text}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </h3>
                      <h3 *ngIf="viewPrfStatus == 'Action Required' && selectedAction != 0" style="padding:5px;">
                        <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 0px; margin-bottom: 20px;">
                          <div fxFlex="15" style="color: black;">
                            Email Subject
                          </div>
                          <div fxFlex="60" style="padding: 7px;">
                            "{{listOfActions[selectedAction].text}} (PRF-{{viewPrfNumber}})"
                          </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="15" style="color: black;">
                            Email Text
                          </div>
                          <div fxFlex="60">
                            <textarea class="emailBox" rows="15" [(ngModel)]="viewPrfEmailText"></textarea>
                          </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px; margin-bottom: 0px;">
                          <div fxFlex="15" style="color: black;">
                            Email To
                          </div>
                          <mat-form-field style="width: 300px; max-width: unset;" class="transactionValueNormalTab">
                            <mat-select multiple placeholder="Email To" [(ngModel)]="emailTo" style="vertical-align: middle;">
                              <mat-option *ngFor="let email of emailOptions" [value]="email.value">{{email.key}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 0px; margin-bottom: 15px;">
                          <div fxFlex="15" style="color: black;">
                            Add Email
                          </div>
                          <mat-form-field style="width: 300px; max-width: unset; font-weight:bold; font-size: 15px; padding-left: 5px;">
                            <input matInput placeholder="New Email" [(ngModel)]="addEmail" autocomplete="nope">
                          </mat-form-field>
                          <div fxFlex="30" style="margin-left: 20px;">
                            <button class="buttonClass" mat-raised-button color="primary" (click)="addNewEmail()"><mat-icon>add</mat-icon></button>
                          </div>
                        </div>
                      </h3>
                    </div>                    
                    <hr class="customDivider" >
                    <h3 style="display: flex;">
                      <div style="margin:10px; padding-left:15px;">
                        <button [disabled]="!exTransactionViewChanged" class="buttonClass" mat-raised-button color="primary" style="margin-right: 20px;" (click)="trusteeSignPrf()">Sign PRF</button>
                        <!--
                          <button class="buttonClass" mat-raised-button color="primary" (click)="showViewReport()">Summary Report</button>
                        --> 
                      </div>
                      <div style="margin:10px;" *ngIf="exTransactionViewChanged == false">
                        <button class="buttonClass" mat-raised-button color="primary" (click)="updatePrfForTrustee()">Update Transactions</button>
                      </div>
                    </h3>
                  </div>
                </div>
                <div class="prfNavigationBox">
                  <button class="prfNavigationBtn" style="margin-right: 20px;" (click)="goBack()" mat-raised-button color="primary"><mat-icon>arrow_back</mat-icon></button>
                </div>
              </mat-step>
            </mat-horizontal-stepper>
            </div>
            <!-- PRF Reports -->
            <div *ngIf="prfReports">
              <!-- Reports Header -->
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>PRF Reports
                <div style="margin-left:10px;" *ngIf="selectedReport != null"> 
                  <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>{{selectedReport.name}}
                </div>
              </h1>
              <mat-divider></mat-divider>
              <br>
              <!-- Selected Report and Parameters -->
              <h3 style="margin: auto; text-align: center;">
                <mat-form-field *ngIf="checkUserType()" style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                  <mat-select placeholder="Select Report" [(ngModel)]="selectedReport">
                    <mat-option [matTooltip]="report.description" *ngFor="let report of listOfReports" [value]="report">
                      {{report.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!checkUserType()" style="width: 500px; max-width: unset;" class="transactionValueNormalTab">
                  <mat-select placeholder="Select Report" [(ngModel)]="selectedReport">
                    <mat-option [matTooltip]="report.description" *ngFor="let report of filterReportsListForAgent(listOfReports)" [value]="report">
                      {{report.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="selectedReport != null"> 
                  <h3 style="margin: auto; text-align: center; display:block;">
                    <div *ngIf="selectedReport.parameters.startDate != null" style="display:inline; margin:10px;">
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                        <mat-label>Start Date <span style="color:#4D5FD1">*</span></mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedReport.parameters.startDate" name="reportStartDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div *ngIf="selectedReport.parameters.endDate != null" style="display:inline; margin:10px;">
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                        <mat-label>End Date <span style="color:#4D5FD1">*</span></mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedReport.parameters.endDate" name="reportEndDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker ></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div *ngIf="selectedReport.parameters.ttaNumber != null && checkUserType()" style="display:inline; margin:10px;">
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                        <mat-label>TTA Number</mat-label>
                        <input matInput [(ngModel)]="selectedReport.parameters.ttaNumber" name="reportTtaNumber">
                      </mat-form-field>
                    </div>
                    <div *ngIf="selectedReport.parameters.supplierName != null" style="display:inline; margin:10px;">
                      <mat-form-field appearance="outline" style="font-weight: bold !important; padding-bottom: 0px !important;">
                        <mat-label>Supplier Name <span style="color:#4D5FD1">*</span></mat-label>
                        <mat-select placeholder="Supplier Name" [(ngModel)]="selectedReport.parameters.supplierName">
                          <mat-option value="All">All</mat-option>
                          <mat-option value="None">None</mat-option>
                          <mat-option value="Various Suppliers">Various Suppliers</mat-option>
                          <mat-option *ngFor="let supplier of suppliersList" [value]="supplier.supplierNameM">
                            {{supplier.supplierNameM}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <button [disabled]="isReportParametersReady()"  class="buttonClass" mat-raised-button color="primary" style="display:inline; margin-bottom:20px; margin-left:10px; " (click)="generatePrfReport('list')">Search</button>
                    <button [disabled]="isReportParametersReady()"  class="buttonClass" mat-raised-button color="primary" style="display:inline; margin-bottom:20px; margin-left:10px; " (click)="generatePrfReport('csv')">CSV</button>                    
                  </h3>
                </div>
              </h3>
              <!-- Report Table -->
              <div *ngIf="selectedReport != null" style="padding:20px;">
                <h3 style="margin-bottom: 0px; margin-left:20px;">
                  <p>{{selectedReport.table.data.length}} Record(s)</p>
                </h3>
                <div *ngIf="selectedReport.table.data.length > 0" name="Reporting (Admin) List">
                  <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                    <!-- Member PRF Volume -->
                    <table *ngIf="selectedReport.name == 'Member PRF Volume'" mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Name','name','String')" style="cursor:pointer;"> Name </mat-header-cell>
                      </ng-container>
                      <ng-container  *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Payment_Amount_1','paymentAmountOne','Number')"  style="cursor:pointer;"> Payment<br>Amount 1 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Payment_Amount_2','paymentAmountTwo','Number')"  style="cursor:pointer;"> Payment<br>Amount 2 </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- No Commission Claims By Members -->
                    <table *ngIf="selectedReport.name == 'No Commission Claims By Members'" mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">                      
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Member Name','name','String')" style="cursor:pointer;"> Member<br>Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('numberOfNewBookings')" matColumnDef="numberOfNewBookings">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Number of New Bookings','numberOfNewBookings','Number')" style="cursor:pointer;"> Number Of<br>New Bookings </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('commission')" matColumnDef="commission">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Commission','commission','Number')" style="cursor:pointer;"> Commission </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('supplierPayment')" matColumnDef="supplierPayment">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier Payment','supplierPayment','Number')" style="cursor:pointer;"> Supplier<br>Payment </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- PRF Email Sent -->
                    <table *ngIf="selectedReport.name == 'PRF Email Sent'" mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('companyName')" matColumnDef="companyName">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Company Name','companyName','String')" style="cursor:pointer;"> Company<br>Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('prfsSubmitted')" matColumnDef="prfsSubmitted">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Prfs Submitted','prfsSubmitted','Number')" style="cursor:pointer;"> Prfs<br>Submitted </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('approxEmailsSent')" matColumnDef="approxEmailsSent">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Approx Emails Sent','approxEmailsSent','Number')" style="cursor:pointer;"> Approx<br>Emails Sent </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>                    
                    <!-- Payment Search -->
                    <table *ngIf="selectedReport.name == 'Payment Search'" mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">  
                      <ng-container *ngIf="columnExists('prfNumber')" matColumnDef="prfNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Prf#','prfNumber','Number')" style="cursor:pointer;"> Prf# </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('assignedTo')" matColumnDef="assignedTo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Assigned To','assignedTo','String')" style="cursor:pointer;"> Assigned<br>To </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('dateSubmitted')" matColumnDef="dateSubmitted">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Submitted','dateSubmitted','Date')" style="cursor:pointer;"> Date<br>Submitted </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('dateAuthorised')" matColumnDef="dateAuthorised">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Authorised','dateAuthorised','Timestamp')" style="cursor:pointer;"> Date<br>Authorised </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('authorisedBy')" matColumnDef="authorisedBy">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Authorised by','authorisedBy','String')" style="cursor:pointer;"> Authorised<br>By </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier Name','supplierName','String')" style="cursor:pointer;"> Supplier<br>Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentMethod')" matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentMethod','paymentMethod','String')" style="cursor:pointer;"> Payment<br>Method </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount1','paymentAmountOne','Number')" style="cursor:pointer;"> Payment<br>Amount 1 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount2','paymentAmountTwo','Number')" style="cursor:pointer;"> Payment<br>Amount 2 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('amount')" matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Amount','amount','Number')" style="cursor:pointer;"> Amount </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('authorised')" matColumnDef="authorised">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Authorised','authorised','String')" style="cursor:pointer;"> Authorised </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('type')" matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Type','type','String')" style="cursor:pointer; display:block; text-align: center;"> Type </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('claimType')" matColumnDef="claimType">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Claim Type','claimType','String')" style="cursor:pointer; display:block; text-align: center;"> Claim<br>Type </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Payment Search - Board Report -->
                    <table *ngIf="selectedReport.name == 'Payment Search - Board Report'" mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Name','name','String')" style="cursor:pointer;"> Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('clientRefundCount')" matColumnDef="clientRefundCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Client_Refund_Count','clientRefundCount','Number')" style="cursor:pointer;"> Client Refund<br>Count </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('clientRefundAmountOne')" matColumnDef="clientRefundAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Client_Refund_Amount','clientRefundAmountOne','Number')" style="cursor:pointer;"> Client Refund<br>Amount </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('commissionCount')" matColumnDef="commissionCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Commission_Count','commissionCount','Number')" style="cursor:pointer;"> Commission<br>Count </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('commissionAmountOne')" matColumnDef="commissionAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Commission_Amount','commissionAmountOne','Number')" style="cursor:pointer;"> Commission<br>Amount </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('reimbursementCount')" matColumnDef="reimbursementCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Reimbursement_Count','reimbursementCount','Number')" style="cursor:pointer;"> Reimbursement<br>Count </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('reimbursementAmountOne')" matColumnDef="reimbursementAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Reimbursement_Amount','reimbursementAmountOne','Number')" style="cursor:pointer;"> Reimbursement<br>Amount </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('supplierPaymentCount')" matColumnDef="supplierPaymentCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier_Payment_Count','supplierPaymentCount','Number')" style="cursor:pointer;"> Supplier Payment<br>Count </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('supplierPaymentAmountOne')" matColumnDef="supplierPaymentAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier_Payment_Amount','supplierPaymentAmountOne','Number')" style="cursor:pointer;"> Supplier Payment<br>Amount </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('overallCount')" matColumnDef="overallCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Overall_Count','overallCount','Number')" style="cursor:pointer;"> Overall<br>Count </mat-header-cell>
                      </ng-container>                      
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Payment Search - Grouped by Payment -->
                    <table *ngIf="selectedReport.name == 'Payment Search - Grouped by Payment'"  mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('prfNumber')" matColumnDef="prfNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Prf#','prfNumber','Number')" style="cursor:pointer;"> Prf# </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('assignedTo')" matColumnDef="assignedTo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Assigned To','assignedTo','String')" style="cursor:pointer;"> Assigned<br>To </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('dateSubmitted')" matColumnDef="dateSubmitted">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Submitted','dateSubmitted','Date')" style="cursor:pointer;"> Date<br>Submitted </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('dateAuthorised')" matColumnDef="dateAuthorised">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Authorised','dateAuthorised','Timestamp')" style="cursor:pointer;"> Date<br>Authorised </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier Name','supplierName','String')" style="cursor:pointer;"> Supplier<br>Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentMethod')" matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentMethod','paymentMethod','String')" style="cursor:pointer;"> Payment<br>Method </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount1','paymentAmountOne','Number')" style="cursor:pointer;"> Payment<br>Amount 1 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount2','paymentAmountTwo','Number')" style="cursor:pointer;"> Payment<br>Amount 2 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('authorised')" matColumnDef="authorised">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Authorised','authorised','String')" style="cursor:pointer;"> Authorised </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('type')" matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Type','type','String')" style="cursor:pointer; display:block; text-align: center;"> Type </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('claimCount')" matColumnDef="claimCount">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Claim Count','claimCount','Number')" style="cursor:pointer; display:block; text-align: center;"> Claim<br>Count </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Supplier Payment Summary -->
                    <table *ngIf="selectedReport.name == 'Supplier Payment Summary'"  mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Supplier Name','supplierName','String')" style="cursor:pointer;"> Supplier Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('count')" matColumnDef="count">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Count','count','Number')" style="cursor:pointer;"> Count </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount1','paymentAmountOne','Number')" style="cursor:pointer;"> Payment Amount 1 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('PaymentAmount2','paymentAmountTwo','Number')" style="cursor:pointer;"> Payment Amount 2  </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Trustee SLA Report - All Members -->
                    <table *ngIf="selectedReport.name == 'Trustee SLA Report - All Members'"  mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">                      
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlineVolume')" matColumnDef="onlineVolume">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Volume','onlineVolume','Number')" style="cursor:pointer;"> Online Volume </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlineDispatched')" matColumnDef="onlineDispatched">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Dispatched','onlineDispatched','Number')" style="cursor:pointer;"> Online Dispatched </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlinePending')" matColumnDef="onlinePending">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Pending','onlinePending','Number')" style="cursor:pointer;"> Online Pending  </mat-header-cell>
                      </ng-container>                      
                      <ng-container *ngIf="columnExists('onlineCancelled')" matColumnDef="onlineCancelled">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Cancelled','onlineCancelled','Number')" style="cursor:pointer;"> Online Cancelled  </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('withinSla')" matColumnDef="withinSla">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Within SLA','withinSla','Number')" style="cursor:pointer;"> Within SLA  </mat-header-cell>
                      </ng-container>                      
                      <ng-container *ngIf="columnExists('percentageSla')" matColumnDef="percentageSla">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('SLA %','percentageSla','Number')" style="cursor:pointer;"> SLA %  </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Trustee SLA Report - By Date -->
                    <table *ngIf="selectedReport.name == 'Trustee SLA Report - By Date'"  mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('dateReceived')" matColumnDef="dateReceived">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Received','dateReceived','Date')" style="cursor:pointer;"> Date Received </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlineVolume')" matColumnDef="onlineVolume">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Volume','onlineVolume','Number')" style="cursor:pointer;"> Online Volume </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlineDispatched')" matColumnDef="onlineDispatched">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Dispatched','onlineDispatched','Number')" style="cursor:pointer;"> Online Dispatched </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('onlinePending')" matColumnDef="onlinePending">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Pending','onlinePending','Number')" style="cursor:pointer;"> Online Pending  </mat-header-cell>
                      </ng-container>                      
                      <ng-container *ngIf="columnExists('onlineCancelled')" matColumnDef="onlineCancelled">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Online Cancelled','onlineCancelled','Number')" style="cursor:pointer;"> Online Cancelled  </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('withinSla')" matColumnDef="withinSla">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Within SLA','withinSla','Number')" style="cursor:pointer;"> Within SLA  </mat-header-cell>
                      </ng-container>                      
                      <ng-container *ngIf="columnExists('percentageSla')" matColumnDef="percentageSla">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('SLA %','percentageSla','Number')" style="cursor:pointer;"> SLA %  </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                    <!-- Current Members with TTA Trustees -->
                    <table *ngIf="selectedReport.name == 'Current Members with TTA Trustees'"  mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Tta Number','ttaNumber','String')" style="cursor:pointer;"> TTA<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('branchName')" matColumnDef="branchName">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Business Name','branchName','String')" style="cursor:pointer;"> Business<br>Name </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('openingDate')" matColumnDef="openingDate">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Date Joined','openingDate','Date')" style="cursor:pointer;"> Opening<br>Date </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('trustee')" matColumnDef="trustee">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Trustee','trustee','String')" style="cursor:pointer;"> Trustee </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('bankAccNo')" matColumnDef="bankAccNo">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Account Number','bankAccNo','Number')" style="cursor:pointer;"> Account<br>Number </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('sortCode')" matColumnDef="sortCode">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Sort Code','sortCode','Number')" style="cursor:pointer;"> Sort<br>Code </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('telephone')" matColumnDef="telephone">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Telephone','telephone','String')" style="cursor:pointer;"> Telephone </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('email')" matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Email','email','String')" style="cursor:pointer;"> Email </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('address1')" matColumnDef="address1">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Address Line 1','address1','String')" style="cursor:pointer;"> Address<br>Line 1 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('address2')" matColumnDef="address2">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Address Line 2','address2','String')" style="cursor:pointer;"> Address<br>Line 2 </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('city')" matColumnDef="city">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Town','city','String')" style="cursor:pointer;"> City </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('county')" matColumnDef="county">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('County','county','String')" style="cursor:pointer;"> County </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('postcode')" matColumnDef="postcode">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Post Code','postcode','String')" style="cursor:pointer;"> Post<br>Code </mat-header-cell>
                      </ng-container>
                      <ng-container *ngIf="columnExists('country')" matColumnDef="country">
                        <mat-header-cell *matHeaderCellDef (click)="sortPrfReportBy('Country','country','String')" style="cursor:pointer;"> Country </mat-header-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="selectedReport.columns"></mat-header-row>
                    </table>
                  </div>
                    <div class="example-container mat-elevation-z8" style="height: fit-content;">                      
                      <!-- Member PRF Volume -->
                      <table *ngIf="selectedReport.name == 'Member PRF Volume'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px;  margin-left: 10px;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container   *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Payment_Amount_1"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Payment_Amount_2"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!-- No Commission Claims By Members -->
                      <table *ngIf="selectedReport.name == 'No Commission Claims By Members'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px;  margin-left: 10px;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Member Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('numberOfNewBookings')" matColumnDef="numberOfNewBookings">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Number of New Bookings"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('commission')" matColumnDef="commission">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Commission"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('supplierPayment')" matColumnDef="supplierPayment">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Supplier Payment"]}} </mat-cell>
                           </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!-- PRF Email Sent -->
                      <table *ngIf="selectedReport.name == 'PRF Email Sent'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('companyName')" matColumnDef="companyName">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Company Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('prfsSubmitted')" matColumnDef="prfsSubmitted">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Prfs Submitted"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('approxEmailsSent')" matColumnDef="approxEmailsSent">
                          <mat-cell style="font-weight:bold; padding: 10px;" *matCellDef="let value"> {{value["Approx Emails Sent"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!-- Payment Search -->
                      <table *ngIf="selectedReport.name == 'Payment Search'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">
                        <ng-container *ngIf="columnExists('prfNumber')" matColumnDef="prfNumber">                          
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Prf#"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('assignedTo')" matColumnDef="assignedTo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Assigned To"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('dateSubmitted')" matColumnDef="dateSubmitted">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Date Submitted"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('dateAuthorised')" matColumnDef="dateAuthorised">                        
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> 
                            <div *ngIf="value['Date Authorised']; else nullValue"> {{value['Date Authorised'].split('T')[0]}}<br>{{value['Date Authorised'].split('T')[1].split('Z').join('').split('.000').slice(0, -1)}} </div>
                            <ng-template #nullValue> </ng-template>
                          </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('authorisedBy')" matColumnDef="authorisedBy">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Authorised by"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Supplier Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentMethod')" matColumnDef="paymentMethod">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentMethod"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount1"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount2"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('amount')" matColumnDef="amount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Amount"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('authorised')" matColumnDef="authorised">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Authorised"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('type')" matColumnDef="type">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Type"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('claimType')" matColumnDef="claimType">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Claim Type"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!-- Payment Search - Board Report -->
                      <table *ngIf="selectedReport.name == 'Payment Search - Board Report'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('name')" matColumnDef="name">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Name"]}} </mat-cell>
                        </ng-container>                       
                        <ng-container *ngIf="columnExists('clientRefundCount')" matColumnDef="clientRefundCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Client_Refund_Count"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('clientRefundAmountOne')" matColumnDef="clientRefundAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Client_Refund_Amount"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('commissionCount')" matColumnDef="commissionCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Commission_Count"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('commissionAmountOne')" matColumnDef="commissionAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Commission_Amount"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('reimbursementCount')" matColumnDef="reimbursementCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Reimbursement_Count"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('reimbursementAmountOne')" matColumnDef="reimbursementAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Reimbursement_Amount"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('supplierPaymentCount')" matColumnDef="supplierPaymentCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Supplier_Payment_Count"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('supplierPaymentAmountOne')" matColumnDef="supplierPaymentAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Supplier_Payment_Amount"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('overallCount')" matColumnDef="overallCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Overall_Count"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                       <!-- Payment Search - Grouped by Payment -->
                       <table *ngIf="selectedReport.name == 'Payment Search - Grouped by Payment'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('prfNumber')" matColumnDef="prfNumber">                          
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Prf#"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('assignedTo')" matColumnDef="assignedTo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Assigned To"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('dateSubmitted')" matColumnDef="dateSubmitted">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Date Submitted"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('dateAuthorised')" matColumnDef="dateAuthorised">                        
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> 
                            <div *ngIf="value['Date Authorised']; else nullValue"> {{value['Date Authorised'].split('T')[0]}}<br>{{value['Date Authorised'].split('T')[1].split('Z').join('').split('.000').slice(0, -1)}} </div>
                            <ng-template #nullValue> </ng-template>
                          </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Supplier Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentMethod')" matColumnDef="paymentMethod">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentMethod"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount1"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount2"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('authorised')" matColumnDef="authorised">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Authorised"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('type')" matColumnDef="type">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Type"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('claimCount')" matColumnDef="claimCount">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block; text-align: center;" *matCellDef="let value"> {{value["Claim Count"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!--  Supplier Payment Summary  -->
                      <table *ngIf="selectedReport.name == 'Supplier Payment Summary'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('supplierName')" matColumnDef="supplierName">
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Supplier Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('count')" matColumnDef="count">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Count"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountOne')" matColumnDef="paymentAmountOne">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount1"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('paymentAmountTwo')" matColumnDef="paymentAmountTwo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["PaymentAmount2"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!-- Trustee SLA Report - All Members -->
                      <table *ngIf="selectedReport.name == 'Trustee SLA Report - All Members'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlineVolume')" matColumnDef="onlineVolume">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Volume"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlineDispatched')" matColumnDef="onlineDispatched">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Dispatched"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlinePending')" matColumnDef="onlinePending">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Pending"]}} </mat-cell>
                        </ng-container>                      
                        <ng-container *ngIf="columnExists('onlineCancelled')" matColumnDef="onlineCancelled">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Cancelled"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('withinSla')" matColumnDef="withinSla">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Within SLA"]}} </mat-cell>
                        </ng-container>                      
                        <ng-container *ngIf="columnExists('percentageSla')" matColumnDef="percentageSla">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["SLA %"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                      <!--  Trustee SLA Report - By Date  -->
                      <table *ngIf="selectedReport.name == 'Trustee SLA Report - By Date'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('dateReceived')" matColumnDef="dateReceived">
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Date Received"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlineVolume')" matColumnDef="onlineVolume">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Volume"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlineDispatched')" matColumnDef="onlineDispatched">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Dispatched"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('onlinePending')" matColumnDef="onlinePending">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Pending"]}} </mat-cell>
                        </ng-container>                      
                        <ng-container *ngIf="columnExists('onlineCancelled')" matColumnDef="onlineCancelled">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Online Cancelled"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('withinSla')" matColumnDef="withinSla">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Within SLA"]}} </mat-cell>
                        </ng-container>                      
                        <ng-container *ngIf="columnExists('percentageSla')" matColumnDef="percentageSla">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["SLA %"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                        <!--  Current Members with TTA Trustees  -->
                      <table *ngIf="selectedReport.name == 'Current Members with TTA Trustees'"  mat-table [dataSource]="selectedReport.table" style="border-bottom-left-radius: 2px; border-bottom-right-radius: 2px;">                        
                        <ng-container *ngIf="columnExists('ttaNumber')" matColumnDef="ttaNumber">
                          <mat-cell style="font-weight:bold; padding: 20px; display:inline-block;" *matCellDef="let value"> {{value["Tta Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('branchName')" matColumnDef="branchName">                          
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Business Name"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('openingDate')" matColumnDef="openingDate">                          
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Date Joined"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('trustee')" matColumnDef="trustee">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Trustee"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('bankAccNo')" matColumnDef="bankAccNo">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Account Number"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('sortCode')" matColumnDef="sortCode">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Sort Code"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('telephone')" matColumnDef="telephone">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Telephone"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('email')" matColumnDef="email">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Email"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('address1')" matColumnDef="address1">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Address Line 1"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('address2')" matColumnDef="address2">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Address Line 2"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('city')" matColumnDef="city">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Town"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('county')" matColumnDef="county">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["County"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('postcode')" matColumnDef="postcode">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Post Code"]}} </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="columnExists('country')" matColumnDef="country">
                          <mat-cell style="font-weight:bold; padding: 10px; display:inline-block;" *matCellDef="let value"> {{value["Country"]}} </mat-cell>
                        </ng-container>
                        <mat-row *matRowDef="let row; columns: selectedReport.columns;"></mat-row>
                      </table>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </mat-card>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</mat-card-content>

<ng-template #myDialog>

  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}} <mat-icon class="errorIcon">error</mat-icon></p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}} <mat-icon class="successIcon">check_circle</mat-icon></p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

</ng-template>

<ng-template #suggestedBookingDialog>
  <div style="padding: 10px; width: 800px;">
    <div style="display: flex; justify-content: space-between;">
      <h1 style="padding: 0px; margin-top: 0px; margin-bottom: 10px; margin-right: 30px; font-size: 30px; display:inline;">
        Suggested Booking {{ createSelectedSuggestedBooking.bookingReference }}
      </h1>
      <div style="display:inline;">
        <button mat-button style="color: rgb(88, 88, 88) !important; margin-top: 0px; margin-bottom: 10px;" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-dialog-content style="padding: 10px; min-height:600px;">
      <h3>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Message
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.message }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Deposit Amount
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.depositAmount }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Net Cost
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.netCost }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider> 
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Paid So Far
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.paidSoFar }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              To Pay Suggestion
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.toPaySuggestion }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Departure Date
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.departureDate }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Return Date
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.returnDate }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Supplier Due Date
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.supplierDueDate }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Supplier Name
            </div>
            <div fxFlex="40">
              <h3>{{ createSelectedSuggestedBooking.supplierName }}</h3>
            </div>
          </div>
        </h3>
        <mat-divider></mat-divider>
        <h3 style="margin-top: 5px; margin-bottom:0px;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
              Supplier Reference
            </div>
            <div fxFlex="40">
              <h3 style="margin-top:10px; margin-bottom:10px;">{{ createSelectedSuggestedBooking.supplierReference }}</h3>
            </div>
          </div>
        </h3>
      </h3>
    </mat-dialog-content>
  </div> 
</ng-template>

<ng-template #helpDialog>
    <div style="padding: 10px; width: 500px;">
      <div style="display: flex; justify-content: space-between;">
        <h1 style="padding: 0px; margin-top: 0px; margin-bottom: 10px; margin-right: 30px; font-size: 30px; display:inline;">
          Online-PRF Info
        </h1>
        <div style="display:inline;">
          <button mat-button style="color: rgb(88, 88, 88) !important; margin-top: 0px; margin-bottom: 10px;" matDialogClose>
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-content style="padding: 10px; min-height:800px;">
          <div [@inAnimation]>
            <!-- PRF USER MANUAL -->
            <div style="width: 100%; margin-bottom: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width:fit-content !important;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionPrfUserManual" (click)="expansionPrfUserManual = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionPrfUserManual" (click)="expansionPrfUserManual = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  PRF User Manual
                </div>
              </div>
              <div [@customExpansionDetails]="expansionPrfUserManual == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
                    <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px;">                      
                      <div style="margin:10px; padding:10px;">
                        <a class="prfUserManualLink" href="https://scribehow.com/shared/SinGS__Create_PRF__2raqJhVhQfy7UJsJLt99_w" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">add</mat-icon> Create PRF</a>
                        <mat-divider style="margin-top: 10px;"></mat-divider>
                      </div>
                      <div style="margin:10px; padding:10px;">
                        <a class="prfUserManualLink" href="https://scribehow.com/shared/SinGS__Update_PRF__u9iHfEY9TKG2CFTWQFEEaQ" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">create</mat-icon> Update PRF</a>
                        <mat-divider style="margin-top: 10px;"></mat-divider>
                      </div>
                      <div style="margin:10px; padding:10px;">
                        <a class="prfUserManualLink" href="https://scribehow.com/shared/SinGS__PRF_List__TjeI1Pg8TxmhgqpsuWnAsA" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">list</mat-icon> PRF List</a>
                        <mat-divider style="margin-top: 10px;"></mat-divider>
                      </div>
                      <div style="margin:10px; padding:10px;">
                        <a class="prfUserManualLink" href="https://scribehow.com/shared/SinGS__PRF_Reports__kdVbo4EsT6evkembHuBeSg" target="_blank" rel="noopener noreferrer"><mat-icon style="margin-bottom: 3px;">description</mat-icon> PRF Reports</a>
                        <mat-divider style="margin-top: 10px;"></mat-divider>
                      </div>
                    </h3>
              </div>
              <div [@customExpansionDetails]="expansionPrfUserManual == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionPrfUserManual = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
            <!-- PRF STATUS SYMBOLS -->
            <div style="width: 100%; margin-bottom: 20px;">
          <div class="ownExpansionHeader">
            <div class="headerTopLeft" style="width:fit-content !important;">
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionPrfStatusSymbols" (click)="expansionPrfStatusSymbols = false;">
                <mat-icon>expand_less</mat-icon>
              </button>
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionPrfStatusSymbols" (click)="expansionPrfStatusSymbols = true;">
                <mat-icon>expand_more</mat-icon>
              </button>
              PRF's Status Symbols
            </div>
          </div>
          <div [@customExpansionDetails]="expansionPrfStatusSymbols == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
                <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px;">
                  <ul style="font-size: 20px;">
                    <li style="margin-top: 10px;">
                      Not Submitted : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >fiber_manual_record</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Processing : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >pending</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Ready for signing : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >radio_button_unchecked</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Action Required : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >new_releases</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Awaiting return date : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >pause_circle_outline</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Awaiting cleared fund : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >pause_circle_outline</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Authorisation Pending : <mat-icon style="color: #4D5FD1; margin-top: 5px; margin-bottom: 10px; font-size: 30px;" >pause_circle_outline</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Authorised : <mat-icon style="color: #00df00; margin-top: 5px; margin-bottom: 10px; font-size: 30px;">check_circle</mat-icon>
                    </li>
                    <li style="margin-top: 10px;">
                      Cancelled : <mat-icon style="color: rgb(196, 47, 47); margin-top: 5px; margin-bottom: 10px; font-size: 30px;">remove_circle</mat-icon>
                    </li>
                  </ul>
                </h3>
          </div>
          <div [@customExpansionDetails]="expansionPrfStatusSymbols == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionPrfStatusSymbols = true;">
            <mat-icon>expand_more</mat-icon>
          </div>
            </div>
          </div>
      </mat-dialog-content>
    </div>   
</ng-template>

<ng-template #editDialog>
  <div style="padding: 10px;">
    <h1 style="padding: 10px; font-size: 35px;">Edit Selected PRF's Status</h1>
    <mat-dialog-content style="padding: 10px;">
      <h3 style="padding: 20px; font-size: 25px; font-weight: bold; margin-bottom: 10px; text-align: center;">
        <span *ngFor="let editPrf of prfsEditList;"> PRF - <span style="color:#4D5FD1">{{editPrf}}</span><br></span>
    </h3>
    </mat-dialog-content>
    <h3 style="padding: 20px; font-size: 30px; font-weight: bold; margin-top: 0px; text-align: center;">
      <mat-form-field class="prfEditInput">
        <mat-label>Status <span style="color:#4D5FD1">*</span></mat-label>
        <mat-select [(ngModel)]="prfsEditStatus" style="vertical-align: middle;">
          <mat-option value="Processing">Processing</mat-option>
          <mat-option value="Authorised">Authorised</mat-option>
          <div *ngIf="checkIfExecutive()">
            <mat-option value="Awaiting return date">Awaiting return date</mat-option>
            <mat-option value="Awaiting cleared fund">Awaiting cleared fund</mat-option>
            <mat-option value="Authorisation Pending">Authorisation Pending</mat-option>
            <mat-option value="Cancelled">Cancelled</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </h3>
    <h3 style="display: block; text-align: center;">
      <button style="margin:20px; font-size:20px; padding-top: 10px; padding-bottom: 10px; padding-left: 30px; padding-right: 30px;" mat-raised-button color="primary" matDialogClose>Cancel</button>
      <button style="margin:20px; font-size:20px; padding-top: 10px; padding-bottom: 10px; padding-left: 30px; padding-right: 30px;" mat-raised-button color="primary" (click)="updatePrfsStatus()" matDialogClose>Apply</button>
    </h3>
  </div>
</ng-template>

<ng-template #popUpPrfDialog>
  <div style="padding: 10px; width: 1000px; height:800px;">
    <div style="display: flex; justify-content: space-between; margin-bottom: 10px; ">
      <h1 fxLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin-top: 0px; margin-bottom: 10px; margin-right:30px; font-size: 30px; display:inline;">
        <p class="prfHeaderText"> PRF - {{viewPrfNumber}}</p>
        <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> {{viewPrfBranchName}} - {{viewPrfTradeCode}}</p>
        <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> Assigned To - {{viewPrfAssignedTo}} </p>
        <p class="prfHeaderDividers"> <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon> </p> <p class="prfHeaderText"> {{viewPrfStatus}} </p>
      </h1>
      <div style="display:inline;">
        <button mat-button style="color: rgb(88, 88, 88) !important; margin-top: 0px; margin-bottom: 10px;" matDialogClose>
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-dialog-content style="padding: 10px; min-height:800px;">
      <div [@inAnimation]>
        
        <!-- ONLINE PRF INFORMATION -->
        <div style="width: 100%; margin-bottom: 20px;">
            <div class="ownExpansionHeader">
              <div class="headerTopLeft" style="width:fit-content !important;">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionOnlinePrf" (click)="expansionOnlinePrf = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionOnlinePrf" (click)="expansionOnlinePrf = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Online PRF Information
              </div>
            </div>
            <div [@customExpansionDetails]="expansionOnlinePrf == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
                <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px;">
                  <div *ngIf="viewPrfBookingReference != null"> 
                    <div *ngIf="viewPrfLinkedBookings.length > 0 && viewPrfLinkedBookings != null ">
                      <h3 style="margin-top: 5px; margin-bottom:10px;">
                        <div fxLayout="row" fxLayoutAlign="start center">
                          <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                            Added Booking Reference(s)
                          </div>
                          <div fxFlex="40">
                            <div class="containerOfReferenceAtReport">
                              <ng-container *ngFor="let reference of viewPrfLinkedBookings">
                                <div class="reference"> {{ reference }} </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </h3>
                      <mat-divider></mat-divider>
                  </div>
                  <div *ngIf="viewPrfLinkedBookings.length == 0 && viewMatchedBookingReference != ''">
                    <h3 style="margin-top: 5px; margin-bottom:10px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Added Booking Reference(s)
                        </div>
                        <div fxFlex="40">
                          <div class="containerOfReference">
                              <div class="reference">{{ viewMatchedBookingReference }}</div>
                          </div>
                        </div>
                      </div>
                    </h3>  
                    <mat-divider></mat-divider>
                  </div>
                  </div>
                  <h3 style="margin-top: 5px; margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Have the client funds been received?
                      </div>
                      <div fxFlex="40">
                        <h3 *ngIf="viewPrfIsClientFundsReceived == 1">Yes</h3>
                        <h3 *ngIf="viewPrfIsClientFundsReceived == 0">No</h3>
                        <h3 *ngIf="viewPrfIsClientFundsReceived == 2">N/A</h3>
                      </div>
                    </div>
                  </h3>
                  <mat-divider></mat-divider>
                  <h3 style="margin-top: 5px; margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Has the client returned from travel?
                      </div>
                      <div fxFlex="40">
                        <h3 *ngIf="viewPrfHasClientReturned == 1">Yes</h3>
                        <h3 *ngIf="viewPrfHasClientReturned == 0">No</h3>
                        <h3 *ngIf="viewPrfHasClientReturned == 2">N/A</h3>
                      </div>
                    </div>
                  </h3>
                  <mat-divider></mat-divider>
                  <h3 style="margin-top: 5px; margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Selected Supplier
                      </div>
                      <div fxFlex="40">
                        <h3>{{ viewPrfSupplierName }}</h3>
                      </div>
                    </div>
                  </h3>
                  <mat-divider></mat-divider>
                  <div *ngIf="viewPrfFinancialProtectionEnable">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Financial Protection
                        </div>
                        <div fxFlex="40">
                          <h3>{{ viewPrfFinancialProtectionText }}</h3>
                        </div>
                      </div>
                    </h3>
                    <mat-divider></mat-divider>
                  </div>
                  <div *ngIf="viewPrfClientReturnDateEnable">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Client Return Date
                        </div>
                        <div fxFlex="40">
                          <h3>{{ convertDate(viewPrfClientReturnDate) }}</h3>
                        </div>
                      </div>
                    </h3>
                    <mat-divider></mat-divider>
                  </div>
                  <h3 style="margin-top: 5px; margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Type(s) of Claim Requested
                      </div>
                      <div fxFlex="40">
                        <div *ngIf="viewClaimSupplierPayment.checked">
                          <h3> Supplier Payment </h3>
                        </div>
                        <div *ngIf="viewClaimReimbursement.checked">
                          <h3> Reimbursement </h3>
                        </div>
                        <div *ngIf="viewClaimClientRefund.checked">
                          <h3> Client Refund </h3>
                        </div>
                        <div *ngIf="viewClaimCommission.checked">
                          <h3> Commission </h3>
                        </div>
                      </div>
                    </div>
                  </h3>
                  <mat-divider></mat-divider>
                  
                  <div *ngIf="viewClaimCommission.checked">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Commission<br>Payment Method
                        </div>
                        <div fxFlex="40">
                          <h3>{{viewClaimCommission.paymentMethod}}</h3>
                        </div>
                      </div>
                    </h3>
                    <mat-divider *ngIf="viewClaimCommission.checked && (viewClaimClientRefund.checked || viewClaimReimbursement.checked || viewClaimSupplierPayment.checked)"></mat-divider>
                  </div>
                  <div *ngIf="viewClaimSupplierPayment.checked">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Supplier<br>Payment Method
                        </div>
                        <div fxFlex="40" >
                          <div fxFlex="40">
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[0]">
                              <h3> Natwest Faster </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[1]">
                              <h3> Natwest CHAPS </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[2]">
                              <h3> Barclays </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[3]">
                              <h3> Corpay </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[4]">
                              <h3> Monteith </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[5]">
                              <h3> Amex Forex Transfer  </h3>
                            </div>
                            <div *ngIf="viewClaimSupplierPayment.paymentMethods.checks[6]">
                              <h3> Other </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimReimbursement.checked">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Reimbursement<br>Payment Method
                        </div>
                        <div fxFlex="40" >
                          <h3>{{viewClaimReimbursement.paymentMethod}}</h3>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div *ngIf="viewClaimClientRefund.checked">
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Client Refund<br>Payment Method
                        </div>
                        <div fxFlex="40">
                          <h3>{{viewClaimClientRefund.paymentMethod}}</h3>
                        </div>
                      </div>
                    </h3>
                  </div>

                  <div *ngIf="checkIfNatWestChapsIsChecked('View')">
                    <mat-divider></mat-divider>
                    <h3 style="margin-top: 5px; margin-bottom:0px;">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                          Natwest CHAPS
                        </div>
                        <div fxFlex="40">
                          <h3 style="color: #FA0E62;">
                            Please note that Chaps payments incur a £12.50 charge.<br>Day 1 to Day 3 Trustees SLA is still applicable.   
                          </h3>
                        </div>
                      </div>
                    </h3>
                  </div>

                </h3>
            </div>
            <div [@customExpansionDetails]="expansionOnlinePrf == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionOnlinePrf = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
        </div>

        <!-- SUPPORTING DOCUMENTATION -->
        <div style="width: 100%; margin-bottom: 20px;">
          <div class="ownExpansionHeader">
            <div class="headerTopLeft" style="width:fit-content !important;">
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSupportingDocumentation" (click)="expansionSupportingDocumentation = false;">
                <mat-icon>expand_less</mat-icon>
              </button>
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionSupportingDocumentation" (click)="expansionSupportingDocumentation = true;">
                <mat-icon>expand_more</mat-icon>
              </button>
              Supporting Documentation
            </div>
          </div>
          <div [@customExpansionDetails]="expansionSupportingDocumentation == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important; padding-bottom: 10px !important;">
            <div *ngIf="viewMatchedBookingReference != '' && viewMatchedBookingReference != null">
              <div *ngIf="viewMultipleBookingReference == true">
                <div style="padding:20px;">
                  <div *ngFor="let tempBooking of viewMatchedBookingData">
                    <div style="margin-bottom: 20px;">
                      <div class="ownExpansionHeader">
                        <div class="headerTopLeft" style="width:fit-content !important;">
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = false;">
                            <mat-icon>expand_less</mat-icon>
                          </button>
                          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!tempBooking.showListOfDocuments" (click)="tempBooking.showListOfDocuments = true;">
                            <mat-icon>expand_more</mat-icon>
                          </button>
                           Attached Documents from {{tempBooking.bookingReference}}       
                          <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3document(tempBooking)" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline;">
                            <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                          </button>                      
                        </div>
                      </div>
                      <div [@customExpansionDetails]="tempBooking.showListOfDocuments == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                        <div *ngIf="tempBooking.listOfDocumentsData.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                          <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                            <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="fileName">
                                <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="lastModified">
                                <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="size">
                                <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                              </ng-container>
                              <ng-container matColumnDef="download">
                                <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                              </ng-container>
                              <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                            </table>
                          </div>
                          <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                            <table mat-table [dataSource]="tempBooking.listOfDocumentsData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                              <ng-container matColumnDef="fileName">
                                <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="lastModified">
                                <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="size">
                                <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                              </ng-container>                          
                              <ng-container matColumnDef="download">
                                <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocumentFromBooking(data,tempBooking.bookingReference)">
                                  <mat-icon>insert_drive_file</mat-icon>
                                </button>
                              </ng-container>
                              <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                            </table>
                          </div>
                        </div>
                        <div [@inAnimation] *ngIf="tempBooking.listOfDocumentsData.data.length == 0" style="clear: both;">
                          <h3>There is no data to show</h3>
                        </div>
                      </div>
                      <div [@customExpansionDetails]="tempBooking.showListOfDocuments == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="tempBooking.showListOfDocuments = true;">
                        <mat-icon>expand_more</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="viewMultipleBookingReference == false">
                <div style="padding:20px;">
                  <div class="ownExpansionHeader">
                    <div class="headerTopLeft" style="width:fit-content !important;">
                      <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="s3Documents" (click)="s3Documents = false;">
                        <mat-icon>expand_less</mat-icon>
                      </button>
                      <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!s3Documents" (click)="s3Documents = true;">
                        <mat-icon>expand_more</mat-icon>
                      </button>
                       Attached Documents from Booking       
                      <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3documents()" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline;">
                        <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                      </button>                      
                    </div>
                  </div>
                  <div [@customExpansionDetails]="s3Documents == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                    <div *ngIf="attachedDocs.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                      <div class="header-container mat-elevation-z8" style="margin-top:10px;">
                        <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="fileName">
                            <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="lastModified">
                            <mat-header-cell *matHeaderCellDef> Last Modified Date </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="size">
                            <mat-header-cell *matHeaderCellDef style="padding-right: 0px;"> Size </mat-header-cell>
                          </ng-container>
                          <ng-container matColumnDef="download">
                            <th style="margin-right: 35px;" mat-header-cell *matHeaderCellDef class="deleteTab"> Download </th>
                          </ng-container>
                          <mat-header-row *matHeaderRowDef="s3FilesColumns"></mat-header-row>
                        </table>
                      </div>
                      <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                        <table mat-table [dataSource]="attachedDocs" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                          <ng-container matColumnDef="fileName">
                            <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;"  *matCellDef="let data"> {{ data.name }} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="lastModified">
                            <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.last_modified | date : "dd.MM.y hh:mm a" }} </mat-cell>
                          </ng-container>
                          <ng-container matColumnDef="size">
                            <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let data"> {{ data.size | filesize }} </mat-cell>
                          </ng-container>                          
                          <ng-container matColumnDef="download">
                            <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let data" (click)="downloadDocument(data)">
                              <mat-icon>insert_drive_file</mat-icon>
                            </button>
                          </ng-container>
                          <mat-row *matRowDef="let row; columns: s3FilesColumns;"></mat-row>
                        </table>
                      </div>
                    </div>
                    <div [@inAnimation] *ngIf="attachedDocs.data.length == 0" style="clear: both;">
                      <h3>There is no data to show</h3>
                    </div>
                  </div>
                  <div [@customExpansionDetails]="s3Documents == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="s3Documents = true;">
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 20px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width:fit-content !important;">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="showPrfDocuments" (click)="showPrfDocuments = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!showPrfDocuments" (click)="showPrfDocuments = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                   Attached Documents from PRF       
                  <button mat-icon-button type="button" (click)="pageLoaded = false; reloadS3prfDocs(false)" matTooltip="Reload Files" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline;">
                    <mat-icon style="zoom: 1; margin-left: 0px;">refresh</mat-icon>
                  </button>                      
                </div>
              </div>
              <div [@customExpansionDetails]="showPrfDocuments == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" >
                <div *ngIf="viewPrfDocumentsData.data.length > 0" style="padding-right: 22.5px; padding-top: 10px; margin-top:10px; margin-bottom:50px;">
                  <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                    <table mat-table style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container matColumnDef="documentType">
                        <mat-header-cell *matHeaderCellDef> Document Type </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="dateAttached">
                        <mat-header-cell *matHeaderCellDef> Date Attached </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="fileName">
                        <mat-header-cell style="padding-right:15px !important;" *matHeaderCellDef> Filename </mat-header-cell>
                      </ng-container>
                      <ng-container matColumnDef="downloadDocument">
                        <th style="margin-right: 20px;" mat-header-cell *matHeaderCellDef class="viewTabOnDocuments"> Download </th>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="viewPrfPopUpDocumentColumns"></mat-header-row>
                    </table>
                  </div>
                  <div class="header-container mat-elevation-z8" style="margin-top:5px;">
                    <table mat-table [dataSource]="viewPrfDocumentsData" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <ng-container matColumnDef="documentType">
                        <mat-cell style="padding-left: 30px; padding-right: 0px; font-weight:bold;" *matCellDef="let document"> {{document['documentType']}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="dateAttached">
                        <mat-cell style="padding: 5px; font-weight:bold;" *matCellDef="let document"> {{document['dateAttached']}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="fileName">
                        <mat-cell style="padding-left: 0px; padding-right: 35px; font-weight:bold;" *matCellDef="let document"> {{document['fileName']}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="downloadDocument">
                        <button mat-button style="margin-right: 35px;" color="primary" *matCellDef="let document" (click)="downloadPrfDocument(document['fileName'])">
                          <mat-icon>insert_drive_file</mat-icon>
                        </button>
                      </ng-container>
                      <mat-row *matRowDef="let row; columns: viewPrfPopUpDocumentColumns;"></mat-row>
                    </table>
                  </div>
                </div>
                <div [@inAnimation] *ngIf="viewPrfDocumentsData.data.length == 0" style="clear: both;">
                  <h3>There is no data to show</h3>
                </div>
              </div>
              <div [@customExpansionDetails]="showPrfDocuments == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="showPrfDocuments = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>
          </div>
          <div [@customExpansionDetails]="expansionSupportingDocumentation == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionSupportingDocumentation = true;">
            <mat-icon>expand_more</mat-icon>
          </div>
        </div>

        <!-- PRF TRANSACTION VALUES -->
        <div style="width: 100%; margin-bottom: 20px;">            
          <div class="ownExpansionHeader">
            <div class="headerTopLeft" style="width:fit-content !important;">
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionTransactionValues" (click)="expansionTransactionValues = false;">
                <mat-icon>expand_less</mat-icon>
              </button>
              <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionTransactionValues" (click)="expansionTransactionValues = true;">
                <mat-icon>expand_more</mat-icon>
              </button>
              PRF Transaction Values
            </div>
          </div>          
          <div [@customExpansionDetails]="expansionTransactionValues == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px; margin-top:30px;">
                <div *ngIf="viewClaimCommission.checked == true" style="margin-bottom: 30px;">
                  <h3 style="margin-top: 5px; margin-bottom:0px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Guarantees and Insurances Collected From
                      </div>
                      <div fxFlex="40">
                        <h3>{{viewPrfCollectedFrom}}</h3>
                      </div>
                    </div>
                  </h3>
                  <mat-divider></mat-divider>
                </div>
                <div *ngFor="let transaction of viewPrfTransactionValuesList; let id=index">
                  <div class="transactionValueTable">
                    <div class="transactionValueContainer">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Client Surname</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 2px;">{{transaction.viewClientSurname}}</h3>
                      </div>
                    </div>
                    <mat-divider [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div *ngIf="transaction.viewDateFundsBanked != null && transaction.viewDateFundsBanked != ''" class="transactionValueContainer">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Date Funds Banked</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewDateFundsBanked}}</h3>
                      </div>
                    </div>
                    <mat-divider *ngIf="transaction.viewDateFundsBanked != null && transaction.viewDateFundsBanked != ''" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div class="transactionValueContainer">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Payment Method</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewPaymentMethod}}</h3>
                      </div>
                    </div>
                    <mat-divider [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div class="transactionValueContainer">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Client Funds Banked</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewGrossClientFunds.currency}} {{transaction.viewGrossClientFunds.amount}}</h3>
                      </div>
                    </div>
                    <mat-divider [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>                    
                    <div class="transactionValueContainer" *ngIf="checkViewSupplierAmountAvailable()">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Supplier Amount</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewSupplierAmount.currency}} {{transaction.viewSupplierAmount.amount}}</h3>
                      </div>
                    </div>                    
                    <mat-divider *ngIf="viewPrfCollectedFrom == 'Trust Account'" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div *ngIf="viewPrfCollectedFrom == 'Trust Account'" class="transactionValueContainer">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Guarantee Deducted</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewGuaranteeDeducted.currency}} {{transaction.viewGuaranteeDeducted.amount}}</h3>
                      </div>
                    </div>
                    <mat-divider *ngIf="viewClaimSupplierPayment.checked == true && viewClaimClientRefund.checked == true" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <mat-divider *ngIf="viewClaimCommission.checked == true && viewClaimClientRefund.checked == true" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div class="transactionValueContainer" *ngIf="viewClaimClientRefund.checked == true">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Client Refund</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewClientRefund.currency}} {{transaction.viewClientRefund.amount}}</h3>
                      </div>
                    </div>
                    <mat-divider *ngIf="viewClaimCommission.checked == true && viewClaimReimbursement.checked == true" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <mat-divider *ngIf="viewClaimSupplierPayment.checked == true && viewClaimReimbursement.checked == true" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div class="transactionValueContainer" *ngIf="viewClaimReimbursement.checked == true">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Reimbursement</p>
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewReimbursement.currency}} {{transaction.viewReimbursement.amount}}</h3>
                      </div>
                    </div>
                    <mat-divider *ngIf="viewClaimCommission.checked == true" [vertical]="true" style="margin-top:10px !important; margin-bottom:10px !important;"></mat-divider>
                    <div class="transactionValueContainer" *ngIf="viewClaimCommission.checked == true">
                      <p class="transactionValueTab" style="color:#4D5FD1 !important;">Commission<span *ngIf="viewPrfBookingReference != null"><br>(Incl.VAT- {{transaction.viewVAT}} )</span></p> 
                      <div style="display:grid;">
                        <h3 style="padding-left: 10px; margin-top: 0px; margin-bottom: 20px;">{{transaction.viewCommission.currency}} {{transaction.viewCommission.amount}}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-divider style="margin-bottom: 30px;" *ngIf="viewPrfAddedTransactionValuesList.length > 0 && viewPrfTransactionValuesList.length > 0"></mat-divider>
              </h3>
            </div>
          </div>          
          <div [@customExpansionDetails]="expansionTransactionValues == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionTransactionValues = true;">
            <mat-icon>expand_more</mat-icon>
          </div>
        </div>

        <!-- SIGN OFF -->
        <div *ngIf="!checkUserType()"> 
          <div style="width: 100%; margin-bottom: 20px;">            
            <div class="ownExpansionHeader">
              <div class="headerTopLeft" style="width:fit-content !important;">
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionSignOff" (click)="expansionSignOff = false;">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionSignOff" (click)="expansionSignOff = true;">
                  <mat-icon>expand_more</mat-icon>
                </button>
                Sign Off
              </div>
            </div>          
            <div [@customExpansionDetails]="expansionSignOff == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" style="padding-left: 0px !important;">
              <h3 style="padding-left: 20px; padding-right: 20px; margin-bottom: 30px;">
                  <h3 style="margin-top: 5px; margin-bottom:30px;">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div fxFlex="50" style="color:#4D5FD1; padding:10px;">
                        Notes to Trustees
                      </div>
                      <div fxFlex="40">
                        <h3 *ngIf="viewPrfNotesToTrustee != ''">{{viewPrfNotesToTrustee}}</h3>
                        <h3 *ngIf="viewPrfNotesToTrustee == ''">N/A</h3>
                      </div>
                    </div>
                  </h3>
              </h3>
            </div>          
            <div [@customExpansionDetails]="expansionSignOff == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansionSignOff = true;">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>
        </div>        

      </div>     
    </mat-dialog-content>
  </div>
</ng-template>