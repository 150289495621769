<div *ngIf="!pageLoaded" class="loading-indicator" style="margin:0 auto;">
  <mat-spinner></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="innerWidth > 1024 && haveAccess" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <!--<button class="navButtons" (click)="switchView('beneficiary')" mat-button [disabled]="beneficiaryView">
          <mat-icon style="margin-top: 2px;">card_membership</mat-icon> Beneficiaries
        </button>-->
        <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
          <mat-icon>visibility</mat-icon> Overview
        </button>
        <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
          <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">credit_score</mat-icon> Disbursement
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="innerWidth < 1025 && haveAccess" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <!--<button class="navButtons" (click)="switchView('beneficiary')" mat-button [disabled]="beneficiaryView">
          <mat-icon style="margin-top: 2px;">card_membership</mat-icon>
        </button>-->
        <button class="navButtons" (click)="switchView('overwiev')" mat-button [disabled]="overView">
          <mat-icon>visibility</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('transaction')" mat-button [disabled]="transactionView">
          <mat-icon matBadge="{{fellohData.data.length}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">credit_score</mat-icon>
        </button>
        <button class="navButtons" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav mode="side" #drawer closed>
    </mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div [@inAnimation] class="sideNavContent" *ngIf="haveAccess">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div class="headerArrow" *ngIf="beneficiaryView">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Beneficiaries
              </h1>
            </div>
            <div class="headerArrow" *ngIf="overView">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Overview
              </h1>
            </div>
            <div class="headerArrow" *ngIf="transactionView">
              <h1 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Disbursement
              </h1>
            </div>

            <div *ngIf="selectedBranch?.fellohConfig?.length > 1" class="fellohConfigDiv">
              <mat-select ngModel placeholder="Trading Name" name="fellohConfig" [(ngModel)]="selectedFellohAccount" id="fellohAccount" #fellohConfig="ngModel"
                (ngModelChange)="this.pageLoaded = false; prepareForFellohTxns()">
                  <mat-option *ngFor="let fellohConfig of selectedBranch?.fellohConfig" [value]="fellohConfig">
                    {{fellohConfig['accountName']}} | {{fellohConfig['accountCode']}}
                  </mat-option>
              </mat-select>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div *ngIf="!beneficiaryView" class="dates">
            <div class="datesAndDownload">
              <mat-form-field style="width: 110px;">
                <input placeholder="From" matInput [(ngModel)]="fromDate" [matDatepicker]="fromDateSet" (dateChange)="changeDate('fromDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="fromDateSet"></mat-datepicker-toggle>
                <mat-datepicker #fromDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: 110px;">
                <input placeholder="To" matInput [(ngModel)]="toDate" [matDatepicker]="toDateSet" (dateChange)="changeDate('toDate', $event)">
                <mat-datepicker-toggle matSuffix [for]="toDateSet"></mat-datepicker-toggle>
                <mat-datepicker #toDateSet></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="max-width: unset; width: 240px;" *ngIf="branchListData.length > 1">
                <mat-select ngModel placeholder="Branch" name="branch" [(ngModel)]="selectedBranch" id="branch" #branch="ngModel" (ngModelChange)="changeBranch($event, 'yes')">
                  <mat-form-field appearance="fill" class="filterSelectList sticky-search-container">
                    <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
                  </mat-form-field>
                  <mat-option *ngFor="let branch of branchListFiltered" [value]="branch" (click)="filterString = ''; filterSelect();">
                    {{branch['fullName']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button *ngIf="fellohData.data.length > 0" mat-stroked-button color="primary" (click)="exportToExcel()" style="margin-top: -4px;" matTooltip="Download XLSX" matTooltipClass="line-normal-tooltip">
                <mat-icon>file_download</mat-icon>
              </button>

              <button style="margin-top: -4px; margin-left: 10px" (click)="switchView('reloadView')" mat-stroked-button color="primary" matTooltip="Reload Transactions" matTooltipClass="line-normal-tooltip">
                <mat-icon>sync</mat-icon>
              </button>

              <h2 *ngIf="transactionView" style="display: inline; margin-left: 20px;">
                <mat-checkbox [(ngModel)]="testView" color="accent" (change)="showTestDataToggle()">Test Data</mat-checkbox>
              </h2>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'" style="padding-top: 7.5px !important;" [hidden]="!transactionView">
              <mat-paginator #paginatorPayout [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>
          </div>

          <div *ngIf="beneficiaryView" class="dates">
            <div class="datesAndDownload">
              <mat-form-field style="max-width: unset; width: 240px;" *ngIf="branchListData.length > 1">
                <mat-select ngModel placeholder="Branch" name="branch" [(ngModel)]="selectedBranch" id="branch" #branch="ngModel" (ngModelChange)="changeBranch($event, 'yes')">
                  <mat-option *ngFor="let branch of branchListData" [value]="branch">
                    {{branch['fullName']}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <h2 style="display: inline; margin-left: 20px;" *ngIf="branchListData.length > 1">
                <mat-checkbox class="checkbox" [(ngModel)]="unapprovedOnly" (change)="activeBeneToggle($event.checked)">Unapproved Only</mat-checkbox>
              </h2>

              <h2 style="margin-top: 20px;" *ngIf="branchListData.length == 0">
                <mat-checkbox class="checkbox" [(ngModel)]="unapprovedOnly" (change)="activeBeneToggle($event.checked)">Unapproved Only</mat-checkbox>
              </h2>
            </div>

            <div [ngClass]="branchListData.length > 1 ? 'paginatorFooter' : 'paginatorFooterLong'" style="padding-top: 7.5px !important;">
              <mat-paginator #paginatorBenefi [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
            </div>
          </div>

          <div [@inAnimation] *ngIf="beneficiaryView && pageLoaded" style="clear: both;">

            <form ngNativeValidate #beneficiaryCreate="ngForm" (ngSubmit)="createBeneficiary(beneficiaryCreate)">
              <mat-table class="tableClass" multiTemplateDataRows [dataSource]="beneficiaryData">
                <ng-container matColumnDef="accountHolderName">
                  <mat-header-cell *matHeaderCellDef> Account Holder Name </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.accountHolderName }} </mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    <mat-form-field class="newBeneFields">
                      <mat-label>Beneficiary Name</mat-label>
                      <input matInput required ngModel name="name" #name="ngModel" minlength="5" placeholder="Free Travel" maxlength="50">
                    </mat-form-field>
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="sortCode">
                  <mat-header-cell *matHeaderCellDef> Sort Code </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.sortCode }} </mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    <mat-form-field class="newBeneFields">
                      <mat-label>Sort Code</mat-label>
                      <input matInput required ngModel name="sortCode" #sortCode="ngModel" minlength="6" placeholder="001122" maxlength="6" pattern="[0-9]+">
                    </mat-form-field>
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="accountNumber">
                  <mat-header-cell *matHeaderCellDef> Account Number </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.accountNumber }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    <mat-form-field class="newBeneFields">
                      <mat-label>Account Number</mat-label>
                      <input matInput required ngModel name="accountNo" #accountNo="ngModel" placeholder="12345678" minlength="8" maxlength="8" pattern="[0-9]+">
                    </mat-form-field>
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
                  <mat-cell *matCellDef="let data"> {{ data.createdAt | date : "dd.MM.y hh:mm a" }}</mat-cell>
                  <mat-footer-cell *matFooterCellDef>
                    <button class="buttonClass" mat-raised-button color="primary" type="submit" [disabled]="beneficiaryCreate.invalid" matTooltip="Add Beneficiary" matTooltipClass="line-normal-tooltip">
                      <mat-icon style="margin-left: 3.5px;">playlist_add</mat-icon>
                    </button>
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                  <mat-cell *matCellDef="let data">
                    <mat-select *ngIf="data.status == 'Unapproved'" ngModel name="beneficiaryStatus" #beneficiaryStatus="ngModel" (selectionChange)="approveBeneficiary(data)" [(ngModel)]="data.status">
                      <mat-option [value]="'Approved'">Approved (TBD Only Trustee can change)</mat-option>
                      <mat-option [value]="'Unapproved'">Unapproved (TBD Only Trustee can change)</mat-option>
                    </mat-select>
                    <span *ngIf="data.status == 'Active'">Active (TBD Only Trustee can change)</span>
                  </mat-cell>
                  <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="beneficiaryColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns : beneficiaryColumns;" class="trueRow"></mat-row>
                <mat-footer-row class="rowNotClick" *matFooterRowDef="beneficiaryColumns"></mat-footer-row>
              </mat-table>
            </form>
          </div>

          <div [@inAnimation] *ngIf="overView && pageLoaded" style="clear: both;">
            <div style="clear: both;" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="topBoxes" fxFlex="25">
                    <h1>Payouts<br>{{ paymentNumber }}</h1>
                  </div>
                  <div class="topBoxes" fxFlex="25">
                    <h1>Average Payout<br>{{ totalBalance / paymentNumber | currency:'GBP' }}</h1>
                  </div>
                  <div class="topBoxes" fxFlex="25" style="float: left;">
                    <h1>Total Payouts<br>{{ totalBalance | currency:'GBP' }}</h1>
                  </div>
                </div>

                <div class="apexChartZoom">
                  <apx-chart id="chart" [series]="chartOptions.series" [chart]="chartOptions.chart" [fill]="chartOptions.fill" [grid]="chartOptions.grid" [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                    [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [legend]="chartOptions.legend" [colors]="chartOptions.colors"></apx-chart>
                </div>
              </div>
            </div>

            <img src="../../../assets/singsLogoMinix75.png" style="margin-top: -2.5px; margin-right: -10px; opacity: 0.25; float: right;">
          </div>

          <div [@inAnimation] *ngIf="transactionView && pageLoaded" style="clear: both; margin-bottom: 50px;">
            <div *ngIf="fellohData.data.length > 0">
              <div class="table-responsive">
                <mat-table class="tableClass" multiTemplateDataRows [dataSource]="fellohData">
                  <ng-container matColumnDef="batchId">
                    <mat-header-cell *matHeaderCellDef>Batch Id</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.bank_reference }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="beneficiary">
                    <mat-header-cell *matHeaderCellDef>Beneficiary</mat-header-cell>
                    <mat-cell *matCellDef="let data">{{ data.receiving_account.name }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>Disbursement Date</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.created_at | date : "dd.MM.y hh:mm a" }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="paymentsNo">
                    <mat-header-cell *matHeaderCellDef>Payments</mat-header-cell>
                    <mat-cell *matCellDef="let data"> {{ data.transactions.length }} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <mat-header-cell *matHeaderCellDef>Total Amount</mat-header-cell>
                    <mat-cell *matCellDef="let data"><b>{{ data.amount | currency:data.currency }}</b></mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="expandedDetail" *matCellDef="let data" [attr.colspan]="6">
                      <mat-table class="tableWithinTable" multiTemplateDataRows [dataSource]="data.transactions">

                        <ng-container matColumnDef="bookingRef">
                          <mat-header-cell *matHeaderCellDef> Booking Reference </mat-header-cell>
                          <mat-cell *matCellDef="let payment"> {{ payment.booking.booking_reference }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="custName">
                          <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                          <mat-cell *matCellDef="let payment"> {{ payment.customer.customer_name }} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                          <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                          <mat-cell *matCellDef="let payment"> {{ payment.amount | currency:payment.currency }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="transactionId">
                          <mat-header-cell *matHeaderCellDef> Transaction Id </mat-header-cell>
                          <mat-cell *matCellDef="let payment"> {{ payment.id }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="paymentDate">
                          <mat-header-cell *matHeaderCellDef> Payment Date </mat-header-cell>
                          <mat-cell *matCellDef="let payment"> {{ payment.completed_at | date : "dd.MM.y hh:mm a" }}</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="payOutColumns"></mat-header-row>
                        <mat-row class="rowNotClick" *matRowDef="let row; columns : payOutColumns;"></mat-row>
                      </mat-table>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="fellohColumns;"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : fellohColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row"></mat-row>
                  <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
                </mat-table>
              </div>
            </div>

            <div *ngIf="pageLoaded" style="margin-left: 22.5px; margin-top: 22.5px;">
              <h3 *ngIf="adminJustOpened">Please click <i>Reload Transactions</i> button to pull the data</h3>
              <h3 *ngIf="!adminJustOpened && fellohData.data.length == 0">There is no data to show for this date range</h3>
            </div>
          </div>
        </div>

        <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
          <div class="errorMessage">
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </div>
        </div>
      </mat-card>


    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<div *ngIf="!haveAccess && pageLoaded">
  <mat-error>
    <p style="text-align: center; margin-top: 2.5%;">We're sorry but You don't have access to this page.</p>
  </mat-error>
</div>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>
