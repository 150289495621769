<div class="mainDiv">
    <div class="chosenDiv">

        <div *ngIf="requestMode == 'supplier'" style="text-align: center;">
            <div class="centeredHeading">
                <div>
                    <button mat-button [disableRipple]="true" matDialogClose>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div>
                    <h2>New Supplier Request</h2>
                </div>
                <div style="width: 60.8px;">
                    
                </div>
            </div>
            <div class="topInformation">
                <i>Please note that your request may take up to 3 working days to process.<br>
                    We appreciate your patience and will do our best to resolve it as soon as possible.<br><br>
                    If you need any assistance or have questions during this process, feel free to contact our support team<br>
                    at <a href="mailto:operations@thetravelnetworkgroup.co.uk">operations@thetravelnetworkgroup.co.uk</a></i>
            </div>

            <form ngNativeValidate #createSupplierForm="ngForm" (ngSubmit)="createUnapprovedSupplier(createSupplierForm, true)">

                <div>
                    <h3>General Information</h3>
                    <div class="flexEven" [ngStyle]="{'height': !followUpWithSFC ? '375px' : '193px'}">
                        <mat-form-field class="supplierNameEdit">
                            <input matInput ngModel required placeholder="Supplier Name" name="supplierNameM" #supplierNameM="ngModel" minlength="3" maxlength="100"
                            [(ngModel)]="selectedSfcSupplier.supplierNameM">
                        </mat-form-field>

                        <!--<mat-form-field class="supplierTypeEdit">
                            <mat-select ngModel required name="supplierType" #supplierType="ngModel" placeholder="Supplier Type" panelClass="supplierTypePanel" [(ngModel)]="selectedSfcSupplier.supplierType">
                                <mat-option *ngFor="let type of supplierTypes" [value]="type['value']">
                                    {{type['viewValue']}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>-->

                        <div class="topInformation" *ngIf="!followUpWithSFC"><i>
                            You are currently requesting this supplier without Supplier Failure Cover.<br><br>
 
                            Should you wish to release funds to your suppliers prior to departure, you are required to:<br>
                            Be acting as the retail agent of another ATOL Holder<br>
                            Apply Supplier Failure Cover for all elements requiring payment<br><br>
                        
                            No funds will be released via our Trustees team unless the relative protections above are met.<br><br>
                            
                            If you wish to apply for Supplier Failure Cover for {{ selectedSfcSupplier.supplierNameM }}, please tick the box below and complete the Supplier Failure Cover application to be reviewed by the Operations Team.<br><br>
                            
                            If you wish to carry on without Supplier Failure Cover, please click Send Request.
                        </i></div>

                        <div class="checkboxElement">
                            <label style="display: flex;">
                              <h3>I would like to create an SFC application</h3>
                              <input type="checkbox" ngModel #sfcFollowUp="ngModel" name="sfcFollowUp" [(ngModel)]="followUpWithSFC" style="margin-left: 71.25px;">
                            </label>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px; margin-bottom: -20px;">
                    <button class="buttonToRight requestButton" mat-button [disableRipple]="true" [disabled]="createSupplierForm.invalid || !pageLoaded" type="submit">
                        <span *ngIf="pageLoaded">Send Request</span>
                        <span *ngIf="!pageLoaded">Please wait..</span>
                    </button>
                </div>

                <!--<div *ngIf="suggestedSuppliers.length > 0">
                    <h3>Did you mean any of the options below?</h3>
                    <div>
                        <div *ngFor="let supplier of suggestedSuppliers" class="supplierSuggestionDiv">
                            <span class="supplierSuggestion" (click)="createUnapprovedSupplier(supplier.suggestion)">
                                {{ supplier.suggestion }}
                            </span>
                        </div>
                        <div class="supplierSuggestionDiv">
                            <span class="supplierSuggestion" style="color: #ce983a;" (click)="createUnapprovedSupplier(selectedSfcSupplier.supplierNameM)">
                                No, I want to proceed with {{ selectedSfcSupplier.supplierNameM }}
                            </span>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px; margin-bottom: -20px;">
                    <button class="buttonToRight requestButton" mat-button [disableRipple]="true" [disabled]="createSupplierForm.invalid || !pageLoaded" type="submit">
                        <span *ngIf="pageLoaded">Send Request</span>
                        <span *ngIf="!pageLoaded">Please wait..</span>
                    </button>
                </div>-->
            </form>
        </div>

        <div *ngIf="requestMode == 'sfc'" style="text-align: center;">
            <div class="centeredHeading">
                <div>
                    <button mat-button [disableRipple]="true" matDialogClose>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div>
                    <h2>New SFC Request</h2>
                </div>
                <div style="width: 60.8px;">
                    
                </div>
            </div>

            <div class="topInformation">
                <i>Please note that your request may take up to 3 working days to process.<br>
                    We appreciate your patience and will do our best to resolve it as soon as possible.<br><br>
                    If you need any assistance or have questions during this process, feel free to contact our support team<br>
                    at <a href="mailto:operations@thetravelnetworkgroup.co.uk">operations@thetravelnetworkgroup.co.uk</a></i>
            </div>


            <div class="topInformation" style="text-align: left;">
                <i>
                    In order for us to process your Supplier Failure Cover application, you must provide one of the following:
                    <ul style="margin-top: 0px;">
                        <li>Copy of the commercial agreement signed between both parties on letterhead</li>
                        <li>Email confirmation from the Finance Director that the legal entity is trading with the TTA member</li>
                    </ul>
                    AND one of the following:
                    <ul style="margin-top: 0px;">
                        <li>Copy of the supplier's letterhead including full bank details and bank account name</li>
                        <li>Copy of an existing invoice including full bank details and bank account name on the supplier's letterhead</li>
                    </ul>
                    Additionally, please attach and fill in the Turnover Template, which you can download <a (click)="getTurnoverTemplate('sfcTurnoverTemplate.docx')">here</a> or at the bottom of the page.<br><br>

                    If you are unable to obtain the above, please use following <a (click)="getTurnoverTemplate('SFCApplicationTemplate.docx')">template</a>. 
                    Send this template to your supplier for completion. It covers all the requirements for the documentation in a single document. Once returned by the supplier, you can upload this instead.<br><br>

                    All of these documents need to be in English, due to underwriter requirements. Documents in languages other than English will not be accepted.
                </i>
            </div>

            <form ngNativeValidate #requestSFCform="ngForm" (ngSubmit)="requestSFC(requestSFCform)">

                <div>
                    <h3>Choose a supplier</h3>
                    <div>
                        <mat-form-field class="supplierName">
                            <mat-select ngModel required name="supplierName" #supplierName="ngModel" [placeholder]="pageLoaded ? 'Supplier Name' : 'Please wait...'" [(ngModel)]="selectedSfcSupplier"
                                (click)="filterString = ''; filterSelect();" (selectionChange)="selectSfcSupplier($event.value)" panelClass="supplierNamePanel">
                                <mat-form-field appearance="fill" class="filterSelectList sticky-search-container">
                                    <input placeholder="Search.." matInput #filterInput="ngModel" [(ngModel)]="filterString" name="filterString" (keyup)="filterSelect()">
                                </mat-form-field>
                                <mat-option *ngFor="let supplier of supplierFilteredData" [value]="supplier" (click)="filterString = ''; filterSelect();">
                                    {{ supplier.supplierNameM }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <h3>Application details</h3>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly; flex-direction: column;">
                        <div class="sfcBlock">
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Trading Name" name="tradingName" #tradingName="ngModel" minlength="3" maxlength="100">
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Address Line 1" name="address1" #address1="ngModel" minlength="3" maxlength="50" required [(ngModel)]="selectedSfcSupplier.addressLine1">
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Website" name="website" #website="ngModel" minlength="3" maxlength="100" [(ngModel)]="selectedSfcSupplier.website">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="sfcBlock">
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Contact Name" name="contactName" #contactName="ngModel" minlength="3" maxlength="100" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Contact Role" name="contactRole" #contactRole="ngModel" minlength="3" maxlength="50" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Telephone" name="telephone" #telephone="ngModel" minlength="3" maxlength="50" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="sfcBlock">
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Email Address" name="email" #email="ngModel" minlength="3" maxlength="100" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Bank Name" name="bankName" #bankName="ngModel" minlength="3" maxlength="100" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Account Number" name="accountNo" #accountNo="ngModel" pattern="[0-9]+" minlength="8" maxlength="12" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="sfcBlock">
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Sort Code" name="sortCode" #sortCode="ngModel" pattern="[0-9]+" minlength="6" maxlength="8" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Account Name" name="accountName" #accountName="ngModel" minlength="3" maxlength="200" required>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="IBAN" name="iban" #iban="ngModel" minlength="15" maxlength="50">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="sfcBlock">
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="SWIFT/BIC" name="swift" #swift="ngModel" minlength="8" maxlength="20">
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="sfcSuppInfo">
                                    <input matInput ngModel placeholder="Currency" name="currency" #currency="ngModel" minlength="3" maxlength="3">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <h3>Supporting documentation</h3>
                    <div>
                        <div class="uploadFields">
                            <div>Email confirmation from the Finance Director</div>
                            <div>
                                <input #fileInput1 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 1)" accept=".doc,.xlsx,.pdf,.csv,.eml,.jpg,.jpeg,.docx,.msg"
                                    multiple="false" />
                                <button matSuffix mat-stroked-button color="primary" (click)="fileInput1.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                    <mat-icon>file_upload</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles1">{{ file.name }}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uploadFields">
                            Copy of the commercial agreement
                            <input #fileInput2 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 2)" accept=".doc,.xlsx,.pdf,.csv,.eml,.jpg,.jpeg,.docx,.msg"
                                multiple="false" />
                            <button matSuffix mat-stroked-button color="primary" (click)="fileInput2.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                <mat-icon>file_upload</mat-icon>
                            </button>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles2">{{ file.name }}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uploadFields">
                            Copy of the suppliers header paper
                            <input #fileInput3 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 3)" accept=".doc,.xlsx,.pdf,.csv,.eml,.jpg,.jpeg,.docx,.msg"
                                multiple="false" />
                            <button matSuffix mat-stroked-button color="primary" (click)="fileInput3.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                <mat-icon>file_upload</mat-icon>
                            </button>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles3">{{ file.name }}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uploadFields">
                            Copy of the existing invoice
                            <input #fileInput4 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 4)" accept=".doc,.xlsx,.pdf,.csv,.eml,.jpg,.jpeg,.docx,.msg"
                                multiple="false" />
                            <button matSuffix mat-stroked-button color="primary" (click)="fileInput4.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                <mat-icon>file_upload</mat-icon>
                            </button>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles4">{{ file.name }}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uploadFields">
                            <a (click)="getTurnoverTemplate('sfcTurnoverTemplate.docx')">Turnover Template</a>
                            <input #fileInput5 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 5)" accept=".doc,.docx" [multiple]="false" />
                            <button matSuffix mat-stroked-button color="primary" (click)="fileInput5.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                <mat-icon>file_upload</mat-icon>
                            </button>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles5">{{ file.name }}</div>
                        </div>
                    </div>

                    <div>
                        <div class="uploadFields">
                            <a (click)="getTurnoverTemplate('SFCApplicationTemplate.docx')">Application Template</a>
                            <input #fileInput6 hidden="true" type="file" onclick="this.value=null" (change)="keepUploadedFile($event, 6)" accept=".doc,.docx" [multiple]="false" />
                            <button matSuffix mat-stroked-button color="primary" (click)="fileInput6.click()" matTooltip="Upload File" type="button" [disableRipple]="true">
                                <mat-icon>file_upload</mat-icon>
                            </button>
                        </div>
                        <div class="uploadFileNames">
                            <div *ngFor="let file of sfcFiles6">{{ file.name }}</div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 20px; margin-bottom: -20px;">
                    <button class="buttonToRight requestButton" mat-button [disableRipple]="true" type="submit"
                        [disabled]="requestSFCform.invalid || (!sfcFormValid && userType != 'sinGSAdmin') || !pageLoaded">
                        <span *ngIf="pageLoaded">Send Request</span>
                        <span *ngIf="!pageLoaded">Please wait..</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #myDialog>
    <div *ngIf="errorMessage != ''" class="errorMessage">
        <p>{{errorMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose [disableRipple]="true">OK</button></p>
    </div>

    <div *ngIf="successMessage != ''" class="successMessage">
        <p>{{successMessage}}</p>
        <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose [disableRipple]="true">OK</button></p>
    </div>
</ng-template>