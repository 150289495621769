<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="haveAccess && innerWidth > 1024" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon> Branch List
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView" *ngIf="userType == 'sinGSAdmin' || userType == 'tapsAdmin'">
          <mat-icon>add</mat-icon> Create Branch
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon> Help
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="haveAccess && innerWidth < 1025" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('edit')" mat-button [disabled]="editView">
          <mat-icon>list</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('create')" mat-button [disabled]="createView" *ngIf="userType == 'sinGSAdmin' || userType == 'tapsAdmin'">
          <mat-icon>add</mat-icon>
        </button>
        <button class="navButtons" style="width: 100px; margin-left: auto;" (click)="showHelp()" mat-button>
          <mat-icon class="helpIcon">help</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div class="sideNavContent">
          <div [@inAnimation] *ngIf="editView && haveAccess">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Branch List
            </h1>
            <div>
              <mat-divider></mat-divider>
            </div>

            <div style="display: flex;">
              <div style="display: flex;">
                <div>
                <mat-form-field style="padding-top: 5px; margin-bottom: -10px; min-width: unset; float: left; margin-left: 22.5px;">
                  <input matInput type="text" (keyup)="doFilter($event)" id="filterBranch" placeholder="Filter Branches">
                </mat-form-field>
                </div>
              </div>

              <div class="paginatorFooter">
                <div>
                  <button class="buttonToRight" color="primary" style="margin-right: 20px; margin-bottom: -7.5px;"
                          mat-icon-button  *ngIf="userType == 'sinGSAdmin' || userType == 'sinGSstaff'" (click)="exportToExcel()">
                        <mat-icon>file_download</mat-icon>
                  </button>
                </div>
                <div>
                  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
            </div>

            <mat-table multiTemplateDataRows [dataSource]="branchData" class="amendBranch">
              <ng-container matColumnDef="tradeCode">
                <mat-header-cell *matHeaderCellDef> Trade Code </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{ branch.tradeCode }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="branchName">
                <mat-header-cell *matHeaderCellDef> Branch Name </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{ branch.branchName }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                <mat-cell *matCellDef="let branch"> {{ branch.tradeCode + ' ' + branch.branchName }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="membershipType">
                <mat-header-cell *matHeaderCellDef> Membership Type </mat-header-cell>
                <mat-cell *matCellDef="let branch">
                  <span *ngIf="branch.membershipType == 'globalTravel'">Global Travel</span>
                  <span *ngIf="branch.membershipType == 'ite'">Independent Travel Experts</span>
                  <span *ngIf="branch.membershipType == 'tta'">Travel Trust Association</span>
                  <span *ngIf="branch.membershipType == 'worldchoice'">Worldchoice</span>
                  <span *ngIf="branch.membershipType == 'worldchoicePlus'">Worldchoice Plus</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let branch">
                  <mat-icon>expand_more</mat-icon>
                </mat-cell>
              </ng-container>

              <!-- Expansion text -->
              <ng-container matColumnDef="expandedDetail">
                <mat-cell class="expandedDetail" *matCellDef="let branch" [attr.colspan]="4">
                  <form class="editBranch" ngNativeValidate #editBranchForm="ngForm" [@inOutAnimation] *ngIf="branch == expandedElement" (ngSubmit)="updateBranch(editBranchForm, branch)">
                    
                    <div *ngIf="userType != 'wcManager' && userType != 'memberManager'">
                      <h3>General Information</h3>
                      <mat-form-field>
                        <input matInput ngModel required placeholder="Trade Code" minlength="5" hmaxlength="5" pattern="[A-Z0-9]*" readonly="true" name="tradeCode" #tradeCode="ngModel" [(ngModel)]="branch.tradeCode" title="Please provide valid trade code">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel required placeholder="Branch Name" minlength="3" maxlength="80" name="branchName" #branchName="ngModel" [(ngModel)]="branch.branchName">
                      </mat-form-field>

                      <mat-form-field style="display: none;">
                        <input matInput ngModel required placeholder="Branch Full Name" minlength="5" maxlength="85" readonly="true" name="fullName" #fullName="ngModel" [(ngModel)]="branch.tradeCode + ' ' + branch.branchName">
                      </mat-form-field>

                      <mat-form-field *ngIf="userType == 'sinGSAdmin'">
                        <input matInput ngModel placeholder="Trading Name" [(ngModel)]="branch.tradingName" name="tradingName" #tradingName="ngModel" maxlength="55">
                      </mat-form-field>

                      <mat-form-field *ngIf="userType == 'sinGSAdmin'">
                        <mat-select ngModel required [(ngModel)]="branch.membershipType" name="membershipType" #membershipType="ngModel"
                        (selectionChange)="filterMemberships(branch)" placeholder="Membership Type">
                          <mat-option *ngFor="let type of membershipTypes" [value]="type['value']">
                            {{type['viewValue']}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="borderTop" style="padding-top: 5px;">
                      <h3>Address Details</h3>

                      <div *ngIf="userType != 'wcManager' && userType != 'memberManager'">
                        <mat-form-field>
                          <input matInput ngModel placeholder="Address Line 1" maxlength="50" [(ngModel)]="branch.address1" name="address1" #addressLine1="ngModel">
                        </mat-form-field>
  
                        <mat-form-field>
                          <input matInput ngModel placeholder="Address Line 2" maxlength="50" [(ngModel)]="branch.address2" name="address2" #addressLine2="ngModel">
                        </mat-form-field>
  
                        <mat-form-field>
                          <input matInput ngModel placeholder="Postcode" maxlength="20" [(ngModel)]="branch.postcode" name="postcode" #postcode="ngModel">
                        </mat-form-field>
  
                        <mat-form-field>
                          <input matInput ngModel placeholder="City" maxlength="50" [(ngModel)]="branch.city" name="city" #city="ngModel">
                        </mat-form-field>
  
                        <br>
  
                        <mat-form-field>
                          <input matInput ngModel placeholder="County" maxlength="50" [(ngModel)]="branch.county" name="county" #county="ngModel">
                        </mat-form-field>
  
                        <mat-form-field>
                          <mat-select ngModel name="country" #country="ngModel" placeholder="Country" [(ngModel)]="branch.country">
                            <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                              <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="countryStr" (keyup)="filterCountries()">
                            </mat-form-field>
                            <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="countryStr = ''; filterCountries();">
                              {{country}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div *ngIf="userType == 'wcManager' || userType == 'memberManager'">
                        <ng-container *ngFor="let field of [branch.address1, branch.address2, branch.postcode, branch.city, branch.county, branch.country]">
                          <div *ngIf="field">{{ field }}</div>
                        </ng-container>
                      </div>
                    </div>

                    <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);" *ngIf="userType == 'wcManager' || userType == 'memberManager'">
                      <div class="checkboxElement">
                        <label style="display: flex;">
                          <h3>Contact Details</h3>
                          <button mat-button color="primary" type="button" (click)="openContactDetails(branch.tradeCode)"
                          style="margin-left: 45.5px;">
                            <mat-icon style="margin: auto;">open_in_new</mat-icon>
                          </button>
                        </label>
                      </div>

                      <div class="checkboxElement">
                        <label style="display: flex;">
                          <h3>Trading Names</h3>
                          <button mat-button color="primary" type="button" (click)="openTradingNames(branch.tradeCode)"
                          style="margin-left: 45.5px;">
                            <mat-icon style="margin: auto;">open_in_new</mat-icon>
                          </button>
                        </label>
                      </div>
                    </div>

                    <div *ngIf="userType == 'wcManager' || userType == 'memberManager'" class="borderTop" style="padding-top: 5px;">
                      <h3>Financial Setup</h3>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Amex Fee (%)" name="cardFeePercAmex" [(ngModel)]="branch.cardFeePercAmex"
                        #cardFeePercAmex="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Credit Card Fee (%)" name="cardFeePercCredit" [(ngModel)]="branch.cardFeePercCredit"
                        #cardFeePercCredit="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Debit Card Fee (%)" name="cardFeePercDebit" [(ngModel)]="branch.cardFeePercDebit"
                        #cardFeePercDebit="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>
                    </div>

                    <div *ngIf="userType == 'sinGSAdmin' || userType == 'tapsAdmin'" class="borderTop" style="padding-top: 5px;">
                      <h3>Financial Setup</h3>

                      <mat-form-field>
                        <input matInput ngModel placeholder="ABTA" [(ngModel)]="branch.abta" name="abta" #abta="ngModel" maxlength="20">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel placeholder="ATOL" [(ngModel)]="branch.atol" [required]="branch.isTAtol == 'yes'" name="atol" #atol="ngModel" maxlength="20">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel placeholder="IATA" [(ngModel)]="branch.iata" name="iata" #iata="ngModel" maxlength="20">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel placeholder="Bank Code" [(ngModel)]="branch.bankCode" name="bankCode" #bankCode="ngModel" type="number" min="0" max="9999999999">
                      </mat-form-field>

                      <br>

                      <mat-form-field>
                        <input matInput ngModel placeholder="Registration No." [(ngModel)]="branch.regNo" name="regNo" #regNo="ngModel" maxlength="50">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Amex Fee (%)" name="cardFeePercAmex" [(ngModel)]="branch.cardFeePercAmex"
                        #cardFeePercAmex="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Credit Card Fee (%)" name="cardFeePercCredit" [(ngModel)]="branch.cardFeePercCredit"
                        #cardFeePercCredit="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput required ngModel placeholder="Debit Card Fee (%)" name="cardFeePercDebit" [(ngModel)]="branch.cardFeePercDebit"
                        #cardFeePercDebit="ngModel" type="number" min="0" step="0.01" max="100">
                      </mat-form-field>

                      <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>Contact Details</h3>
                            <button mat-button color="primary" type="button" (click)="openContactDetails(branch.tradeCode)"
                            style="margin-left: 45.5px;">
                              <mat-icon style="margin: auto;">open_in_new</mat-icon>
                            </button>
                          </label>
                        </div>

                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>Bank Accounts</h3>
                            <button mat-button color="primary" type="button" (click)="openBankingAccounts(branch.tradeCode)"
                            style="margin-left: 57.5px;">
                              <mat-icon style="margin: auto;">open_in_new</mat-icon>
                            </button>
                          </label>
                        </div>

                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>Trading Names</h3>
                            <button mat-button color="primary" type="button" (click)="openTradingNames(branch.tradeCode)"
                            style="margin-left: 59px;">
                              <mat-icon style="margin: auto;">open_in_new</mat-icon>
                            </button>
                          </label>
                        </div>
                      </div>

                      <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>Active Branch</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isLive')"
                            [checked]="branch.isLive == 'yes'" style="margin-left: 82px;"
                            value="{{branch.isLive}}">
                          </label>
                        </div>
                        
                        <div class="checkboxElement" style="margin-left: 18px;">
                          <label style="display: flex;">
                            <h3>VAT Registered</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isVatReg')"
                            [checked]="branch.isVatReg == 'yes'" style="margin-left: 83.25px;"
                            value="{{branch.isVatReg}}">
                          </label>
                        </div>
                        
                        <div class="checkboxElement" style="margin-left: 16px;">
                          <label style="display: flex;">
                            <h3 matTooltip="Branch will be able to generate ATOL Certificate">T-ATOL Holder</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isTAtol')"
                            [checked]="branch.isTAtol == 'yes'" style="margin-left: 90.25px;"
                            value="{{branch.isTAtol}}">
                          </label>
                        </div>
                      </div>

                      <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>Felloh Registered</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'fellohSetup')"
                            [checked]="branch.fellohSetup == 'yes'" style="margin-left: 53px;"
                            value="{{branch.fellohSetup}}">
                          </label>
                        </div>

                        <div class="checkboxElement" style="margin-left: 16px;" *ngIf="branch.fellohSetup == 'yes'">
                          <label style="display: flex;">
                            <h3>Felloh Configuration</h3>
                            <button mat-button color="primary" type="button" (click)="openFellohConfig(branch.tradeCode)"
                            style="margin-left: 15.5px;">
                              <mat-icon style="margin: auto;">open_in_new</mat-icon>
                            </button>
                          </label>
                        </div>
                      </div>

                      <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <h3>TAPS Reigstered</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isTapsReg')"
                            [checked]="branch.isTapsReg == 'yes'" style="margin-left: 60.5px;"
                            value="{{branch.isTapsReg}}">
                          </label>
                        </div>

                        <div class="checkboxElement" style="margin-left: 15.5px;" *ngIf="branch.membershipType == 'tta'">
                          <label style="display: flex;">
                            <h3 matTooltip="Branch will be able to create, upload and amend skeleton booking data from other systems">One Touch Reg.</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isOneTouchReg')"
                            [checked]="branch.isOneTouchReg == 'yes'" style="margin-left: 79px;"
                            value="{{branch.isOneTouchReg}}">
                          </label>
                        </div>
                        
                        <div class="checkboxElement" style="margin-left: 16px;" *ngIf="branch.membershipType == 'tta'">
                          <label style="display: flex;">
                            <h3>PRF Registered</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isPrfReg')"
                            [checked]="branch.isPrfReg == 'yes'" style="margin-left: 82px;" value="{{branch.isPrfReg}}">
                          </label>
                        </div>

                        <div class="checkboxElement" style="margin-left: 15px;" *ngIf="branch.isPrfReg == 'yes'">
                          <label style="display: flex;">
                            <h3>Smart PRF Access</h3>
                            <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isSmartPrf')"
                            [checked]="branch.isSmartPrf == 'yes'" style="margin-left: 62px;" value="{{branch.isSmartPrf}}">
                          </label>
                        </div>
                      </div>

                      <div *ngIf="branch.isTapsReg == 'yes'">

                        <div class="checkboxContainer" style="margin-top: 20px;">
                          <div class="checkboxElement">
                            <label style="display: flex;">
                              <span>TAPS Master</span>
                              <input type="checkbox" (change)="checkboxYesNo($event, branch, 'isTapsMaster')"
                              [checked]="branch.isTapsMaster == 'yes'" style="margin-left: 92.5px;"
                              value="{{branch.isTapsMaster}}">
                            </label>
                          </div>

                          <div class="checkboxElement" style="margin-left: 15px;">
                            <label style="display: flex;">
                              <span matTooltip="MISTTA will override some of the details on a weekly basis">MISTTA Override</span>
                              <input type="checkbox" (change)="checkboxYesNo($event, branch, 'tapsSynchOverride')"
                              [checked]="branch.tapsSynchOverride == 'yes'" style="margin-left: 74px;"
                              value="{{branch.tapsSynchOverride}}">
                            </label>
                          </div>

                          <mat-form-field style="width: 90px; height: 20px; margin-top: -17px; margin-left: 10px;">
                            <input matInput reqiuired ngModel placeholder="Threshold" [(ngModel)]="branch.tapsThreshold" name="tapsThreshold"
                            #tapsThreshold="ngModel" min="0" max="100" type="number" step="0.01">
                          </mat-form-field>
                        </div>
  
                        <div *ngIf="branch.isTapsMaster == 'yes'">
                          <b style="margin-left: 4px;">Children</b>
                          <div class="checkboxContainerChildren">
                            <div class="checkboxElementChildren" *ngFor="let member of filteredByMembership">
                              <label>
                                <input type="checkbox" (change)="addRemoveMemberChildren($event)"
                                    [checked]="selectedChildren.indexOf(member.tradeCode) > -1"
                                    value="{{member.tradeCode}}">
                                    {{member.fullName}}
                            </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="borderTop" style="padding-top: 5px;">
                      <h3>Social Media Links</h3>
                      <mat-form-field>
                        <input matInput ngModel placeholder="Facebook" maxlength="255" name="urlFacebook" #urlFacebook="ngModel" [(ngModel)]="branch.urlFacebook">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel placeholder="Instagram" maxlength="255" name="urlInstagram" #urlInstagram="ngModel" [(ngModel)]="branch.urlInstagram">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput ngModel placeholder="LinkedIn" maxlength="255" name="urlLinkedIn" #urlLinkedIn="ngModel" [(ngModel)]="branch.urlLinkedIn">
                      </mat-form-field>
                    </div>

                    <div class="borderTop" style="padding-top: 5px;">
                      <h3>Miscellaneous</h3>
                      <mat-form-field *ngIf="userType == 'sinGSAdmin'">
                        <input matInput ngModel placeholder="Style Sheet" matTooltip="File Name Only" matTooltipClass="line-normal-tooltip" name="styleSheet" #styleSheet="ngModel" maxlength="50" [(ngModel)]="branch.styleSheet">
                      </mat-form-field>

                      <!--<mat-form-field>
                        <input matInput ngModel placeholder="Logo" name="logoRef" #logo="ngModel" maxlength="50" [(ngModel)]="branch.logoRef" readonly="true" matTooltip="Recommended dimensions: 500x100px" matTooltipClass="line-normal-tooltip">
                        <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadFile($event, 'logo', branch)" accept=".png,.jpeg,.jpg" multiple="false" />
                        <span matSuffix>
                          <button mat-icon-button type="button" (click)="openMedia('logo', branch)" *ngIf="branch.logoRef != ''" matTooltip="View File" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;">
                            <mat-icon>preview</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="csvInput1.click()" matTooltip="Upload File" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;">
                            <mat-icon>file_upload</mat-icon>
                          </button>
                        </span>
                      </mat-form-field>
                      
                      <mat-form-field>
                        <input matInput ngModel placeholder="Footer" name="footerLink" #footer="ngModel" maxlength="50" [(ngModel)]="branch.footerLink" readonly="true" matTooltip="Recommended dimensions: 2480x150px"
                          matTooltipClass="line-normal-tooltip">
                        <input #csvInput2 hidden="true" type="file" onclick="this.value=null" (change)="uploadFile($event, 'footer', branch)" accept=".png,.jpeg,.jpg" multiple="false" />
                        <span matSuffix>
                          <button mat-icon-button type="button" (click)="openMedia('footer', branch)" *ngIf="branch.footerLink != ''" matTooltip="View File" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;">
                            <mat-icon>preview</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="csvInput2.click()" matTooltip="Upload File" matTooltipClass="line-normal-tooltip" color="primary" style="display: inline; margin: 0px;">
                            <mat-icon>file_upload</mat-icon>
                          </button>
                        </span>
                      </mat-form-field>-->

                      <mat-form-field>
                        <mat-label>Opening Date</mat-label>
                        <input matInput [(ngModel)]="branch.openingDate" name="openingDate" [matDatepicker]="openingDate" (dateChange)="valiDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="openingDate"></mat-datepicker-toggle>
                        <mat-datepicker #openingDate></mat-datepicker>
                      </mat-form-field>

                      <p *ngIf="userType == 'sinGSAdmin'"></p>

                      <mat-form-field style="max-width: unset; width: 369px;">
                        <input matInput ngModel placeholder="Wordpress Branch ID" [(ngModel)]="branch.wpBranchID" name="wpBranchID" #wpBranchID="ngModel" maxlength="255" [readonly]="true">
                      </mat-form-field>
                    </div>

                    <div class="borderTop" style="padding-top: 5px;">
                      <h3 style="margin-top: 0px;">
                        Customer Side & Itinerary Setup 
                        <button mat-icon-button type="button" color="primary" (click)="infoOrderExpanded = !infoOrderExpanded">
                          <mat-icon *ngIf="!infoOrderExpanded" style="margin-top: -5px;">expand_more</mat-icon>
                          <mat-icon *ngIf="infoOrderExpanded" style="margin-top: -5px;">expand_less</mat-icon>
                        </button>
                      </h3>
                    </div>

                    <div *ngIf="infoOrderExpanded" style="display: flex; flex-direction: row; justify-content: space-between; margin-top: -10px; margin-bottom: 20px;">
                      <div>
                        <div class="dropList-header"><b>Itinerary Order</b></div>

                        <div style="margin-bottom: 15px;">
                          <mat-radio-group ngModel [(ngModel)]="itineraryOption" name="itineraryOption" style="display: flex; align-items: center; justify-content: space-evenly;">
                            <mat-radio-button value="chronologically">Chronological</mat-radio-button>
                            <mat-radio-button value="successive">Successive Items</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        
                        <div cdkDropList class="dropList-list" (cdkDropListDropped)="dropListElement($event, itineraryInfoOrder)" *ngIf="itineraryOption == 'successive'">
                          <div class="dropList-box" *ngFor="let element of itineraryInfoOrder" cdkDrag>
                            <mat-icon class="elementArrows">chevron_right</mat-icon>
                              {{element.name}}
                            <mat-icon class="elementArrows">chevron_left</mat-icon>
                          </div>
                        </div>

                        <div class="dropList-list" *ngIf="itineraryOption == 'chronologically'">
                          <div class="dropList-box dropList-box-example">Itinerary example</div>
                          <div class="dropList-box dropList-box-example">Flight on 10.01.2025</div>
                          <div class="dropList-box dropList-box-example">Hotel on 11.01.2025</div>
                          <div class="dropList-box dropList-box-example">Flight on 15.01.2025</div>
                        </div>
                      </div>
                      
                      <div>
                        <div class="dropList-header"><b>Booking Information Order</b></div>
                        <div cdkDropList class="dropList-list" (cdkDropListDropped)="dropListElement($event, bookingInfoOrder)">
                          <div class="dropList-box" *ngFor="let element of bookingInfoOrder" cdkDrag>
                            <mat-icon class="elementArrows">chevron_right</mat-icon>
                              {{element.name}}
                            <mat-icon class="elementArrows">chevron_left</mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>

                    <input matInput hidden="true" ngModel [(ngModel)]="branch.id" name="id" #id="ngModel">

                    <button style="margin-bottom: 10px; width: 120px; margin-right: 20px;" mat-raised-button color="accent" type="submit" [disabled]="editBranchForm.invalid" *ngIf="memberLive">
                      <mat-icon class="iconInButton">edit</mat-icon>Save
                    </button>
                    <button style="margin-bottom: 10px; width: 120px;" mat-raised-button color="warn" type="button" (click)="removeBranch(branch)" *ngIf="userEmail == 'greg@gmail.com'">
                      <mat-icon class="iconInButton">delete</mat-icon>Remove
                    </button>
                  </form>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns : displayedColumns;" class="trueRow" (click)="expandedElement = expandedElement === row ? null : row; generateListElement(row); filterMemberships(row); setFellohCurrencies(row);"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden"></mat-row>
            </mat-table>
          </div>

          <div [@inAnimation] *ngIf="createView && (userType == 'sinGSAdmin' || userType == 'tapsAdmin') && haveAccess">
            <h1 fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>Create Branch
            </h1>
            <div>
              <mat-divider></mat-divider>
            </div>

            <form class="createBranch" ngNativeValidate #createBranchForm="ngForm" (ngSubmit)="createBranch(createBranchForm)">
              <div class="withinMatCard">
                <h5><i>Fill in all fields marked with an asterisk - you can come back to the Branch and edit it at any time.</i></h5>
                
                <div>
                <h3>General Information</h3>
                  <mat-form-field>
                    <input matInput ngModel required placeholder="Trade Code" id="createTradeCode" name="tradeCode" #tradeCode="ngModel" minlength="5" maxlength="5" pattern="[A-Z0-9]*" title="Please provide valid trade code">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel required placeholder="Branch Name" id="createBranchName" name="branchName" #branchName="ngModel" minlength="3" maxlength="80">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Trading Name" name="tradingName" #tradingName="ngModel" maxlength="55">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-select ngModel required name="membershipType" #membershipType="ngModel" placeholder="Membership Type"
                    [(ngModel)]="newBranch.membershipType" (selectionChange)="filterMemberships(newBranch)">
                      <mat-option *ngFor="let type of membershipTypes" [value]="type['value']">
                        {{type['viewValue']}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="borderTop" style="padding-top: 5px;">
                  <h3>Address Details</h3>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Address Line 1" maxlength="50" name="address1" #addressLine1="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Address Line 2" maxlength="50" name="address2" #addressLine2="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Postcode" maxlength="20" name="postcode" #postcode="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="City" maxlength="50" name="city" #city="ngModel">
                  </mat-form-field>

                  <br>

                  <mat-form-field>
                    <input matInput ngModel placeholder="County" maxlength="50" name="county" #county="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <mat-select ngModel name="country" #country="ngModel" placeholder="Country">
                      <mat-form-field appearance="fill" class="filterSelectList sticky-search-container" style="margin-bottom: 5px;">
                        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="countryStr" (keyup)="filterCountries()">
                      </mat-form-field>
                      <mat-option *ngFor="let country of countryFiltered" [value]="country" (click)="countryStr = ''; filterCountries();">
                        {{country}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field>
                    <mat-label>Opening Date</mat-label>
                    <input matInput ngModel name="openingDate" [matDatepicker]="openingDate" (dateChange)="valiDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="openingDate"></mat-datepicker-toggle>
                    <mat-datepicker #openingDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="borderTop" style="padding-top: 5px;">
                  <h3>Financial Setup</h3>

                  <mat-form-field>
                    <input matInput ngModel placeholder="ABTA" name="abta" #abta="ngModel" maxlength="20">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="ATOL" [required]="newBranch.isTAtol == 'yes'" name="atol" #atol="ngModel" maxlength="20">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="IATA" name="iata" #iata="ngModel" maxlength="20">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Bank Code" id="createBankCode" name="bankCode" #bankCode="ngModel" type="number" min="0" max="9999999999">
                  </mat-form-field>

                  <br>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Registration No." id="regNo" name="regNo" #regNo="ngModel" maxlength="50">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput required ngModel placeholder="Amex Fee (%)" id="amexFee" name="cardFeePercAmex" #cardFeePercAmex="ngModel" type="number" min="0" step="0.01" max="100">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput required ngModel placeholder="Credit Card Fee (%)" id="creditFee" name="cardFeePercCredit" #cardFeePercCredit="ngModel" type="number" min="0" step="0.01" max="100">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput required ngModel placeholder="Debit Card Fee (%)" id="debitFee" name="cardFeePercDebit" #cardFeePercDebit="ngModel" type="number" min="0" step="0.01" max="100">
                  </mat-form-field>

                  <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                    <div class="checkboxElement">
                      <label style="display: flex;">
                        <h3>VAT Registered</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isVatReg')"
                        [checked]="newBranch.isVatReg == 'yes'" style="margin-left: 71.25px;"
                        value="{{newBranch.isVatReg}}">
                      </label>
                    </div>
                    
                    <div class="checkboxElement" style="margin-left: 16px;">
                      <label style="display: flex;">
                        <h3 matTooltip="Branch will be able to generate ATOL Certificate">T-ATOL Holder</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isTAtol')"
                        [checked]="newBranch.isTAtol == 'yes'" style="margin-left: 89.25px;"
                        value="{{newBranch.isTAtol}}">
                      </label>
                    </div>

                    <div class="checkboxElement" style="margin-left: 16px;">
                      <label style="display: flex;">
                        <h3 matTooltip="Branch will be able to use Felloh">Felloh Registered</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'fellohSetup')"
                        [checked]="newBranch.fellohSetup == 'yes'" style="margin-left: 89.25px;"
                        value="{{newBranch.fellohSetup}}">
                      </label>
                    </div>
                  </div>

                  <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);" *ngIf="newBranch.membershipType == 'tta'">
                    <div class="checkboxElement">
                      <label style="display: flex;">
                        <h3>PRF Registered</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isPrfReg')"
                        [checked]="newBranch.isPrfReg == 'yes'" style="margin-left: 69px;" value="{{newBranch.isPrfReg}}">
                      </label>
                    </div>

                    <div class="checkboxElement" style="margin-left: 15px;" *ngIf="newBranch.isPrfReg == 'yes'">
                      <label style="display: flex;">
                        <h3>Smart PRF Access</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isSmartPrf')"
                        [checked]="newBranch.isSmartPrf == 'yes'" style="margin-left: 61px;" value="{{newBranch.isSmartPrf}}">
                      </label>
                    </div>
                  </div>

                  <div class="checkboxContainer" style="margin-bottom: unset; border-top: 1px solid rgb(219, 219, 219);">
                    <div class="checkboxElement">
                      <label style="display: flex;">
                        <h3>TAPS Reigstered</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isTapsReg')"
                        [checked]="newBranch.isTapsReg == 'yes'" style="margin-left: 60.5px;"
                        value="{{newBranch.isTapsReg}}">
                      </label>
                    </div>

                    <div class="checkboxElement" style="margin-left: 15.5px;" *ngIf="newBranch.membershipType == 'tta'">
                      <label style="display: flex;">
                        <h3 matTooltip="Branch will be able to create, upload and amend skeleton booking data from other systems">One Touch Reg.</h3>
                        <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isOneTouchReg')"
                        [checked]="newBranch.isOneTouchReg == 'yes'" style="margin-left: 79px;"
                        value="{{newBranch.isOneTouchReg}}">
                      </label>
                    </div>
                  </div>

                  <div *ngIf="newBranch.isTapsReg == 'yes'">

                    <div class="checkboxContainer" style="margin-top: 10px;">
                      <div class="checkboxContainer" style="margin-top: 10px;">
                        <div class="checkboxElement">
                          <label style="display: flex;">
                            <span>TAPS Master</span>
                            <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'isTapsMaster')"
                            [checked]="newBranch.isTapsMaster == 'yes'" style="margin-left: 92.5px;"
                            value="{{newBranch.isTapsMaster}}">
                          </label>
                        </div>

                        <div class="checkboxElement" style="margin-left: 15px;">
                          <label style="display: flex;">
                            <span matTooltip="MISTTA will override some of the details on a weekly basis">MISTTA Override</span>
                            <input type="checkbox" (change)="checkboxYesNo($event, newBranch, 'tapsSynchOverride')"
                            [checked]="newBranch.tapsSynchOverride == 'yes'" style="margin-left: 74px;"
                            value="{{newBranch.tapsSynchOverride}}">
                          </label>
                        </div>

                        <mat-form-field style="width: 90px; height: 20px; margin-top: -17px; margin-left: 10px;">
                          <input matInput reqiuired ngModel placeholder="Threshold" [(ngModel)]="newBranch.tapsThreshold" name="tapsThreshold"
                          #tapsThreshold="ngModel" min="0" max="100" type="number" step="0.01">
                        </mat-form-field>
                      </div>
                    </div>

                    <div *ngIf="newBranch.isTapsMaster == 'yes'">
                      <b style="margin-left: 4px;">Children</b>
                      <div class="checkboxContainerChildren">
                        <div class="checkboxElementChildren" *ngFor="let member of filteredByMembership">
                          <label>
                            <input type="checkbox" (change)="addRemoveMemberChildren($event)"
                                [checked]="selectedChildren.indexOf(member.tradeCode) > -1"
                                value="{{member.tradeCode}}">
                                {{member.fullName}}
                        </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="borderTop" style="padding-top: 5px;">
                  <h3>Social Media Links</h3>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Facebook" maxlength="255" name="urlFacebook" #urlFacebook="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="Instagram" maxlength="255" name="urlInstagram" #urlInstagram="ngModel">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput ngModel placeholder="LinkedIn" maxlength="255" name="urlLinkedIn" #urlLinkedIn="ngModel">
                  </mat-form-field>
                </div>

                <button style="width: 120px; margin-top: 10px;" type="submit" id="createBranch" mat-raised-button color="accent">
                  <mat-icon class="iconInButton">add</mat-icon>Create
                </button>
              </div>
            </form>
          </div>

          <div class="accessDenied" *ngIf="pageLoaded && !haveAccess">
            <div class="errorMessage">
              <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
            </div>
          </div>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>

<ng-template #helpDialog>
  <mat-dialog-content>
    <h1 style="margin-top: 0px;"><u>Branches</u></h1>
    <p>This is where you can manage your own branch.</p>

    <h2>General Information</h2>
    <p>Mandatory fields globally used inside and outside of SinGS system.</p>

    <h2>Customer Side & Itinerary Setup</h2>
    <p>Configure the page layout in which you would like your customers to see their booking / itinerary<br>
      For more information please visit link below<br>
      <a href="https://docs.google.com/document/d/1yznNcziQNVBzfe2KKFMPYDKNnMIQG2p4wPcF4nK7Jzg/edit?usp=sharing" target="_blank">Customer-view custom templates</a>
    </p>

    <h2>Miscellaneous</h2>
    <p>
      <span *ngIf="userType == 'sinGSAdmin'">Section where you set Felloh account and upload logos and footers*.</span>
      <span *ngIf="userType != 'singsAdmin'">Section where you can upload your own logo and footer at any time*.</span>
      <br>Click link below for WordPress plugin and instructions<br>
      <a href="https://docs.google.com/document/d/1FxeuwUOav7bSFVy4RHqDNoQ91k7DfAoR1Lmq4VYuD84/edit?usp=sharing" target="_blank">Add SinGS widget to your WordPress site</a>
    </p>
    
    <h2 *ngIf="userType == 'sinGSAdmin'">Financial Setup</h2>
    <p *ngIf="userType == 'sinGSAdmin'">Information used for various reports e.g. SAGE export (TBD??)</p>

    <p>
      <i>*Formats allowed: <b>png, jpg, jpeg</b>.<br>
        *Maximum file size of <b>1MB</b></i>.
    </p>

    <p style="margin: auto; text-align: center; margin-bottom: 0px;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </mat-dialog-content>
</ng-template>

<ng-template #tradingNamesBox style="padding: 0px !important;">
  <div style="width: 100%; min-width: 1000px;">
    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="tradNameData">
      <ng-container matColumnDef="tradingName">
          <mat-header-cell *matHeaderCellDef>Trading Name</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <span *ngIf="userType == 'sinGSAdmin'">
              <input matInput ngModel required placeholder="Trading Name*" name="tradingName"
              #tradingName="ngModel" [(ngModel)]="name.tradingName" maxlength="80">
            </span>
            <span *ngIf="userType != 'sinGSAdmin'">
              {{ name.tradingName }}
            </span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <input matInput ngModel required placeholder="Trading Name*" name="tradingName"
            #tradingName="ngModel" [(ngModel)]="newTradingName.tradingName" maxlength="80">
          </mat-footer-cell>
      </ng-container>
    
      <ng-container matColumnDef="logoRef">
          <mat-header-cell *matHeaderCellDef>Logo</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadFile($event, 'logo', name)" accept=".png,.jpeg,.jpg" multiple="false" />
            <button mat-icon-button type="button" (click)="openMedia('logo', name)" [disabled]="!name.logoRef" matTooltip="View File" matTooltipClass="line-normal-tooltip" color="primary" class="tNamesBtn">
              <mat-icon>preview</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="csvInput1.click()" matTooltip="Upload file - recommended dimensions: 500x100px" matTooltipClass="line-normal-tooltip" color="primary" class="tNamesBtn" *ngIf="memberLive">
              <mat-icon>file_upload</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
          </mat-footer-cell>
      </ng-container>
    
      <ng-container matColumnDef="footerLink">
          <mat-header-cell *matHeaderCellDef>Footer</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <input #csvInput2 hidden="true" type="file" onclick="this.value=null" (change)="uploadFile($event, 'footer', name)" accept=".png,.jpeg,.jpg" multiple="false"/>
            <button mat-icon-button type="button" (click)="openMedia('footer', name)" [disabled]="!name.footerLink" matTooltip="View File" matTooltipClass="line-normal-tooltip" color="primary" class="tNamesBtn">
              <mat-icon>preview</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="csvInput2.click()" matTooltip="Upload file - recommended dimensions: 2480x150px" matTooltipClass="line-normal-tooltip" color="primary" class="tNamesBtn" *ngIf="memberLive">
              <mat-icon>file_upload</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
          </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <mat-header-cell *matHeaderCellDef>Phone Number</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <input matInput ngModel required placeholder="Phone Number*" name="phoneNumber"
          #phoneNumber="ngModel" [(ngModel)]="name.phoneNumber" maxlength="50">
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <input matInput ngModel required placeholder="Phone Number*" name="phoneNumber"
          #phoneNumber="ngModel" [(ngModel)]="newTradingName.phoneNumber" maxlength="50">
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="emailAddress">
        <mat-header-cell *matHeaderCellDef>Email Address</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <input matInput ngModel required placeholder="Email Address*" name="emailAddress"
          #emailAddress="ngModel" [(ngModel)]="name.emailAddress" maxlength="100">
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <input matInput ngModel required placeholder="Email Address*" name="emailAddress"
          #emailAddress="ngModel" [(ngModel)]="newTradingName.emailAddress" maxlength="100">
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="website">
        <mat-header-cell *matHeaderCellDef>Website</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <input matInput ngModel required placeholder="Website" name="website"
          #website="ngModel" [(ngModel)]="name.website" maxlength="100">
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <input matInput ngModel required placeholder="Website" name="website"
          #website="ngModel" [(ngModel)]="newTradingName.website" maxlength="100">
        </mat-footer-cell>
      </ng-container>
    
      <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <span *ngIf="!['wcManager', 'memberManager'].includes(userType)">
              <span *ngIf="name.active == 'yes'">
                <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="name.active = 'no'">
                    <mat-icon>done</mat-icon>
                </button>
              </span>
    
              <span *ngIf="name.active == 'no'">
                  <button mat-icon-button color="warn" type="button" [disableRipple]="true" (click)="name.active = 'yes'">
                      <mat-icon>close</mat-icon>
                  </button>
              </span>
            </span>

            <span *ngIf="['wcManager', 'memberManager'].includes(userType)">
              <mat-icon *ngIf="name.active == 'yes'">done</mat-icon>
              <mat-icon *ngIf="name.active == 'no'">close</mat-icon>
            </span>

          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="update">
        <mat-header-cell *matHeaderCellDef>Update</mat-header-cell>
        <mat-cell *matCellDef="let name">
            <span *ngIf="memberLive">
                <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="amendTradingName(name, false)">
                    <mat-icon>update</mat-icon>
                </button>
            </span>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <button type="button" mat-icon-button color="primary" [disableRipple]="true" (click)="addTradingName()">
                <mat-icon>add</mat-icon>
            </button>
        </mat-footer-cell>
    </ng-container>

    <ng-container>
      <mat-header-row *matHeaderRowDef="tradingNamesColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns : tradingNamesColumns;" class="trueRow"></mat-row>
      <ng-container *ngIf="userType === 'sinGSAdmin'">
        <mat-footer-row class="rowNotClick" *matFooterRowDef="tradingNamesColumns"></mat-footer-row>
      </ng-container>
    </ng-container>
    </mat-table>
    
    <h3 style="text-align: center; margin: auto; margin-top: 16px; margin-bottom: 16px; width: 80%; font-size: 14.5px;">
      By using multiple trading names, you can assign a specific name to each booking, allowing for better organization and management of your transactions.
      The trading names you choose will appear on important documents, including ATOL certificates, receipts, and certain emails that we send to your customers.<br><br>
      If you need to add another trading name to your account, please reach out to us at  <a href="mailto:operations@thetravelnetworkgroup.co.uk">operations@thetravelnetworkgroup.co.uk</a>
    </h3>
  </div>
</ng-template>

<ng-template #fellohConfigBox style="padding: 0px !important;">
  <div style="width: 100%; min-width: 1000px; margin-bottom: 3px;">
    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="fellohConfData">
      <ng-container matColumnDef="accountName">
          <mat-header-cell *matHeaderCellDef>Account Name</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <input matInput ngModel required placeholder="Account Name*" name="accountName" [disabled]="userEmail != 'greg@gmail.com'"
            #accountName="ngModel" [(ngModel)]="name.accountName" maxlength="80">
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            <input matInput ngModel required placeholder="Account Name*" name="accountName"
            #accountName="ngModel" [(ngModel)]="newFellohConfig.accountName" maxlength="80">
          </mat-footer-cell>
      </ng-container>
    
      <ng-container matColumnDef="accountCode">
        <mat-header-cell *matHeaderCellDef>Account Code</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <input matInput ngModel required placeholder="Account Code*" name="accountCode" [disabled]="userEmail != 'greg@gmail.com'"
          #accountCode="ngModel" [(ngModel)]="name.accountCode" maxlength="20">
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
          <input matInput ngModel required placeholder="Account Code*" name="accountCode"
          #accountCode="ngModel" [(ngModel)]="newFellohConfig.accountCode" maxlength="20">
        </mat-footer-cell>
      </ng-container>


      <ng-container matColumnDef="surcharging">
        <mat-header-cell *matHeaderCellDef>Surcharging</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="name.surcharging = 'no'" *ngIf="name.surcharging == 'yes'">
              <mat-icon>done</mat-icon>
          </button>

          <button mat-icon-button color="warn" type="button" [disableRipple]="true" (click)="name.surcharging = 'yes'" *ngIf="name.surcharging == 'no'">
              <mat-icon>close</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="bankingPull">
        <mat-header-cell *matHeaderCellDef>Banking Pull</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="name.bankingPull = 'no'" *ngIf="name.bankingPull == 'yes'">
              <mat-icon>done</mat-icon>
          </button>

          <button mat-icon-button color="warn" type="button" [disableRipple]="true" (click)="name.bankingPull = 'yes'" *ngIf="name.bankingPull == 'no'">
              <mat-icon>close</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="currencies">
        <mat-header-cell *matHeaderCellDef>Currencies</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <div style="display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%;">
            <div class="checkboxElement" *ngFor="let currency of fellohCurrencies" style="font-size: 14px;">
              <label style="display: flex; align-items: center;">
                <input type="checkbox" (change)="addRemoveFellohCurrencies($event, name)" style="margin-left: 10px;"
                    [checked]="name.selectedCurrencies.indexOf(currency) > -1" [disabled]="currency == 'GBP'"
                    value="{{currency}}">{{currency}}
            </label>
            </div>
          </div>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
    
      <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
          <mat-cell *matCellDef="let name">
            <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="name.active = 'no'" *ngIf="name.active == 'yes'">
                <mat-icon>done</mat-icon>
            </button>
  
            <button mat-icon-button color="warn" type="button" [disableRipple]="true" (click)="name.active = 'yes'" *ngIf="name.active == 'no'">
                <mat-icon>close</mat-icon>
            </button>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="update">
        <mat-header-cell *matHeaderCellDef>Update</mat-header-cell>
        <mat-cell *matCellDef="let name">
          <button mat-icon-button color="primary" type="button" [disableRipple]="true" (click)="amendFellohConfig(name)">
            <mat-icon>update</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
            <button type="button" mat-icon-button color="primary" [disableRipple]="true" (click)="addFellohConfig()">
                <mat-icon>add</mat-icon>
            </button>
        </mat-footer-cell>
    </ng-container>

    <ng-container>
      <mat-header-row *matHeaderRowDef="fellohConfigColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns : fellohConfigColumns;" class="trueRow"></mat-row>
      <mat-footer-row class="rowNotClick" *matFooterRowDef="fellohConfigColumns"></mat-footer-row>
    </ng-container>
    </mat-table>
  </div>
</ng-template>

<ng-template #bankingExternalBox style="padding: 0px !important;">
  <app-bank-accounts [ownerCode]="selectedTradeCode" [ownerType]="'branch'"></app-bank-accounts>
</ng-template>

<ng-template #contactExternalBox style="padding: 0px !important;">
  <app-contacts [ownerName]="selectedTradeCode" [ownerType]="'branch'"></app-contacts>
</ng-template>