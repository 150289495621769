<div *ngIf="showNotice" class="zoom-notice">
  <!--For the best experience, please zoom in / out (press <strong>Ctrl + -/+</strong> on Windows or <strong>Cmd + -/+</strong> on macOS).-->
</div>

<mat-toolbar [@inAnimation] color="primary" class="expanded-toolbar" *ngIf="isLoggedIn">
  <mat-toolbar-row class="expanded-toolbar">
    <span #mainPanel>
      <button mat-button class="topNavIconButtons" (click)="menuReset(); menuOpened = true; menuMain = true;" #menuOpen>
        <mat-icon>menu</mat-icon>
      </button>
      <span *ngIf="router.url == '/newLink'" style="vertical-align: middle !important;">New Payment Link (deprecated)</span>
      <span *ngIf="router.url == '/newPaymentLink'" style="vertical-align: middle !important;">New Payment Link</span>
      <span *ngIf="router.url == '/'" style="vertical-align: middle !important;">Dashboard</span>
      <span *ngIf="router.url == '/main'" style="vertical-align: middle !important;">Payment Links (deprecated)</span>
      <span *ngIf="router.url == '/paymentLinks'" style="vertical-align: middle !important;">Payment Links</span>
      <span *ngIf="router.url == '/paymentsOut'" style="vertical-align: middle !important;">Disbursement</span>
      <span *ngIf="router.url == '/fellohCharges'" style="vertical-align: middle !important;">Payment Processing Charges</span>
      <span *ngIf="router.url == '/fellohTxn'" style="vertical-align: middle !important;">Felloh Transactions</span>
      <span *ngIf="router.url == '/customerList'" style="vertical-align: middle !important;">CRM</span>
      <span *ngIf="router.url == '/branchList'" style="vertical-align: middle !important;">Branches</span>
      <span *ngIf="router.url == '/userList'" style="vertical-align: middle !important;">Users</span>
      <span *ngIf="router.url == '/groups'" style="vertical-align: middle !important;">User Groups</span>
      <span *ngIf="router.url == '/bookingList'" style="vertical-align: middle !important;">Bookings</span>
      <span *ngIf="router.url.includes('/booking/')" style="vertical-align: middle !important;">Booking</span>
      <span *ngIf="router.url == '/addElement'" style="vertical-align: middle !important;">New Supplier</span>
      <span *ngIf="router.url.includes('/addElement/')" style="vertical-align: middle !important;">New Element</span>
      <span *ngIf="router.url.includes('/element/')" style="vertical-align: middle !important;">Edit Element</span>
      <span *ngIf="router.url.includes('/package/')" style="vertical-align: middle !important;">Package Element</span>
      <span *ngIf="router.url.includes('/financialReports')" style="vertical-align: middle !important;">Financial Reports</span>
      <span *ngIf="router.url == '/supplierList'" style="vertical-align: middle !important;">Suppliers</span>
      <span *ngIf="router.url == '/supplementList'" style="vertical-align: middle !important;">Supplements</span>
      <span *ngIf="router.url == '/prf'" style="vertical-align: middle !important;">Payment Request Form</span>
      <span *ngIf="router.url == '/taps'" style="vertical-align: middle !important;">TAPS</span>
      <span *ngIf="router.url == '/banking'" style="vertical-align: middle !important;">Banking</span>

      <span [@inAnimation] *ngIf="!fellohNav && bookingReference != ''">
        <button style="text-align: center; width: auto; color: white; height: 36px; max-height: 40px; transform: scale(0.85);" mat-raised-button color="accent" matTooltip="Open Booking" matTooltipClass="line-normal-tooltip"
          routerLink="/booking/{{bookingReference}}" *ngIf="innerWidth > 677">
          <mat-icon style="vertical-align: middle; margin-left: -8px; margin-right: 2px;">sell</mat-icon><span style="font-family: Azeret Mono; font-size: 12px; margin-right: -7px; vertical-align: top;">{{bookingReference | uppercase }}</span>
        </button>

        <button style="text-align: center; width: auto; color: white; height: 36px; max-height: 40px; transform: scale(0.85);" mat-raised-button color="accent" routerLink="/booking/{{bookingReference}}" matTooltip="Open Booking"
          matTooltipClass="line-normal-tooltip" *ngIf="innerWidth <= 677 && innerWidth > 400">
          <mat-icon>sell</mat-icon>
        </button>

        <button class="topNavIconButtons" style="float: right;" mat-button (click)="closeBooking('fromButton')" matTooltip="Close Booking" matTooltipClass="line-normal-tooltip">
          <mat-icon>close</mat-icon>
        </button>
      </span>
    </span>

    <span style="margin: auto; height: 100%;" #iconsPanel [@inAnimation] *ngIf="!fellohNav && bookingReference != ''">
      <span [@inAnimation] *ngIf="navPanelWidthSum > 0" style="vertical-align: middle;">
        <svg-icon *ngIf="passengersDataText != ''" (click)="iconToBookingTab(bookingReference, 2)" src="assets/icons/group.svg" [svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5}" matTooltipPosition="below"
          matTooltip="{{passengersDataText}}" matTooltipClass="line-broken-tooltip" style="margin-right: 7.5px;"></svg-icon>

        <span *ngFor="let supplier of bookingCostings" style="margin-left: 7.5px; margin-right: 7.5px;">
          <span *ngIf="supplier.elementType != 'package'">
            <span *ngFor="let cruise of supplier['cruises']">
              <svg-icon *ngIf="cruise.cruiseStatus == 'enquiry'" src="assets/icons/cruise.svg" (click)="iconToBookingElement('cruise', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{cruise.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="cruise.cruiseStatus == 'booking'" src="assets/icons/cruise.svg" (click)="iconToBookingElement('cruise', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{cruise.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="cruise.cruiseStatus == 'cancelled'" src="assets/icons/cruise.svg" (click)="iconToBookingElement('cruise', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{cruise.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let flight of supplier['flights']">
              <svg-icon *ngIf="flight.flightStatus == 'enquiry'" src="assets/icons/plane.svg" (click)="iconToBookingElement('flight', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{flight.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="flight.flightStatus == 'booking'" src="assets/icons/plane.svg" (click)="iconToBookingElement('flight', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{flight.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="flight.flightStatus == 'cancelled'" src="assets/icons/plane.svg" (click)="iconToBookingElement('flight', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{flight.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let accom of supplier['accoms']">
              <svg-icon *ngIf="accom.accomStatus == 'enquiry'" src="assets/icons/hotel.svg" (click)="iconToBookingElement('accommodation', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{accom.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="accom.accomStatus == 'booking'" src="assets/icons/hotel.svg" (click)="iconToBookingElement('accommodation', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{accom.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="accom.accomStatus == 'cancelled'" src="assets/icons/hotel.svg" (click)="iconToBookingElement('accommodation', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{accom.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let misc of supplier['miscs']">
              <svg-icon *ngIf="misc.miscStatus == 'enquiry'" src="assets/icons/services.svg" (click)="iconToBookingElement('miscellaneous', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{misc.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="misc.miscStatus == 'booking'" src="assets/icons/services.svg" (click)="iconToBookingElement('miscellaneous', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{misc.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="misc.miscStatus == 'cancelled'" src="assets/icons/services.svg" (click)="iconToBookingElement('miscellaneous', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{misc.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let attraction of supplier['attractions']">
              <svg-icon *ngIf="attraction.attractionStatus == 'enquiry'" src="assets/icons/attraction.svg" (click)="iconToBookingElement('attraction', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{attraction.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="attraction.attractionStatus == 'booking'" src="assets/icons/attraction.svg" (click)="iconToBookingElement('attraction', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{attraction.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="attraction.attractionStatus == 'cancelled'" src="assets/icons/attraction.svg" (click)="iconToBookingElement('attraction', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{attraction.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let carHire of supplier['carhires']">
              <svg-icon *ngIf="carHire.carHireStatus == 'enquiry'" src="assets/icons/sports-car.svg" (click)="iconToBookingElement('carHire', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carHire.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="carHire.carHireStatus == 'booking'" src="assets/icons/sports-car.svg" (click)="iconToBookingElement('carHire', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carHire.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="carHire.carHireStatus == 'cancelled'" src="assets/icons/sports-car.svg" (click)="iconToBookingElement('carHire', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carHire.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let carPark of supplier['carparks']">
              <svg-icon *ngIf="carPark.carparkStatus == 'enquiry'" src="assets/icons/parked-car.svg" (click)="iconToBookingElement('carParking', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carPark.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="carPark.carparkStatus == 'booking'" src="assets/icons/parked-car.svg" (click)="iconToBookingElement('carParking', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carPark.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="carPark.carparkStatus == 'cancelled'" src="assets/icons/parked-car.svg" (click)="iconToBookingElement('carParking', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{carPark.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let train of supplier['trains']">
              <svg-icon *ngIf="train.trainStatus == 'enquiry'" src="assets/icons/train_symbol.svg" (click)="iconToBookingElement('train', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{train.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="train.trainStatus == 'booking'" src="assets/icons/train_symbol.svg" (click)="iconToBookingElement('train', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
                matTooltipPosition="below" matTooltip="{{train.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="train.trainStatus == 'cancelled'" src="assets/icons/train_symbol.svg" (click)="iconToBookingElement('train', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{train.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>

            <span *ngFor="let transfer of supplier['transfers']">
              <svg-icon *ngIf="transfer.transferStatus == 'enquiry'" src="assets/icons/transfer.svg" (click)="iconToBookingElement('transfer', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{transfer.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="transfer.transferStatus == 'booking'" src="assets/icons/transfer.svg" (click)="iconToBookingElement('transfer', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{transfer.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
              <svg-icon *ngIf="transfer.transferStatus == 'cancelled'" src="assets/icons/transfer.svg" (click)="iconToBookingElement('transfer', supplier)"
                [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{transfer.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            </span>
          </span>
          <span *ngIf="supplier.elementType == 'package'">
            <svg-icon *ngIf="supplier.elementStatus == 'enquiry'" src="assets/icons/package2.svg" (click)="iconToBookingElement('package', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#d1bf4d','marginTop.px':7.5,'margin-right.px':5}"
              matTooltipPosition="below" matTooltip="{{supplier['packages'][0].freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            <svg-icon *ngIf="supplier.elementStatus == 'booking'" src="assets/icons/package2.svg" (click)="iconToBookingElement('package', supplier)" [svgStyle]="{'height.px':25,'width.px':25,'fill':'#ffffff','marginTop.px':7.5,'margin-right.px':5}"
              matTooltipPosition="below" matTooltip="{{supplier['packages'][0].freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
            <svg-icon *ngIf="supplier.elementStatus == 'cancelled'" src="assets/icons/package2.svg" (click)="iconToBookingElement('package', supplier)"
              [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{supplier['packages'][0].freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
          </span>
        </span>

        <!--<span *ngFor="let insurance of bookingInsurance" style="margin-left: 7.5px; margin-right: 7.5px;">
          <svg-icon *ngIf="insurance.insuranceStatus == 'covered'" (click)="iconToBookingTab(bookingReference, 4)" src="assets/icons/insurance.svg"
            [svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{insurance.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
          <svg-icon *ngIf="insurance.insuranceStatus == 'cancelled'" (click)="iconToBookingTab(bookingReference, 4)" src="assets/icons/insurance.svg"
            [svgStyle]="{'height.px':25,'width.px':25,'fill':'#a6a6a6','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below" matTooltip="{{insurance.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
        </span>-->

        <span *ngFor="let receipt of bookingReceipts" style="margin-left: 7.5px; margin-right: 7.5px;">
          <svg-icon (click)="iconToBookingTab(bookingReference, 5)" src="assets/icons/piggy-bank.svg" [svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below"
            matTooltip="{{receipt.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
        </span>

        <span *ngFor="let payment of bookingPayments" style="margin-left: 7.5px; margin-right: 7.5px;">
          <svg-icon (click)="iconToBookingTab(bookingReference, 6)" src="assets/icons/pay.svg" [svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5}" matTooltipPosition="below"
            matTooltip="{{payment.freeText}}" matTooltipClass="line-broken-tooltip"></svg-icon>
        </span>

        <span *ngFor="let note of bookingNotes" style="margin-left: 7.5px; margin-right: 7.5px;">
          <svg-icon (click)="iconToBookingTab(bookingReference, 8)" *ngIf="note.important == 'yes'"
          src="assets/icons/note.svg"[svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5}"
          matTooltipPosition="below" matTooltip="{{note.createTS | date : 'dd.MM.y hh:mm a':'GMT+0'}}&#10;&#10;{{note.note}}"
          matTooltipClass="line-broken-tooltip-center"></svg-icon>

          <svg-icon (click)="iconToBookingTab(bookingReference, 8)" *ngIf="note.important == 'no'"
          src="assets/icons/note.svg"[svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5,'opacity':0.6}"
          matTooltipPosition="below" matTooltip="{{note.createTS | date : 'dd.MM.y hh:mm a':'GMT+0'}}&#10;&#10;{{note.note}}"
          matTooltipClass="line-broken-tooltip-center"></svg-icon>
        </span>

        <span *ngFor="let note of elementNotes" style="margin-left: 7.5px; margin-right: 7.5px;">
          <svg-icon (click)="iconToBookingTab(bookingReference, 8)" src="assets/icons/note.svg"
          [svgStyle]="{'height.px':25,'width.px':25,'fill':'white','marginTop.px':7.5,'margin-right.px':5,'opacity':0.6}"
          matTooltipPosition="below" matTooltip="{{note}}" matTooltipClass="line-broken-tooltip"></svg-icon>
        </span>
      </span>
    </span>

    <div class="box" #searchPanel>
      <div style="height: 40px;" *ngIf="!fellohNav || ['sinGSAdmin', 'sinGSstaff', 'trustee', 'tapsAdmin'].includes(userType) || session.getBranch().isPrfReg == 'yes' || session.getBranch().isOneTouchReg == 'yes'">
        <form mdbWavesEffect ngNativeValidate #searchBookingForm="ngForm" (ngSubmit)="searchBookingRef(searchBookingForm)" class="box">

          <!-- BOOKING USERS ONLY BELOW -->
          <span *ngIf="['memberManager', 'memberStaff', 'wcManager', 'wcMember'].includes(userType)">
            <button mat-button color="secondary" type="button" class="prefixButton" (click)="searchPrefixOnOff()">
              <mat-icon style="margin: auto; padding-top: 2px;" *ngIf="prefixOn">chevron_left</mat-icon>
              <mat-icon style="margin: auto; padding-top: 2px;" *ngIf="!prefixOn">chevron_right</mat-icon>
            </button>

            <mat-form-field class="searchBooking" floatLabel="never">
              <span matPrefix *ngIf="prefixOn">{{session.getUser().bookRefStr | uppercase }}-</span>
              <mat-label>Search</mat-label>
              <input matInput ngModel type="text" name="bookingReference" #bookingReference="ngModel" [maxlength]="maxBkLength">
            </mat-form-field>

            <span [ngClass]="{'button-active': bookingPop, 'button-inactive': !bookingPop}" (click)="bookingPopSwitch(!bookingPop)" class="custom-tooltip" *ngIf="!fellohNav">
              <mat-icon style="height: 20px; padding-top: 1px;">open_in_new</mat-icon>
              <span class="tooltip-text" *ngIf="!bookingPop">Overview</span>
              <span class="tooltip-text" *ngIf="bookingPop">Full Details</span>
            </span>

            <span *ngIf="innerWidth > 520">
              <button mat-button color="secondary" type="submit" class="topNavIconButtons"id="searchButton">
                <mat-icon style="margin: auto; padding-top: 2px;">search</mat-icon>
              </button>
            </span>
          </span>
          <!-- BOOKING USERS ONLY ABOVE -->

          <!-- SINGS ADMINS ONLY BELOW -->
          <span *ngIf="['sinGSAdmin', 'sinGSstaff', 'trustee', 'tapsAdmin'].includes(userType)" [style.margin-top]="prefixOn ? '2px' : '0.5px'">
            <button mat-button color="secondary" type="button" class="prefixButton" (click)="searchPrefixOnOff()">
              <mat-icon style="margin: auto; padding-top: 2px;" *ngIf="prefixOn">chevron_left</mat-icon>
              <mat-icon style="margin: auto; padding-top: 2px;" *ngIf="!prefixOn">chevron_right</mat-icon>
            </button>
            
            <span *ngIf="prefixOn">
              <mat-select ngModel [(ngModel)]="bookSearch" name="bookPrefix" #bookPrefix="ngModel" class="searchBookingPrefix">
                <mat-option [value]="'GTGS-'">GTGS-</mat-option>
                <mat-option [value]="'TTAS-'">TTAS-</mat-option>
                <mat-option [value]="'ttng-'">TTNG-</mat-option>
              </mat-select>
            </span>
            
            <mat-form-field [ngClass]="{'searchBookingShort': prefixOn, 'searchBooking': !prefixOn}" floatLabel="never">
              <mat-label>Search</mat-label>
              <input matInput ngModel type="text" name="bookingReference" #bookingReference="ngModel" [maxlength]="maxBkLength">
            </mat-form-field>

            <span [ngClass]="{'button-active': bookingPop, 'button-inactive': !bookingPop}" (click)="bookingPopSwitch(!bookingPop)" class="custom-tooltip" *ngIf="!fellohNav">
              <mat-icon style="height: 20px; padding-top: 1px;">open_in_new</mat-icon>
              <span class="tooltip-text" *ngIf="!bookingPop">Overview</span>
              <span class="tooltip-text" *ngIf="bookingPop">Full Details</span>
            </span>

            <span *ngIf="innerWidth > 520">
              <button mat-button color="secondary" type="submit" class="topNavIconButtons" id="searchButton">
                <mat-icon style="margin: auto; padding-top: 2px;">search</mat-icon>
              </button>
            </span>
          </span>
          <!-- SINGS ADMINS ONLY ABOVE -->
        </form>
      </div>

      <button mat-button color="secondary" type="submit" class="topNavIconButtons" id="uploadButton"
              (click)="openDialog('bookingList')" *ngIf="['wcManager', 'wcMember'].includes(userType) && (session.getBranch().isPrfReg == 'yes' || session.getBranch().isOneTouchReg == 'yes')">
        <mat-icon style="margin: auto;">library_books</mat-icon>
      </button>

      <button mat-button color="secondary" type="submit" class="topNavCalendarButton" (click)="openDialog('calendarInfo')"
              *ngIf="['sinGSAdmin', 'sinGSstaff', 'memberManager', 'memberStaff'].includes(userType) && innerWidth > 780">
        <mat-icon [style.margin-top]="['sinGSAdmin', 'sinGSstaff', 'trustee', 'tapsAdmin'].includes(userType) ? '-2px' : '0px'">calendar_month</mat-icon>
      </button>
      <button mat-button color="secondary" type="submit" class="topNavIconButtons" (click)="openDialog('userInfo')">
        <mat-icon [style.margin-top]="['sinGSAdmin', 'sinGSstaff', 'trustee', 'tapsAdmin'].includes(userType) ? '-2px' : '0px'">account_circle</mat-icon>
      </button>
      <button class="topNavIconButtons" style="float: right;" mat-button (click)="logout()">
        <mat-icon [style.margin-top]="['sinGSAdmin', 'sinGSstaff', 'trustee', 'tapsAdmin'].includes(userType) ? '-2px' : '0px'">logout</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div #menuBox [style.display]="menuOpened ? 'inline' : 'none'" class="onTop">
  <div *ngIf="!['supplier', 'tapsAdmin'].includes(userType) && fellohNav" class="fellohNav">
    <div [style.display]="menuMain ? 'inline' : 'none'" class="menuMain">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>short_text</mat-icon>Main Menu
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" [routerLink]="getRouterLink('new')" class="topNavMenuButton"
      *ngIf="!['sinGSAdmin', 'sinGSstaff', 'trustee'].includes(userType) && session.getBranch().fellohSetup == 'yes' && memberLive">
        <mat-icon>add_circle</mat-icon>New Payment Link
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" [routerLink]="getRouterLink('list')" class="topNavMenuButton" *ngIf="session.getBranch().fellohSetup == 'yes'">
        <mat-icon>view_list</mat-icon>Payment Links
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/fellohTxn" class="topNavMenuButton" *ngIf="session.getBranch().fellohSetup == 'yes'">
        <mat-icon>payments</mat-icon>Felloh Transactions
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/paymentsOut" class="topNavMenuButton" *ngIf="session.getBranch().fellohSetup == 'yes'">
        <mat-icon>credit_score</mat-icon>Disbursement
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/fellohCharges" class="topNavMenuButton" *ngIf="session.getBranch().fellohSetup == 'yes'">
        <mat-icon>percent</mat-icon>Payment Charges
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/customerList" *ngIf="userType != 'trustee'" class="topNavMenuButton">
        <mat-icon>people</mat-icon>CRM
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/banking" class="topNavMenuButton"
      *ngIf="canSeeBanking.test(currentTradeCode) && ['sinGSAdmin', 'sinGSstaff', 'memberManager', 'wcManager'].includes(userType)">
        <mat-icon>account_balance</mat-icon>Banking
      </button>

      <button #buttonReports mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/financialReports" class="topNavMenuButton">
        <mat-icon>description</mat-icon>Reports
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/prf" class="topNavMenuButton"
      *ngIf="(session.getUser().company == 'tta' && session.getBranch().isPrfReg == 'yes' && ['wcManager', 'memberManager'].includes(userType)) || ['sinGSAdmin', 'trustee'].includes(userType)">
        <mat-icon>request_quote</mat-icon>PRF
      </button>

      <button #buttonReports mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/taps" class="topNavMenuButton"
      *ngIf="(session.getUser().company == 'tta' && session.getBranch().isTapsReg == 'yes' && ['wcManager', 'memberManager'].includes(userType)) || userType == 'sinGSAdmin'">
        <mat-icon>payment</mat-icon>TAPS
      </button>

      <button #buttonSetup mat-menu-item (click)="menuReset(); menuSetup = true;" class="topNavMenuButton" *ngIf="['wcManager', 'sinGSAdmin'].includes(userType)">
        <mat-icon>settings</mat-icon>System Setup
      </button>

      <mat-checkbox *ngIf="['memberStaff', 'memberManager', 'sinGSstaff', 'sinGSAdmin'].includes(userType)" class="mat-menu-item" style="padding-left: 21px; width: 146px; background-color: #6973B5; color: white"
        [checked]="true" (click)="fellohNavOnOff()">
        <svg-icon src="assets/icons/felloh.svg" [svgStyle]="{'width.px':56,'height.px':28,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'19'}"></svg-icon>
      </mat-checkbox>
    </div>

    <div [style.display]="menuSetup ? 'inline' : 'none'" class="menuSetup">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>settings</mat-icon>System Setup
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['wcManager', 'sinGSAdmin'].includes(userType)" routerLink="/branchList" class="topNavMenuButton">
        <mat-icon>storefront</mat-icon>Branches
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['wcManager', 'sinGSAdmin'].includes(userType)" routerLink="/supplierList" class="topNavMenuButton">
        <mat-icon>business_center</mat-icon>Suppliers
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['wcManager', 'sinGSAdmin'].includes(userType)" routerLink="/userList" class="topNavMenuButton">
        <mat-icon>account_circle</mat-icon>Users
      </button>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>
  </div>

  <div *ngIf="['supplier', 'tapsAdmin'].includes(userType) && fellohNav" class="fellohNav">
    <div [style.display]="menuMain ? 'inline' : 'none'" class="menuMain">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>short_text</mat-icon>Main Menu
      </button>
      <button #buttonReports mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/taps" class="topNavMenuButton">
        <mat-icon>payment</mat-icon>TAPS
      </button>
      <button #buttonSetup mat-menu-item (click)="menuReset(); menuSetup = true;" class="topNavMenuButton">
        <mat-icon>settings</mat-icon>System Setup
      </button>

    </div>

    <div [style.display]="menuSetup ? 'inline' : 'none'" class="menuSetup">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>settings</mat-icon>System Setup
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="userType == 'tapsAdmin'" routerLink="/branchList" class="topNavMenuButton">
        <mat-icon>storefront</mat-icon>Branches
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/supplierList" class="topNavMenuButton">
        <mat-icon>business_center</mat-icon>Suppliers
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="userType == 'tapsAdmin'" routerLink="/userList" class="topNavMenuButton">
        <mat-icon>account_circle</mat-icon>Users
      </button>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>
  </div>

  <div *ngIf="!fellohNav" class="singsNav">
    <div [style.display]="menuMain ? 'inline' : 'none'" class="menuMain">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>short_text</mat-icon>Main Menu
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/" class="topNavMenuButton">
        <mat-icon>home</mat-icon>Dashboard
      </button>
      <button #buttonSource mat-menu-item (click)="menuReset(); usersBranches.length == 0 ? menuSource = true : menuGroup = true;" 
        class="topNavMenuButton" *ngIf="['memberManager', 'memberStaff'].includes(userType) && memberLive">
          <mat-icon>add</mat-icon>New Booking
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/bookingList" class="topNavMenuButton">
        <mat-icon>library_books</mat-icon>Bookings
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/customerList" class="topNavMenuButton">
        <mat-icon>people</mat-icon>CRM
      </button>
      <!-- <button #buttonMarketing mat-menu-item (click)="menuReset(); menuMarketing = true;" class="topNavMenuButton" *ngIf="['sinGSstaff', 'sinGSAdmin'].includes(userType)">
        <mat-icon>campaign</mat-icon>Marketing
      </button> -->
      <button #buttonReports mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/financialReports" class="topNavMenuButton">
        <mat-icon>description</mat-icon>Reports
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/banking" class="topNavMenuButton"
      *ngIf="canSeeBanking.test(currentTradeCode) && ['sinGSAdmin', 'sinGSstaff', 'memberManager', 'wcManager'].includes(userType)">
        <mat-icon>account_balance</mat-icon>Banking
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/prf" class="topNavMenuButton"
        *ngIf="(session.getUser().company == 'tta' && session.getBranch().isPrfReg == 'yes' && ['wcManager', 'memberManager'].includes(userType)) || userType == 'sinGSAdmin'">
        <mat-icon>request_quote</mat-icon>PRF
      </button>

      <button #buttonReports mat-menu-item (click)="menuReset(); menuOpened = false;" routerLink="/taps" class="topNavMenuButton"
        *ngIf="(session.getUser().company == 'tta' && session.getBranch().isTapsReg == 'yes' && ['wcManager', 'memberManager'].includes(userType)) || userType == 'sinGSAdmin' || session.getUser().tradeCode == 'GB001'">
        <mat-icon>payment</mat-icon>TAPS
      </button>

      <button #buttonSetup mat-menu-item (click)="menuReset(); menuSetup = true;" class="topNavMenuButton" *ngIf="['memberManager', 'sinGSAdmin'].includes(userType)">
        <mat-icon>settings</mat-icon>System Setup
      </button>

      <mat-checkbox *ngIf="fellohCheckbox" class="mat-menu-item" style="padding-left: 21px; width: 146px; background-color: #6973B5; color: white" [checked]="false" (click)="fellohNavOnOff()">
        <svg-icon src="assets/icons/felloh.svg" [svgStyle]="{'width.px':56,'height.px':28,'vertical-align':'middle','margin-top.px':'-5','fill':'rgb(255,255,255)','margin-left.px':'19'}"></svg-icon>
      </mat-checkbox>
    </div>

    <div [style.display]="menuGroup ? 'inline' : 'none'" class="menuGroup">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>storefront</mat-icon>Booking Branch
      </button>

      <mat-form-field appearance="fill" class="filterSelectList" style="font-size: 15px">
        <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="filterString" (keyup)="filterSelect()">
      </mat-form-field>

      <div class="groupBranchList">
        <ng-container *ngFor="let branch of usersBranchesFiltered">
          <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuSource = true; bookingTradeCode = branch.tradeCode; filterString = ''; filterSelect();" class="topNavMenuButton">
            <span class="branchName">
              <mat-icon>store</mat-icon>{{branch.fullName}}
            </span>
          </button>
        </ng-container>
      </div>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>

    <div [style.display]="menuSource ? 'inline' : 'none'" class="menuSource">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>travel_explore</mat-icon>Booking Source
      </button>

      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Google AdWords');" class="topNavMenuButton">
        <mat-icon>desktop_windows</mat-icon>Google AdWords
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('New Customer');" class="topNavMenuButton">
        <mat-icon>face</mat-icon>New Customer
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Promotions');" class="topNavMenuButton">
        <mat-icon>local_offer</mat-icon>Promotions
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Referral');" class="topNavMenuButton">
        <mat-icon>reviews</mat-icon>Referral
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Repeat Business');" class="topNavMenuButton">
        <mat-icon>repeat</mat-icon>Repeat Business
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Social Media');" class="topNavMenuButton">
        <mat-icon>share</mat-icon>Social Media
      </button>
      <button mat-menu-item style="width: 100%;" (click)="menuReset(); menuOpened = false; createBooking('Website Enquiry');" class="topNavMenuButton">
        <mat-icon>public</mat-icon>Website Enquiry
      </button>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>

    <div [style.display]="menuMarketing ? 'inline' : 'none'" class="menuMarketing">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>campaign</mat-icon>Marketing
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['sinGSstaff', 'sinGSAdmin'].includes(userType)" routerLink="/marketing/config" class="topNavMenuButton">
        <mat-icon>settings</mat-icon>Configuration
      </button>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button>
    </div>

    <div [style.display]="menuSetup ? 'inline' : 'none'" class="menuSetup">
      <button mat-menu-item class="topNavMenuButtonDisabled" [disabled]="true">
        <mat-icon>settings</mat-icon>System Setup
      </button>

      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['sinGSAdmin', 'memberManager'].includes(userType)" routerLink="/branchList" class="topNavMenuButton">
        <mat-icon>storefront</mat-icon>Branches
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="userType == 'sinGSAdmin'" routerLink="/groups" class="topNavMenuButton">
        <mat-icon>groups</mat-icon>Groups
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['sinGSAdmin', 'memberManager'].includes(userType)" routerLink="/supplierList" class="topNavMenuButton">
        <mat-icon>business_center</mat-icon>Suppliers
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="userType == 'sinGSAdmin'" routerLink="/supplementList" class="topNavMenuButton">
        <mat-icon>shopping_basket</mat-icon>Supplements
      </button>
      <button mat-menu-item (click)="menuReset(); menuOpened = false;" *ngIf="['sinGSAdmin', 'memberManager'].includes(userType)" routerLink="/userList" class="topNavMenuButton">
        <mat-icon>account_circle</mat-icon>Users
      </button>

      <button mat-menu-item style="width: 100%; border-bottom-right-radius: 10px;" (click)="menuReset(); menuMain = true;" class="topNavMenuButton">
        <mat-icon>arrow_back</mat-icon>Back
      </button> 
    </div>
  </div>
</div>

<div [@inAnimation] class="container">
  <div *ngIf="!parentLoaded" class="loading-indicator">
    <mat-spinner></mat-spinner>
  </div>
  <router-outlet *ngIf="parentLoaded"></router-outlet>
</div>

<ng-template #userInfoBox>
  <mat-dialog-content style="display: contents !important;">
    <mat-icon *ngIf="!goodBrowser" style="float: right;" matTooltipClass="line-broken-tooltip" matTooltip="{{browserTooltip}}">warning</mat-icon>

    <div class="ownExpansionHeader">
      <div class="headerTopLeft" style="width: 100%; display: flex; justify-content: flex-start;">
        <div>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="accountDetails" (click)="accountDetails = false;">
            <mat-icon>expand_less</mat-icon>
          </button>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!accountDetails" (click)="accountDetails = true;">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <div>
          <h3 style="display: inline;">Account Details</h3>
        </div>
      </div>
    </div>

    <div [@customExpansionDetails]="accountDetails == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
      <h4 style="margin-top: 0px; margin-left: 2%;">
        <mat-icon style="margin-right: 5px;">account_circle</mat-icon>{{session.getUser().fullName}}<br>
        <mat-icon style="margin-right: 5px;">email</mat-icon>{{session.getUser().email}}<br>
        <mat-icon style="margin-right: 5px;">storefront</mat-icon>{{session.getUser().tradeCode}} {{session.getBranch().branchName}}
      </h4>
    </div>

    <div class="ownExpansionHeader" *ngIf="usersBranches.length > 0">
      <div class="headerTopLeft" style="width: 100%; display: flex; justify-content: flex-start;">
        <div>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="accountGroup" (click)="accountGroup = false;">
            <mat-icon>expand_less</mat-icon>
          </button>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!accountGroup" (click)="accountGroup = true;">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <div>
          <h3 style="display: inline;">Change Branch</h3>
        </div>
      </div>
    </div>

    <div [@customExpansionDetails]="accountGroup == true ? 'expanded' : 'collapsed'" class="ownExpansionBody" *ngIf="usersBranches.length > 0">
      <h4 style="margin-top: 0px; margin-left: 2%;">Branch which will be used to auto-load data in SinGS.<br>
        Please click below to expand all available options.<br></h4>
      <div style="text-align: center;">
        <mat-form-field class="accountField">
          <mat-select ngModel [(ngModel)]="currentTradeCode" name="tradeCode" #tradeCode="ngModel" placeholder="Trade Code" (selectionChange)="setSelfTradeCode($event.value)" style="margin-top: 7.5px;">
            <ng-container *ngFor="let branch of usersBranches">
              <mat-option *ngIf="branch.tradeCode == currentTradeCode" [value]="branch.tradeCode" [disabled]="true">{{branch.fullName}}</mat-option>
              <mat-option *ngIf="branch.tradeCode != currentTradeCode" [value]="branch.tradeCode">{{branch.fullName}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="ownExpansionHeader">
      <div class="headerTopLeft" style="width: 100%; display: flex; justify-content: flex-start;">
        <div>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="accountPassword" (click)="accountPassword = false;">
            <mat-icon>expand_less</mat-icon>
          </button>
          <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!accountPassword" (click)="accountPassword = true;">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <div>
          <h3 style="display: inline;">Change Password</h3>
        </div>
      </div>
    </div>

    <div [@customExpansionDetails]="accountPassword == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
      <form ngNativeValidate #resetPasswordForm="ngForm" (ngSubmit)="changeUserPassword(resetPasswordForm)" class="userDialogForm">
        <h4 *ngIf="passwordExpires != null" style="color: red; margin-bottom: 0px;">Current password expires on {{ passwordExpires | date : "dd.MM.y hh:mm a":"GMT+0" }}</h4>
        <mat-form-field class="accountField">
          <input matInput ngModel required placeholder="Password" maxlength="20" minlength="5" name="password" #password="ngModel" type="password" (input)="validatePassword($event.target)">
        </mat-form-field>

        <br>

        <mat-form-field class="accountField">
          <input matInput ngModel required placeholder="Password Repeat" maxlength="20" minlength="5" name="passwordRepeat" #passwordRepeat="ngModel" type="password">
        </mat-form-field>

        <br>

        <button mat-raised-button color="accent" type="submit" [disabled]="resetPasswordForm.invalid || passwordMessage != ''" class="accountButtons" matTooltip="Reset" matTooltipClass="line-normal-tooltip">
          <mat-icon>restart_alt</mat-icon>
        </button>

        <p style="color: red;" *ngIf="passwordMessage != ''"><b>{{passwordMessage}}</b></p>
      </form>
    </div>

    <div *ngIf="['sinGSAdmin', 'sinGSstaff', 'memberManager', 'memberStaff'].includes(userType)">
      <div class="ownExpansionHeader">
        <div class="headerTopLeft" style="width: 100%; display: flex; justify-content: flex-start;">
          <div>
            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="accountSignature" (click)="accountSignature = false;">
              <mat-icon>expand_less</mat-icon>
            </button>
            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!accountSignature" (click)="accountSignature = true;">
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
          <div>
            <h3 style="display: inline;">Virtual Signature</h3>
          </div>
        </div>
      </div>

      <div [@customExpansionDetails]="accountSignature == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
        <img [src]="'https://sings-media.s3.eu-west-2.amazonaws.com/signature/' + userSignatureURL" style="width: 500px; height: 120px; border: 1px dashed #9a9a9a; text-indent: -10000px;">
        
        <div>
          <input #csvInput1 hidden="true" type="file" onclick="this.value=null" (change)="uploadSignature($event)" accept=".png,.jpeg,.jpg" multiple="false"/>
          <button mat-button type="button" (click)="csvInput1.click()" color="primary" style="width:100%;" matTooltip="Recommended dimensions: 500x120px" matTooltipClass="line-normal-tooltip">
            <mat-icon>file_upload</mat-icon> Upload Signature
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="['sinGSAdmin', 'sinGSstaff', 'memberManager', 'memberStaff'].includes(userType)">
      <div class="ownExpansionHeader">
        <div class="headerTopLeft" style="width: 100%; display: flex; justify-content: flex-start;">
          <div>
            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="accountGoogle" (click)="accountGoogle = false;">
              <mat-icon>expand_less</mat-icon>
            </button>
            <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!accountGoogle" (click)="accountGoogle = true;">
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
          <div>
            <h3 style="display: inline;">Google Calendar</h3>
          </div>
        </div>
      </div>

      <div [@customExpansionDetails]="accountGoogle == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
        <form ngNativeValidate #updateGoogleAccInfoForm="ngForm" (ngSubmit)="updateGoogleAccInfo(updateGoogleAccInfoForm)" style="text-align: center;" class="userDialogForm">
          <mat-form-field class="accountField">
            <input matInput ngModel placeholder="Calendar ID" name="googleCalID" #googleCalID="ngModel" maxlength="200" [(ngModel)]="session.getUser().googleCalID"/>
          </mat-form-field>

          <br>

          <mat-form-field class="accountField">
            <input matInput ngModel placeholder="Calendar API Key" name="googleCalAPIkey" #googleCalAPIkey="ngModel" maxlength="200" [(ngModel)]="session.getUser().googleCalAPIkey"/>
          </mat-form-field>

          <br>

          <button mat-raised-button color="accent" type="submit" [disabled]="updateGoogleAccInfoForm.invalid" class="accountButtons" matTooltip="Update" matTooltipClass="line-normal-tooltip">
            <mat-icon>edit</mat-icon>
          </button>
        </form>
      </div>
    </div>
    
    <br />
    <p class="dialogBackButton">
      <button mat-raised-button color="primary" matDialogClose>Back</button>
    </p>
  </mat-dialog-content>
</ng-template>

<ng-template #userCalendarBox>
  <mat-dialog-content style="max-height: unset; overflow: unset; padding: unset !important; margin-top: -24px; margin-bottom: -44px; background-color: #F9F9F9">

  <div style="width: 100%; margin-bottom: 20px;">

    <div>
      <div class="ownExpansionHeader">
        <div class="headerTopLeft" style="width: 100%; justify-content: space-between;">
          <div class="calendarHeader">
            <div class="calendarButtons">
              <button type="button" (click)="closeOpenMonthViewDay()" mat-button color="primary" mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="calendarViewCurrent" [disableRipple]="true">
                Previous
              </button>
              <button type="button" mat-button color="accent" mwlCalendarToday [(viewDate)]="viewDate" [disableRipple]="true">
                Today
              </button>
              <button type="button" (click)="closeOpenMonthViewDay()" mat-button color="primary" mwlCalendarNextView [(viewDate)]="viewDate" [view]="calendarViewCurrent" [disableRipple]="true">
                Next
              </button>
            </div>
        
            <div style="width: 20%; text-align: center;">
              <h3>{{ viewDate | calendarDate:(calendarViewCurrent + 'ViewTitle'):'en' }}</h3>
            </div>
        
            <div class="calendarButtons">
              <button type="button" (click)="setCalendarView(CalendarView.Month)" mat-button color="primary"
              [class.active]="calendarViewCurrent === CalendarView.Month" [disableRipple]="true">
                Month
              </button>
              <button type="button" (click)="setCalendarView(CalendarView.Week)" mat-button color="primary" [disabled]="!googleCalendarOpen"
              [class.active]="calendarViewCurrent === CalendarView.Week" [disableRipple]="true">
                Week
              </button>
              <button type="button" (click)="setCalendarView(CalendarView.Day)" mat-button color="primary" [disabled]="!googleCalendarOpen"
              [class.active]="calendarViewCurrent === CalendarView.Day" [disableRipple]="true">
                Day
              </button>
            </div>
          </div>
        </div>

      </div>

      <div>
        <mat-toolbar style="height: 40px !important; padding: 0px; margin-bottom: 10px; background-color: #f9f9f9;">
          <div style="width: 100%; display: flex;">
            <div style="width: 100%; text-align: center;">
              <button class="navButtonsDialog" mat-button (click)="closeOpenMonthViewDay(); googleCalendarOpen = true"
              [disableRipple]="true" [disabled]="googleCalendarOpen">
                <mat-icon style="margin-top: -2px;">calendar_month</mat-icon> Google Calendar
              </button>
            </div>
            <div style="width: 100%; text-align: center;">
              <button class="navButtonsDialog" mat-button (click)="getBookingEvents();"
              [disableRipple]="true" [disabled]="!googleCalendarOpen">
                <mat-icon style="margin-top: -2px;">event</mat-icon> Booking Calendar
              </button>
            </div>
          </div>
        </mat-toolbar>
      </div>

      <div class="ownExpansionBody" style="overflow: auto; height: 657px;">
        
        <div *ngIf="googleCalendarOpen" [ngSwitch]="calendarViewCurrent" style="width: 74vw; max-width: unset; max-height: unset; margin-bottom: -20px;">

          <!--<div class="calNotFound" *ngIf="!calendarExists">
            <h1 style="text-align: center; color: orangered;width: 98%;margin: auto;">
              Google Calendar has not been found
            </h1>
          </div>-->

          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="clickGoogleEvent($event.event)">
          </mwl-calendar-month-view>
          
          <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [dayStartHour]="7"
            [dayStartMinute]="0"
            [dayEndHour]="21"
            [dayEndMinute]="0"
            (eventClicked)="clickGoogleEvent($event.event)">
          </mwl-calendar-week-view>
      
          <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [dayStartHour]="7"
            [dayStartMinute]="0"
            [dayEndHour]="21"
            [dayEndMinute]="0"
            (eventClicked)="clickGoogleEvent($event.event)">
          </mwl-calendar-day-view>
        </div>

        <div *ngIf="!googleCalendarOpen" [ngSwitch]="calendarViewCurrent" style="width: 74vw; max-width: unset; max-height: unset; margin-bottom: -20px;">

          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="bookingEvents"
            [activeDayIsOpen]="activeDayIsOpen"
            [openDayEventsTemplate]="customOpenDayEventsTemplate"
            (dayClicked)="dayClicked($event.day)">
          </mwl-calendar-month-view>

          <ng-template #customOpenDayEventsTemplate let-events="events" let-isOpen="isOpen">
            <div class="cal-open-day-events" [@collapse] *ngIf="isOpen" style="display: flex; flex-wrap: wrap; flex-direction: row; justify-content: flex-start; align-items: center;">
              <div *ngFor="let event of events" [ngClass]="event?.cssClass" [ngStyle]="{'color': event.color.primary}" style="margin-right: 15px; margin-left: 15px;">
                <div (click)="clickCalendarEvent(event.bookingReference)" style="display: flex; align-items: center;">
                  <svg-icon *ngIf="event?.elementType == 'Attraction'" src="assets/icons/attraction.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Cruise'" src="assets/icons/cruise.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Flight'" src="assets/icons/plane.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Hotel'" src="assets/icons/hotel.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Miscellaneous'" src="assets/icons/services.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Train'" src="assets/icons/train_symbol.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Car Hire'" src="assets/icons/sports-car.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Car Park'" src="assets/icons/parked-car.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <svg-icon *ngIf="event?.elementType == 'Transfer'" src="assets/icons/transfer.svg"
                    [svgStyle]="{'height.px':25,'width.px':25,'fill':event.color.primary,'margin-right.px':7.5}">
                  </svg-icon>
                  <mat-icon *ngIf="event?.elementType == 'Booking'" style="vertical-align: middle; margin-right: 5px;">sell
                  </mat-icon>
                  <span [matTooltip]="event?.description" matTooltipClass="line-broken-tooltip-bigger" matTooltipPosition="above"
                        class="eventDescription">{{event.bookingReference}}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        
      </div>
    </div>
  </div>
  </mat-dialog-content>
</ng-template>

<ng-template #systemMessageBox>
  <mat-dialog-content>
    <div *ngIf="systemMessage != ''" class="successMessage">
      <p>{{systemMessage}}</p>
      <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
    </div>
  </mat-dialog-content>
</ng-template>

<ng-template #bookingExternalBox style="padding: 0px !important;">
  <app-booking-external [bookingOperation]="outOptionExternal" [bookingReference]="bookingRefExternal" [userTypeOut]="userType"></app-booking-external>
</ng-template>

<div *ngIf="!memberLive" class="eodDiv">Read-Only Mode Activated</div>