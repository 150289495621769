<div *ngIf="!pageLoaded" class="loading-indicator">
  <mat-spinner style="margin:0 auto;"></mat-spinner>
</div>

<div [hidden]="true">
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>

<mat-card-content>
  <mat-toolbar style="background-color: #717FDA; height: 40px; padding: 0px;">
    <div [@inAnimation] *ngIf="innerWidth > 1024 && haveAccess" style="width: 100%;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('myLast')" mat-button [disabled]="myLast5" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon>format_list_numbered</mat-icon> My Last 5
        </button>
        <button class="navButtons" (click)="switchView('performanceView')" mat-button [disabled]="performanceView">
          <mat-icon>trending_up</mat-icon> Performance
        </button>
        <button class="navButtons" (click)="switchView('monthlyReturnView')" mat-button [disabled]="monthlyReturnView" *ngIf="['memberManager', 'sinGSAdmin', 'sinGSstaff'].includes(userType)">
          <mat-icon *ngIf="monthlyReturnSummary?.status == 'New'" matBadge="!" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">event_repeat</mat-icon>
          <mat-icon *ngIf="monthlyReturnSummary?.status != 'New'">event_repeat</mat-icon>
          Monthly Return
        </button>
        <button class="navButtons" (click)="switchView('dailyBanking')" mat-button [disabled]="dailyBanking" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon *ngIf="badgeValues.dailyNo > 0" matBadge="{{badgeValues.dailyNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">check_box</mat-icon>
          <mat-icon *ngIf="badgeValues.dailyNo == 0" matBadge="{{badgeValues.dailyNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">check_box</mat-icon>
          Daily Banking
        </button>
        <button class="navButtons" (click)="switchView('reminders')" mat-button [disabled]="reminders" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon *ngIf="badgeValues.reminderNo > 0" matBadge="{{badgeValues.reminderNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">notifications_active</mat-icon>
          <mat-icon *ngIf="badgeValues.reminderNo == 0" matBadge="{{badgeValues.reminderNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">notifications_active</mat-icon>
          Reminders
        </button>
      </div>
    </div>

    <div [@inAnimation] *ngIf="innerWidth < 1025 && haveAccess" style="width: 100%">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button class="navButtons" (click)="switchView('myLast')" mat-button [disabled]="myLast5" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon>format_list_numbered</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('performanceView')" mat-button [disabled]="performanceView">
          <mat-icon>trending_up</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('monthlyReturnView')" mat-button [disabled]="monthlyReturnView" *ngIf="['memberManager', 'sinGSAdmin', 'sinGSstaff'].includes(userType)">
          <mat-icon *ngIf="monthlyReturnSummary?.status == 'New'" matBadge="!" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">event_repeat</mat-icon>
          <mat-icon *ngIf="monthlyReturnSummary?.status != 'New'">event_repeat</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('dailyBanking')" mat-button [disabled]="dailyBanking" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon *ngIf="badgeValues.dailyNo > 0" matBadge="{{badgeValues.dailyNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">check_box</mat-icon>
          <mat-icon *ngIf="badgeValues.dailyNo == 0" matBadge="{{badgeValues.dailyNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">check_box</mat-icon>
        </button>
        <button class="navButtons" (click)="switchView('reminders')" mat-button [disabled]="reminders" *ngIf="['memberManager', 'memberStaff'].includes(userType)">
          <mat-icon *ngIf="badgeValues.reminderNo > 0" matBadge="{{badgeValues.reminderNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent">notifications_active</mat-icon>
          <mat-icon *ngIf="badgeValues.reminderNo == 0" matBadge="{{badgeValues.reminderNo}}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="primary">notifications_active</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #drawer mode="side" closed></mat-sidenav>

    <mat-sidenav-content>
      <mat-card [style.overflow]="'auto'">
        <div class="sideNavContent" *ngIf="haveAccess">
          <h1 fxLayout="row" fxLayoutAlign="start center" style="float: left;">
            <mat-icon color="primary" class="navToggleIcon">chevron_right</mat-icon>
            <span *ngIf="myLast5">My Last 5</span>
            <span *ngIf="dailyBanking">Daily Banking</span>
            <span *ngIf="reminders">Reminders</span>
            <span *ngIf="performanceView">Performance</span>
            <span *ngIf="monthlyReturnView">Monthly Return</span>
          </h1>

          <mat-form-field *ngIf="branchList.length > 1" class="branchEdit" style="max-width: unset; width: 322.5px; padding-top: 5px; margin-bottom: -10px; float: right; display: block-inline;">
            <mat-select #tradeCode="ngModel" required name="tradeCode" [(ngModel)]="currentRequest.tradeCode" placeholder="Branch" (selectionChange)="changeBranch($event.value)">
              <mat-option *ngFor="let branch of branchList" [value]="branch.tradeCode">
                {{branch.fullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="divider" style="clear: both;">
            <mat-divider></mat-divider>
          </div>

          <div [@inAnimation] *ngIf="myLast5">

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansion5bookings" (click)="expansion5bookings = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansion5bookings" (click)="expansion5bookings = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  My Last 5 Bookings
                </div>
              </div>

              <div [@customExpansionDetails]="expansion5bookings == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div fxLayout="row" fxLayoutAlign="space-around start">
                  <div *ngIf="bookingsData.data.length > 0" style="width: 70%; display: inline-block;">
                    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="bookingsData">

                      <ng-container matColumnDef="bookingRef">
                        <mat-header-cell *matHeaderCellDef>
                          <span class="clickBkgRef" (click)="showExtRefSwitch()" [matTooltip]="!showExtReference ? 'Switch to your references' : 'Switch to Sings references'">
                            Booking Reference
                          </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let booking">
                          <a (click)="goToBooking(booking.bookingReference, 'normal')" style="font-family: Azeret Mono;">
                            <span *ngIf="!showExtReference">{{ booking.bookingReference | uppercase }}</span>
                            <span *ngIf="showExtReference">{{ booking.extBookingSource }}</span>
                          </a>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="tradingName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.branchName }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Booked </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.bookingDate | date:'dd.MM.yyyy' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="departureDate">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.deptDate | date:'dd.MM.yyyy' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="leadName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Name </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.leadName }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="grossVal">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Gross </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.custPrice | currency:'GBP' }} </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row class="rowNotClick" *matRowDef="let row; columns : displayedColumns;"></mat-row>
                    </mat-table>
                  </div>

                  <div *ngIf="bookingsData.data.length > 0" class="apexChartZoom" style="width: 30%; display: inline-block;">
                    <apx-chart id="chart" [series]="bookingChart.series" [chart]="bookingChart.chart" [tooltip]="bookingChart.tooltip" [title]="bookingChart.title" [yaxis]="bookingChart.yaxis" [xaxis]="bookingChart.xaxis"
                      [dataLabels]="bookingChart.dataLabels" [stroke]="bookingChart.stroke">
                    </apx-chart>
                  </div>
                </div>

                <div *ngIf="bookingsData.data.length == 0">
                  <h3 *ngIf="pageLoaded">There are no bookings to show</h3>
                  <h3 *ngIf="!pageLoaded">Please wait..</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="expansion5bookings == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" style="clear: both;" (click)="expansion5bookings = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 20px; clear: both;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansion5enquiries" (click)="expansion5enquiries = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansion5enquiries" (click)="expansion5enquiries = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  My Last 5 Enquiries
                </div>
              </div>

              <div [@customExpansionDetails]="expansion5enquiries == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">

                <div fxLayout="row" fxLayoutAlign="space-around start">
                  <div *ngIf="enquiriesData.data.length > 0" style="width: 70%; display: inline-block;">
                    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="enquiriesData">

                      <ng-container matColumnDef="bookingRef">
                        <mat-header-cell *matHeaderCellDef>
                          <span class="clickBkgRef" (click)="showExtRefSwitch()" [matTooltip]="!showExtReference ? 'Switch to your references' : 'Switch to Sings references'">
                            Booking Reference
                          </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let booking">
                          <a (click)="goToBooking(booking.bookingReference, 'normal')" style="font-family: Azeret Mono;">
                            <span *ngIf="!showExtReference">{{ booking.bookingReference | uppercase }}</span>
                            <span *ngIf="showExtReference">{{ booking.extBookingSource }}</span>
                          </a>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="tradingName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                        <mat-cell *matCellDef="let booking" class="centerTable"> {{ booking.branchName }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="departureDate">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                        <mat-cell *matCellDef="let enquiry" class="centerTable"> {{ enquiry.deptDate | date:'dd.MM.yyyy' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="leadName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Name </mat-header-cell>
                        <mat-cell *matCellDef="let enquiry" class="centerTable"> {{ enquiry.leadName }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="grossVal">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Gross </mat-header-cell>
                        <mat-cell *matCellDef="let enquiry" class="centerTable"> {{ enquiry.custPrice | currency:'GBP' }} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="created">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Created </mat-header-cell>
                        <mat-cell *matCellDef="let enquiry" class="centerTable"> {{ enquiry.createTS | date:'dd.MM.yyyy' }} </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row class="rowNotClick" *matRowDef="let row; columns : displayedColumns;"></mat-row>
                    </mat-table>
                  </div>

                  <div *ngIf="enquiriesData.data.length > 0" class="apexChartZoom" style="width: 30%; display: inline-block;">
                    <apx-chart id="chart" [series]="enquiryChart.series" [chart]="enquiryChart.chart" [tooltip]="enquiryChart.tooltip" [title]="enquiryChart.title" [yaxis]="enquiryChart.yaxis" [xaxis]="enquiryChart.xaxis"
                      [dataLabels]="enquiryChart.dataLabels" [stroke]="enquiryChart.stroke" [fill]="enquiryChart.fill" [colors]="enquiryChart.colors">
                    </apx-chart>
                  </div>
                </div>

                <div *ngIf="enquiriesData.data.length == 0">
                  <h3 *ngIf="pageLoaded">There are no enquiries to show</h3>
                  <h3 *ngIf="!pageLoaded">Please wait..</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="expansion5enquiries == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansion5enquiries = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

          </div>

          <div [@inAnimation] *ngIf="performanceView">

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionPerformanceView" (click)="expansionPerformanceView = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionPerformanceView" (click)="expansionPerformanceView = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Performance
                </div>
              </div>

              <div [@customExpansionDetails]="expansionPerformanceView == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div style="text-align: center;">
                  <mat-form-field *ngIf="['sinGSAdmin', 'sinGSstaff'].includes(userType)" class="branchEdit" style="max-width: unset; width: 322.5px; padding-top: 5px; margin-bottom: -10px; display: block-inline;">
                    <mat-select [(ngModel)]="selBranchAdmin" #tradeCode="ngModel" required name="tradeCode" placeholder="Branch"
                    (selectionChange)="populateYtdChartNormal($event.value); branchFilterStr = ''; filterMonthly()" (click)="branchFilterStr = ''; filterMonthly()">
                      <div class="sticky-search-container" style="margin-bottom: unset !important;">
                        <mat-form-field appearance="fill" class="filterSelectList">
                            <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="branchFilterStr" (keyup)="filterMonthly()" autocomplete="off">
                        </mat-form-field>
                      </div>
                      
                      <mat-option *ngFor="let branch of performanceList | keyvalue" [value]="branch.value">
                        {{branch.key}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-evenly center" style="text-align: center;">
                  <div fxFlex="28.33">
                    <h2>Last Month<br />
                      <span *ngIf="ytdChartDetails.lastMonthSales != 0">
                        {{ ytdChartDetails.lastMonthSales | currency:'GBP' }}
                      </span>
                      <span *ngIf="ytdChartDetails.lastMonthSales == 0">
                        N/A
                      </span>
                    </h2>
                  </div>

                  <div fxFlex="5" style="text-align: center;">
                    <h2 *ngIf="ytdChartDetails.lastMonthSales != 0">
                      <span *ngIf="ytdChartDetails.currentMonthSales >= ytdChartDetails.lastMonthSales">
                        <mat-icon style="color: green;">arrow_upward</mat-icon>
                      </span>
                      <span *ngIf="ytdChartDetails.currentMonthSales < ytdChartDetails.lastMonthSales">
                        <mat-icon style="color: red;">arrow_downward</mat-icon>
                      </span><br />
                      {{(ytdChartDetails.currentMonthSales / ytdChartDetails.lastMonthSales) - 1 | percent : '1.2'}}
                    </h2>
                  </div>

                  <div fxFlex="33.33">
                    <h1>Current Month Sales<br />
                      {{ ytdChartDetails.currentMonthSales | currency:'GBP' }}
                    </h1>
                  </div>

                  <div fxFlex="5" style="text-align: center;">
                    <h2 *ngIf="ytdChartDetails.lastYearSales != 0">
                      <span *ngIf="ytdChartDetails.currentMonthSales >= ytdChartDetails.lastYearSales">
                        <mat-icon style="color: green;">arrow_upward</mat-icon>
                      </span>
                      <span *ngIf="ytdChartDetails.currentMonthSales < ytdChartDetails.lastYearSales">
                        <mat-icon style="color: red;">arrow_downward</mat-icon>
                      </span><br />
                      {{ (ytdChartDetails.currentMonthSales / ytdChartDetails.lastYearSales) - 1 | percent : '1.2' }}
                    </h2>
                  </div>

                  <div fxFlex="28.33">
                    <h2>Last Year<br />
                      <span *ngIf="ytdChartDetails.lastYearSales != 0">
                        {{ ytdChartDetails.lastYearSales | currency:'GBP' }}
                      </span>
                      <span *ngIf="ytdChartDetails.lastYearSales == 0">
                        N/A
                      </span>
                    </h2>
                  </div>
                </div>

                <div class="apexChartZoom">
                  <apx-chart id="chartDonut" [series]="ytdMoneyChart.series" [chart]="ytdMoneyChart.chart" [fill]="ytdMoneyChart.fill" [labels]="ytdMoneyChart.labels" [dataLabels]="ytdMoneyChart.dataLabels" [plotOptions]="ytdMoneyChart.plotOptions"
                    [xaxis]="ytdMoneyChart.xaxis" [yaxis]="ytdMoneyChart.yaxis" [legend]="ytdMoneyChart.legend" [stroke]="ytdMoneyChart.stroke" [colors]="ytdMoneyChart.colors" [title]="ytdMoneyChart.title"></apx-chart>
                </div>

                <div style="text-align: right; margin-right: 30px;">
                  <h4>Last updated {{yesterDate}} at 11:00pm</h4>
                </div>

                <div class="apexChartZoom">
                  <apx-chart id="chartDonut" [series]="ytdPaxBookChart.series" [chart]="ytdPaxBookChart.chart" [fill]="ytdPaxBookChart.fill" [labels]="ytdPaxBookChart.labels" [dataLabels]="ytdPaxBookChart.dataLabels"
                    [plotOptions]="ytdPaxBookChart.plotOptions" [xaxis]="ytdPaxBookChart.xaxis" [yaxis]="ytdPaxBookChart.yaxis" [legend]="ytdPaxBookChart.legend" [stroke]="ytdPaxBookChart.stroke" [colors]="ytdPaxBookChart.colors"
                    [title]="ytdPaxBookChart.title"></apx-chart>
                </div>

                <div style="text-align: right; margin-right: 20px;">
                  <h4>Last updated {{yesterDate}} at 11:00pm</h4>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionPerformanceView == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionPerformanceView = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

          </div>

          <div [@inAnimation] *ngIf="dailyBanking">

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width: 90%;">
                  <div style="display: flex; align-items: center; margin-right: 50px;">
                    <button type="button" mat-icon-button style="margin-left: 5px;" *ngIf="expansionDailyBanking" (click)="expansionDailyBanking = false;">
                      <mat-icon>expand_less</mat-icon>
                    </button>
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionDailyBanking" (click)="expansionDailyBanking = true;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    Receipt Date Range
                  </div>

                  <div style="display: flex; align-items: center;">
                    <div style="display: flex;">
                      <div>
                        <mat-datepicker-toggle matSuffix [for]="receiptFrom" style="color: black;"></mat-datepicker-toggle>
                        <mat-datepicker #receiptFrom></mat-datepicker>
                      </div>
                      <div style="display: flex; flex-direction: column; align-items: center;">
                        <div style="margin-left: -5px; font-size: 14px; font-weight: 600; color: #ffffffd9;">
                          From Date                      
                        </div>
                        <div>
                          <input matInput [(ngModel)]="bankingFrom" name="receiptFrom" [matDatepicker]="receiptFrom" (dateChange)="changeDate('fromDate', $event)" class="dailyDatePick">
                        </div>
                      </div>
                    </div>

                    <div style="display: flex; margin-left: 50px;">
                      <div>
                        <mat-datepicker-toggle matSuffix [for]="receiptTo" style="color: black;"></mat-datepicker-toggle>
                        <mat-datepicker #receiptTo></mat-datepicker>
                      </div>
                      <div style="display: flex; flex-direction: column; align-items: center;">
                        <div style="margin-left: -13px; font-size: 14px; font-weight: 600; color: #ffffffd9;">
                          To Date                      
                        </div>
                        <div>
                          <input matInput [(ngModel)]="bankingTo" name="receiptTo" [matDatepicker]="receiptTo" (dateChange)="changeDate('toDate', $event)" class="dailyDatePick">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <span *ngIf="bankingReportData.data.length > 0">
                    <button mat-icon-button style="vertical-align: middle; transform: scale(0.7); float: right; background-color: #BABABA; margin-right: 10px;" (click)="exportToExcel('bankingReport')">
                      <svg-icon src="assets/icons/xlsx-file-format-extension.svg" [svgStyle]="{'width.px':40,'height.px':40,'vertical-align':'middle','margin-top.px':'0','fill':'rgb(0, 0, 0)'}"></svg-icon>
                    </button>
                  </span>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionDailyBanking == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div fxLayout="row" fxLayoutAlign="space-around start">
                  <div style="width: 75%; display: inline-block;">
                    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="bankingReportData">

                      <ng-container matColumnDef="bookingReference">
                        <mat-header-cell *matHeaderCellDef> Booking Reference </mat-header-cell>
                        <mat-cell *matCellDef="let report"><a (click)="goToBooking(report[0], 'normal')" style="font-family: Azeret Mono;">{{ report[0] | uppercase }}</a></mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>Total</b></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="leadPax">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                        <mat-cell *matCellDef="let report" class="centerTable"> {{ report[2] }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="receiptDate">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Receipted </mat-header-cell>
                        <mat-cell *matCellDef="let report" class="centerTable"> {{ report[7] | date:'dd.MM.yyyy' }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="payerName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Payer </mat-header-cell>
                        <mat-cell *matCellDef="let report" class="centerTable"> {{ report[8] }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Method </mat-header-cell>
                        <mat-cell *matCellDef="let report" class="centerTable"> {{ report[9] }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="value">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Value </mat-header-cell>
                        <mat-cell *matCellDef="let report" class="centerTable"> {{ report[10] | currency:'GBP' }} </mat-cell>
                        <mat-footer-cell *matFooterCellDef class="centerTable"> {{ bankingReportTotalValue.value | currency:'GBP' }}</mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="ticked">
                        <mat-header-cell *matHeaderCellDef style="text-align: center !important;" class="centerTable"> Checked </mat-header-cell>
                        <mat-cell *matCellDef="let report" style="text-align: center !important;" class="centerTable">
                          <svg-icon *ngIf="report[11] == 'no'" src="assets/icons/blank-check-box.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'orange','padding.px':1,'margin.px':1,'margin-top.px':5}" (click)="checkBankingRow(report)" [hidden]="!memberLive">
                          </svg-icon>
                          <svg-icon *ngIf="report[11] == 'yes'" src="assets/icons/check-box.svg" [svgStyle]="{'width.px':30,'height.px':30,'fill':'green','padding.px':1,'margin.px':1}"></svg-icon>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef style="text-align: center !important;"></mat-footer-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="bankingColumns"></mat-header-row>
                      <mat-row class="rowNotClick" *matRowDef="let row; columns : bankingColumns;"></mat-row>
                      <mat-footer-row class="rowNotClick" *matFooterRowDef="bankingColumns"></mat-footer-row>
                    </mat-table>
                  </div>

                  <div class="apexChartZoom" style="width: 25%; display: inline-block;">
                    <apx-chart id="chartDonut" [series]="dailyBaChart.series" [chart]="dailyBaChart.chart" [fill]="dailyBaChart.fill" [labels]="dailyBaChart.labels" [dataLabels]="dailyBaChart.dataLabels" [plotOptions]="dailyBaChart.plotOptions"
                      [xaxis]="dailyBaChart.xaxis" [yaxis]="dailyBaChart.yaxis" [legend]="dailyBaChart.legend" [colors]="dailyBaChart.colors" [title]="dailyBaChart.title"></apx-chart>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionDailyBanking == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionDailyBanking = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

          </div>

          <div [@inAnimation] *ngIf="reminders">

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="expansionReminders" (click)="expansionReminders = false;">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionReminders" (click)="expansionReminders = true;">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  Balances Due
                </div>

                <span *ngIf="customerDueReportData.data.length > 0">
                  <button mat-icon-button style="vertical-align: middle; transform: scale(0.7); float: right; background-color: #BABABA; margin-right: 10px;" (click)="exportToExcel('customerDueReport')">
                    <svg-icon src="assets/icons/xlsx-file-format-extension.svg" [svgStyle]="{'width.px':40,'height.px':40,'vertical-align':'middle','margin-top.px':'0','fill':'rgb(0, 0, 0)'}"></svg-icon>
                  </button>
                </span>
              </div>

              <div [@customExpansionDetails]="expansionReminders == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div class="apexChartZoom" style="width: 97.5%; display: inline-block;">
                  <apx-chart id="chart" [series]="reminderChart.series" [chart]="reminderChart.chart" [tooltip]="reminderChart.tooltip" [title]="reminderChart.title" [yaxis]="reminderChart.yaxis" [xaxis]="reminderChart.xaxis"
                    [dataLabels]="reminderChart.dataLabels" [stroke]="reminderChart.stroke" [fill]="reminderChart.fill" [colors]="reminderChart.colors">
                  </apx-chart>
                </div>

                <mat-table class="tableWithinExpansV2" [dataSource]="customerDueReportData">

                  <ng-container matColumnDef="bookingReference">
                    <mat-header-cell *matHeaderCellDef> Booking Ref. </mat-header-cell>
                    <mat-cell *matCellDef="let report"><a (click)="goToBooking(report[0], 'reminder')" style="font-family: Azeret Mono;">{{ report[0] | uppercase }}</a></mat-cell>
                    <mat-footer-cell *matFooterCellDef><b>Total</b></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Trading Name </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['2'] }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="leadPax">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Pax </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['3'] }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="custDueDate">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Customer Due </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['4'] | date:'dd.MM.yyyy' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="suppDueDate">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Supplier Due </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['5'] | date:'dd.MM.yyyy' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="deptDate">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Departure </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['6'] | date:'dd.MM.yyyy' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="returnDate">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Return </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['7'] | date:'dd.MM.yyyy' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="gross">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Gross </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['8'] | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Discount </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['9'] | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="totalReceipted">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Receipted </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['10'] | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="balanceToOp">
                    <mat-header-cell *matHeaderCellDef class="centerTable"> Balance </mat-header-cell>
                    <mat-cell *matCellDef="let data" class="centerTable"> {{ data['11'] | currency:'GBP' }} </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="centerTable">{{ custDueTotalValue.value | currency:'GBP' }}</mat-footer-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="custDueColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns : custDueColumns;" class="rowNotClick"></mat-row>
                  <mat-footer-row class="rowNotClick" *matFooterRowDef="custDueColumns"></mat-footer-row>
                </mat-table>
              </div>

              <div [@customExpansionDetails]="expansionReminders == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionReminders = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

          </div>

          <div [@inAnimation] *ngIf="monthlyReturnView">

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft" style="width: 90%;">
                  <div style="display: flex; align-items: center; margin-right: 50px;">
                    <button type="button" mat-icon-button style="margin-left: 5px;" *ngIf="expansionMonthlySummary" (click)="expansionMonthlySummary = false;">
                      <mat-icon>expand_less</mat-icon>
                    </button>
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionMonthlySummary" (click)="expansionMonthlySummary = true;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    Monthly Return
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionMonthlySummary == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div style="display: flex; justify-content: space-around;">
                  <div style="margin-bottom: 10px; margin-top: 10px;">
                    <label for="month" style="display: inline-block; padding-right: 15px;">Month:</label>
                    <input id="month" type="number" [(ngModel)]="monthlyMonth" min="1" max="12" class="customInput">
                  </div>

                  <div style="margin-bottom: 10px; margin-top: 10px;">
                    <label for="year" style="display: inline-block; padding-right: 15px;">Year:</label>
                    <input id="year" type="number" [(ngModel)]="monthlyYear" class="customInput">
                  </div>
                </div>

                <div style="text-align: center;">
                  <button mat-stroked-button color="primary" (click)="getMonthlyReturns(currentRequest.tradeCode, true)">Search</button>
                </div>

                <div *ngIf="['sinGSAdmin', 'sinGSstaff'].includes(userType) && monthlyReturnAll.length > 0" style="margin-top: 15px; text-align: center; margin-bottom: 30px;">
                  <span>Member:</span><br>
                  <mat-select style="width: 480px; padding: 5px; border: 1px solid #ccc; border-radius: 4px; text-align: center; margin-top: 10px;" [(ngModel)]="monthlyTradeCode"
                          (selectionChange)="getMonthlyReturns($event.value, true); branchFilterStr = ''; filterBranches()" (click)="branchFilterStr = ''; filterBranches()" panelClass="monthlyBranches">
                    
                    <div class="sticky-search-container" style="margin-bottom: unset !important;">
                      <mat-form-field appearance="fill" class="filterSelectList">
                          <input placeholder="Search.." matInput name="filterInput" #filterInput="ngModel" [(ngModel)]="branchFilterStr" (keyup)="filterBranches()" autocomplete="off">
                      </mat-form-field>

                      <div style="text-align: left; display: flex; justify-content: space-around; padding: 10px; margin-top: 3px;">
                        <mat-checkbox [(ngModel)]="monthlyBoxFilter.pending" (change)="filterBranchesCheckbox('pending')">Pending Only</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="monthlyBoxFilter.approved" (change)="filterBranchesCheckbox('approved')">Approved Only</mat-checkbox>
                      </div>
                    </div>
                    
                    <mat-option value="" disabled></mat-option>
                    <mat-option *ngFor="let month of monthlyRetFiltered" [value]="month.tradeCode" [disabled]="month.status == 'New'">
                      {{ month.tradeCode }} {{ month.branchName }}
                      <span *ngIf="month.status == 'New'" style="color:#ce983a;">(Not submitted)</span>
                      <span *ngIf="month.status == 'Pending'" style="color:#ce983a;">({{ month.status }})</span>
                      <span *ngIf="month.status == 'Approved'" style="color:rgb(129,199,132);">({{ month.status }})</span>
                    </mat-option>
                  </mat-select>
                  <div style="margin-top: 15px; margin-bottom: -10px;">
                    <button mat-stroked-button color="primary"  (click)="exportToExcel('monthlyReturns')">Export All</button>
                  </div>
                </div>
                
                <div *ngIf="monthlyReturnSummary?.tradeCode" style="border: 2px solid black; width: 80%; max-width: 1000px; margin: auto; margin-top: 20px; padding: 10px; padding-bottom: 5px; margin-bottom: 30px;">
                  <div style="margin-top: 10px; margin-bottom: 30px; text-align: center;">
                    <u><b>TRAVEL TRUST ASSOCIATION<br>
                    MEMBERS CERTIFICATE OF COMPLIANCE</b></u>
                  </div>

                  <div style="text-align: center;">
                    Status: 
                    <span *ngIf="monthlyReturnSummary.status != 'Approved'" style="color: #ce983a; font-weight: bold;">{{ monthlyReturnSummary.status }}</span>
                    <span *ngIf="monthlyReturnSummary.status == 'Approved'" style="color: rgb(129,199,132); font-weight: bold;">{{ monthlyReturnSummary.status }}</span>
                  </div>

                  <div style="margin-top: 20px; margin-bottom: 20px; text-align: center;">
                    We hereby certify the following during the period of <b>{{ monthlyPeriod }}</b>; the company has operated the Trust Account correctly and in accordance with
                      the Trust Deed and the Code of Conduct during this period; all monies received from customers of the business have been paid into the Trust Account; a
                      Stand Alone Safe Seat Plan Guarantee has been issued to every passenger; and that the subsequent premiums have been collected.
                  </div>

                  <div style="margin-top: 60px; margin-bottom: 20px;">
                    Company Name & Address
                  </div>

                  <div style="margin-bottom: 40px;">
                    <div *ngIf="motnhlyReturnBranch.branchName">{{ motnhlyReturnBranch.branchName }}</div>
                    <div *ngIf="motnhlyReturnBranch.address1">{{ motnhlyReturnBranch.address1 }}</div>
                    <div *ngIf="motnhlyReturnBranch.address2">{{ motnhlyReturnBranch.address2 }}</div>
                    <div *ngIf="motnhlyReturnBranch.city">{{ motnhlyReturnBranch.city }}</div>
                    <div *ngIf="motnhlyReturnBranch.county">{{ motnhlyReturnBranch.county }}</div>
                    <div *ngIf="motnhlyReturnBranch.postcode">{{ motnhlyReturnBranch.postcode }}</div>
                    <div *ngIf="motnhlyReturnBranch.country">{{ motnhlyReturnBranch.country }}</div>
                  </div>

                  <div *ngIf="monthlyReturnSummary.status == 'New'">Date: {{ currentDate | date: 'dd.MM.yyyy' }}</div>
                  
                  <div style="margin-top: 40px; margin-bottom: 20px;">
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of New Bookings made during the period:
                      <div *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                        <span style="color: red; margin-right: 10px;" *ngIf="monthlyReportPaxNo.bookings != null">
                          <span *ngIf="monthlyReturnSummary.bookingNo != monthlyReportPaxNo.bookings">
                            <span *ngIf="monthlyReturnSummary.bookingNo - monthlyReportPaxNo.bookings > 0">
                              +</span>{{ monthlyReturnSummary.bookingNo - monthlyReportPaxNo.bookings }}
                          </span>
                        </span>
                        <input id="bookingNo" type="number" [(ngModel)]="monthlyReturnSummary.bookingNo" step="1" class="customInput" (change)="recalcMonthlyReport()">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">
                        {{ monthlyReturnSummary.bookingNo }}
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for All bookings made during the period:
                      <div *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                        <span style="color: red; margin-right: 10px;" *ngIf="monthlyReportPaxNo.paxNo != null">
                          <span *ngIf="monthlyReturnSummary.paxNo != monthlyReportPaxNo.paxNo">
                            <span *ngIf="monthlyReturnSummary.paxNo - monthlyReportPaxNo.paxNo > 0">
                              +</span>{{ monthlyReturnSummary.paxNo - monthlyReportPaxNo.paxNo }}
                          </span>
                        </span>
                        <input id="paxNo" type="number" [(ngModel)]="monthlyReturnSummary.paxNo" step="1" class="customInput" (change)="recalcMonthlyReport()">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">
                        {{ monthlyReturnSummary.paxNo }}
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for Flight Based Package bookings made under your T-ATOL:
                      <div *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                        <span style="color: red; margin-right: 10px;" *ngIf="monthlyReportPaxNo.paxNoTAtolPackage != null">
                          <span *ngIf="monthlyReturnSummary.tAtolPaxPkg != monthlyReportPaxNo.paxNoTAtolPackage">
                            <span *ngIf="monthlyReturnSummary.tAtolPaxPkg - monthlyReportPaxNo.paxNoTAtolPackage > 0">
                              +</span>{{ monthlyReturnSummary.tAtolPaxPkg - monthlyReportPaxNo.paxNoTAtolPackage }}
                          </span>
                        </span>
                        <input id="tAtolPaxPkg" type="number" [(ngModel)]="monthlyReturnSummary.tAtolPaxPkg" step="1" class="customInput"
                              [disabled]="motnhlyReturnBranch.isTAtol == 'no'" (change)="recalcMonthlyReport()">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">
                        {{ monthlyReturnSummary.tAtolPaxPkg }}
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
                      Number of Passengers for Flight Only bookings made under your T-ATOL:
                      <div *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                        <span style="color: red; margin-right: 10px;" *ngIf="monthlyReportPaxNo.paxNoTAtolFlOnly != null">
                          <span *ngIf="monthlyReturnSummary.tAtolPaxFlOnly != monthlyReportPaxNo.paxNoTAtolFlOnly">
                            <span *ngIf="monthlyReturnSummary.tAtolPaxFlOnly - monthlyReportPaxNo.paxNoTAtolFlOnly > 0">
                              +</span>{{ monthlyReturnSummary.tAtolPaxFlOnly - monthlyReportPaxNo.paxNoTAtolFlOnly }}
                          </span>
                        </span>
                        <input id="tAtolPaxFlOnly" type="number" [(ngModel)]="monthlyReturnSummary.tAtolPaxFlOnly" step="1" class="customInput"
                              [disabled]="motnhlyReturnBranch.isTAtol == 'no'" (change)="recalcMonthlyReport()">
                      </div>
                      <div *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">
                        {{ monthlyReturnSummary.tAtolPaxFlOnly }}
                      </div>
                    </div>
                  </div>

                  <div style="margin-top: 30px; margin-bottom: 10px;" *ngIf="monthlyReturnSummary.status != 'New'">
                    <span *ngIf="monthlyReturnSummary.status != 'New'">
                      Signed By: {{ monthlyReturnSummary.signeeName }}<br>
                      Date: {{ monthlyReturnSummary.signedTS | date: 'dd.MM.yyyy hh:mm a' }}
                    </span>

                    <span *ngIf="monthlyReturnSummary.status == 'Approved'">
                      <br><br>Approved By: {{ monthlyReturnSummary.approvedName }}<br>
                      Date: {{ monthlyReturnSummary.approvedTS | date: 'dd.MM.yyyy hh:mm a' }}
                    </span>
                  </div>

                  <div style="padding: 2px; border-bottom: 2px solid black; border-left: 2px solid black; border-right: 2px solid black;"
                        *ngIf="(monthlyReturnSummary.status == 'New' || userType != 'memberManager') && memberLive">
                    <div style="background: black; margin: -2px; padding: 2px; color: white;">Electronic Signature</div>

                    <form ngNativeValidate #authForm="ngForm" (ngSubmit)="authenticate(authForm)">
                      <div style="margin-bottom: 20px; margin-top: 20px; display: flex; flex-direction: row; justify-content: space-around; align-items: center;">
                        <div>Email:
                          <input id="email" required class="customInput" ngModel name="email" #email="ngModel">
                        </div>
                        <div>Password:
                          <input id="password" type="password" required class="customInput" ngModel name="password" #password="ngModel">
                        </div>
                        <div>
                          <button mat-stroked-button color="primary">Submit</button>
                        </div>
                      </div>

                      <div *ngIf="monthlySubmitDisabled" style="margin-top: 20px; color: red; text-align: center; font-size: 15px; height: 45.17px;">
                        Please ensure that all numbers above match the totals in the Monthly Return Breakdown at the bottom, if applicable.
                      </div>
                    </form>

                    <div style="margin-bottom: 10px;">
                      <b>Note: I have read the Compliance Certificate and have entered the correct information on to the certificate.</b>
                    </div>

                    <div style="margin-bottom: 10px;">
                      <b>Please enter your credentials once this has been submitted you will not be allowed to make any modifications.<br>
                        If you have made an error please email <a href="mailto:Operations@thetravelnetworkgroup.co.uk">Operations@thetravelnetworkgroup.co.uk</a></b>
                    </div>
                  </div>
                </div>

                <div *ngIf="!monthlyReturnSummary?.tradeCode && userType == 'memberManager'" style="text-align: center;">
                  <h3>Monthly return for this period is not available</h3>
                </div>

                <div *ngIf="!monthlyReturnSummary?.tradeCode && monthlyReturnAll.length > 0 && userType != 'memberManager'" style="text-align: center;">
                  <h3>Please select the member and press the Search button</h3>
                </div>

                <div *ngIf="!monthlyReturnSummary?.tradeCode && monthlyReturnAll.length == 0 && userType != 'memberManager'" style="text-align: center;">
                  <h3>There are no monthly returns for this period</h3>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionMonthlySummary == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionMonthlySummary = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

            <div style="width: 100%; margin-bottom: 30px;">
              <div class="ownExpansionHeader">
                <div class="headerTopLeft">
                  <div style="display: flex; align-items: center; margin-right: 50px;">
                    <button type="button" mat-icon-button style="margin-left: 5px;" *ngIf="expansionMonthlyBreakdown" (click)="expansionMonthlyBreakdown = false;">
                      <mat-icon>expand_less</mat-icon>
                    </button>
                    <button type="button" mat-icon-button style="margin-right: 5px;" *ngIf="!expansionMonthlyBreakdown" (click)="expansionMonthlyBreakdown = true;">
                      <mat-icon>expand_more</mat-icon>
                    </button>
                    Monthly Return Breakdown
                  </div>
                </div>
                <mat-paginator #paginatorReturns [pageSize]="20" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
              </div>

              <div [@customExpansionDetails]="expansionMonthlyBreakdown == true ? 'expanded' : 'collapsed'" class="ownExpansionBody">
                <div>
                  <div>
                    <mat-table class="tableWithinExpansV2" multiTemplateDataRows [dataSource]="monthlyReturnBreakdown" *ngIf="expansionMonthlyBreakdown">
                      <ng-container matColumnDef="bookingRefMonthly">
                        <mat-header-cell *matHeaderCellDef>
                          <span class="clickBkgRef" (click)="showExtRefSwitch()" [matTooltip]="!showExtReference ? 'Switch to your references' : 'Switch to Sings references'">
                            Booking Reference
                          </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">
                          <a (click)="goToBooking(data.bookingReference, 'overview')" style="font-family: Azeret Mono;">
                            <span *ngIf="!showExtReference">{{ data.bookingReference | uppercase }}</span>
                            <span *ngIf="showExtReference">{{ data.extBookingSource }}</span>
                          </a>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef><b>Total</b></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="leadName">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Lead Name </mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centerTable">{{ data.leadName }}</mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="paxNo">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Pax. No. </mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centerTable">
                          <span *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">{{ data.paxNoSbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                            <input id="bookingNo" type="number" [(ngModel)]="data.paxNoSbmt" step="1" (change)="recalcMonthlyReport()" class="customInput">
                            <span *ngIf="data.paxNoSbmt != data.paxNo" style="color: red; margin-left: 15px;">{{ data.paxNo }}</span>
                          </span>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef class="centerTable">{{ monthlyReportPaxNo.paxNo }}</mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="paxNoTatolPackage">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> T-ATOL (Pkg) </mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centerTable">
                          <span *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">{{ data.tAtolPaxPkgSbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                            <input id="bookingNo" type="number" [(ngModel)]="data.tAtolPaxPkgSbmt" step="1" (change)="recalcMonthlyReport()" class="customInput" [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                            <span *ngIf="data.tAtolPaxPkgSbmt != data.tAtolPaxPkg" style="color: red; margin-left: 15px;">{{ data.tAtolPaxPkg }}</span>
                          </span>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef class="centerTable">{{ monthlyReportPaxNo.paxNoTAtolPackage }}</mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="paxNoTatolFlOnly">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> T-ATOL (FO) </mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centerTable">
                          <span *ngIf="monthlyReturnSummary.status != 'New' && userType == 'memberManager'">{{ data.tAtolPaxFlOnlySbmt }}</span>
                          <span *ngIf="monthlyReturnSummary.status == 'New' || userType != 'memberManager'">
                            <input id="bookingNo" type="number" [(ngModel)]="data.tAtolPaxFlOnlySbmt" step="1" (change)="recalcMonthlyReport()" class="customInput" [disabled]="motnhlyReturnBranch.isTAtol == 'no'">
                            <span *ngIf="data.tAtolPaxFlOnlySbmt != data.tAtolPaxFlOnly" style="color: red; margin-left: 15px;">{{ data.tAtolPaxFlOnly }}</span>
                          </span>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef class="centerTable">{{ monthlyReportPaxNo.paxNoTAtolFlOnly }}</mat-footer-cell>
                      </ng-container>

                      <ng-container matColumnDef="bookedTS">
                        <mat-header-cell *matHeaderCellDef class="centerTable"> Booked </mat-header-cell>
                        <mat-cell *matCellDef="let data" class="centerTable">{{ data.bookedTS | date : "dd.MM.y hh:mm a" }}</mat-cell>
                        <mat-footer-cell *matFooterCellDef class="centerTable">{{ monthlyReportPaxNo.bookings }}</mat-footer-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="monthlyRetColumns"></mat-header-row>
                      <mat-row class="rowNotClick" *matRowDef="let row; columns : monthlyRetColumns;"></mat-row>
                      <mat-footer-row class="rowNotClick" *matFooterRowDef="monthlyRetColumns"></mat-footer-row>
                    </mat-table>
                  </div>
                </div>
              </div>

              <div [@customExpansionDetails]="expansionMonthlyBreakdown == false ? 'expanded' : 'collapsed'" class="ownExpansionBodyEmpty" (click)="expansionMonthlyBreakdown = true;">
                <mat-icon>expand_more</mat-icon>
              </div>
            </div>

          </div>
        </div>

        <div class="accessDenied" *ngIf="!haveAccess && pageLoaded">
          <mat-error>
            <h2 style="text-align: center; margin-top: 2.5%;">Access denied</h2>
          </mat-error>
        </div>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</mat-card-content>

<ng-template #myDialog>
  <div *ngIf="errorMessage != ''" class="errorMessage">
    <p>{{errorMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>

  <div *ngIf="successMessage != ''" class="successMessage">
    <p>{{successMessage}}</p>
    <p style="margin: auto; text-align: center;"><button mat-raised-button color="primary" matDialogClose>OK</button></p>
  </div>
</ng-template>
